import Resizer from "react-image-file-resizer";

export const resizeImage = (file, sizePx = 800) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      sizePx,
      sizePx,
      file?.name?.split('.').pop().toLowerCase() === "png" ? "PNG" : file?.name?.split('.').pop().toLowerCase() === "webp" ? "WEBP" : "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
});