import React, { Fragment, useRef, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { getImageUrl } from "../../../utils";
import { resizeImage } from "../../../utils/resize-image";
import { UPLOAD_IMAGE_ACCEPT_FILETYPES } from "../../../config";

const GET_LISTS_QUERY = gql`
    query GetLists {
        LearningCategoryList(orderBy: { name: ASC }) {
            id
            name
        }
        LearningAuthorList(orderBy: { name: ASC }) {
            id
            name
        }
        LearningSourceList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_COURSE_MUTATION = gql`
    mutation Upsert($id: UUID, $title: String, $description: String, $thumbnail: Upload, $category: inp_LearningCategory, $source: inp_LearningSource, $author: inp_LearningAuthor, $url: String, $language: inp_Language_, $isVisible: Boolean, $duration: Int, $isPaid: Boolean, $hasCertificate: Boolean) {
        upsert_LearningCourse(learningCourse: { id: $id, title: $title, description: $description, thumbnail: $thumbnail, category: $category, source: $source, author: $author, url: $url, language: $language, isVisible: $isVisible, duration: $duration, isPaid: $isPaid, hasCertificate: $hasCertificate }) {
            id
        }
    }
`;

const UPSERT_VIDEO_MUTATION = gql`
    mutation Upsert($id: UUID, $title: String, $description: String, $thumbnail: Upload, $category: inp_LearningCategory, $source: inp_LearningSource, $author: inp_LearningAuthor, $url: String, $language: inp_Language_, $isVisible: Boolean, $duration: Int) {
        upsert_LearningVideo(learningVideo: { id: $id, title: $title, description: $description, thumbnail: $thumbnail, category: $category, source: $source, author: $author, url: $url, language: $language, isVisible: $isVisible, duration: $duration }) {
            id
        }
    }
`;

const UPSERT_PODCAST_MUTATION = gql`
    mutation Upsert($id: UUID, $title: String, $description: String, $thumbnail: Upload, $category: inp_LearningCategory, $source: inp_LearningSource, $author: inp_LearningAuthor, $url: String, $language: inp_Language_, $isVisible: Boolean, $duration: Int) {
        upsert_LearningPodcast(learningPodcast: { id: $id, title: $title, description: $description, thumbnail: $thumbnail, category: $category, source: $source, author: $author, url: $url, language: $language, isVisible: $isVisible, duration: $duration }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        LearningCourseById(id: $id) {
            id
            title
            description
            url
            thumbnail
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            language {
                id
                name
            }
            isVisible
            duration
            isPaid
            hasCertificate
        }
        LearningVideoById(id: $id) {
            id
            title
            description
            url
            thumbnail
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            language {
                id
                name
            }
            isVisible
            duration
        }
        LearningPodcastById(id: $id) {
            id
            title
            description
            url
            thumbnail
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            language {
                id
                name
            }
            isVisible
            duration
        }
    }
`;

const GET_METADATA_QUERY = gql`
    query Get($url: String!) {
        OpenGraphMetadata(url: $url) {
            title
            description
            image {
                path
                fileName
                storageName
            }
            type
            duration
        }
    }
`;

function AdminNewLearningItem() {

    const navigate = useNavigate();
    
    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null) 

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();
    const watchType = watch("type")
    const watchUrl = watch("url")

    const [upsertCourseMutation, { upsertCourseMutationData }] = useMutation(UPSERT_COURSE_MUTATION);
    const [upsertVideoMutation, { upsertVideoMutationData }] = useMutation(UPSERT_VIDEO_MUTATION);
    const [upsertPodcastMutation, { upsertPodcastMutationData }] = useMutation(UPSERT_PODCAST_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const [ getMetadata, { data: metadataData, loading: metadataLoading, error: metadataError } ] = useLazyQuery(GET_METADATA_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset({
            type: editData?.LearningCourseById ? 'course' : editData?.LearningVideoById ? 'video' : editData?.LearningPodcastById ? 'podcast' : null,
            ...(editData?.LearningCourseById || editData?.LearningVideoById || editData?.LearningPodcastById)
        });
    }, [editData, listsData]);

    useEffect(() => {
        console.log(metadataData)
        if(!editData && metadataData && metadataData.OpenGraphMetadata && metadataData.OpenGraphMetadata.title) {
            const metadata = {
                type: watchType,
                url: watchUrl,
                title: metadataData.OpenGraphMetadata.title,
                description: metadataData.OpenGraphMetadata.description,
                thumbnail: metadataData.OpenGraphMetadata.image,
                duration: metadataData.OpenGraphMetadata.duration,
            }
            reset(metadata);
        }
    }, [metadataData]);

    const upsert = async (formData) => {
        // optional select fields
        if(!formData?.category?.id || formData?.category?.id === '') {
            formData.category = null
        }

        if(!formData?.source?.id || formData?.source?.id === '') {
            formData.source = null
        }

        if(!formData?.author?.id || formData?.author?.id === '') {
            formData.author = null
        }

        const fileVariable = {}
        if(selectedFile) {
            fileVariable.thumbnail = selectedFile
        }

        console.log(formData)
        console.log(`${formData?.thumbnail.storageName}://${formData?.thumbnail.path}`)
        formData.thumbnail = selectedFile || editData?.LearningCourseById?.thumbnail || editData?.LearningVideoById?.thumbnail || editData?.LearningPodcastById?.thumbnail || `${formData?.thumbnail?.storageName}://${formData?.thumbnail?.path}?name=${formData?.thumbnail?.fileName}`;

        let upsertResult = null;

        if(formData.type === 'video') {
            upsertResult = await upsertVideoMutation({ 
                variables: {
                    id: id,
                    ...formData,
                    ...fileVariable
                },
            });
        } else if(formData.type === 'course') {
            upsertResult = await upsertCourseMutation({ 
                variables: {
                    id: id,
                    ...formData,
                    thumbnail: selectedFile
                },
            });
        } else if(formData.type === 'podcast') {
            upsertResult = await upsertPodcastMutation({ 
                variables: {
                    id: id,
                    ...formData,
                    thumbnail: selectedFile
                },
            });
        }

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/learning`)
        }
    }

    const onFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFile(file)
    };

    const currentThumbnail = editData?.LearningCourseById?.thumbnail
                                || editData?.LearningVideoById?.thumbnail
                                || editData?.LearningPodcastById?.thumbnail
                                || (metadataData?.OpenGraphMetadata?.image?.storageName + '://' + metadataData?.OpenGraphMetadata?.image?.path + '?name=' + metadataData?.OpenGraphMetadata?.image?.fileName);
    
    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Learning</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        
                                        <label className="mont-font fw-600 font-xsss">Tipo</label>
                                        <div className="card border-0 mb-3">
                                            <div className="payment-group">
                                                <div className="payment-radio">
                                                    <input type="radio" value="video" {...register("type", { required: true })} />
                                                    <label className="payment-label font-xsss text-grey-900 ms-2 me-4">Vídeo</label>
                                                
                                                    <input type="radio" value="course" {...register("type", { required: true })} />
                                                    <label className="payment-label font-xsss text-grey-900 ms-2 me-4">Curso</label>

                                                    <input type="radio" value="podcast" {...register("type", { required: true })} />
                                                    <label className="payment-label font-xsss text-grey-900 ms-2">Podcast</label>
                                                </div>
                                            </div>
                                            {errors.type && <span className="text-red">Este campo é obrigatório</span>}
                                        </div>
                                    
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">URL</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("url", {
                                                        required: true,
                                                        onBlur: (e) => {
                                                            const url = e.target.value;
                                                            if(!editData) {
                                                                getMetadata({ variables: { url } })
                                                            }
                                                        }
                                                    })} />
                                                    {errors.url && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("title", { required: true })} />
                                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-3 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("description")} />
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>                                     
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("category.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.LearningCategoryList && listsData.LearningCategoryList.map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.category?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Fonte</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("source.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.LearningSourceList && listsData.LearningSourceList.map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.source?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Autor</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("author.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.LearningAuthorList && listsData.LearningAuthorList.map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.author?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Duração (minutos)</label>
                                                    <input type="number" min={0} step={1} className="form-control" autoComplete="off" {...register("duration")} />
                                                    {errors.duration && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            { watchType === 'course' &&
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss" htmlFor="isPaid">Pago</label><br />
                                                    <input type="checkbox" className="form-check-input" id="isPaid" {...register("isPaid")} />
                                                    {errors.isPaid && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            }
                                            { watchType === 'course' &&
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss" htmlFor="hasCertificate">Tem certificado</label><br />
                                                    <input type="checkbox" className="form-check-input" id="hasCertificate" {...register("hasCertificate")} />
                                                    {errors.hasCertificate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isVisible" {...register("isVisible")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isVisible">Publicado?</label>
                                                    {errors.isVisible && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Imagem</label>
                                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!currentThumbnail || selectedFile) && <>
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar uma imagem' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { currentThumbnail && !selectedFile && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFile.current.click()}><img src={getImageUrl(currentThumbnail)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewLearningItem;