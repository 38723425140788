import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { getProfilePicture } from '../utils';
import { REACTION_OPTIONS } from '../config';

const GET_QUERY = gql`
    query Get($id: String!) {
        PostById(id: $id) {
            id
            reactions {
                id
                type
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        picture
                    }
                }
            }
        }
    }
`;

function PostReactions({ id, onHide }) {

    const navigate = useNavigate();

    const { loading, error, data } = useQuery(GET_QUERY, { variables: { id } });
    if (loading) return <LoadingOverlayWrapper/>;

    const reactions = data?.PostById?.reactions;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="section full pe-3 ps-4 pt-4 position-relative feed-body">
                <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">Reações</h4>
                <div class="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer"><i class="ti-close text-grey-900 mt-2 d-inline-block" onClick={() => {onHide && onHide()}}></i></div>
                
                <ul className="list-group list-group-flush">

                    {reactions.map((value , index) => (
                        <li key={`reaction-${index}`} className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center pointer" onClick={() => navigate(`/employees/${value?.user?.employee?.id}`)}>
                            <figure className="avatar float-left mb-0 me-2">
                                <img src={getProfilePicture(value.user)} alt={value?.user?.name} className="w35 rounded-circle aspect-ratio-1" />
                            </figure>
                            <h3 className="fw-700 mb-0 mt-0">
                                <span className="font-xssss text-grey-600 d-block text-dark model-popup-chat">{value?.user?.name}</span>
                            </h3>
                            <span className="badge text-white badge-pill fw-500 mt-0" style={{top: 0, fontSize: '16px'}}>
                                <i className={REACTION_OPTIONS.filter(e => e.id === value.type)[0]?.icon}></i> 
                            </span>
                        </li>
                    ))}
                    
                </ul>
            </div>

            {!loading && reactions.length === 0 && <span className="d-block font-xssss fw-500 p-2 mb-3 text-grey-500 text-center">Ainda não existem reações a esta publicação.</span>}

            
        </div>
    );
}

export default PostReactions;