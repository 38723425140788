import React,{Fragment, useEffect, useState} from 'react';
import { Link , NavLink } from 'react-router-dom';

import { useQuery, useLazyQuery, gql, useMutation } from '@apollo/client';

import { config } from '../config';
import { Modal } from 'react-bootstrap';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import WYSIWYGEditor from "./WYSIWYGEditor";

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $title: String!, $content: String, $isPinned: Boolean, $allowComments: Boolean) {
        upsert_Post(post: { id: $id, title: $title, content: $content, isPinned: $isPinned, allowComments: $allowComments }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        PostById(id: $id) {
            id
            title
            content
            isPinned
            allowComments
        }
    }
`;

function EditPostModal(props) {

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const [ getPost, {data: editData, loading: editLoading, error: editError} ] = useLazyQuery(GET_QUERY);

    useEffect(() => {
        if(props.show) {
            getPost({ variables: { id: props.id } })
        }
    }, [props.show])

    useEffect(() => {
        reset(editData?.PostById);
    }, [editData]);

    const upsert = async (formData) => {
        // optional select fields
        //formData.thumbnail = shouldRemoveFile ? null : selectedFile || editData?.thumbnail;

        const upsertResult = await upsertMutation({ 
            variables: {
                id: props.id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            props.onHide && props.onHide()
        }
    }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        contentClassName="shadow-xss rounded-xxl border-0"
      >
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
                    Editar Publicação
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body w-100 border-0 pt-0">
                    <form onSubmit={handleSubmit(upsert)}>
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                    <input type="text" className="form-control" autoComplete="off" {...register("title")} />
                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="form-group">
                                    <label className="mont-font fw-600 font-xsss">Conteúdo</label>
                                    <Controller
                                        render={({ field }) => <WYSIWYGEditor {...field} editorStyle={{minHeight: '100px', maxHeight: '400px', border: '2px #eee solid'}} />}
                                        name="content"
                                        control={control}
                                        //defaultValue=""
                                        /*rules={{
                                            validate: {
                                            required: (v) =>
                                                (v && stripHtml(v).result.length > 0) ||
                                                "Description is required",
                                            maxLength: (v) =>
                                                (v && stripHtml(v).result.length <= 2000) ||
                                                "Maximum character limit is 2000",
                                            },
                                        }}*/
                                    />
                                    {errors.content && <span className="text-red">Verifique este campo</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="form-group">
                                    <input type="checkbox" className="form-check-input" id="isPinned" {...register("isPinned")} />
                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isPinned">Afixar Publicação</label>
                                    {errors.isPinned && <span className="text-red">Verifique este campo</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="form-group">
                                    <input type="checkbox" className="form-check-input" id="allowComments" {...register("allowComments")} />
                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="allowComments">Permitir Comentários</label>
                                    {errors.allowComments && <span className="text-red">Verifique este campo</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mb-0">
                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </Modal.Body>
        </Fragment>
      </Modal>
    );
}

export default EditPostModal;