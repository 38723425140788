import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { getImageUrl } from "../../../../utils";
import { RECRUITMENT_JOB_APPLICATION_STATUS } from "../../../../config";


const GET_QUERY = gql`
    query Get {
        JobApplicationExternalList(orderBy: { createdDate: DESC }) {
            id
            jobOpportunity {
                id
                title
                referenceCode
            }
            motivation
            jobApplicant {
                id
                name
                email
                phone
                birthdate
            }
            status
            createdDate
            attachments {
                id
                file
                name
            }
        }
        JobApplicationInternalList(orderBy: { createdDate: DESC }) {
            id
            jobOpportunity {
                id
                title
                referenceCode
            }
            motivation
            employee {
                id
                name
                fullName
            }
            status
            createdDate
            attachments {
                id
                file
                name
            }
        }
    }
`;

const KanbanColumn = ({title, className, cards}) => {
    return (
        <div className="col-lg-2 col-xl-2 col-md-2 mb-2 mt-2 pe-2">
            <div className="card p-0 bg-white rounded-3 shadow-xs border-0">
                <div class={`card-body p-3 border-top-lg border-size-lg ${className} p-0`}>
                    <h4><span className="font-xsss fw-700 text-grey-900 mt-2 d-inline-block text-dark">{title} ({cards.length}) </span></h4>
                </div>
                {cards.map(c => <KanbanCard jobApplication={c} />)}
            </div>
        </div>
    )
}

const KanbanCard = ({ jobApplication }) => {
    return (
        <div className="card-body p-3 bg-lightblue theme-dark-bg mt-0 mb-3 ms-3 me-3 rounded-3">
            <h4 className="font-xsss fw-700 text-grey-900 mb-2 mt-1 d-block"><Link to={`/admin/recruitment/jobApplications/${jobApplication.id}`}>{jobApplication.jobApplicant?.name || jobApplication.employee?.name}</Link></h4>
            <p className="font-xssss lh-24 fw-500 text-grey-500 mt-3 d-block mb-3"><Moment format={"DD/MM/YYYY"}>{jobApplication.createdDate}</Moment></p>
            <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 alert-info d-inline-block text-info">{jobApplication.jobOpportunity.title}</span>
            {jobApplication.employee && <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 alert-success d-inline-block text-success me-1">Interna</span>}
            {jobApplication.jobApplicant && <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 alert-warning d-inline-block text-warning me-1">Externa</span>}
        </div>
    )
}


function AdminJobApplicationList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const results = (data?.JobApplicationExternalList || []).concat(data?.JobApplicationInternalList || []);

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Candidaturas Ativas"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/recruitment/jobOpportunities" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Vagas</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <CustomLoadingOverlay active={loading} />
                                    
                                    <div className="row">
                                        {RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.displayOrder >= 0).map(s => (
                                            <KanbanColumn title={s.name} className={s.kanbanClassName} cards={results.filter(r => r.status === s.id)} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminJobApplicationList;