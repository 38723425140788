import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import WYSIWYGEditor from "../../../components/WYSIWYGEditor";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import moment from "moment";

const GET_LISTS_QUERY = gql`
    query GetLists {
        DepartmentList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $name: String!, $department: inp_Department, $location: String, $startTime: LocalTime!, $endTime: LocalTime!, $isActive: Boolean!) {
        upsert_Shift(shift: { id: $id, name: $name, department: $department, location: $location, startTime: $startTime, endTime: $endTime, isActive: $isActive }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        ShiftById(id: $id) {
            id
            name
            location
            department {
                id
                name
            }
            startTime
            endTime
            isActive
        }
    }
`;

function AdminNewShift() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null)

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);

    useEffect(() => {
        reset(editData?.ShiftById);
    }, [editData, listsData]);

    const upsert = async (formData) => {
        if(!formData?.department?.id || formData?.department?.id === '') {
            formData.department = null
        }
        
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/shifts`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Turno</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Departamento</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("department.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData?.DepartmentList?.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.department?.id && <span className="text-red">Verifique este campo</span>}
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Localização</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("location")} />
                                                    {errors.location && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Hora de Início</label>
                                                    <input type="time" className="form-control" autoComplete="off" {...register("startTime")} />
                                                    {errors.startTime && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Hora de Fim</label>
                                                    <input type="time" className="form-control" autoComplete="off" {...register("endTime")} />
                                                    {errors.endTime && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isActive" {...register("isActive")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isActive">Ativo?</label>
                                                    {errors.isActive && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewShift;