import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from "../../components/Appfooter";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($fetchEmployee: Boolean! $id: String!) {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: {isActive: {_eq: true} }, orderBy: { name: ASC }) {
            id
            name
            jobTitle
            user {
                id
                picture
            }
        }
        EmployeeById(id: $id) @include(if: $fetchEmployee)  {
            id
            name
            birthdate
            email
            jobTitle
            user {
                picture
            }
        }
    }
`;

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_FEEDBACK_REQUEST_MUTATION = gql`
    mutation UpsertFeedbackRequest($requesterId: UUID!, $recipientId: UUID!, $message: String) {
        upsert_FeedbackRequest(feedbackRequest: { requester: { id: $requesterId }, recipient: { id: $recipientId }, message: $message }) {
            id
        }
    }
`;

function GiveFeedback() {

    const navigate = useNavigate();

    const { employeeId } = useParams();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertFeedbackRequestMutation, { upsertFeedbackRequestMutationData }] = useMutation(UPSERT_FEEDBACK_REQUEST_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    
    const { loading, error, data } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: employeeId || "00000000-0000-0000-0000-000000000000", fetchEmployee: employeeId !== null }});
    
    const requestFeedback = async (formData) => {
        const upsertResult = await upsertFeedbackRequestMutation({ 
            variables: {
                requesterId: viewerData.Viewer.employee.id,
                recipientId: employeeId || formData.receiverId,
                message: formData.message,
            },
        });

        if(upsertResult.data) {
            toast.success('Pedido enviado!');
            navigate(`/employees/${employeeId || formData.receiverId}`)
        }
    }

    const selectedEmployee = data?.EmployeeById || {}
    const employeeList = data?.EmployeeList || []

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                { (loading || viewerData) && <LoadingOverlayWrapper /> }

                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Pedir Feedback</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(requestFeedback)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                    Nesta área podes pedir feedback aos teus colegas, sobre um determinado projeto ou coooperação, de forma simples, direta e digitial.
                                                    <br />
                                                    O feedback é importante para o crescimento profissional, pessoal e permite criar um ambiente de confiança.
                                                    <br /><br />
                                                    Quando tiveres uma resposta a este pedido, serás o único a ter acesso à mensagem de feedback.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xssss">Feedback de quem?</label>
                                                    { employeeId && <input type="text" className="form-control" defaultValue={selectedEmployee.name}  {...register("receiverId")} readOnly /> }
                                                    { !employeeId && 
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("receiverId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            employeeList
                                                            .filter(e => viewerData && e.id !== viewerData?.Viewer?.employee?.id)
                                                            .map((employee) => {
                                                                return (
                                                                    <option key={employee.id} value={employee.id}>{employee.name} {employee.jobTitle && `(${employee.jobTitle})`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    }
                                                    {errors.receiverId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mt-3 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xssss">Mensagem</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("message", { required: true })} />
                                                    {errors.message && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>                                     
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(requestFeedback)}>Enviar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default GiveFeedback;