import React, { useState, useReducer, useEffect } from "react";

import ShopContext from "./shop-context";
import { shopReducer, initializer, ADD_PRODUCT, REMOVE_PRODUCT, CLEAR_CART } from "./reducers";
import { toast } from "react-toastify";

const GlobalState = props => {
  const [cartState, dispatch] = useReducer(shopReducer, { cart: [] }, initializer);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartState));
  }, [cartState]);

  const addProductToCart = product => {
    setTimeout(() => {
      // setCart(updatedCart);
      dispatch({ type: ADD_PRODUCT, product: product });
      toast.success("Produto adicionado ao carrinho");
    }, 700);
  };

  const removeProductFromCart = productId => {
    setTimeout(() => {
      // setCart(updatedCart);
      dispatch({ type: REMOVE_PRODUCT, productId: productId });
      toast.success("Produto removido do carrinho");
    }, 700);
  };

  const clearCart = () => {
    setTimeout(() => {
      // setCart(updatedCart);
      dispatch({ type: CLEAR_CART });
      //toast.success("Carrinho atualizado");
    }, 700);
  };

  return (
    <ShopContext.Provider
      value={{
        cart: cartState.cart,
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        clearCart: clearCart,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

export default GlobalState;
