import React, { Fragment } from "react";

import { useQuery, gql } from '@apollo/client';

import { useParams } from "react-router";

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';

const GET_STATIC_PAGE_QUERY = gql`
    query Get($path: String!) {
        StaticPageList(
            filter: { 
                AND: [ 
                    { path: {_eq: $path} }, 
                    { component: {_eq: APP_FRONTEND} } 
                ]
            } 
            limit: 1
        ) {
            id
            path
            title
            headline
            content
            createdDate
            lastModifiedDate
        }
    }
`;
function StaticPage() {

    const { path } = useParams();

    const { data, loading, error } = useQuery(GET_STATIC_PAGE_QUERY, { variables: { path: path }});

    const staticPage = data?.StaticPageList?.length > 0 && data?.StaticPageList[0];

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row justify-content-center">
                            <CustomLoadingOverlay active={loading}>
                            <div className="col-xl-10">
                                <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center" 
                                style={{backgroundImage: `url("/assets/images/help-bg.png")`}}>
                                    <div className="card-body p-md-5 p-4 text-center bg-primary-gradiant">
                                        <h2 className="fw-700 display2-size text-white display2-md-size lh-2">{staticPage.title}</h2>
                                        {staticPage.headline && <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">{staticPage.headline}</p>}
                                    </div>
                                </div>
                                <div className="card w-100 border-0 shadow-none bg-transparent mt-5" dangerouslySetInnerHTML={{__html: staticPage.content}} />
                            </div>
                            </CustomLoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>            
    );
}

export default StaticPage;