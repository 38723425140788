import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Moment from "react-moment";
import { calculateAge, getAttachmentUrl, getProfilePicture } from "../../../../utils";
import { RECRUITMENT_JOB_APPLICATION_STATUS } from "../../../../config";

const GET_QUERY = gql`
    query Get($id: String!) {
        JobApplicantById(id: $id) {
            id
            name
            email
            phone
            birthdate
            applications {
                id
                jobOpportunity {
                    id
                    title
                }
                status
                createdDate
            }
        }
    }
`;

function AdminJobApplicant() {

    let { id } = useParams();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const jobApplicant = data?.JobApplicantById;

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Candidato</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{jobApplicant?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplicant?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplicant?.email}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telefone
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplicant?.phone}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Nascimento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplicant?.birthdate && <><Moment format="DD/MM/YYYY">{jobApplicant?.birthdate}</Moment> ({calculateAge(new Date(jobApplicant.birthdate))} anos)</>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Candidaturas</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ jobApplicant?.applications?.length } resultados</h4>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Data</th>
                                                                <th className="border-0 p-2 px-4">Vaga</th>
                                                                <th className="border-0 p-2 px-4">Estado</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {jobApplicant?.applications?.slice()
                                                            .sort(function (a, b) {
                                                                const d1 = new Date(a.createdDate).getTime()
                                                                const d2 = new Date(b.createdDate).getTime()
                                                                return d2 - d1;
                                                            }).map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.createdDate && <Moment format="DD/MM/YYYY">{value.createdDate}</Moment>}</span>
                                                                </td>
                                                                <td className="text-center wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/recruitment/jobOpportunities/${value.jobOpportunity?.id}`}>{value.jobOpportunity?.title}</Link></span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminJobApplicant;