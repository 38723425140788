import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import Appfooter from "../../components/Appfooter";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';

function ComplaintsHome() {

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Canal de Denúncias</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <p className="text-grey-700 fw-500">
                                        Como colaborador, pode denunciar situações sérias de forma anónima ou alguma situação caso suspeite da mesma.
                                    </p>
                                    
                                    <h5 className="fw-700 mt-4 font-xss text-grey-900">Denúncia anónima de situações graves</h5>
                                    
                                    <p className="text-grey-700 fw-500">
                                    Nesta página, pode submeter informação sobre situações condenáveis ou denunciar ações antiéticas, ilegais ou que violem políticas internas. O esquema deverá ser utilizado para revelar situações que, de outra forma, não seriam reveladas.
                                    </p>
                                    
                                    <p className="text-grey-700 fw-500">
                                    As denúncias podem ser efetuadas de forma confidencial, indicando o nome e as informações de contacto ou, se quiser, de forma 100% anónima.
                                    </p>
                                    
                                    <p className="text-grey-700 fw-500">
                                    Todos os pedidos de consulta são tratados de forma confidencial e segura.
                                    </p>
                                    
                                    <h5 className="fw-700 mt-4 font-xss text-grey-900">Assuntos relacionados com RH</h5>
                                    
                                    <p className="text-grey-700 fw-500">
                                    As situações relacionadas com os RH não podem ser denunciadas nesta plataforma, devendo, em alternativa, ser discutidas com o supervisor direto, diretor ou RH.
                                    </p>
                                    
                                    <p className="text-grey-700 fw-500">
                                    A partir desta página, pode apresentar ou acompanhar uma denúncia de forma segura.
                                    </p>
                                    
                                    <h5 className="fw-700 mt-4 font-xss text-grey-900">
                                    O acompanhamento do seu caso pode ser relevante por vários motivos:
                                    </h5>
                                    <ol type="1">
                                        <li style={{listStyle: 'auto'}}>Pode querer consultar o estado da sua denúncia para verificar se estão a ser tomadas medidas.</li>
                                        <li style={{listStyle: 'auto'}}>Pode querer fornecer informação adicional relativa à sua denúncia.</li>
                                        <li style={{listStyle: 'auto'}}>Os administradores do sistema pediram-lhe informação adicional para os ajudar a resolver o problema ou tomar as medidas apropriadas.</li>
                                    </ol>
                                    
                                    <div className="row mt-5">
                                        <div className="col-lg-12 mb-0">
                                            <Link className="bg-current text-center text-white font-xsss fw-600 p-3 rounded-3 d-inline-block w-auto me-3" to={'/whistleblowing/complaints/new'}>Criar nova denúncia</Link>
                                            <Link className="bg-grey text-center text-grey-700 font-xsss fw-600 p-3 rounded-3 d-inline-block w-auto" to={'/whistleblowing/complaints/follow-up'}>Acompanhar denúncia já existente</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default ComplaintsHome;