import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT, RSVP_STATUS_OPTIONS, TRAINING_ACTION_STATUS } from "../../../config";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture, getImageUrl } from "../../../utils";
import AdminMediaGallery from "../../../components/AdminMediaGallery";

const GET_QUERY = gql`
    query Get($id: String!) {
        NewsArticleById(id: $id) {
            id
            title
            headline
            content
            category {
                id
                name
            }
            thumbnail
            createdDate
            mediaGallery {
                id
                name
                items {
                    id
                    name
                    file
                    displayOrder
                }
            }
        }
    }
`;

const UPSERT_ASSOCIATE_MEDIA_GALLERY_MUTATION = gql`
    mutation Upsert($id: UUID!, $galleryId: UUID!) {
        upsert_NewsArticle(newsArticle: { id: $id, mediaGallery: { id: $galleryId } }) {
            id
        }
    }
`;

function AdminNewsArticle() {

    let { id } = useParams();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [upsertAssociateMediaGalleryMutation] = useMutation(UPSERT_ASSOCIATE_MEDIA_GALLERY_MUTATION);

    const associateMediaGallery = async(galleryId) => {
        return upsertAssociateMediaGalleryMutation({ 
            variables: {
                id: data.NewsArticleById?.id,
                galleryId
            },
        });
    }

    return (
        <RBACWrapper requiredRoles={['admin', 'post-contributor']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Notícia</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.NewsArticleById?.title}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Título
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.NewsArticleById?.title}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.NewsArticleById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Resumo
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.NewsArticleById?.category?.headline}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500 post-preview-container" dangerouslySetInnerHTML={{ __html: data?.NewsArticleById?.content }}></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <Link to={`/admin/news/edit/${data?.NewsArticleById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>

                                                <AdminMediaGallery mediaGallery={data?.NewsArticleById?.mediaGallery} associateMediaGallery={associateMediaGallery} refetch={refetch} />
                                                
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminNewsArticle;