import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import Graph from "react-vis-network-graph"

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";

import { getProfilePicture } from "../../utils";
import { BADGES_INTERACTIONS_CHART_MAX_NODE_SIZE, BADGES_INTERACTIONS_CHART_MIN_NODE_SIZE, BADGES_INTERACTIONS_CHART_SHOW_LABELS } from "../../config";

const GET_INTERACTIONS_QUERY = gql`
  query GetInteractions {
    BadgeInteractions {
      id
      sender {
        id
        name
        picture
      }
      receiver {
        id
        name
        picture
      }
      total
    }
  }
`;

function BadgeInteractionsChart() {
    
    const navigate = useNavigate();
   
    const options = {
      autoResize: true,
      height: '100%',
      width: '100%',
      //physics: false,
      nodes: {
        brokenImage: '/assets/images/user.png',
        borderWidth: 2,
        borderWidthSelected: 4,
        size: 30,
        color: {
          //border: "#e2f6e9",
          border: "#10d876",
          background: "#e2f6e9",
          highlight: {
            border: '#e2f6e9',
            background: '#e2f6e9'
          },
          hover: {
            border: '#e2f6e9',
            background: '#e2f6e9'
          }
        },
        font: {
          color: "#212529",
          face: '"Open Sans", sans-serif',
          size: 12
        },
      },
      edges: {
        color: "#10d876",
        //color: "#e2f6e9",
        width: 1,
        /*smooth: {
          type: 'cubicBezier',
          forceDirection: 'vertical',
          roundness: 0.4
        },*/
        arrows: {
          to: {
            enabled: false,
          },
          middle: {
            enabled: false,
          },
          from: {
            enabled: false,
          }
        }
      },
      layout: {
        /*hierarchical: {
          direction: 'UD'
        }*/
      }
    };
   
    const events = {
      select: function(event) {
        var { nodes, edges } = event;
        if(nodes.length > 0) {
          navigate(`/employees/${nodes[0]}`)
        }
      }
    };
  

    const { loading, error, data } = useQuery(GET_INTERACTIONS_QUERY);
    //if (loading || viewerLoading) return <LoadingOverlayWrapper />;

    const interactions = (data && data.BadgeInteractions) || [];


    var nodes = [];
    interactions.reduce(function(res, value) {
      if (!res[value.receiver.id]) {
        res[value.receiver.id] = { id: value.receiver.id, shape: "circularImage", label: BADGES_INTERACTIONS_CHART_SHOW_LABELS ? value.receiver.name : null, image: getProfilePicture(value.receiver), total: 0 };
        nodes.push(res[value.receiver.id])
      }
      if (!res[value.sender.id]) {
        res[value.sender.id] = { id: value.sender.id, shape: "circularImage", label: BADGES_INTERACTIONS_CHART_SHOW_LABELS ? value.sender.name : null, image: getProfilePicture(value.sender), total: 0 };
        nodes.push(res[value.sender.id])
      }
      res[value.receiver.id].total += parseInt(value.total);
      return res;
    }, {});

    const minInteractions = Math.min(...nodes.map(o => o.total));
    const maxInteractions = Math.max(...nodes.map(o => o.total));

    const lerp = (x, y, a) => x * (1 - a) + y * a;

    nodes.forEach((element, index) => {
      nodes[index].size = lerp(BADGES_INTERACTIONS_CHART_MIN_NODE_SIZE, BADGES_INTERACTIONS_CHART_MAX_NODE_SIZE, (element.total - minInteractions) / (maxInteractions - minInteractions));
    });

    const edges = interactions.filter((obj, index) => {
      return index === interactions.findIndex(o => obj.receiver.id === o.receiver.id && obj.sender.id === o.sender.id && obj.sender.id !== o.receiver.id);
    }).map(e => ({ from: e.sender.id, to: e.receiver.id }));

    const graph = {
      nodes: nodes,
      // create connections between people
        // value corresponds with the amount of contact between two people
      edges: edges
    };

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title="Rede de Influências" customButton={{link: '/badges', icon: 'feather-grid'}} color="success"/>
                                
                                { loading && <LoadingOverlayWrapper /> }
                                
                                <div className="row ps-2 pe-1" style={{height: '600px'}}>
                                <Graph
                                  key={uuid()}
                                  graph={graph}
                                  options={options}
                                  events={events}
                                  getNetwork={network => {
                                    //  if you want access to vis.js network api you can set the state in a parent component using this property
                                  }}
                                />
                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default BadgeInteractionsChart;