import React, { Component, Fragment, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import { InView } from "react-intersection-observer";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';

import Load from "../../components/Load";

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { formatDuration, getImageUrl } from "../../utils";

const GET_CATEGORIES_QUERY = gql`
query GetLearningCategories {
    LearningCategoryList(orderBy: { name: ASC }) {
        id
        name
        thumbnail
    }
  }
`;

const GET_LEARNING_ITEMS_QUERY = gql`
query GetLearningItems($filter: inp_LearningItemFilterCondition, $limit: Int, $offset: Int) {
    LearningItemList(filter: { AND: [{ isVisible: { _eq: true } }, $filter]}, orderBy: { createdDate: DESC }, limit: $limit, offset: $offset) {
        id
        title
        thumbnail
        thumbnailExternalUrl
        source {
            id
            name
        }
        author {
            id
            name
        }
        category {
            id
            name
        }
        duration
        createdDate
    }
  }
`;


function LearningList() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCategoryId, setSelectedCategoryId] = useState(searchParams.get('category'));

    const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = useQuery(GET_CATEGORIES_QUERY);
    const { data: itemsData, loading: itemsLoading, fetchMore: itemsFetchMore, error: itemsError } = useQuery(GET_LEARNING_ITEMS_QUERY, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        variables: {
            limit: 10,
            offset: 0,
            filter: selectedCategoryId ? { category: { id: { _eq: selectedCategoryId } } } : { } }
    });

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Learning" color="success" />
                                <div className="row">
                                    <h2 className="fw-700 font-xss mt-0 lh-20 ms-2 mt-3 mb-2"><i className="em em-clap me-1"></i> O que vais aprender hoje?</h2>
                                </div>
                                <div className="row">
                                    <div className="search-form-2 mb-3">
                                        <select type="text" className="form-control mb-0 pt-0 pv-0 theme-dark-bg border-0" value={selectedCategoryId || ''} onChange={(e) => setSelectedCategoryId(e.target.value)}>
                                            <option value="">Todas as Categorias</option>
                                            {
                                                categoriesData && categoriesData.LearningCategoryList && categoriesData.LearningCategoryList
                                                .map((c) => {
                                                    return (
                                                        <option key={c.id} value={c.id}>{c.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <CustomLoadingOverlay active={itemsLoading}>
                                    <div className="row ps-2 pe-2">
                                        {
                                            itemsData && itemsData.LearningItemList && itemsData.LearningItemList
                                            .map((learningItem, index) => {
                                                return (
                                                    <div key={index} className="col-lg-3 col-md-3 col-sm-6 mb-3 pe-2 ps-2">
                                                        <div className="card w-100 p-0 hover-card shadow-xss border-0 rounded-3 overflow-hidden me-1 h-100">
                                                            {learningItem.feature ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''}
                                                            <div className="card-image w-100 mb-3">
                                                                <a href={"/learning/items/" + learningItem.id} className="position-relative d-block"><img src={getImageUrl(learningItem.thumbnail, learningItem.thumbnailExternalUrl)} alt={learningItem.title} className="w-100 aspect-ratio-285-160" style={{objectFit: 'cover'}} /></a>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                {false && <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i>}
                                                                <h4 className="fw-700 font-xss mt-0 lh-20 mb-2"><a href={"/learning/items/" + learningItem.id} className="text-dark text-grey-900 font-xssss text-max-2-lines">{learningItem.title}</a></h4>
                                                                <h6 className="font-xssss text-grey-500 fw-600 mt-0 mb-2"> {learningItem.category.name}</h6>                                            
                                                                <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><Link to={`/learning?author=${learningItem.author?.id}`}>{learningItem.author?.name}</Link></h5>
                                                                <div className="clearfix"></div>
                                                                { learningItem.duration && <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-time text-grey-500 me-1"></i> {formatDuration(learningItem.duration)}</h5> }
                                                                <div className="clearfix"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        { itemsLoading && <Load /> }
                                        {itemsData && (
                                            <InView
                                            onChange={async (inView) => {
                                                const currentLength = (itemsData && itemsData.LearningItemList && itemsData.LearningItemList.length) || 0;
                                                if (inView) {
                                                    console.log('itemsFetchMore')
                                                    await itemsFetchMore({
                                                        variables: {
                                                            offset: currentLength,
                                                            limit: 10,
                                                            filter: selectedCategoryId ? { category: { id: { _eq: selectedCategoryId } } } : { }
                                                        },
                                                    });
                                                }
                                            }}
                                            />
                                        )}
                                        {!itemsLoading && itemsData && itemsData.LearningItemList && itemsData.LearningItemList.length === 0 && <p className="text-center">Não existem conteúdos.</p>}
                                    </div>
                                </CustomLoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter />
        </Fragment>
    );
}

export default LearningList;