import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import moment from "moment";

import AdminHeader from "../../../components/AdminHeader";
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import Pagetitle from "../../../components/Pagetitle";
import { USER_MOOD_SCORE, DATE_FILTERS, RECRUITMENT_JOB_APPLICATION_STATUS } from "../../../config";


const GET_QUERY = gql`
    query Get($dateFilter: [inp_DateTimeFilterCondition]) {
        JobOpportunityList(filter: { isActive: { _eq: true } }) {
            id
            company {
                id
                name
            }
            title
            type
            createdDate
        }
        JobApplicationList(orderBy: { createdDate: DESC }, filter: { createdDate: $dateFilter }) {
            id
            jobOpportunity {
                id
                title
            }
            status
            createdDate
        }
    }
`;

function AdminRecruitmentHome() {
    
    const navigate = useNavigate();

    const [selectedDateFilter, setSelectedDateFilter] = useState(null)
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: selectedDateFilter && { dateFilter: DATE_FILTERS.filter(e => e.id === selectedDateFilter)[0].value }});

    useEffect(() => {
        setSelectedDateFilter('past_7_days')
    }, []);

    const results = [];

    const jobOpportunities = data?.JobOpportunityList || [];
    const jobApplications = data?.JobApplicationList || [];

    const countBy = (arr, fn) => arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
    }, {});
    
    const jobApplicationsByOpportunity = countBy(jobApplications, x => x.jobOpportunity.title);
    console.log(jobApplicationsByOpportunity)

    let jobApplicationChartSeriesData = [];
    let jobApplicationChartCategories = [];

    for (var k in jobApplicationsByOpportunity) {
        jobApplicationChartSeriesData.push(jobApplicationsByOpportunity[k])
        jobApplicationChartCategories.push(k)
    }
    
    const areaChartConfig = {
        //labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May' ,'Jun', 'Jul', 'Aug', 'Sep', 'Oct' , 'Nov', 'Dec'],
        series: [{
            name: 'Candidaturas',
            data: jobApplicationChartSeriesData,
        }],
        options: {
            chart: {
                type: 'bar',
            //   width:'100%',
                //stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true,
                    type: 'x',  
                    autoScaleYaxis: false,  
                    zoomedArea: {
                      fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                      },
                      stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                      }
                    }
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
            },
            /*
            markers: {
                size: 6,
                strokeWidth: 3,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                  size: 8
                }
            },
            */
            //colors: ['#E50202', '#FE9431', '#ffcc4d', '#1e74fd', '#4cd964'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                    endingShape: 'rounded',
                    distributed: true
                },
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1
            },
            xaxis: {
                title: {
                  text: 'Vagas'
                }
            },
            xaxis: {
                categories: jobApplicationChartCategories,
            },
            yaxis: {
                title: {
                  text: 'Candidaturas'
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            },
            tooltip: {
                enabled: true,
                /*
                x: {
                    show: true,
                    format: 'dd MMM yyyy',
                    formatter: undefined,
                },
                */
                y: {
                    formatter: undefined,
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
            },
            /*
            xaxis: {
                type: 'datetime',
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            },
            */
            yaxis: {
                //tickAmount: 1,
                min: 0,
                //max: 5
                labels: {
                    formatter: function(val) {
                        return val.toFixed(0);
                    }
                }
            },            
        },
    };

    const donutChartConfig = {
        
        series: [
            jobApplications.filter(e => e.status === 'NEW').length,
            jobApplications.filter(e => e.status === 'IN_PROCESS').length,
            jobApplications.filter(e => e.status === 'SCREENING').length,
            jobApplications.filter(e => e.status === 'INTERVIEW').length,
            jobApplications.filter(e => e.status === 'OFFER').length,
            jobApplications.filter(e => e.status === 'HIRED').length,
            jobApplications.filter(e => e.status === 'REJECTED').length,
        ],
        options: {
            labels: [
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'NEW')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'IN_PROCESS')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'SCREENING')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'INTERVIEW')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'OFFER')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'HIRED')[0].name,
                RECRUITMENT_JOB_APPLICATION_STATUS.filter(e => e.id === 'REJECTED')[0].name,
            ],
            chart: {
                type: 'pie',
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }],
            colors: ['#cccccc', '#FE9431', '#FE9431', '#ffcc4d', '#1e74fd', '#4cd964', '#E50202'],
            
            dataLabels: {
                formatter(val, opts) {
                    const name = opts.w.globals.labels[opts.seriesIndex]
                    return [name, val.toFixed(1) + '%']
                }
            },
            legend: {
                /*show: false,*/
                position: 'top',
                horizontalAlign: 'left'
            }
        },
    };

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content bg-white">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Recrutamento"/>

                                    <div className="col-lg-12 mb-3">
                                        <div className="float-right">
                                            <Link to="/admin/recruitment/settings" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Configurações</Link>
                                            <Link to="/admin/recruitment/jobOpportunities" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Vagas</Link>
                                            <Link to="/admin/recruitment/jobApplications" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Candidaturas</Link>

                                            <select className="searchCat sort ms-1 me-1" onChange={e => setSelectedDateFilter(e.target.value)} value={selectedDateFilter}>
                                                <option value="" disabled>Data</option>
                                                { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                        <div className="card-body d-flex p-0 pointer" onClick={() => navigate(`/admin/recruitment/jobOpportunities`)}>
                                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-user-plus font-md text-white"></i>
                                            <h4 className="text-success font-xl fw-700">{ jobOpportunities.length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Oportunidades Abertas</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#fff0e9`}} onClick={() => navigate(`/admin/recruitment/jobOpportunities`)}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-globe font-md text-white"></i>
                                            <h4 className="text-warning font-xl fw-700">{ jobOpportunities.filter(e => e.type === 'EXTERNAL').length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Oportunidades Externas</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2 ps-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#f6f3ff`}} onClick={() => navigate(`/admin/recruitment/jobOpportunities`)}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-home font-md text-white"></i>
                                            <h4 className="text-secondary font-xl fw-700">{ jobOpportunities.filter(e => e.type === 'INTERNAL').length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Oportunidades Internas</span></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 pe-2">
                                    <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#e5f6ff`}} onClick={() => navigate(`/admin/recruitment/jobApplications`)}>
                                        <div className="card-body d-flex p-0">
                                            <i className="btn-round-lg d-inline-block me-3 bg-primary feather-file-text font-md text-white"></i>
                                            <h4 className="text-primary font-xl fw-700">{ jobApplications.length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Candidaturas</span></h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <Chart
                                        options={areaChartConfig.options}
                                        series={areaChartConfig.series}
                                        type="bar"
                                        height={400}
                                        width="100%"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                        <Chart
                                        options={donutChartConfig.options}
                                        series={donutChartConfig.series}
                                        type="pie"
                                        height={400}
                                        width={400}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminRecruitmentHome;