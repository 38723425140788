import React,{Component, useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { getInitials } from '../utils';
import { useAuth } from '../providers/authProvider';


function Appfooter() {
    
    const [currentUser, setCurrentUser] = useState(null);
    
    const auth = useAuth()
    
    useEffect(() => {
        if(auth.isAuthenticated()) {
            auth.getUser().then(u => {
                setCurrentUser(u?.profile)
            })
        }
        //setCurrentUser(profile)
    }, [auth])

    const userInitials = getInitials(currentUser?.name);

    return (
        <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
            <Link to="/feed" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
            <Link to="/employees" className="nav-content-bttn"><i className="feather-users"></i></Link>
            <Link to="/benefits" className="nav-content-bttn"><i className="feather-gift"></i></Link>
            <Link to="/badges" className="nav-content-bttn"><i className="feather-award"></i></Link>
            {false && <Link to="/settings" className="nav-content-bttn"><img src="assets/images/female-profile.png" alt="user" className="w30 shadow-xss" /></Link>}
            <Link to="/settings" className="nav-content-bttn"><span className="btn-round-sm bg-primary-gradiant ls-3 text-white font-xssss fw-700">{userInitials}</span></Link>
        </div>        
    );
}

export default Appfooter;