import React, { Component , Fragment, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Moment from 'react-moment';

import 'react-image-lightbox/style.css';
import { getImageUrl } from "../../utils";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        JobOpportunityById(id: $id) {
            id
            company {
                id
                name
                logo
            }
            department {
                id
                name
            }
            title
            description
            referenceCode
            category {
                id
                name
            }
            location
            isActive
            deadline
            createdDate
        }
    }
`;

const UPSERT_JOB_APPLICATION_MUTATION = gql`
    mutation Upsert($id: UUID!, $employeeId: UUID, $motivation: String!) {
        upsert_JobApplicationInternal(jobApplicationInternal: { jobOpportunity: { id: $id }, employee: { id: $employeeId }, motivation: $motivation }) {
            id
        }
    }
`;

const UPSERT_JOB_APPLICATION_ATTACHMENT_MUTATION = gql`
    mutation UpsertJobApplicationAttachment($jobApplicationId: UUID!, $file: Upload!) {
        upsert_JobApplicationAttachment(jobApplicationAttachment: { jobApplication: { id: $jobApplicationId }, file: $file }) {
            id
        }
    }
`;

function JobOpportunitySingle() {

    let { id } = useParams();

    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = useState(null);
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data } = useQuery(GET_QUERY, { variables: { id: id }});

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertJobApplicationInternalMutation, { upsertJobApplicationInternalMutationData }] = useMutation(UPSERT_JOB_APPLICATION_MUTATION);
    const [upsertJobApplicationAttachmentMutation, { upsertJobApplicationAttachmentMutationData }] = useMutation(UPSERT_JOB_APPLICATION_ATTACHMENT_MUTATION);

    const jobOpportunity = data?.JobOpportunityById;

    const upsert = async (formData) => {
        if(!selectedFile) {
            toast.error('O CV é obrigatório.');
            return;
        }
        
        const upsertResult = await upsertJobApplicationInternalMutation({ 
            variables: {
                id: id,
                employeeId: viewerData.Viewer.employee.id,
                motivation: formData.motivation,
            },
        });

        if(upsertResult.data) {
            const upsertAttachmentResult = await upsertJobApplicationAttachmentMutation({ 
                variables: {
                    jobApplicationId: upsertResult.data.upsert_JobApplicationInternal.id,
                    file: selectedFile,
                },
            });

            toast.success('Candidatura enviada com sucesso!');
            navigate(`/recruitment/jobOpportunities`)
        }
    }

    const onFileChange = event => { 
        setSelectedFile(event.target.files[0])
    };
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <CustomLoadingOverlay active={loading}>
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-0">
                                <Pagetitle title="Oportunidades Internas" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                

                                <div className="card d-block mt-3 border-0 shadow-xss bg-white p-lg-5 p-4">
                                    <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white ">{ jobOpportunity?.category?.name }</span>
                                    <h2 className="fw-700 font-lg mt-3 mb-2">{ jobOpportunity?.title }</h2>
                                    { jobOpportunity?.referenceCode && <p className="font-xsss fw-500 text-grey-500 lh-30 pe-5 mt-3 me-5">Referência: { jobOpportunity?.referenceCode }</p> }
                                    
                                    <div className="clearfix"></div>
                                    
                                    <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mb-0">Adicionada <Moment fromNow>{ jobOpportunity?.createdDate }</Moment></p>
                                    { jobOpportunity?.deadline && <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mb-0">Prazo para candidaturas: <Moment format="DD/MM/YYYY">{ jobOpportunity?.deadline }</Moment></p> }
                                    
                                    <div className="clearfix"></div>
                                    { jobOpportunity?.location && <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-location-pin text-grey-500 me-1"></i> {jobOpportunity?.location}</h5> }
                                    <div className="clearfix"></div>
                                </div>

                                <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-4">
                                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 mb-3">Descrição</h2>
                                    <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 ps-2 w-100" dangerouslySetInnerHTML={{ __html: jobOpportunity?.description }}></p>
                                </div>

                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <div className="card w-100 border-0 mt-3 mb-4 shadow-xss position-relative rounded-3 bg-white rounded-xxl overflow-hidden">
                                    <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("`+ "" +`")`}}></div>
                                    <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                                        <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 z-index-1"><img src={getImageUrl(jobOpportunity?.company?.logo, "/assets/images/unknown-company-icon.png")} alt={jobOpportunity?.company?.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                                        <div className="clearfix"></div>
                                        <h4 className="fw-700 font-xsss mt-2 mb-1">{jobOpportunity?.company?.name} </h4>
                                        {jobOpportunity?.department && <p className="fw-500 font-xsss text-grey-500 mt-0 mb-2">{jobOpportunity?.department.name}</p>}
                                        <div className="clearfix mb-2"></div>
                                    </div>
                                </div>

                                <div className="card w-100 border-0 mt-4 mb-4 p-4 shadow-xss position-relative rounded-3 bg-white">
                                <h2 className="fw-700 font-sm mb-4 mt-1 ps-1 mb-3">Enviar Candidatura</h2>
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mb-2 md-mb-2">
                                                    <textarea className="w-100 h200 style2-textarea p-3 form-control" placeholder="Fala-nos sobre a motivação para a tua candidatura" {...register("motivation", { required: true })}></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label htmlFor="file" className="fw-700">CV</label>
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <a role="button" className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-3 d-block mt-0 w-100 p-2 lh-34 text-center ls-3 " onClick={handleSubmit(upsert)}>Enviar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </CustomLoadingOverlay>
            <Appfooter /> 

        </Fragment>
    );
}

export default JobOpportunitySingle;