import React, { Component , Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql } from '@apollo/client';

import 'react-image-lightbox/style.css';
import Pagetitle from "../../components/Pagetitle";
import NewsArticlesWidget from "../../components/NewsArticlesWidget";
import NewsArticleView from "../../components/NewsArticleView";
import Gallery from "../../components/Gallery";
import track, { useTracking } from "react-tracking";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            name
            employee {
                id
                jobTitle
                company {
                    id
                    name
                }
            }
        }
    }
`;


const GET_NEWS_ARTICLE_QUERY = gql`
    query GetNewsArticle($id: String!) {
        NewsArticleById(id: $id) {
            id
            title
            headline
            content
            thumbnail
            createdDate
            category {
                id
                name
            }
            mediaGallery {
                id
            }
        }
    }
`;

const GET_LATEST_NEWS_QUERY = gql`
    query GetLatestNewsArticles {
        NewsArticleList(orderBy: { createdDate: DESC }, limit: 6) {
            id
            title
            headline
            content
            thumbnail
            createdDate
            category {
                id
                name
            }
        }
    }
`;


function NewsArticleSingle() {

    let { id } = useParams();
    
    const { trackEvent } = useTracking();

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading: loadingNewsArticle, error: errorNewsArticle, data: dataNewsArticle } = useQuery(GET_NEWS_ARTICLE_QUERY, { variables: { id: id }});

    const { data: latestNewsData, loading: latestNewsLoading, error: latestNewsError } = useQuery(GET_LATEST_NEWS_QUERY);

    useEffect(() => {
        trackEvent({ event: "view", customData: id });
    }, [])

    if (loadingNewsArticle) return <CustomLoadingOverlay active={loadingNewsArticle} />;

    const newsArticle = dataNewsArticle && dataNewsArticle.NewsArticleById;
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xs-12">
                                <Pagetitle title="Notícias" color="success" customButton={{link: `/admin/news/edit/${newsArticle?.id}`, icon: 'feather-edit', requiredRoles: ['admin', 'post-contributor']}} />
                            </div>
                        </div>
                        <div className="row">
                            <CustomLoadingOverlay active={loadingNewsArticle} />
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <NewsArticleView news={newsArticle} />

                                <Gallery title="Galeria" id={newsArticle?.mediaGallery?.id} showContainer={true} />
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <NewsArticlesWidget title="Últimas Notícias" items={latestNewsData && latestNewsData.NewsArticleList}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter /> 

        </Fragment>
    );
}

//export default NewsArticleSingle;

export default track(
    { page: "News" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(NewsArticleSingle);