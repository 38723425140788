import React,{Component, Fragment, useState, useEffect, useContext} from 'react';
import { Link , NavLink, useNavigate } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';

import Moment from 'react-moment';

import { getInitials } from '../utils';
import { config } from '../config';
import NotificationModal from './NotificationModal';
import { useAuth } from '../providers/authProvider';

const GET_NOTIFICATIONS_QUERY = gql`
    query GetUnreadNotifications($externalId: String!) {
        NotificationList(filter: {
            AND: [
                { receiver: { externalId: { _eq: $externalId } } },
                { readDate: { _isNull: true } }
            ]
        }, orderBy: { createdDate: DESC }) {
            id
            type {
                id
                code
                name
            }
            sender {
                id
                name
            }
            title
            subtitle
            body
            readDate
            createdDate
        }

        PopupNotificationList(filter: {
            AND: [
                { user: { externalId: { _eq: $externalId } } },
                { readDate: { _isNull: true } }
            ]
        }, orderBy: { createdDate: DESC }) {
            id
            type
            title
            body
            createdDate
        }

        DirectMessageList(filter: {
            AND: [
                { receiver: { externalId: { _eq: $externalId } } },
                { readDate: { _isNull: true } }
            ]
        }, orderBy: { createdDate: DESC }) {
            id
        }
    }
`;

function NotificationsDropdown() {

    const [isNoti, setIsNoti] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    
    const auth = useAuth()
    
    useEffect(() => {
        if(auth.isAuthenticated()) {
            auth.getUser().then(u => {
                setCurrentUser(u?.profile)
            })
        }
    }, [auth])

    const navigate = useNavigate()
    
    const { loading, error, data , startPolling, stopPolling } = useQuery(GET_NOTIFICATIONS_QUERY, {
        variables: { externalId: currentUser?.sub },
        pollInterval: config.notifications.POLLING_INTERVAL_MILLIS,
        //pollInterval: 1000,
        //fetchPolicy: 'network-only'
    });

/*
    useEffect(() => {
        startPolling(config.notifications.POLLING_INTERVAL_MILLIS); // poll interval
    
        return () => {
          stopPolling();
        };
    }, []);*/
    //if (loading) return <Fragment/>;

    const notifications = (data && data.NotificationList) || [];
    const messages = (data && data.DirectMessageList) || [];

    const notificationUnreadCount = notifications.filter(n => !n.readAt).length;
    const messageUnreadCount = messages.length;

    const toggleisNoti = () => setIsNoti(!isNoti);

    const notiClass = `${isNoti ? " show" : ""}`;

    return (
        <Fragment>
            <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleisNoti}>
                { notificationUnreadCount > 0 && <span className="dot-count bg-warning"></span> }
                <i className="feather-bell font-xl text-current"></i>
            </span>
            <span className={`p-2 pointer text-center ms-3 menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => navigate('/messages')}>
                { messageUnreadCount > 0 && <span className="dot-count bg-warning"></span> }
                <i className="feather-message-square font-xl text-current"></i>
            </span>
            <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`} aria-labelledby="dropdownMenu3">
                <h4 className="fw-700 font-xss mb-4">{ notificationUnreadCount } { notificationUnreadCount === 1 ? 'Notificação' : 'Notificações'}</h4>
                {
                    notifications
                    .map((n) => {
                        return (
                            <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3" key={n.id}>
                                {false && <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />}
                                <Link to="/notifications"><span className="btn-round-sm bg-primary-gradiant ls-3 text-white font-xssss fw-700 w40 h40 lh-40 position-absolute left-0">{getInitials(n.sender?.name) || <i className="font-sm text-white icon-empathize-square" style={{verticalAlign: 'middle'}}></i>}</span></Link>
                                <Link to="/notifications"><h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{ n.sender?.name || config.APP_NAME } <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> <Moment fromNow>{ n.createdDate }</Moment></span></h5></Link>
                                <Link to="/notifications"><h6 className="text-grey-500 fw-500 font-xssss lh-4">{ n.title }</h6></Link>
                            </div>
                        )
                    })
                }
                {false && <div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>

                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
                </div>}
            </div>

            <NotificationModal notification={data?.PopupNotificationList[0]}/>
        </Fragment>
    );
}

export default NotificationsDropdown;