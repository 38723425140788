import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import Pagetitle from '../../components/Pagetitle';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { getProfilePicture } from "../../utils";
import { config } from "../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
                picture
            }
            picture
        }
    }
`;

const GET_USER_QUERY = gql`
    query Get($id: String!) {
        UserById(id: $id) {
            id
            name
            picture
            employee {
                picture
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: UUID!, $limit: Int!) {
        DirectMessageList(filter: {
            OR:[
                {receiver:  { id: { _eq: $id } } },
                {sender:    { id: { _eq: $id } } },
            ] },
            orderBy: { createdDate: DESC },
            limit: $limit
        ) {
            id
            receiver {
                id
                name
                employee {
                    id
                    picture
                }
            }
            sender {
                id
                name
                employee {
                    id
                    picture
                }
            }
            content

            createdDate
        }
    }
`;

const UPSERT_MESSAGE_MUTATION = gql`
    mutation Upsert($senderId: UUID!, $receiverId: UUID!, $content: String) {
        upsert_DirectMessage(directMessage: { sender: { id: $senderId }, receiver: { id: $receiverId }, content: $content }) {
            id
        }
    }
`;

const UPSERT_MARK_CONVERSATION_READ_MUTATION = gql`
    mutation Upsert($id: UUID!) {
        MarkConversationAsRead(id: $id)
    }
`;

function ConversationSingle() {
    
    let { id } = useParams();

    const [inputValue, setInputValue] = useState("");
    const messagesEndRef = useRef(null)

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: receiverData, loading: receiverLoading, error: receiverError } = useQuery(GET_USER_QUERY, {variables: { id: id }});
    const { loading, error, data, refetch } = useQuery(GET_QUERY, {
        variables: { id: id, limit: config.messages.MESSAGE_HISTORY_LIMIT },
        pollInterval: config.messages.POLLING_INTERVAL_MILLIS,
    });

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertMessageMutation ] = useMutation(UPSERT_MESSAGE_MUTATION);
    const [markConversationAsReadMutation] = useMutation(UPSERT_MARK_CONVERSATION_READ_MUTATION);

    useEffect(() => {
        scrollToBottom()
    }, [data]);

    useEffect(() => {
        markConversationAsRead(id)
    }, [id]);

    const messages = data?.DirectMessageList || [];
    const receiver = receiverData?.UserById;

    const sendMessage = async (formData) => {
        if(id && inputValue.trim().length > 0) {
            const upsertResult = await upsertMessageMutation({ 
                variables: {
                    senderId: viewerData.Viewer.id,
                    receiverId: id,
                    content: inputValue
                },
            });

            if(upsertResult.data) {
                toast.success('Mensagem enviada!');
                resetInputField();
                refetch()
            }
        }
    }

    const markConversationAsRead = async (id) => {
        const mutationResult = await markConversationAsReadMutation({ 
            variables: {
                id
            },
        });
    }

    // Input Field handler
    const handleUserInput = (e) => {
        setInputValue(e.target.value);
    };

    // Reset Input Field handler
    const resetInputField = () => {
        setInputValue("");
    };
    
    const handleKeyDown = (event) => {
        /*
        if (event.key === 'Enter') {
            event.preventDefault();
            submitComment()
        }
        */
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        
        <Fragment> 
            <Header />
            <Leftnav />

            { (loading || viewerLoading || receiverLoading) && <LoadingOverlayWrapper/> }

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                        <div className="row">
                            <div className="col-lg-12 position-relative">
                                <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <div className="chat-body p-3 ">
                                        <Pagetitle title={receiver?.name || "Mensagens"}/>
                                        <div className="messages-content pb-5">
                                            {messages.slice()
                                            .sort(function (a, b) {
                                                const d1 = new Date(a.createdDate).getTime()
                                                const d2 = new Date(b.createdDate).getTime()
                                                return d1 - d2;
                                            }).map((value, index) => (
                                            <div key={value.id} className={`message-item ${value.sender.id === viewerData?.Viewer?.id ? "outgoing-message" : ""}`}>
                                                <div className="message-user">
                                                    <figure className="avatar">
                                                        <img src={getProfilePicture(value.sender.id === viewerData?.Viewer?.id ? viewerData?.Viewer : receiver)} alt={value.sender.name} />
                                                    </figure>
                                                    <div>
                                                        <h5>{value.sender?.name}</h5>
                                                        <div className="time"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></div>
                                                    </div>
                                                </div>
                                                <div className="message-wrap" style={{marginBottom: (index == messages.length -1) ? "90px" : null}}>{value.content}</div>
                                            </div>
                                            ))}
                                            <div className="clearfix"></div>

                                            <div ref={messagesEndRef} />

                                        </div>
                                    </div>
                                </div>
                                <div className="chat-bottom dark-bg p-3 pe-4 shadow-none theme-dark-bg" style={{width:"98%"}}>
                                    {false && <form className="chat-form" onSubmit={handleSubmit(sendMessage)}>
                                        <div className="form-group">
                                            <input type="text" className="" placeholder="Escreve a tua mensagem..." onKeyDown={handleKeyDown} value={inputValue} onChange={handleUserInput} />
                                        </div>
                                        <button className="bg-current" onClick={handleSubmit(sendMessage)}><i className="ti-arrow-right text-white"></i></button>
                                    </form> }
                                    <form className="me-3 w-100" onSubmit={handleSubmit(sendMessage)}>
                                        <div className="form-group mb-0 w-100">
                                            <input
                                                type="text"
                                                className="bg-grey border-0 lh-32 pt-2 pb-2 ps-3 pe-3 font-xssss fw-500 rounded-3 w350 theme-dark-bg w-100"
                                                autoComplete="off"
                                                placeholder="Escreve a tua mensagem..."
                                                onKeyDown={handleKeyDown}
                                                value={inputValue}
                                                onChange={handleUserInput}
                                            />
                                        </div>
                                    </form>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter />
        </Fragment>
    );

}

export default ConversationSingle;