import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { useQuery, gql, useMutation } from '@apollo/client';
import { toast } from "react-toastify";
import CustomLoadingOverlay from './CustomLoadingOverlay';

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            name
            wallet {
                id
                balance
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        SurveyById(id: $id) {
            id
            title
            questions {
                id
                questionText
                displayOrder
                options {
                    id
                    value
                    displayOrder
                    answers {
                        id
                        response {
                            user {
                                id
                            }
                        }
                    }
                }
            }
            responses {
                id
                user {
                    id
                }
            }
        }
    }
`;

const UPSERT_CREATE_SURVEY_RESPONSE_MUTATION = gql`
    mutation CreateSurveyResponse($surveyId: UUID, $answers: [inp_QuestionAnswerDTO]) {
        CreateSurveyResponse(surveyId: $surveyId, answers: $answers) {
            id
        }
    }
`;

function SurveyView(props) {

    const { id } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const [upsertCreateSurveyResponseMutation] = useMutation(UPSERT_CREATE_SURVEY_RESPONSE_MUTATION);

    const { loading: loadingViewer, error: errorViewer, data: dataViewer } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    if (loading) return <CustomLoadingOverlay/>;

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = (userReaction) => {
        if(!isActive && userReaction) {
            //handleDeleteReaction(userReaction.id)
        } else {
            setIsActive(!isActive);
        }
    }

    const createSurveyResponse = async (questionId, optionId) => {
        if(existingResponse) {
            return
        }

        setSelectedOption(optionId)

        const result = await upsertCreateSurveyResponseMutation({ 
            variables: {
                surveyId: id,
                answers: [ { option: { question: { id: questionId }, option: { id: optionId }  } } ]
            },
        })

        if(result.data) {
            toast.success("Responsta enviada com sucesso!")
            refetch()
        } else {
            toast.error("Ocorreu um erro ao enviar a resposta.")
        }
    }

    //console.log(data)
    const question = data?.SurveyById?.questions[0];
    const existingResponse = data?.SurveyById?.responses?.filter(r => r.user?.id === dataViewer?.Viewer?.id)[0]
    const totalResponses = data?.SurveyById?.responses?.length || 0

    //console.log(existingResponse);

    return (
        <div className="survey">
            <div className="wrapper">
                <header className="fw-700 lh-26 font-xssss">{question?.questionText}</header>
                <div className="poll-area">
                    {question?.options?.map(e => (
                        <input key={`input-${e.id}`} type="checkbox" name="poll" id={e.id} />
                    ))}

                    {question?.options?.map(e => (
                        <label
                            key={`label-${e.id}`}
                            htmlFor={e.id}
                            className={`${e.id} ${e.answers?.filter(a => a.response?.user?.id==dataViewer?.Viewer?.id).length > 0 ? 'selected' : ''} ${existingResponse ? 'selectall' : ''}`}
                            onClick={() => createSurveyResponse(question.id, e.id)}
                        >
                            <div className="survey-row">
                                <div className="column">
                                    <span className="circle"></span>
                                    <span className="text font-xssss fw-500 lh-26">{e.value}</span>
                                </div>
                                <span className="percent">{totalResponses > 0 ? Math.round((e.answers?.length / totalResponses)*100) : 0}% ({e.answers?.length})</span>
                            </div>
                            <div className={`progress progress-${totalResponses > 0 ? Math.round((e.answers?.length / totalResponses)*10)*10 : 0}`}></div>
                        </label>
                    ))}
                    
                    {false && <>
                    
                    <label htmlFor="opt-2" className="opt-2">
                        <div className="survey-row">
                            <div className="column">
                                <span className="circle"></span>
                                <span className="text">Java</span>
                            </div>
                            <span className="percent">20%</span>
                        </div>
                        <div className="progress"></div>
                    </label>
                    <label htmlFor="opt-3" className="opt-3">
                        <div className="survey-row">
                            <div className="column">
                                <span className="circle"></span>
                                <span className="text">Python</span>
                            </div>
                            <span className="percent">40%</span>
                        </div>
                        <div className="progress"></div>
                    </label>
                    <label htmlFor="opt-4" className="opt-4">
                        <div className="survey-row">
                            <div className="column">
                                <span className="circle"></span>
                                <span className="text">jQuery</span>
                            </div>
                            <span className="percent">10%</span>
                        </div>
                        <div className="progress"></div>
                    </label>
                    </>}
                </div>
            </div>
        </div>
    );
}

export default SurveyView;