import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import { getImageUrl, getProfilePicture } from "../../../utils";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";

const GET_QUERY = gql`
    query Get {
        ProductList {
            id
            name
            description
            sku
            thumbnail
            price
            cost
            category {
                id
                name
            }
            isActive
            stock
        }
    }
`;

const UPSERT_PRODUCT_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $isActive: Boolean!) {
        upsert_Product(product: { id: $id, isActive: $isActive }) {
            id
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Product(id: $id)
    }
`;

function AdminProductList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const [upsertProductStatusMutation, { upsertProductStatusMutationData }] = useMutation(UPSERT_PRODUCT_STATUS_MUTATION);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const updateProductStatus = async (id, isActive) => {
        const upsertResult = await upsertProductStatusMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Produto ${isActive ? 'ativado' : 'desativado'}`)
        }
    }

    const deleteProduct = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o produto?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Produto eliminado com sucesso!')
            }
        }
    }

    const products = (data && data.ProductList.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Produtos"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ products.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/market/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                                <Link to="/admin/market/products/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Produto</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4">Categoria</th>
                                                            <th className="border-0 p-4">Preço</th>
                                                            <th className="border-0 p-4">Stock</th>
                                                            <th className="border-0 p-4">Ativo?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getImageUrl(value.thumbnail)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/market/products/edit/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.category?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.price} €</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.stock}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => updateProductStatus(value.id, !value.isActive)}><i className={`${!value.isActive ? ' ti-check text-success ' : ' ti-na text-danger '} font-xs me-2`}></i></a>
                                                                <a role="button" onClick={() => deleteProduct(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminProductList;