import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDuration, getImageUrl } from '../utils';


function LearningItemsWidget(props) {

    const { title, items } = props;

    const navigate = useNavigate();

    if(!items || items.length === 0) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">{title}</h4>
                {false && <a href="/defaultevent" className="fw-600 ms-auto font-xssss text-success">See all</a>}
            </div>
            {items.map((value , index) => (
            <div key={index} className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden pointer" onClick={() => navigate(`/learning/items/${value.id}`)}>
                <img src={getImageUrl(value.thumbnail, value.thumbnailExternalUrl)} alt={value.title} className="rounded-xxxl me-2 aspect-ratio-285-160" style={{width: '35%', objectFit: 'cover'}} />
                <h4 className="fw-700 text-grey-900 font-xssss mt-1"><span className="text-max-2-lines" alt={value.title}>{value.title} </span> 
                { value.category && <span className="d-block font-xssss fw-500 mt-0 lh-4 text-grey-500">{value.category.name}</span> }
                { value.author && <span className="d-block font-xssss fw-500 mt-0 lh-4 text-grey-500">{value.author.name}</span> }
                { value.duration && <span className="d-block font-xssss fw-500 mt-1 lh-4 text-grey-500"><i className="feather-clock"></i> {formatDuration(value.duration)}</span> }
                </h4>
            </div>
            ))}
        </div>
    );
}

export default LearningItemsWidget;