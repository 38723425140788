import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import Moment from 'react-moment';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getAttachmentUrl, getImageUrl } from "../../utils";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get {
        JobOpportunityList(filter: { AND: [ { isActive: { _eq: true } }, { type: { _eq: INTERNAL } } ] }, orderBy: { createdDate: DESC }) {
            id
            company {
                id
                name
                logo
            }
            department {
                id
                name
            }
            title
            location
            category {
                id
                name
            }
            createdDate
        }
    }
`;

const LISTS_QUERY = gql`
    query Get($userId: UUID!) {
        JobApplicationInternalList(filter: { employee: { user: { id: { _eq: $userId } } } }) {
            id
            jobOpportunity {
                id
                title
                referenceCode
            }
            motivation
            employee {
                id
                name
                fullName
            }
            status
            createdDate
            attachments {
                id
                file
                name
            }
        }
    }
`;


function JobOpportunityList() {

    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { data: data, loading: loading, error: error } = useQuery(GET_QUERY);
    const { data: listsData, loading: listsLoading, error: errorLoading } = useQuery(LISTS_QUERY, {
        variables: { userId: viewerData && viewerData.Viewer && viewerData.Viewer.id },
        skip: !(viewerData && viewerData.Viewer && viewerData.Viewer)
    });

    const jobOpportunities = data && data.JobOpportunityList || [];

    const nodeRef = React.useRef(null);
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            { false &&
                            <div className="col-lg-12">
                                <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <img src="/assets/images/employee-refer.png" alt="Refer a Friend" className="w-100" />
                                        </div>
                                        <div className="col-lg-6 ps-lg-5">
                                            <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Oportunidades Internas</h2>
                                            <p className="font-xssss fw-500 text-grey-500 lh-26">Acreditamos que os nossos colaboradores são os melhores embaixadores da nossa empresa e que podem transmitir a experiência positiva de trabalhar connosco.</p>
                                            <p className="font-xssss fw-500 text-grey-500 lh-26">Vamos recrutar juntos e encontrar o teu/a tua próximo(a) colega.</p>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            }
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title="Oportunidades Internas" color="success" />
                                
                                <div className="row">
                                    <CustomLoadingOverlay active={viewerLoading || loading}>
                                    {jobOpportunities.map((value , index) => (
                                    <div key={index} className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4" style={{paddingLeft: "120px"}}>
                                        
                                        <img src={getImageUrl(value.company?.logo, "/assets/images/unknown-company-icon.png")} alt={value.company?.name} className="position-absolute p-2 bg-lightblue2 w65 ms-4 left-0" />
                                        <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4"><Link to={`/recruitment/jobOpportunities/${value.id}`}>{value.title}</Link> <span className="font-xssss fw-500 text-grey-500 ms-4">adicionada <Moment fromNow>{value.createdDate}</Moment></span> </h4>
                                        {value.company?.name && <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Empresa:</span> {value.company?.name}</h5>}
                                        {value.department?.name && <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Departamento:</span> {value.department?.name}</h5>}
                                        {value.location && <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Local de Trabalho:</span> {value.location}</h5>}
                                        {value.deadline && <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Prazo para Candidaturas:</span> <Moment format="DD/MM/YYYY">{value.deadline}</Moment></h5>}
                                        { listsData?.JobApplicationInternalList?.filter(e => e.jobOpportunity.id === value.id).length > 0 && <h6 className="d-inline-block p-2 text-success alert-success fw-600 font-xssss rounded-3 me-2">Já te candidaste a esta vaga</h6> }
                                        <Link to={`/recruitment/jobOpportunities/${value.id}`} className="position-absolute bottom-15 mb-3 right-15"><i className="btn-round-sm bg-success text-white font-sm feather-chevron-right"></i></Link>
                                    
                                    </div>
                                    ))}
                                    
                                    {jobOpportunities.length === 0 && <p className="text-grey-500 text-center">Não existem oportunidades abertas.</p>}

                                    </CustomLoadingOverlay>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default JobOpportunityList;