import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";

const GET_LISTS_QUERY = gql`
    query GetLists {
        TrainingEntityList {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $trainingEntity: inp_TrainingEntity!, $name: String!, $email: String, $phone: String, $certificateNumber: String, $isActive: Boolean) {
        upsert_Trainer(trainer: { id: $id, trainingEntity: $trainingEntity, name: $name, email: $email, phone: $phone, certificateNumber: $certificateNumber, isActive: $isActive }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainerById(id: $id) {
            id
            trainingEntity {
                id
            }
            name
            email
            phone
            certificateNumber
            isActive
        }
    }
`;

function AdminNewTrainer() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.TrainerById);

        if(!editData && searchParams.get("entityId")) {
            reset({
                trainingEntity: { id: searchParams.get("entityId") }
            })
        }
    }, [editData, listsData, searchParams]);

    const upsert = async (formData) => {
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/training/trainingEntities/${formData.trainingEntity.id}`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Formador</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Entidade Formadora</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" {...register("trainingEntity.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingEntityList && listsData.TrainingEntityList.slice().sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.trainingEntity?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("email")} />
                                                    {errors.email && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone")} />
                                                    {errors.phone && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nº do Certificado</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("certificateNumber")} />
                                                    {errors.certificateNumber && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Website</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("website")} />
                                                    {errors.website && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isActive" {...register("isActive")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isActive">Ativo</label>
                                                    {errors.isVisible && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewTrainer;