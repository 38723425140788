import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import Moment from 'react-moment';
import Load from './Load';
import { getProfilePicture } from '../utils';

const GET_FEEDBACK_REQUESTS_QUERY = gql`
    query GetFeedbackRequests($fetchRequests: Boolean!, $recipientId: UUID) {
        FeedbackRequestList(filter: { 
        AND: [
            { recipient: { id: { _eq: $recipientId } } },
            { isDeclined: { _eq: false } },
            { feedbackReply: { _isNull: true } }
        ] }, orderBy: { createdDate: DESC }, limit: 6)  @include(if: $fetchRequests) {
            id
            requester {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
            message
            createdDate
        }
    }
`;

const UPSERT_DECLINE_FEEDBACK_REQUEST_MUTATION = gql`
    mutation UpsertFeedbackRequest($id: UUID!) {
        upsert_FeedbackRequest(feedbackRequest: { id: $id, isDeclined: true }) {
            id
        }
    }
`;

function FeedbackRequests({ employeeId }) {

    const { data, loading, error, refetch } = useQuery(GET_FEEDBACK_REQUESTS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { recipientId: employeeId || "00000000-0000-0000-0000-000000000000", fetchRequests: employeeId !== null },
        skip: !employeeId
    });
    
    const [upsertDeclineFeedbackRequestMutation, { upsertDeclineFeedbackRequestMutationData }] = useMutation(UPSERT_DECLINE_FEEDBACK_REQUEST_MUTATION);

    if(!employeeId) return null;

    if(loading) return <Load/>;

    if(!data?.FeedbackRequestList || data?.FeedbackRequestList?.length === 0) return null;

    const declineRequest = async (id) => {
        const upsertResult = await upsertDeclineFeedbackRequestMutation({ 
            variables: {
                id
            },
        })

        if(upsertResult.data) {
            toast.success('Pedido recusado!');
        }
        
        await refetch();
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Pedidos de Feedback <i className="em em-speech_balloon ms-1"></i></h4>
                <Link to={"/feedback/requests"} className="fw-600 ms-auto font-xssss text-success">Ver todos</Link>
            </div>
            {data && data.FeedbackRequestList && data.FeedbackRequestList.map((value , index) => (
            <div className="wrap" key={index}>
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                    <figure className="avatar me-3"><img src={getProfilePicture(value.requester)} alt={value.requester.name} className="shadow-sm rounded-circle w45 aspect-ratio-1"/></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">{value.requester.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"><Moment fromNow>{ value.createdDate }</Moment></span></h4>
                </div>
                <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                    <Link to={`/feedback/give?requestId=${value.id}`} className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Responder</Link>
                    <a role="button" className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => declineRequest(value.id)}>Recusar</a>
                </div>
            </div>

            ))}

            
        </div>
    );
}

export default FeedbackRequests;