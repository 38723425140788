import React, { Fragment } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getImageUrl } from "../../utils";
import { pt } from "date-fns/locale";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_USER_MOOD_QUERY = gql`
    query GetUserMoods($userId: UUID) {
        UserMoodList(filter: { user: { id: { _eq: $userId } } }, orderBy: { createdDate: DESC }) {
            id
            score
            comments
            createdDate
        }
    }
`;

function MoodTrackerCalendar() {
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { data: userMoodsData, loading: userMoodsLoading, error: userMoodsError } = useQuery(GET_USER_MOOD_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { userId: viewerData && viewerData.Viewer && viewerData.Viewer.id },
        skip: !(viewerData && viewerData.Viewer && viewerData.Viewer)
    });

    const userMoods = userMoodsData && userMoodsData.UserMoodList || [];

    const dateRange = (startDate, endDate) => {
        var start      = startDate.split('-');
        var end        = endDate.split('-');
        var startYear  = parseInt(start[0]);
        var endYear    = parseInt(end[0]);
        var dates      = [];
      
        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1])-1 : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            dates.push([i, displayMonth, '01'].join('-'));
          }
        }
        return dates;
    }

    var result = userMoods.reduce(function(h, obj) {
        h[obj.createdDate.split('T')[0]] = (h[obj.createdDate.split('T')[0]] || []).concat(obj);
        return h; 
    }, {});
      
    result = Object.keys(result).map(key => {
        return {
            date: key, 
            scoreAvg: result[key].reduce((a, b) => a + (b.score || 0), 0)/result[key].length,
            scoreCount: result[key].length,
        }
    });
    console.log(result);

    const currentDateRange = dateRange(`${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`);

    const mood1Days = result.filter(m => m.scoreAvg <= 1.45).map(m => new Date(m.date));
    const mood2Days = result.filter(m => m.scoreAvg > 1.45 && m.scoreAvg < 4.45).map(m => new Date(m.date));
    const mood3Days = result.filter(m => m.scoreAvg >= 4.45 && m.scoreAvg <= 5.55).map(m => new Date(m.date));
    const mood4Days = result.filter(m => m.scoreAvg > 5.55 && m.scoreAvg < 9.45).map(m => new Date(m.date));
    const mood5Days = result.filter(m => m.scoreAvg >= 9.45).map(m => new Date(m.date));

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title="Bem-estar" color="success" />

                                <CustomLoadingOverlay active={viewerLoading || userMoodsLoading}>
                                <div className="row">
                                    {currentDateRange.map((date, index) => (
                                    <div key={index} className="col-md-4">
                                        <DayPicker
                                        disableNavigation
                                        mode="single"
                                        locale={pt}
                                        defaultMonth={new Date(date)}
                                        modifiers={{
                                            mood1: mood1Days,
                                            mood2: mood2Days,
                                            mood3: mood3Days,
                                            mood4: mood4Days,
                                            mood5: mood5Days,
                                        }}
                                        modifiersStyles={{
                                            mood1: { backgroundImage: 'url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f622.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundColor: 'transparent', color: 'transparent' },
                                            mood2: { backgroundImage: 'url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f60c.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundColor: 'transparent', color: 'transparent' },
                                            mood3: { backgroundImage: 'url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f31d.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundColor: 'transparent', color: 'transparent' },
                                            mood4: { backgroundImage: 'url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f601.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundColor: 'transparent', color: 'transparent' },
                                            mood5: { backgroundImage: 'url(https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/1f60a.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundColor: 'transparent', color: 'transparent' },
                                        }}
                                        />
                                    </div>
                                    ))}
                                </div>
                                </CustomLoadingOverlay>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default MoodTrackerCalendar;