import React, { Component, useState } from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';

import { formatDuration, getImageUrl } from '../utils';

import Comments from './comments/Comments';
import { toast } from 'react-toastify';

const LearningVideo = ({ video, youtubeVideoId }) => (
    <Fragment>
        {!youtubeVideoId && (video.thumbnail || video.thumbnailExternalUrl) ?
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
            <a href={video.url} target="_blank" rel="noopener" className="video-btn">
                <img src={getImageUrl(video.thumbnail, video.thumbnailExternalUrl)} className="rounded-3 w-100" alt={video.title} />
            </a>
        </div>
        : ''}
        {youtubeVideoId ?  
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
            <iframe className="w-100" style={{aspectRatio: '16 / 9'}} src={`https://www.youtube.com/embed/${youtubeVideoId}?&autoplay=1`} title={video.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        : ''}
        <div className="card-body ps-4 pe-4 pt-0 pb-0 mb-3">
            <p className="fw-700 lh-26 font-xs w-100 mb-1">{video.title}</p>
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                {video.category && <span><i className="font-xsss text-grey-500 feather-list me-1"></i> <a href={`/learning?category=${video.category.id}`} className="fw-600 text-success">{video.category.name}</a></span>}
                {video.source && <span><i className="font-xsss text-grey-500 feather-folder ms-3 me-1"></i> <a href={`/learning?source=${video.source.id}`} className="fw-600 text-success">{video.source.name}</a></span>}
                {video.author && <span><i className="font-xsss text-grey-500 feather-user ms-3 me-1"></i> <a href={`/learning?author=${video.author.id}`} className="fw-600 text-success">{video.author.name}</a></span>}
            </p>
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2">{ video.description }</p>
            <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mt-3">Adicionado <Moment fromNow>{ video.createdDate }</Moment></p>
            {false && <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"><a href="/defaultvideo" className="fw-600 text-success">See more</a></p>}
        </div>
    </Fragment>
);

const LearningCourse = ({ course }) => (
    <Fragment>
        <div className="card-body ps-4 pe-4 pt-4 pb-0 mb-3">
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                {course.category && <span><i className="font-xsss text-grey-500 feather-list me-1"></i> <a href={`/learning?category=${course.category.id}`} className="fw-600 text-success">{course.category.name}</a></span>}
                {course.source && <span><i className="font-xsss text-grey-500 feather-folder ms-3 me-1"></i> <a href={`/learning?source=${course.source.id}`} className="fw-600 text-success">{course.source.name}</a></span>}
                {course.author && <span><i className="font-xsss text-grey-500 feather-user ms-3 me-1"></i> <a href={`/learning?author=${course.author.id}`} className="fw-600 text-success">{course.author.name}</a></span>}
            </p>
            <p className="fw-700 lh-26 font-sm w-100 mt-1 mb-1">{course.title}</p>
            <div className="row">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                    <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2">{ course.description }</p>
                    <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mt-3">Adicionado <Moment fromNow>{ course.createdDate }</Moment></p>
                    <a href={course.url} target="_blank" rel="noopener" className="mt-1 p-0 btn p-2 lh-24 ps-3 pe-3  ms-1 ls-3 d-inline-block rounded-xxxl bg-current font-xssss fw-700 ls-lg text-white">Ir para o curso <i className="font-xss text-white feather-external-link ms-1 mt-1"></i></a>
                </div>
                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                    <img src={getImageUrl(course.thumbnail, course.thumbnailExternalUrl)} className="rounded-3 w-100 mb-4 aspect-ratio-285-160" alt={course.title} />
                    { course.duration && <h5 className="mt-1 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-time text-grey-500 me-1"></i> {formatDuration(course.duration)}</h5> }
                    <div className="clearfix"></div>
                    <h5 className="mt-1 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-money text-grey-500 me-1"></i> { course.isPaid ? 'Pago' : 'Gratuito' }</h5>
                    <div className="clearfix"></div>
                    { course.hasCertificate && <h5 className="mt-1 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-tag text-grey-500 me-1"></i> Com certificado </h5> }
                    <div className="clearfix"></div>
                    { course.language && <h5 className="mt-1 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-headphone text-grey-500 me-1"></i> {course.language.name}</h5> }
                </div>
            </div>
        </div>
    </Fragment>
);

const LearningPodcast = ({ item, spotifyId }) => (
    <Fragment>
        {!spotifyId && (item.thumbnail || item.thumbnailExternalUrl) ?
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
            <a href={item.url} target="_blank" rel="noopener" className="video-btn">
                <img src={getImageUrl(item.thumbnail, item.thumbnailExternalUrl)} className="rounded-3 w-100" alt={item.title} />
            </a>
        </div>
        : ''}
        {spotifyId ?  
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
            <iframe style={{borderRadius: "12px"}} src={`https://open.spotify.com/embed/${spotifyId}`} width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
        : ''}
        <div className="card-body ps-4 pe-4 pt-0 pb-0 mb-3">
            <p className="fw-700 lh-26 font-xs w-100 mb-1">{item.title}</p>
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                {item.category && <span><i className="font-xsss text-grey-500 feather-list me-1"></i> <a href={`/learning?category=${item.category.id}`} className="fw-600 text-success">{item.category.name}</a></span>}
                {item.source && <span><i className="font-xsss text-grey-500 feather-folder ms-3 me-1"></i> <a href={`/learning?source=${item.source.id}`} className="fw-600 text-success">{item.source.name}</a></span>}
                {item.author && <span><i className="font-xsss text-grey-500 feather-user ms-3 me-1"></i> <a href={`/learning?author=${item.author.id}`} className="fw-600 text-success">{item.author.name}</a></span>}
            </p>
            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-0 mb-2">{ item.description }</p>
            <p className="review-link font-xssss fw-600 text-grey-500 lh-3 mt-3">Adicionado <Moment fromNow>{ item.createdDate }</Moment></p>
            {false && <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2"><a href="/defaultvideo" className="fw-600 text-success">See more</a></p>}
        </div>
    </Fragment>
);

function LearningItemView(props) {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [isCommentsOpen, setIsCommentsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleActive = (userReaction) => {
        if(!isActive && userReaction) {
            handleDeleteReaction(userReaction.id)
        } else {
            setIsActive(!isActive);
        }
    }

    const toggleCommentsOpen = () => setIsCommentsOpen(!isCommentsOpen);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('Copiado para a área de transferência');
        } catch (err) {
            toast.error('Ocorreu um erro ao copiar para a área de transferência');
        }
    }

    const { video, course, podcast, handleLike, handleLove, handleDeleteReaction, handleCreateComment, handleCreateReply, currentUser, onCommentDeleted } = props;
    
    const menuClass = `${isOpen ? " show" : ""}`;
    const emojiClass = `${isActive ? " active" : ""}`;

    const item = video || course || podcast;
    
    const commentsCount = (item.comments || []).length;
    const reactionsLikeCount = (item.reactions || []).filter(r => r.type === 'LIKE').length;
    const reactionsLoveCount = (item.reactions || []).filter(r => r.type === 'LOVE').length;

    const hasUserReact = (item.reactions || []).filter(r => r.user.id === currentUser).length > 0;
    const userReaction = (hasUserReact && (item.reactions || []).filter(r => r.user.id === currentUser)[0]) || null;
    const reactionClass = `${hasUserReact ? " bg-grey p-2 rounded-xl" : ""}`;

    let itemContent = null;
    if(video) {
        const url = new URL(video.url);
        const searchParams = url.searchParams;

        var youtubeVideoId = null;
        if(video.url.includes("youtube")) {
            youtubeVideoId = searchParams.get('v'); // 'node'
        }

        itemContent = <LearningVideo video={video} youtubeVideoId={youtubeVideoId} />
        //postContent = <BadgeAssignmentPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} />
    } else if(course) {
        itemContent = <LearningCourse course={course} />
    } else if(podcast) {
        var spotifyId = null;
        if(podcast?.url.includes("spotify")) {
            spotifyId = podcast.url.replace('https://open.spotify.com/', '')
        }

        itemContent = <LearningPodcast item={podcast} spotifyId={spotifyId} />
        //postContent = <EmployeeEntryPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} />
    } else {
        //postContent = <DefaultPostContent title={title} content={des} postImage={postimage} postVideo={postvideo} attachments={attachments} employee={employee} />
    }

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3">
            {itemContent}

            <div className="card-body d-flex p-4 pt-0">
                <div className={`emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2 ${reactionClass}`} onClick={ () => { toggleActive(userReaction) }}><i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> {reactionsLikeCount} <i className="feather-heart text-white bg-red-gradiant ms-2 me-2 btn-round-xs font-xss"></i>{ reactionsLoveCount }</div>
                <div className={`emoji-wrap pointer ${emojiClass}`}>
                    <ul className="emojis list-inline mb-0">
                        { handleLike && <li className="emoji list-inline-item" onClick={ () => { handleLike(); toggleActive(); } }><i className="em em---1"></i> </li> }
                        { handleLove && <li className="emoji list-inline-item" onClick={ () => { handleLove(); toggleActive(); } }><i className="em em-heart"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-angry"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-anguished"></i> </li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-astonished"></i> </li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-blush"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-clap"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-cry"></i></li> }
                        { false && <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li> }
                    </ul>
                </div>
                <a role="button" className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss" onClick={ () => { toggleCommentsOpen(); } }><i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss">{ commentsCount + (commentsCount === 1 ? ' comentário' : ' comentários') }</span></a>
                
                {false && <a role="button" className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ms-2" onClick={ () => { } }><i className="feather-bookmark text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss">Ver Depois</span></a> }
                {false && <a role="button" className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ms-2" onClick={ () => { } }><i className="feather-list text-dark text-grey-900 btn-round-sm font-lg"></i><span className="d-none-xss">Guardar</span></a> }
                
                {true &&
                <Fragment>
                <div className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`} id={`dropdownMenu${item.id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span className="d-none-xs">Partilhar</span></div>
                <div className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`} aria-labelledby={`dropdownMenu${item.id}`} style={{top: '50%'}}>
                    <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Partilhar <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2" onClick={toggleOpen}></i></h4>
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><a href={`mailto:?subject=${item.title}&body=${item.title}%0A${window.location.href}`} target="_blank" rel="noopener"><span className="btn-round-lg pointer bg-dark"><i className="font-xs ti-email text-white"></i></span></a></li>
                            {false && <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i className="font-xs ti-facebook text-white"></i></span></li>}
                            {false && <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i className="font-xs ti-twitter-alt text-white"></i></span></li>}
                            <li className="me-1"><a href={`http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`} target="_blank" rel="noopener"><span className="btn-round-lg pointer bg-linkedin"><i className="font-xs ti-linkedin text-white"></i></span></a></li>
                            {false && <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i className="font-xs ti-instagram text-white"></i></span></li>}
                            {false && <li><span className="btn-round-lg pointer bg-pinterest"><i className="font-xs ti-pinterest text-white"></i></span></li>}
                        </ul>
                    </div>
                    { false &&
                    <div className="card-body p-0 d-flex">
                        <ul className="d-flex align-items-center justify-content-between mt-2">
                            <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i className="font-xs ti-tumblr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i className="font-xs ti-youtube text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i className="font-xs ti-flickr text-white"></i></span></li>
                            <li className="me-1"><span className="btn-round-lg pointer bg-black"><i className="font-xs ti-vimeo-alt text-white"></i></span></li>
                            <li><span className="btn-round-lg pointer bg-whatsup"><i className="font-xs feather-phone text-white"></i></span></li>
                        </ul>
                    </div>
                    }
                    <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">Copiar Link</h4>
                    <input type="text" value={window.location.href} onClick={(event) => { event.target.select(); copyToClipboard(window.location.href); }} className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" readOnly autoComplete='off' />
                </div>
                </Fragment>
                }
            </div>
            <div className="ps-4 pe-4">
                { isCommentsOpen && <Comments postId={item.id} comments={item.comments} currentUser={currentUser} commentsEnabled={true} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateReply} onCommentDeleted={onCommentDeleted} type="comment" /> }
            </div>
        </div>
    );
}

export default LearningItemView;