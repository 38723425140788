import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback().then(async resp => {
                console.log('signinSilentCallback dispatch')
                // dispatch({
                //   type: 'update',
                //   userData: {
                //     id: resp.profile.sub,
                //     jti: resp.profile.jti,
                //     roles: resp.profile.roles,
                //     auth: true,
                //     profile: resp.profile
                //   }
                // })
                
              }).catch(error => {
                // dispatch({
                //   type: 'reset'
                // })
                // setStatus(error.message)
                console.log('error: ' + error)
              });
            return <span>loading</span>;
        }}
    </AuthConsumer>
);