import React, { useState } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { useQuery, gql } from '@apollo/client';

import { getImageUrl } from '../utils';

const GET_QUERY = gql`
    query Get($id: String!) {
        MediaGalleryById(id: $id) {
            id
            items {
                id
                name
                file
                displayOrder
            }
        }
    }
`;

function Gallery({ id, title, showContainer, maxImages }) {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const { loading, error, data, refetch } = useQuery(GET_QUERY, {
        variables: { id: id },
        skip: !id
    });

    const openImage = (index) => {
        setSelectedIndex(index)
        setIsOpen(true)
    }

    if(!id) return null;

    const images = data?.MediaGalleryById?.items?.slice().sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1) || [];

    if(images.length === 0) return null;
    //console.log(images)

    let nextImage, prevImage;
    nextImage = images.length > 1 ? images[(selectedIndex + 1) % images.length]?.file : undefined;
    prevImage = images.length > 1 ? images[(selectedIndex + images.length - 1) % images.length]?.file : undefined;
    
    return (
        <div className={`card d-block border-0 ${showContainer ? "rounded-3 overflow-hidden p-4 shadow-xss mt-4" : ""}`}>
            { title && <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 mb-3">{ title }</h2> }
            <div className="row ps-3 pe-3">
                {images.map((value , index) => (
                    <div className="col-sm-4 col-xss-4 pe-1 ps-1 mb-2" key={index}>
                        {isOpen && (
                            <Lightbox
                                mainSrc={getImageUrl(images[selectedIndex]?.file)}
                                nextSrc={getImageUrl(nextImage)}
                                prevSrc={getImageUrl(prevImage)}
                                enableZoom={false}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() => setSelectedIndex((selectedIndex + images.length - 1) % images.length)}
                                onMoveNextRequest={() => setSelectedIndex((selectedIndex + 1) % images.length)}
                            />
                        )}
                        
                        {(!maxImages || index < maxImages) &&
                        <div onClick={() => openImage(index)}>
                            <a href="#gallery" className={`${index == (maxImages-1) ? 'position-relative d-block' : 'false'}`}>
                                <img src={getImageUrl(value.file)} alt={value.name} className="w-100 rounded-3 aspect-ratio-385-300" style={{objectFit: 'cover'}} />
                                { images.length > maxImages && index == (maxImages-1) && <span className="img-count font-sm text-white ls-3 fw-600"><b>+{images.length-maxImages}</b></span> }
                            </a>
                        </div>}
                    
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;