import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import { generateRandonString } from "../../utils";
import { Modal } from "react-bootstrap";
import Appfooter from "../../components/Appfooter";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            email
            employee {
                id
                phone
                email
            }
        }
    }
`;

const GET_CATEGORIES_QUERY = gql`
    query GetCategories {
        ComplaintCategoryList(filter: { isActive: { _eq: true } }, orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_COMPLAINT_MUTATION = gql`
    mutation UpsertComplaint($userId: UUID!, $name: String!, $email: String!, $phone: String!, $subject: String, $description: String, $categoryId: UUID, $password: String) {
        upsert_Complaint(complaint: { user: { id: $userId }, name: $name, email: $email, phone: $phone, subject: $subject, description: $description, category: { id: $categoryId }, password: $password, status: NEW }) {
            id
        }
    }
`;

const UPSERT_ANONYMOUS_COMPLAINT_MUTATION = gql`
    mutation UpsertComplaint($subject: String, $description: String, $categoryId: UUID, $password: String) {
        upsert_Complaint(complaint: { subject: $subject, description: $description, category: { id: $categoryId }, password: $password, status: NEW }) {
            id
        }
    }
`;

const UPSERT_COMPLAINT_ATTACHMENT_MUTATION = gql`
    mutation UpsertComplaintAttachment($complaintId: UUID, $file: Upload, $password: String) {
        upsert_ComplaintAttachment(complaintAttachment: { complaint: { id: $complaintId }, file: $file, password: $password }) {
            id
            name
        }
    }
`;

const ConfirmModal = (props) => {
    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success('Copiado para a área de transferência');
        } catch (err) {
            toast.error('Ocorreu um erro ao copiar para a área de transferência');
        }
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Denúncia enviada com sucesso!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb-3">A sua denúncia foi submetida. Guarde a seguinte palavra-passe:</h4>
          <p>
            <span className="text-danger fw-700">IMPORTANTE:</span> Guarde a palavra-passe abaixo e armazene-a com segurança. A sua denúncia foi enviada e a palavra-passe dá-lhe acesso ao caso. Não partilhe a palavra-passe com ninguém.
          </p>
          <p className="fw-700">A sua palavra-passe, que deve ser guardada:</p>
          
            <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
            <input type="text" className="bg-grey text-grey-500 font-xss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" autoComplete="off" value={`${props.id}:${props.password}`} onClick={(event) => { event.target.select(); copyToClipboard(`${props.id}:${props.password}`); }} readOnly />
          <p className="text-dark fw-700">A palavra-passe não será enviada para o seu e-mail. Deve guardar a palavra-passe desta página antes de continuar.</p>

          <p>Recebemos a sua comunicação e voltaremos a entrar em contacto consigo tão breve quanto possível. Pode acompanhar e ver mensagens enviadas por nós através da consulta da sua denúncia.</p>
        </Modal.Body>
        <Modal.Footer>
          <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}

function CreateComplaint() {

    const navigate = useNavigate();

    const [complaintPassword, setComplaintPassword] = useState([]);
    const [complaintId, setComplaintId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);

    const [isConfidentialReadMoreOpen, setIsConfidentialReadMoreOpen] = useState(false);
    const [isAnonymousReadMoreOpen, setIsAnonymousReadMoreOpen] = useState(false);

    const toggleConfidentialReadMoreOpen = () => setIsConfidentialReadMoreOpen(!isConfidentialReadMoreOpen);
    const toggleAnonymousReadMoreOpen = () => setIsAnonymousReadMoreOpen(!isAnonymousReadMoreOpen);

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm({
        defaultValues: {
          mode: 'confidential',
        }
    });

    useEffect(() => {
        setComplaintPassword(generateRandonString(12))
      }, []);

    const watchComplaintMode = watch("mode");

    const [upsertComplaintMutation, { upsertComplaintMutationData }] = useMutation(UPSERT_COMPLAINT_MUTATION);
    const [upsertAnonymousComplaintMutation, { upsertAnonymousComplaintMutationData }] = useMutation(UPSERT_ANONYMOUS_COMPLAINT_MUTATION);
    const [upsertComplaintAttachmentMutation, { upsertComplaintAttachmentMutationData }] = useMutation(UPSERT_COMPLAINT_ATTACHMENT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = useQuery(GET_CATEGORIES_QUERY);

    const createComplaint = async (formData) => {
        const upsertResult = formData.mode === 'confidential' ? (
            await upsertComplaintMutation({ 
                variables: {
                    userId: viewerData.Viewer.id,
                    name: formData.name,
                    email: formData.email,
                    phone: formData.phone,
                    subject: formData.subject,
                    description: formData.description,
                    categoryId: formData.categoryId,
                    password: complaintPassword,
                    //attachments: [{ file: selectedFile }],
                },
            })
        ) : (
            await upsertAnonymousComplaintMutation({ 
                variables: {
                    subject: formData.subject,
                    description: formData.description,
                    categoryId: formData.categoryId,
                    password: complaintPassword,
                    //attachments: [{ file: selectedFile }],
                },
            })
        )

        if(upsertResult.data) {
            attachments.map(async a => {
                uploadAttachment(upsertResult.data.upsert_Complaint.id, a)
            })
            
            toast.success('Denúncia enviada com sucesso!');
            //navigate(`/whistleblowing/complaints`)
            setComplaintId(upsertResult.data.upsert_Complaint.id)
            setModalShow(true);
        }
    }

    const uploadAttachment = async (complaintId, file) => {
        const upsertResult = await upsertComplaintAttachmentMutation({ 
            variables: {
                complaintId: complaintId,
                file: file,
                password: complaintPassword,
            },
        });
    }

    const onFileChange = event => { 
        //uploadAttachment(event.target.files[0])
        setSelectedFile(event.target.files[0])
        event.target.files[0].storageName = "privateFs"
        console.log(event.target.files[0])
        setAttachments(prevState => [...prevState, event.target.files[0]])
        console.log(attachments)
    };

    const removeAttachment = name => {
        setAttachments(prevState => prevState.filter(a => a.name !== name))
    }
    
    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Nova Denúncia</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <p>Como colaborador, pode denunciar situações sérias de forma anónima ou alguma situação caso suspeite da mesma.</p>

                                    <form onSubmit={handleSubmit(createComplaint)}>
                                        <label className="mont-font fw-600 font-xsss">Escolha como pretende efetuar a denúncia</label>
                                        <div className="card border-0 mb-3 bg-greyblue p-3">
                                            <div className="payment-group mb-4">
                                                <div className="payment-radio">
                                                    <input type="radio" value="confidential" {...register("mode", { required: true })} />
                                                    <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">Denunciar de forma confidencial</label>
                                                </div>
                                                <div>
                                                    <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                    Pode escolher denunciar de forma confidencial. A sua identidade será apenas revelada às pessoas que tratarem a denúncia, com confidencialidade e aparecerá como anónima e confidencial a outras pessoas também envolvidas no processamento da denúncia.
                                                    </p>
                                                    { isConfidentialReadMoreOpen && <>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        A sua identidade não será revelada a outras pessoas dentro da organização sem o seu consentimento. A sua concordância com a franqueza sobre a sua identidade só será solicitada se for necessária para o processamento interno do caso. 
                                                        </p>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        Ao usar o canal de denúncias de forma confidencial, a sua denúncia e a sua identidade serão registradas, o que significa que pode documentar qualquer direito à proteção sob a legislação de denúncias desde o primeiro contacto feito. 
                                                        </p>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        É sempre recomendável usar a opção de denúncia confidencial, pois fornece o melhor processamento possível e garante-lhe a melhor proteção possível.
                                                        </p>
                                                    </> }
                                                    <a role="button" className="font-xssss lh-24 text-grey-700 fw-600 ps-4" onClick={() => toggleConfidentialReadMoreOpen()}>{isConfidentialReadMoreOpen ? "Ler menos" : "Ler mais"}</a>
                                                </div>
                                            </div>
                                            <div className="payment-group">
                                                <div className="payment-radio">
                                                    <input type="radio" value="anonymous" {...register("mode", { required: true })} />
                                                    <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">Denunciar de forma anónima</label>
                                                </div>
                                                <div>
                                                    <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                    Se preferir não revelar a sua identidade durante o processo, pode escolher denunciar de forma anónima.
                                                    </p>
                                                    { isAnonymousReadMoreOpen && <>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        Ao denunciar de forma anónima, poderá ter dificuldades, futuramente, ao invocar a proteção do abrigo da legislação sobre denúncia de irregularidades.
                                                        </p>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        Se escolher denunciar de forma anónima, deve certificar-se de que não deixa rastos eletrónicos ao longo do processo. Verifique a existência de metadados nos ficheiros que carregar e não utilize este sistema num computador ou telemóvel da empresa. Assim, terá a melhor proteção possível enquanto denunciante anónimo/a.
                                                        </p>
                                                        <p className="font-xssss lh-24 text-grey-700 fw-500 ps-4">
                                                        Recomendamos que denuncie de forma confidencial, uma vez que lhe garante a melhor proteção possível.
                                                        </p>
                                                    </> }
                                                    <a role="button" className="font-xssss lh-24 text-grey-700 fw-600 ps-4" onClick={() => toggleAnonymousReadMoreOpen()}>{isAnonymousReadMoreOpen ? "Ler menos" : "Ler mais"}</a>
                                                </div>
                                            </div>
                                            {errors.mode && <span className="text-red">Este campo é obrigatório</span>}
                                        </div>

                                        {watchComplaintMode === 'confidential' &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name")} />
                                                    {errors.name && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        
                                        {watchComplaintMode === 'confidential' &&
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("email")} />
                                                    {errors.email && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone")} />
                                                    {errors.phone && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("categoryId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            categoriesData && categoriesData.ComplaintCategoryList && categoriesData.ComplaintCategoryList
                                                            .map((c) => {
                                                                return (
                                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.categoryId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Assunto</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("subject", { required: true })} />
                                                    {errors.subject && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("description", { required: true })} />
                                                    {errors.description && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <label className="mont-font fw-600 font-xsss">Anexos</label>
                                                    <ul className="mb-3">
                                                    {attachments.map((a, index) => (
                                                        <li key={index}><i className="font-xss text-grey-500 feather-paperclip me-2"></i> {a.name}<a role="button" onClick={() => removeAttachment(a.name)}><i className="font-xss text-grey-500 feather-x ms-2"></i></a></li>
                                                    ))}
                                                    </ul>
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createComplaint)}>Enviar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        <ConfirmModal
            show={modalShow}
            id={complaintId}
            password={complaintPassword}
            onHide={() => navigate(`/whistleblowing/complaints`)}
        />

        </Fragment>
    );
}

export default CreateComplaint;