import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import { KEY_RESULT_STATUS_OPTIONS } from "../../config";
import Appfooter from "../../components/Appfooter";

const GET_KEY_RESULT_QUERY = gql`
    query GetKeyResult($fetchKeyResult: Boolean! $id: String!) {
        KeyResultById(id: $id) @include(if: $fetchKeyResult)  {
            id
            name
        }
    }
`;


const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_KEY_RESULT_CHECKIN_MUTATION = gql`
    mutation UpsertKeyResultCheckin($keyResultId: UUID!, $currentValue: Int, $status: KeyResultStatus, $reflections: String) {
        upsert_KeyResultCheckin(keyResultCheckin: { keyResult: { id: $keyResultId }, currentValue: $currentValue, status: $status, reflections: $reflections }) {
            id
        }
    }
`;


function CreateKeyResultCheckin() {

    const navigate = useNavigate();

    const { keyResultId } = useParams();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertKeyResultCheckinMutation, { upsertKeyResultCheckinMutationData }] = useMutation(UPSERT_KEY_RESULT_CHECKIN_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    
    const { loading: keyResultLoading, error: keyResultError, data: keyResultData } = useQuery(GET_KEY_RESULT_QUERY, { variables: { id: keyResultId || "00000000-0000-0000-0000-000000000000", fetchKeyResult: keyResultId !== null }});
    
    if (keyResultLoading || viewerLoading) return <LoadingOverlayWrapper/>;

    const createKeyResultCheckin = async (formData) => {
        const upsertResult = await upsertKeyResultCheckinMutation({ 
            variables: {
                keyResultId: keyResultId,
                currentValue: formData.currentValue,
                status: formData.status,
                reflections: formData.reflections,
            },
        });

        if(upsertResult.data) {
            toast.success('Checkin criado com sucesso!');
            navigate(-1)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Checkin</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(createKeyResultCheckin)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                    Texto texto
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xsss">Resultado Chave</label>
                                                    <input type="text" className="form-control" defaultValue={keyResultData && keyResultData.KeyResultById && keyResultData.KeyResultById.name}  {...register("keyResultId")} readOnly />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                        <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Valor Atual</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("currentValue", { required: true })} />
                                                    {errors.currentValue && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Estado</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("status", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            KEY_RESULT_STATUS_OPTIONS
                                                            .map((o) => {
                                                                return (
                                                                    <option key={o.id} value={o.id}>{o.name}</option> 
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.status && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Reflexões</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("reflections")} />
                                                    {errors.reflections && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>                                     
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createKeyResultCheckin)}>Guardar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default CreateKeyResultCheckin;