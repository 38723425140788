import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { TRAINING_ACTION_STATUS } from "../../../../config";
import moment from "moment";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        CompanyList(orderBy: { name: ASC }) {
            id
            name
        }
        DepartmentList(orderBy: { name: ASC }) {
            id
            name
        }
        UserList(filter: { isActive: { _eq: true } }, orderBy: { name: ASC }) {
            id
            name
        }
        TrainingEntityList(filter: { isActive: { _eq: true } }) {
            id
            name
        }
        TrainingActionTypeList(orderBy: { name: ASC }) {
            id
            name
        }
        TrainingAreaList(orderBy: { name: ASC }) {
            id
            name
        }
        TrainingActionInitiativeTypeList(orderBy: { name: ASC }) {
            id
            name
        }
        TrainingActionScheduleTypeList(orderBy: { name: ASC }) {
            id
            name
        }
        TrainingCertificateTypeList(orderBy: { name: ASC }) {
            id
            name
        }
        TrainingQualificationLevelList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $company: inp_Company, $name: String, $externalId: String, $trainingEntity: inp_TrainingEntity, $year: Int!, $expectedStartDate: Date, $startDate: DateTime, $endDate: DateTime, $expectedTrainingHoursAmount: Float, $trainingHoursAmount: Float, $enableEmployeeRegistration: Boolean, $maxRegistrations: Int, $requireRegistrationApproval: Boolean, $cost: Float, $proposerDepartment: inp_Department, $targetAudience: String, $objectives: String, $comments: String, $evaluationExpectedDate: Date, $evaluationAssignee: inp_User, $evaluationDate: Date, $evaluationEffectiveness: Boolean, $evaluationResults: String, $evaluationComments: String, $status: TrainingActionStatus, $type: inp_TrainingActionType, $area: inp_TrainingArea, $initiativeType: inp_TrainingActionInitiativeType, $scheduleType: inp_TrainingActionScheduleType, $certificateType: inp_TrainingCertificateType, $qualificationLevel: inp_TrainingQualificationLevel) {
        upsert_TrainingAction(trainingAction: { id: $id, company: $company name: $name, externalId: $externalId, trainingEntity: $trainingEntity, year: $year, expectedStartDate: $expectedStartDate, startDate: $startDate, endDate: $endDate, expectedTrainingHoursAmount: $expectedTrainingHoursAmount, trainingHoursAmount: $trainingHoursAmount, enableEmployeeRegistration: $enableEmployeeRegistration, maxRegistrations: $maxRegistrations, requireRegistrationApproval: $requireRegistrationApproval, cost: $cost, proposerDepartment: $proposerDepartment, targetAudience: $targetAudience, objectives: $objectives, comments: $comments, evaluationExpectedDate: $evaluationExpectedDate, evaluationAssignee: $evaluationAssignee, evaluationDate: $evaluationDate, evaluationEffectiveness: $evaluationEffectiveness, evaluationResults: $evaluationResults, evaluationComments: $evaluationComments, status: $status, type: $type, area: $area, initiativeType: $initiativeType, scheduleType: $scheduleType, certificateType: $certificateType, qualificationLevel: $qualificationLevel }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainingActionById(id: $id) {
            id
            company {
                id
            }
            name
            externalId
            trainingEntity {
                id
            }
            year
            expectedStartDate
            startDate
            endDate
            expectedTrainingHoursAmount
            trainingHoursAmount
            enableEmployeeRegistration
            maxRegistrations
            requireRegistrationApproval
            cost
            proposerDepartment {
                id
            }
            targetAudience
            objectives
            comments
            evaluationExpectedDate
            evaluationAssignee {
                id
            }
            evaluationDate
            evaluationEffectiveness
            evaluationResults
            evaluationComments
            status
            type {
                id
            }
            area {
                id
            }
            initiativeType {
                id
            }
            scheduleType {
                id
            }
            certificateType {
                id
            }
            qualificationLevel {
                id
            }
        }
    }
`;

function AdminNewTrainingAction() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const watchName = watch("name");

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.TrainingActionById);

        if(!editData && searchParams.get("entityId")) {
            reset({
                trainingEntity: { id: searchParams.get("entityId") }
            })
        }
    }, [editData, searchParams, listsData]);

    const upsert = async (formData) => {
        // optional select fields
        if(!formData?.proposerDepartment?.id || formData?.proposerDepartment?.id === '') {
            formData.proposerDepartment = null
        }

        if(!formData?.evaluationAssignee?.id || formData?.evaluationAssignee?.id === '') {
            formData.evaluationAssignee = null
        }

        if(!formData?.evaluationEffectiveness || formData?.evaluationEffectiveness === '') {
            formData.evaluationEffectiveness = null
        }

        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/training/trainingActions/${upsertResult.data.upsert_TrainingAction.id}`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Ação de Formação</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Empresa</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("company.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.CompanyList && listsData.CompanyList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.company?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Departamento</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("proposerDepartment.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.DepartmentList && listsData.DepartmentList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.proposerDepartment?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Ano</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("year", { required: true })} />
                                                    {errors.year && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Designação</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">ID Externo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("externalId")} />
                                                    {errors.externalId && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Entidade Formadora</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("trainingEntity.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingEntityList && listsData.TrainingEntityList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.trainingEntity?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data Prevista (Início)</label>
                                                    { /* <input type="datetime-local" className="form-control" autoComplete="off" {...register("expectedStartDate")} /> */ }
                                                    <Controller
                                                        control={control}
                                                        name="expectedStartDate"
                                                        rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                onChange={(date) => setValue(field.name, date.toDate())}
                                                                format={"DD/MM/YYYY HH:mm"}
                                                                plugins={[
                                                                    <TimePicker position="right"/>,
                                                                ]}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.expectedStartDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Início</label>
                                                    { /* <input type="datetime-local" className="form-control" autoComplete="off" {...register("startDate")} /> */ }
                                                    <Controller
                                                        control={control}
                                                        name="startDate"
                                                        rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                onChange={(date) => setValue(field.name, date.toDate())}
                                                                format={"DD/MM/YYYY HH:mm"}
                                                                plugins={[
                                                                    <TimePicker position="right"/>,
                                                                ]}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.startDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Fim</label>
                                                    { /* <input type="datetime-local" className="form-control" autoComplete="off" {...register("endDate")} /> */ }
                                                    <Controller
                                                        control={control}
                                                        name="endDate"
                                                        rules={{ valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                onChange={(date) => setValue(field.name, date.toDate())}
                                                                format={"DD/MM/YYYY HH:mm"}
                                                                plugins={[
                                                                    <TimePicker position="right"/>,
                                                                ]}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.endDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Carga Horária Prevista (h)</label>
                                                    <input type="number" min="0" step="0.01" className="form-control" autoComplete="off" {...register("expectedTrainingHoursAmount", { valueAsNumber: true })} />
                                                    {errors.expectedTrainingHoursAmount && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Carga Horária (h)</label>
                                                    <input type="number" min="0" step="0.01" className="form-control" autoComplete="off" {...register("trainingHoursAmount", { valueAsNumber: true })} />
                                                    {errors.trainingHoursAmount && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Custo</label>
                                                    <input type="number" min="0" step="0.01" className="form-control" autoComplete="off" {...register("cost", { valueAsNumber: true })} />
                                                    {errors.cost && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Vagas/Máx. Inscrições</label>
                                                    <input type="number" min="0" step="1" className="form-control" autoComplete="off" {...register("maxRegistrations", { valueAsNumber: true })} />
                                                    {errors.maxRegistrations && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="enableEmployeeRegistration" {...register("enableEmployeeRegistration")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="enableEmployeeRegistration">Permitir inscrições?</label>
                                                    {errors.enableEmployeeRegistration && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="requireRegistrationApproval" {...register("requireRegistrationApproval")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="requireRegistrationApproval">Necessária aprovação das inscrições?</label>
                                                    {errors.requireRegistrationApproval && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Estado</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("status", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            TRAINING_ACTION_STATUS.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.status && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Destinatários</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("targetAudience")} ></textarea>
                                                    {errors.targetAudience && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Objetivos</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("objectives")} ></textarea>
                                                    {errors.objectives && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Observações</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("comments")} ></textarea>
                                                    {errors.comments && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                            <div className="card-body p-0">
                                                <h6 className="fw-600 text-grey-500 font-xs">Informações Relatório Único</h6>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Modalidade</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("type.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingActionTypeList && listsData.TrainingActionTypeList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.type?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Área de Educação/Formação</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("area.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingAreaList && listsData.TrainingAreaList.slice().sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.area?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Iniciativa</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("initiativeType.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingActionInitiativeTypeList && listsData.TrainingActionInitiativeTypeList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.initiativeType?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Horário</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("scheduleType.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingActionScheduleTypeList && listsData.TrainingActionScheduleTypeList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.scheduleType?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Tipo de Certificado/Diploma</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("certificateType.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingCertificateTypeList && listsData.TrainingCertificateTypeList.slice().map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.certificateType?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nível de Qualificação</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("qualificationLevel.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.TrainingQualificationLevelList && listsData.TrainingQualificationLevelList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.qualificationLevel?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                            <div className="card-body p-0">
                                                <h6 className="fw-600 text-grey-500 font-xs">Avaliação/Resultados da Formação</h6>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data Prevista da Avaliação</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("evaluationExpectedDate")} />
                                                    {errors.evaluationExpectedDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Responsável pela Avaliação</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("evaluationAssignee.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.UserList && listsData.UserList.filter(e => e.name)
                                                            .map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.evaluationAssignee?.id && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data da Avaliação</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("evaluationDate")} />
                                                    {errors.evaluationDate && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Eficácia</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("evaluationEffectiveness")}>
                                                        <option value=''>Selecionar...</option>
                                                        <option value={true}>Sim</option>
                                                        <option value={false}>Não</option>
                                                    </select>
                                                    {errors.evaluationEffectiveness && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Resultados da Avaliação</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("evaluationResults")} ></textarea>
                                                    {errors.evaluationResults && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Comentários da Avaliação</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("evaluationComments")} ></textarea>
                                                    {errors.evaluationComments && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewTrainingAction;