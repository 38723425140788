import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";

import Moment from "react-moment";

import { COMPLAINT_STATUS_OPTIONS } from "../../../config";

const GET_QUERY = gql`
    query Get {
        ComplaintList(orderBy: {createdDate: DESC}) {
            id
            user {
                id
                name
            }
            name
            phone
            email
            subject
            category {
                id
                name
            }
            severity
            status
            createdDate
            messages {
                id
            }
        }
    }
`;

function AdminComplaintList() {

    const [selectedStatus, setSelectedStatus] = useState("")

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    
    const results = (data && data.ComplaintList.filter(a => selectedStatus === "" || a.status === selectedStatus)) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Denúncias"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <select className="searchCat float-right sort" onChange={e => setSelectedStatus(e.target.value)}>
                                                <option value="">Todas</option>
                                                { COMPLAINT_STATUS_OPTIONS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                            </select>
                                            
                                            <Link to="/admin/whistleblowing/categories" className="float-right p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-success feather-message-square font-md text-white"></i>
                                                    <h4 className="text-success font-xl fw-700">{results.length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">denúncias</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-warning feather-inbox font-md text-white"></i>
                                                    <h4 className="text-warning font-xl fw-700">{ results.filter(a => a.status === 'NEW').length }<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">novas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#f6f3ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-loader font-md text-white"></i>
                                                    <h4 className="text-secondary font-xl fw-700">{results.filter(a => a.status !== 'CLOSED').length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">não concluídas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-primary feather-check font-md text-white"></i>
                                                    <h4 className="text-primary font-xl fw-700">{results.filter(a => a.status === 'CLOSED').length} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">concluídas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">Denunciante</th>
                                                            <th className="border-0 p-4">Categoria</th>
                                                            <th className="border-0 p-4">Assunto</th>
                                                            <th className="border-0 p-4">Estado</th>
                                                            <th className="border-0 p-4">Data de Receção</th>
                                                            <th className="border-0 p-4">Mensagens</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.user?.name + (value.name && ` (${value.name})`) || '(anónimo)'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.category?.name}</span>
                                                            </td>
                                                            <td className="wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/whistleblowing/complaints/${value.id}`}>{value.subject}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`p-1 ps-2 pe-2 ${COMPLAINT_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.className} font-xssss rounded-3`}><strong>{COMPLAINT_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.name}</strong></span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.createdDate && <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment> }</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.messages?.length}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/whistleblowing/complaints/${value.id}`}><i className="feather-file-text font-xs text-grey-500"></i></Link>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminComplaintList;