import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { getImageUrl } from "../../../../utils";
import Moment from "react-moment";
import VisTimeline from "../../../../components/VisTimeline";
import moment from "moment";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";


const GET_QUERY = gql`
    query Get {
        ShiftAssignmentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { startDateTime: ASC }) {
            id
            shift {
                id
            }
            startDateTime
            endDateTime
            employee {
                id
                name
            }
        }
        ShiftList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } } orderBy: { name: ASC }) {
            id
            name
            department {
                id
                name
            }
            location
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Shift(id: $id)
    }
`;

function AdminShiftAssignmentHome() {

    const navigate = useNavigate();

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o turno?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Turno eliminado com sucesso!')
            }
        }
    }

    const results = (data && data.ShiftAssignmentList) || [];

    const options = {
        //width: '500px',
        //height: '800px',
        locale: 'pt_PT',
        orientation: 'both',
        //start: new Date(),
        //end: new Date() + 7,
    };

    const groups = data?.ShiftList?.map(e => ({ id: e.id, content: e.name }));
    const items = data?.ShiftAssignmentList?.map(e => ({id: e.id, content: e.employee?.name || '(não atribuído)', start: moment(e.startDateTime).toDate(), end: moment(e.endDateTime).toDate(), group: e.shift.id, className: e.employee?.id ? 'bg-success border-0' : 'bg-warning border-0' }));

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Turnos"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ groups?.length } resultados</h4>
                                            <div className="float-right">
                                                { false && <Link to="/admin/events/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link> }
                                                <Link to="/admin/shifts" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Lista</Link>
                                                <Link to="/admin/shifts/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Turno</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                                <div className="card-body d-flex p-0 pointer" onClick={() => navigate(`/admin/shifts`)}>
                                                    <i className="btn-round-lg d-inline-block me-3 bg-primary feather-clock font-md text-white"></i>
                                                    <h4 className="text-primary font-xl fw-700">{ groups?.length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Turnos Ativos</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#fff0e9`}} onClick={() => navigate(`/admin/recruitment/jobOpportunities`)}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-warning feather-x font-md text-white"></i>
                                                    <h4 className="text-warning font-xl fw-700">{ data?.ShiftAssignmentList?.filter(e => !e.employee?.id).length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Não Atribuídos</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#e2f6e9`}} onClick={() => navigate(`/admin/recruitment/jobOpportunities`)}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-success feather-check font-md text-white"></i>
                                                    <h4 className="text-success font-xl fw-700">{ data?.ShiftAssignmentList?.filter(e => e.employee?.id).length } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Atribuídos</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3 pointer" style={{background:`#f6f3ff`}} onClick={() => navigate(`/admin/recruitment/jobApplications`)}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-file-text font-md text-white"></i>
                                                    <h4 className="text-secondary font-xl fw-700">{ 0 } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">Candidaturas</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <VisTimeline items={items} groups={groups} options={options}
                                                onClick={
                                                    function (properties) {
                                                        alert('selected items: ' + properties.items);
                                                    }
                                                }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminShiftAssignmentHome;