import React from 'react';
import { Link } from 'react-router-dom';

import { ENABLED_FEATURES, config } from '../config';

function FeedWelcome({ user }) {

    if(!user) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
                <p className="font-xss fw-700 text-grey-900 card-body p-0 d-flex align-items-center"><i className="btn-round-lg font-xxl text-success icon-empathize pt-1 me-3 bg-greylight"></i>Olá, { user.name}!</p>
            </div>
            
            { user.employee &&
            <div className="card-body d-flex p-0 mt-0">
                <Link to={`/employees/${user.employee.id}`} className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-success feather-user me-2"></i><span className="d-none-xs">O Meu Perfil</span></Link>
                <Link to={`/account/qrCode`} className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning ti-id-badge me-2"></i><span className="d-none-xs">Cartão de Visita</span></Link>
                { ENABLED_FEATURES.feedback && <Link to={`/feedback/requests/new`} className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-danger feather-message-square me-2"></i><span className="d-none-xs">Pedir Feedback</span></Link> }
            </div>
            }

            { false && config.FEATURES_PREVIEW_ENABLED && 
            <div className="card-body d-flex p-0 mt-0">
                <a href="#video" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a>
                <a href="#photo" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-success feather-image me-2"></i><span className="d-none-xs">Photo/Video</span></a>
                <a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning feather-camera me-2"></i><span className="d-none-xs">Feeling/Activity</span></a>
            </div>
            }
        </div>
    );
}

export default FeedWelcome;