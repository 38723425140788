import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import ShopContext from "../../context/shop-context";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import { getImageUrl } from "../../utils";
import MarketHeader from "../../components/MarketHeader";

const GET_PRODUCTS_QUERY = gql`
    query GetProducts {
        ProductList(filter: { isActive: { _eq: true }}, orderBy: { name: ASC }) {
            id
            name
            thumbnail
            price
            category {
                id
                name
            }
            createdDate
        }
    }
`;

function MarketHome() {

    const { loading, error, data } = useQuery(GET_PRODUCTS_QUERY);

    const products = (data && data.ProductList) || [];

    return (
        <ShopContext.Consumer>
            {context => (
            <Fragment> 
                <Header />
                <Leftnav />
                
                <div className="main-content bg-white">
            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12 col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <MarketHeader title="Market" />
                                        </div>
                                    
                                        <div className="col-lg-12 mt-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ products.length } produtos</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <Link to="/market/orders" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Minhas Encomendas</Link>
                                        </div>

                                        {products.map((value , index) => (

                                        <div key={index} className="col-lg-4 col-md-6" >
                                            <div className="card w-100 border-0 mt-4">
                                                <div className="card-image w-100 p-0 text-center bg-greylight rounded-3 mb-2">
                                                    <Link to={`/market/products/${value.id}`}><img src={getImageUrl(value.thumbnail, '/assets/images/product.png')} alt={value.name} className="w-100 mt-0 mb-0 p-5" /></Link>
                                                </div>
                                                <div className="card-body w-100 p-0 text-center">
                                                    <h2 className="mt-2 mb-1"><Link to={`/market/products/${value.id}`} className="text-black fw-700 font-xsss lh-26">{value.name}</Link></h2>
                                                    <h6 className="font-xss fw-600 text-success ls-2">{value.price} <i className="icon-empathize-square ms-1"></i></h6>
                                                </div>                                
                                            </div>
                                        </div>

                                        ))}

                                        
                                        { false && <div className="col-lg-12 mt-3 mb-5 text-center"><a href="/shop1" className="fw-700 text-white font-xssss text-uppercase ls-3 lh-32 rounded-3 mt-3 text-center d-inline-block p-2 bg-current w150">Load More</a></div> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>            
                </div>
                
                <Appfooter /> 
            </Fragment>
            )}
        </ShopContext.Consumer>
    );
}

export default MarketHome;