import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";

import ShopContext from "../../context/shop-context";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import MarketHeader from "../../components/MarketHeader";
import { getImageUrl } from "../../utils";

function Cart() {
    
    const context = useContext(ShopContext);

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <MarketHeader title="O meu carrinho" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8 mb-3">
                                        <div className="table-content table-responsive">
                                            {context.cart.length <= 0 && <p className="text-center fw-600 mt-2 mb-2">Não existem produtos no carrinho!</p>}
                                            {context.cart.length > 0 &&
                                            <table className="table text-center">
                                                <thead className="bg-greyblue rounded-3">
                                                    <tr>
                                                        <th className="border-0 p-4 d-none d-lg-table-cell">&nbsp;</th>
                                                        <th className="border-0 p-4 text-left">Produto</th>
                                                        <th className="border-0 p-4">Preço</th>
                                                        <th className="border-0 p-4">Quantidade</th>
                                                        <th className="border-0 p-4 d-none d-lg-table-cell">Total</th>
                                                        {false && <th className="border-0 p-4">&nbsp;</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {context.cart.map(cartItem => (
                                                    <tr key={cartItem.id}>
                                                        <td className="product-thumbnail text-center ps-0 d-none d-lg-table-cell">
                                                            <img src={getImageUrl(cartItem.thumbnail, '/assets/images/product.png')} alt={cartItem.name} className="w75 rounded-3" />
                                                        </td>
                                                        <td className="product-headline text-left wide-column ps-4">
                                                            <h3>
                                                                <Link to={`/market/products/${cartItem.id}`} className="text-grey-900 fw-600 font-xsss">{cartItem.name}</Link>
                                                            </h3>
                                                        </td>
                                                        <td className="product-p">
                                                            <span className="product-price-wrapper">
                                                                <span className="money text-grey-500 fw-600 font-xsss">{cartItem.price} <i className="icon-empathize-square ms-1"></i></span>
                                                            </span>
                                                        </td>
                                                        <td className="product-quantity">
                                                            <div className="quantity">
                                                                <input type="number" className="quantity-input open-font fw-600" name="qty" id="qty-1" placeholder="1" min="1" value={cartItem.quantity} readOnly /> 
                                                            <div className="dec qtybutton" onClick={context.removeProductFromCart.bind(this, cartItem.id)}>-</div><div className="inc qtybutton" onClick={context.addProductToCart.bind(this, cartItem)}>+</div></div>
                                                        </td>
                                                        <td className="product-total-price d-none d-lg-table-cell">
                                                            <span className="product-price-wrapper">
                                                                <span className="money fmont"><strong>{cartItem.price * cartItem.quantity} <i className="icon-empathize-square ms-1"></i></strong></span>
                                                            </span>
                                                        </td>
                                                        {false && <td className="product-remove text-right"><a role="button" onClick={context.removeProductFromCart.bind(this, cartItem.id)}><i className="ti-trash font-xs text-grey-500"></i></a></td>}
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            }
                                        </div>
                                        {false &&
                                        <div className="coupon float-left mb-2">
                                            <input type="text" className="input-code form-control h60 p-3" placeholder="Coupon Code.." />
                                            <a href="/cart" className="bg-dark text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Apply Coupon</a>
                                        </div>
                                        }
                                        {false &&
                                        <a href="/cart" className="update-cart bg-dark float-right text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Update Cart</a>
                                        }
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="checkout-payment card border-0 mb-3 bg-greyblue p-4">
                                            <div className="cart-totals">
                                                <h4 className="mont-font fw-600 font-md mb-5">Total</h4>
                                                <div className="table-content table-responsive">
                                                    <table className="table order-table">
                                                        <tbody>
                                                            {false && <tr>
                                                                <td className="font-xsss pt-2 fw-600">Subtotal</td>
                                                                <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">{ context.cart.reduce((total, curItem) => { return total + (curItem.price * curItem.quantity);}, 0) }<i className="icon-empathize-square ms-1"></i></td>  
                                                            </tr>}
                                                            {false && <tr>
                                                                <td className="font-xsss pt-2 fw-600">Envio</td>
                                                                <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">
                                                                    <span>Flat rate: $20.00</span>
                                                                </td>  
                                                            </tr>}
                                                            <tr className="order-total">
                                                                <td className="font-xsss pt-2 fw-600">Total</td>
                                                                <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">
                                                                    <span className="product-price-wrapper">
                                                                        <span className="money fmont">{ context.cart.reduce((total, curItem) => { return total + (curItem.price * curItem.quantity);}, 0) }<i className="icon-empathize-square ms-1"></i></span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <Link to="/market/checkout" className="bg-dark float-right text-white fw-600 text-uppercase font-xsss border-dark border rounded-3 border-size-md d-inline-block w-100 p-3 text-center ls-3">Comprar</Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter /> 
        </Fragment>
    );
}

export default Cart;