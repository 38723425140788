import React, { Fragment, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { ICONS } from "../../../config";

const UPSERT_MUTATION = gql`
    mutation UpsertQuickLink($id: UUID, $title: String, $icon: String, $url: String, $openInNewWindow: Boolean, $isVisible: Boolean, $displayOrder: Int) {
        upsert_QuickLink(quickLink: { id: $id, title: $title, icon: $icon, url: $url, openInNewWindow: $openInNewWindow, isVisible: $isVisible, displayOrder: $displayOrder }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        QuickLinkById(id: $id) {
            id
            title
            icon
            url
            openInNewWindow
            isVisible
            displayOrder
        }
    }
`;

function AdminNewQuickLink() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();
    const watchIcon = watch("icon");

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.QuickLinkById);
    }, [editData]);

    const upsert = async (formData) => {
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/quickLinks`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Link Rápido</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-8 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("title", { required: true })} />
                                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Ícone</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" {...register("icon")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            ICONS.slice().sort((a, b) => a.name.localeCompare(b.name))
                                                            .map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.icon && <span className="text-red">Este campo é obrigatório</span>}
                                                    
                                                </div>
                                            </div>
                                            <div className="col-lg-1 mb-3 mt-4 pt-2">
                                                <i className={`font-xl text-success ${watchIcon || 'feather-globe'}`}></i>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">URL</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("url", { required: true })} />
                                                    {errors.url && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="openInNewWindow" {...register("openInNewWindow")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="openInNewWindow">Abrir numa nova janela</label>
                                                    {errors.openInNewWindow && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="isVisible" {...register("isVisible")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="isVisible">Visível</label>
                                                    {errors.isVisible && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewQuickLink;