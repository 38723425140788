import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT, RSVP_STATUS_OPTIONS, TRAINING_ACTION_STATUS } from "../../../config";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture, getImageUrl } from "../../../utils";
import AdminMediaGallery from "../../../components/AdminMediaGallery";

const GET_LISTS_QUERY = gql`
    query GetLists {
        SkillList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}) {
            id
            name
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        ShiftById(id: $id) {
            id
            name
            department {
                id
                name
            }
            location
            requiredSkills {
                id
                skill {
                    id
                    name
                }
            }
            assignments {
                id
                startDateTime
                endDateTime
                employee {
                    id
                    fullName
                }
            }
        }
    }
`;

const UPSERT_SKILL_MUTATION = gql`
    mutation Upsert($shiftId: UUID, $skill: inp_Skill) {
        upsert_ShiftSkill(shiftSkill: { shift: { id: $shiftId }, skill: $skill }) {
            id
        }
    }
`;

const DELETE_SKILL_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_ShiftSkill(id: $id)
    }
`;

function AdminShift() {

    let { id } = useParams();

    const [isSkillFormOpen, setIsSkillFormOpen] = useState(false);

    
    const { register: registerSkill, handleSubmit: handleSubmitSkill, formState: { errors: errorsSkill }, reset: resetSkill } = useForm();
    

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);

    const [upsertSkillMutation] = useMutation(UPSERT_SKILL_MUTATION);
    const [deleteSkillMutation] = useMutation(DELETE_SKILL_MUTATION);

    const upsertSkill = async (formData) => {
        const upsertResult = await upsertSkillMutation({ 
            variables: {
                shiftId: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Competência adicionada!');
            setIsSkillFormOpen(false)
            resetSkill();
            await refetch();
        }
    }

    const deleteSkill = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover a competência?')) {
            const deleteResult = await deleteSkillMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Competênica removida com sucesso!')
                await refetch();
            }
        }
    }

    const skills = (data?.ShiftById?.requiredSkills?.slice().sort((a, b) => a?.skill?.name?.localeCompare(b?.skill?.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Turno</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.ShiftById?.name}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ShiftById?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Departamento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ShiftById?.department?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Localização
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ShiftById?.location}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <Link to={`/admin/shifts/edit/${data?.ShiftById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>
                                                
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Competências</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ skills.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setIsSkillFormOpen(true)}>Adicionar</a>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4 text-left">Nome</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { isSkillFormOpen &&
                                                            <tr className="bg-lightgreen">
                                                                <td>
                                                                    <select type="text" className="form-control mb-0 pt-0 pv-0 lh-24" style={{height: '28px'}} defaultValue='' {...registerSkill("skill.id", { required: true })}>
                                                                        <option value=''>Selecionar...</option>
                                                                        {
                                                                            listsData?.SkillList
                                                                            .slice()
                                                                            //.filter(e => e.user)
                                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                                            .map((e) => {
                                                                                return (
                                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {errorsSkill?.skill?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td className="text-center">
                                                                    <a role="button" className="p-2 ps-3 pe-3 bg-success border-0 text-white text-center font-xssss fw-600 rounded-xl" onClick={handleSubmitSkill(upsertSkill)}>Adicionar</a>
                                                                    <a role="button" className="ms-2 p-2 ps-3 pe-3 bg-grey border-0 text-grey-700 text-center font-xssss fw-600 rounded-xl" onClick={() => setIsSkillFormOpen(false)}>Cancelar</a>
                                                                </td>
                                                            </tr>
                                                            }

                                                            {skills.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss">{value.skill?.name}</span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-right">
                                                                    <a role="button" onClick={() => deleteSkill(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Histórico</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ data?.ShiftById?.assignments?.length } resultados</h4>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Início</th>
                                                                <th className="border-0 p-2 px-4">Fim</th>
                                                                <th className="border-0 p-2 px-4">Colaborador</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.ShiftById?.assignments?.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.startDateTime}</Moment></span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.endDateTime}</Moment></span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.employee?.fullName}</span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>


                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminShift;