import React from 'react';

function CreditCard(props) {
    
    const { logo, bank, holder, balance, currencySymbol } = props;

    return (
        <div className="card border-0 shadow-none" style={{maxWidth: '280px'}}>
            <div className="card-body d-block text-left p-0">
                <div className="item w-100 h150 bg-white rounded-xxl overflow-hidden text-left shadow-md ps-3 pt-2 align-items-end flex-column d-flex">
                    <div className="card border-0 shadow-none p-0 bg-transparent-card text-left w-100">
                        <div className="row">
                            <div className="col-6">
                                { logo && <img src={logo} alt="icon" className="w40 float-left d-inline-block" />}
                                { bank && <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-md logo-text mb-0">{bank} </span> }
                            </div>
                            <div className="col-6 text-right pe-4">
                                <img src="/assets/images/chip.png" alt="icon" className="w30 float-right d-inline-block mt-2 me-2" />
                            </div>
                        </div>
                    </div>
                    <div className="card border-0 shadow-none p-0 bg-transparent-card text-left w-100 mt-auto">
                        <h4 className="text-grey-900 font-sm fw-700 mont-font mb-3 text-dark-color">{currencySymbol} {balance} <span className="d-block fw-500 text-grey-500 font-xssss mt-1 text-dark-color">{holder}</span></h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreditCard;