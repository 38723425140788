import React, { Fragment, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import { getImageUrl } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($id: String!) {
        EmployeeById(id: $id) {
            id
            name
            jobTitle
        }
    }
`;

const GET_BADGE_ASSIGNMENTS_QUERY = gql`
    query GetBadgeAssignments($employeeId: UUID) {
        BadgeAssignmentList(filter: { receiver: { id: { _eq: $employeeId } } }, orderBy: { createdDate: DESC }) {
            id
            badge {
                id
                name
                description
                thumbnail
                points
            }
            sender {
                id
                name
                user {
                    id
                    picture
                }
            }
            message
            createdDate
        }
    }
`;

function EmployeeBadgeList() {
    
    let { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const { loading: employeeLoading, error: employeeError, data: employeeData } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: id }});
    const { data: badgeAssignmentsData, loading: badgeAssignmentsLoading, error: badgeAssignmentsError } = useQuery(GET_BADGE_ASSIGNMENTS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { employeeId: id }
    });

    useEffect(() => {
        const badgeAssignmentId = searchParams.get('id');
        if(!badgeAssignmentsLoading && badgeAssignmentId) {
            const element = document.getElementById(`b-${badgeAssignmentId}`);
            if (element) {
                // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [badgeAssignmentsData]);

    const employee = employeeData && employeeData.EmployeeById;
    const badges = (badgeAssignmentsData && badgeAssignmentsData.BadgeAssignmentList) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title={`Aplausos de ${employee && employee.name} (${badges.length})`}  customButton={{link: `/employees/${id}`, icon: 'feather-user'}} />
                                
                                <CustomLoadingOverlay loading={employeeLoading || badgeAssignmentsLoading}>

                                {badges.map((value , index) => (

                                <div key={index} className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4" style={{paddingLeft: "120px"}} id={`b-${value.id}`}>
                                    <img src={getImageUrl(value.badge.thumbnail)} alt={value.badge.name} className="position-absolute p-2 bg-lightblue2 w65 ms-4 left-0" />
                                    <i className="feather-award font-md text-grey-500 position-absolute right-0 me-3"></i>
                                    <h4 className="font-xss fw-700 text-grey-900 mb-2 pe-4">{value.badge.name}</h4>
                                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className=" fw-500text-grey-500 font-xssss"><Moment format="DD \d\e MMMM \d\e YYYY">{ value.createdDate }</Moment></span></h5>
                                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-500 font-xssss text-dark">por </span> <Link to={`/employees/${value.sender.id}`}>{value.sender.name}</Link></h5>
                                    <p className="text-grey-900">{value.message}</p>
                                    { value.badge.points ? <h6 className="d-inline-block p-2 text-success alert-success fw-600 font-xssss rounded-3 me-2">{`${value.badge.points} pontos`}</h6> : '' }
                                </div>

                                ))}
                                
                                {!badgeAssignmentsLoading && badges.length === 0 && <p className="text-grey-500 text-center">Ainda não recebeu aplausos</p>}

                                </CustomLoadingOverlay>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeBadgeList;