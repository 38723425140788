import { getPastDaysDate } from "./utils";

const subdomain = window.location.host.split('.')[0];

const prod = {
    environment: 'production',
    APP_NAME: 'empathize',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: false,
    FEATURES_PREVIEW_ENABLED: false,
    openid: {
        AUTHORITY_URI: 'https://id.empathize.pt/realms/' + subdomain,
        CLIENT_ID: 'hr-app-frontend',
        REDIRECT_URI: 'https://' + subdomain + '.empathize.pt/signin-oidc',
        SILENT_REDIRECT_URI: 'https://' + subdomain + '.empathize.pt/silentrenew',
        POST_LOGOUT_REDIRECT_URI: 'https://' + subdomain + '.empathize.pt/',
    },
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.empathize.pt/graphql',
    IMAGE_URL_FORMAT: 'https://' + subdomain + '.api.empathize.pt/public/files?fileRef={0}',
    FILE_URL_FORMAT: 'https://' + subdomain + '.api.empathize.pt/public/files?fileRef={0}&attachment=true',
    notifications: {
        POLLING_INTERVAL_MILLIS: (15 * 1000),
    },
    messages : {
        MESSAGE_HISTORY_LIMIT: 100,
        POLLING_INTERVAL_MILLIS: (5 * 1000),
    },
    moodTracker: {
        THANKYOU_MESSAGE: 'Muito obrigado! O teu feedback é importante para nós!',
        REMINDER_INTERVAL_HOURS: 12,
    }
};

const preview = {
    environment: 'preview',
    APP_NAME: 'empathize',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: false,
    FEATURES_PREVIEW_ENABLED: false,
    openid: {
        AUTHORITY_URI: 'https://id.empathize.pt/realms/' + subdomain,
        CLIENT_ID: 'hr-app-frontend',
        REDIRECT_URI: 'https://' + subdomain + '.preview.empathize.pt/signin-oidc',
        SILENT_REDIRECT_URI: 'https://' + subdomain + '.preview.empathize.pt/silentrenew',
        POST_LOGOUT_REDIRECT_URI: 'https://' + subdomain + '.preview.empathize.pt/',
    },
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.empathize.pt/graphql',
    IMAGE_URL_FORMAT: 'https://' + subdomain + '.api.empathize.pt/public/files?fileRef={0}',
    FILE_URL_FORMAT: 'https://' + subdomain + '.api.empathize.pt/public/files?fileRef={0}&attachment=true',
    notifications: {
        POLLING_INTERVAL_MILLIS: (15 * 1000),
    },
    messages : {
        MESSAGE_HISTORY_LIMIT: 100,
        POLLING_INTERVAL_MILLIS: (5 * 1000),
    },
    moodTracker: {
        THANKYOU_MESSAGE: 'Muito obrigado! O teu feedback é importante para nós!',
        REMINDER_INTERVAL_HOURS: 12,
    }
};

const qa = {
    environment: 'qa',
    APP_NAME: 'empathize (Testes)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: true,
    FEATURES_PREVIEW_ENABLED: false,
    openid: {
        AUTHORITY_URI: 'https://id.qa.empathize.pt/realms/' + subdomain,
        CLIENT_ID: 'hr-app-frontend',
        REDIRECT_URI: 'https://' + subdomain + '.qa.empathize.pt/signin-oidc',
        SILENT_REDIRECT_URI: 'https://' + subdomain + '.qa.empathize.pt/silentrenew',
        POST_LOGOUT_REDIRECT_URI: 'https://' + subdomain + '.qa.empathize.pt/',
    },
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.qa.empathize.pt/graphql',
    IMAGE_URL_FORMAT: 'https://' + subdomain + '.api.qa.empathize.pt/public/files?fileRef={0}',
    FILE_URL_FORMAT: 'https://' + subdomain + '.api.qa.empathize.pt/public/files?fileRef={0}&attachment=true',
    notifications: {
        POLLING_INTERVAL_MILLIS: (15 * 1000),
    },
    messages : {
        MESSAGE_HISTORY_LIMIT: 100,
        POLLING_INTERVAL_MILLIS: (5 * 1000),
    },
    moodTracker: {
        THANKYOU_MESSAGE: 'Muito obrigado! O teu feedback é importante para nós!',
        REMINDER_INTERVAL_HOURS: 12,
    }
};


const dev = {
    environment: 'dev',
    APP_NAME: 'empathize (DEV)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: true,
    FEATURES_PREVIEW_ENABLED: false,
    openid: {
        AUTHORITY_URI: 'https://id.qa.empathize.pt/realms/' + subdomain,
        CLIENT_ID: 'hr-app-frontend',
        REDIRECT_URI: 'http://' + subdomain + '.test.com:3000/signin-oidc',
        SILENT_REDIRECT_URI: 'http://' + subdomain + '.test.com:3000/silentrenew',
        POST_LOGOUT_REDIRECT_URI: 'http://' + subdomain + '.test.com:3000/',
    },
    GRAPHQL_API_BASE_URL: 'http://10.0.200.100:8080/graphql',
    IMAGE_URL_FORMAT: 'http://10.0.200.100:8080/public/files?fileRef={0}',
    FILE_URL_FORMAT: 'http://10.0.200.100:8080/public/files?fileRef={0}&attachment=true',
    notifications: {
        POLLING_INTERVAL_MILLIS: (15 * 1000),
    },
    messages : {
        MESSAGE_HISTORY_LIMIT: 100,
        POLLING_INTERVAL_MILLIS: (5 * 1000),
    },
    moodTracker: {
        THANKYOU_MESSAGE: 'Muito obrigado! O teu feedback é importante para nós!',
        REMINDER_INTERVAL_HOURS: 12,
    }
};

const devPublic = {
    APP_NAME: 'empathize (DEV Public)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: true,
    FEATURES_PREVIEW_ENABLED: false,
    openid: {
        AUTHORITY_URI: 'https://id.qa.empathize.pt/realms/' + subdomain,
        CLIENT_ID: 'hr-app-frontend',
        REDIRECT_URI: 'https://' + subdomain + '.dev.empathize.pt/signin-oidc',
        SILENT_REDIRECT_URI: 'https://' + subdomain + '.dev.empathize.pt/silentrenew',
        POST_LOGOUT_REDIRECT_URI: 'https://' + subdomain + '.dev.empathize.pt/',
    },
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.dev.empathize.pt/graphql',
    IMAGE_URL_FORMAT: 'https://' + subdomain + '.api.dev.empathize.pt/public/files?fileRef={0}',
    FILE_URL_FORMAT: 'https://' + subdomain + '.api.dev.empathize.pt/public/files?fileRef={0}&attachment=true',
    notifications: {
        POLLING_INTERVAL_MILLIS: (15 * 1000),
    },
    messages : {
        MESSAGE_HISTORY_LIMIT: 100,
        POLLING_INTERVAL_MILLIS: (5 * 1000),
    },
    moodTracker: {
        THANKYOU_MESSAGE: 'Muito obrigado! O teu feedback é importante para nós!',
        REMINDER_INTERVAL_HOURS: 12,
    }
};

//const ACTIVE_ENVIRONMENT = dev;
//const ACTIVE_ENVIRONMENT = qa;

//export const config = ACTIVE_ENVIRONMENT || (process.env.NODE_ENV === 'development' ? dev : (process.env.NODE_ENV === 'qa' ? qa : prod));
export const config = process.env.REACT_APP_ENV === 'prod' ? prod : (process.env.REACT_APP_ENV === 'preview' ? preview : (process.env.REACT_APP_ENV === 'qa' ? qa : dev));

export const APPLE_APPSTORE_URL = "https://apps.apple.com/app/empathize/id6529520331"
export const GOOGLE_PLAY_URL ="https://play.google.com/store/apps/details?id=pt.dizme.empathize"

// GraphQL API
export const GRAPHQL_MAX_RESULTS_LIMIT = 2147483647;
export const UPLOAD_MAX_FILE_SIZE = 5242880 // 5MB = 5242880 bytes

export const UPLOAD_IMAGE_ACCEPT_FILETYPES = "image/png, image/gif, image/jpeg"

// ADMIN
export const ADMIN_ROLE = 'admin';

export const ENABLED_FEATURES = {
    feedback: false
}

// ROLES
export const USER_ROLES = [
    { id: 'user', name: 'Utilizador', enabled: true },
    { id: 'post-contributor', name: 'Gestor Publicações', enabled: true },
    { id: 'admin', name: 'Administrador', enabled: true },
]

// Notifications
export const NOTIFICATIONS_META = {
    'new-badge': {
        icon: 'feather-award bg-primary-gradiant',
        link: '',
    },
    'new-post': {
        icon: 'feather-edit bg-success',
        link: '/feed/{0}',
    },
    'new-reaction': {
        icon: '',
        link: '/feed/{0}',
    },
    'new-like': {
        icon:  'feather-thumbs-up bg-primary-gradiant',
        link: '/feed/{0}',
    },
    'new-love': {
        icon: 'feather-heart bg-red-gradiant',
        link: '/feed/{0}',
    },
    'new-comment': {
        icon: 'feather-message-circle bg-primary-gradiant',
        link: '/feed/{0}',
    },
    'new-comment-reply': {
        icon: 'feather-message-circle bg-primary-gradiant',
        link: '/feed/{0}',
    },
    'event-reminder': {
        icon: 'feather-calendar bg-primary-gradiant',
        link: '/events/{0}',
    },
    'new-employee-feedback': {
        icon: 'feather-message-square bg-gold-gradiant',
        link: '/feedback',
    },
    'new-feedback-request': {
        icon: 'feather-message-square bg-gold-gradiant',
        link: '/feedback/give?requestId={0}',
    },
    'new-direct-message': {
        icon: 'feather-message-circle bg-gold-gradiant',
        link: '',
    },
    'update-order-status': {
        icon: 'feather-shopping-bag bg-red-gradiant',
        link: '/market/orders',
    },
}

export const NOTIFICATIONS_SEND_EMAIL_KEY = "notifications.sendEmailNotifications";

export const NOTIFICATIONS_SEND_REMINDERS_KEY = "notifications.sendReminders";

// Users
export const USER_SIGNUP_REQUEST_STATUS_OPTIONS = [
    { id: 'PENDING', name: 'Pendente', className: 'text-primary alert-primary'},
    { id: 'ACCEPTED', name: 'Aceite', className: 'text-success alert-success'},
    { id: 'REJECTED', name: 'Rejeitado', className: 'text-danger alert-danger'},
];

// Employees
export const PERSON_GENDER_OPTIONS = [
    { id: 'MALE', name: 'Masculino' },
    { id: 'FEMALE', name: 'Feminino' },
];

// Moodtracker
export const USER_MOOD_STEPS = [
    { id: 'score', nextStep: 'comments', message: 'Olá, como te sentes hoje?' },
    { id: 'comments', nextStep: null, message: 'Queres partilhar algo connosco?' }
];

// Reactions
export const REACTION_OPTIONS = [
    { id: 'LOVE', icon: 'em em-heart' },
    { id: 'LIKE', icon: 'em em---1' },
]

export const USER_MOOD_SCORE = [
    /*{ score: 1, icon: 'em-cry' },
    { score: 5, icon: 'em-full_moon_with_face' },
    { score: 10, icon: 'em-blush' },*/
    { score: 10, icon: 'em-blush', name: 'Ótimo' },
    { score: 8, icon: 'em-grin', name: 'Bem' },
    { score: 5, icon: 'em-full_moon_with_face', name: 'Médio' },
    { score: 2, icon: 'em-relieved', name: 'Stress' },
    { score: 0, icon: 'em-cry', name: 'Mal' },
];

// Events
export const RSVP_STATUS_OPTIONS = [
    { id: 'YES', name: 'Sim', description: 'Aceite'},
    { id: 'NO', name: 'Não', description: 'Recusado'},
    { id: 'MAYBE', name: 'Talvez', description: 'Talvez'},
];

// Key Results
export const KEY_RESULT_METRIC_OPTIONS = [
    { id: 'YES_NO', name: 'Sim/Não'},
    { id: 'NUMERIC', name: 'Numérico'},
    { id: 'PERCENTAGE', name: 'Percentagem'},
    { id: 'CURRENCY', name: 'Moeda'},
];

export const KEY_RESULT_PRIORITY_OPTIONS = [
    { id: 'LOW', name: 'Baixa'},
    { id: 'MEDIUM', name: 'Média'},
    { id: 'HIGH', name: 'Alta'},
];

export const KEY_RESULT_STATUS_OPTIONS = [
    { id: 'NOT_STARTED', name: 'Não iniciado'},
    { id: 'ON_TRACK', name: 'On track'},
    { id: 'BEHIND', name: 'Atrasado'},
    { id: 'AT_RISK', name: 'Em risco'},
    { id: 'COMPLETED', name: 'Concluído'},
    { id: 'POSTPONED', name: 'Adiado'},
];

// Complaints
export const COMPLAINT_STATUS_OPTIONS = [
    { id: 'NEW', name: 'Novo', className: 'text-danger alert-danger'},
    { id: 'RECEIVED', name: 'Recebido', className: 'text-warning alert-warning'},
    { id: 'UNDER_INVESTIGATION', name: 'Investigação em curso', className: 'text-grey'},
    { id: 'WAITING_WHISTLEBLOWER', name: 'A aguardar por denunciante', className: 'text-primary alert-primary'},
    { id: 'CLOSED', name: 'Fechado', className: 'text-success alert-success'},
];

// MARKET
export const MARKET_ORDER_STATUS_OPTIONS = [
    { id: 'PENDING', name: 'Aguarda confirmação de pagamento', className: 'text-warning alert-warning'},
    { id: 'PAID', name: 'Pagamento aceite', className: 'text-primary alert-primary'},
    { id: 'PROCESSING', name: 'Em processamento', className: 'text-primary alert-primary'},
    { id: 'READY_FOR_PICKUP', name: 'Pronta para levantamento', className: 'text-success alert-success'},
    { id: 'SHIPPED', name: 'Enviada', className: 'text-success alert-success'},
    { id: 'DELIVERED', name: 'Entregue', className: 'text-success alert-success'},
    { id: 'CANCELED', name: 'Cancelada', className: 'text-danger alert-danger'},
    { id: 'REFUNDED', name: 'Reembolso efetuado', className: 'text-grey'},
];

// TRAINING
export const TRAINING_ACTION_STATUS = [
    { id: 'IN_PLANNING', name: 'Em planeamento', className: 'text-grey' },
    { id: 'PLANNED', name: 'Planeada', className: 'text-warning alert-warning' },
    { id: 'REGISTRATIONS_OPEN', name: 'Inscrições abertas', className: 'text-warning alert-warning' },
    { id: 'IN_EXECUTION', name: 'Em execução', className: 'text-primary alert-primary' },
    { id: 'COMPLETED', name: 'Concluída', className: 'text-success alert-success' },
    { id: 'CANCELED', name: 'Cancelada', className: 'text-danger alert-danger' },
];

// BADGES
export const BADGES_INTERACTIONS_CHART_MIN_NODE_SIZE = 20;
export const BADGES_INTERACTIONS_CHART_MAX_NODE_SIZE = 40;
export const BADGES_INTERACTIONS_CHART_SHOW_LABELS = false;


// APP COMPONENT TYPES
export const APP_COMPONENT_TYPES = [
    { id: 'APP_FRONTEND', name: 'Empathize' },
    { id: 'RECRUITMENT_FRONTEND', name: 'Recrutamento' },
];

// DATE FILTERS - GrahpQL
export const DATE_FILTERS = [
    { id: 'all', name: 'Desde sempre', value: { } },
    { id: 'past_0_days', name: 'Hoje', value: { _gte: getPastDaysDate(0) } },
    { id: 'past_7_days', name: 'Últimos 7 dias', value: { _gte: getPastDaysDate(7) } },
    { id: 'past_30_days', name: 'Últimos 30 dias', value: { _gte: getPastDaysDate(30) } },
    { id: 'past_90_days', name: 'Últimos 90 dias', value: { _gte: getPastDaysDate(90) } },
];

// RECRUITMENT
export const RECRUITMENT_JOB_OPPORTUNITY_TYPES = [
    { id: 'INTERNAL', name: 'Interna' },
    { id: 'EXTERNAL', name: 'Externa' },
];

export const RECRUITMENT_JOB_APPLICATION_STATUS = [
    { id: 'NEW', name: 'Nova', className: 'text-grey', kanbanClassName: 'border-dark', displayOrder: 0 },
    { id: 'IN_PROCESS', name: 'Em curso', className: 'text-warning alert-warning', kanbanClassName: 'border-warning', displayOrder: 1 },
    { id: 'SCREENING', name: 'Pré-seleção', className: 'text-warning alert-warning', kanbanClassName: 'border-warning', displayOrder: 2 },
    { id: 'INTERVIEW', name: 'Entrevista', className: 'text-warning alert-warning', kanbanClassName: 'border-warning', displayOrder: 3 },
    { id: 'OFFER', name: 'Oferta', className: 'text-primary alert-primary', kanbanClassName: 'border-primary', displayOrder: 4 },
    { id: 'HIRED', name: 'Contratado', className: 'text-success alert-success', kanbanClassName: 'border-success', displayOrder: 5 },
    { id: 'REJECTED', name: 'Rejeitado', className: 'text-danger alert-danger', kanbanClassName: 'border-danger', displayOrder: -1 },
];

// SHIFTS
export const EMPLOYEE_AVAILABILITY_TYPES = [
    { id: 'DESIRED', name: 'Preferencial', className: 'bg-success' },
    { id: 'UNDESIRED', name: 'Indesejado', clasName: 'bg-warning' },
    { id: 'UNAVAILABLE', name: 'Indisponível', className: 'bg-danger' },
];

// ICONS
export const ICONS = [
    { id: 'feather-activity', name: 'activity' },
    { id: 'feather-airplay', name: 'airplay' },
    { id: 'feather-alert-circle', name: 'alert-circle' },
    { id: 'feather-alert-octagon', name: 'alert-octagon' },
    { id: 'feather-alert-triangle', name: 'alert-triangle' },
    { id: 'feather-align-center', name: 'align-center' },
    { id: 'feather-align-justify', name: 'align-justify' },
    { id: 'feather-align-left', name: 'align-left' },
    { id: 'feather-align-right', name: 'align-right' },
    { id: 'feather-anchor', name: 'anchor' },
    { id: 'feather-aperture', name: 'aperture' },
    { id: 'feather-archive', name: 'archive' },
    { id: 'feather-arrow-down-circle', name: 'arrow-down-circle' },
    { id: 'feather-arrow-down-left', name: 'arrow-down-left' },
    { id: 'feather-arrow-down-right', name: 'arrow-down-right' },
    { id: 'feather-arrow-down', name: 'arrow-down' },
    { id: 'feather-arrow-left-circle', name: 'arrow-left-circle' },
    { id: 'feather-arrow-left', name: 'arrow-left' },
    { id: 'feather-arrow-right-circle', name: 'arrow-right-circle' },
    { id: 'feather-arrow-right', name: 'arrow-right' },
    { id: 'feather-arrow-up-circle', name: 'arrow-up-circle' },
    { id: 'feather-arrow-up-left', name: 'arrow-up-left' },
    { id: 'feather-arrow-up-right', name: 'arrow-up-right' },
    { id: 'feather-arrow-up', name: 'arrow-up' },
    { id: 'feather-at-sign', name: 'at-sign' },
    { id: 'feather-award', name: 'award' },
    { id: 'feather-bar-chart-2', name: 'bar-chart-2' },
    { id: 'feather-bar-chart', name: 'bar-chart' },
    { id: 'feather-battery-charging', name: 'battery-charging' },
    { id: 'feather-battery', name: 'battery' },
    { id: 'feather-bell-off', name: 'bell-off' },
    { id: 'feather-bell', name: 'bell' },
    { id: 'feather-bluetooth', name: 'bluetooth' },
    { id: 'feather-bold', name: 'bold' },
    { id: 'feather-book-open', name: 'book-open' },
    { id: 'feather-book', name: 'book' },
    { id: 'feather-bookmark', name: 'bookmark' },
    { id: 'feather-box', name: 'box' },
    { id: 'feather-briefcase', name: 'briefcase' },
    { id: 'feather-calendar', name: 'calendar' },
    { id: 'feather-camera-off', name: 'camera-off' },
    { id: 'feather-camera', name: 'camera' },
    { id: 'feather-cast', name: 'cast' },
    { id: 'feather-check-circle', name: 'check-circle' },
    { id: 'feather-check-square', name: 'check-square' },
    { id: 'feather-check', name: 'check' },
    { id: 'feather-chevron-down', name: 'chevron-down' },
    { id: 'feather-chevron-left', name: 'chevron-left' },
    { id: 'feather-chevron-right', name: 'chevron-right' },
    { id: 'feather-chevron-up', name: 'chevron-up' },
    { id: 'feather-chevrons-down', name: 'chevrons-down' },
    { id: 'feather-chevrons-left', name: 'chevrons-left' },
    { id: 'feather-chevrons-right', name: 'chevrons-right' },
    { id: 'feather-chevrons-up', name: 'chevrons-up' },
    { id: 'feather-chrome', name: 'chrome' },
    { id: 'feather-circle', name: 'circle' },
    { id: 'feather-clipboard', name: 'clipboard' },
    { id: 'feather-clock', name: 'clock' },
    { id: 'feather-cloud-drizzle', name: 'cloud-drizzle' },
    { id: 'feather-cloud-lightning', name: 'cloud-lightning' },
    { id: 'feather-cloud-off', name: 'cloud-off' },
    { id: 'feather-cloud-rain', name: 'cloud-rain' },
    { id: 'feather-cloud-snow', name: 'cloud-snow' },
    { id: 'feather-cloud', name: 'cloud' },
    { id: 'feather-code', name: 'code' },
    { id: 'feather-codepen', name: 'codepen' },
    { id: 'feather-codesandbox', name: 'codesandbox' },
    { id: 'feather-coffee', name: 'coffee' },
    { id: 'feather-columns', name: 'columns' },
    { id: 'feather-command', name: 'command' },
    { id: 'feather-compass', name: 'compass' },
    { id: 'feather-copy', name: 'copy' },
    { id: 'feather-corner-down-left', name: 'corner-down-left' },
    { id: 'feather-corner-down-right', name: 'corner-down-right' },
    { id: 'feather-corner-left-down', name: 'corner-left-down' },
    { id: 'feather-corner-left-up', name: 'corner-left-up' },
    { id: 'feather-corner-right-down', name: 'corner-right-down' },
    { id: 'feather-corner-right-up', name: 'corner-right-up' },
    { id: 'feather-corner-up-left', name: 'corner-up-left' },
    { id: 'feather-corner-up-right', name: 'corner-up-right' },
    { id: 'feather-cpu', name: 'cpu' },
    { id: 'feather-credit-card', name: 'credit-card' },
    { id: 'feather-crop', name: 'crop' },
    { id: 'feather-crosshair', name: 'crosshair' },
    { id: 'feather-database', name: 'database' },
    { id: 'feather-delete', name: 'delete' },
    { id: 'feather-disc', name: 'disc' },
    { id: 'feather-divide-circle', name: 'divide-circle' },
    { id: 'feather-divide-square', name: 'divide-square' },
    { id: 'feather-divide', name: 'divide' },
    { id: 'feather-dollar-sign', name: 'dollar-sign' },
    { id: 'feather-download-cloud', name: 'download-cloud' },
    { id: 'feather-download', name: 'download' },
    { id: 'feather-dribbble', name: 'dribbble' },
    { id: 'feather-droplet', name: 'droplet' },
    { id: 'feather-edit-2', name: 'edit-2' },
    { id: 'feather-edit-3', name: 'edit-3' },
    { id: 'feather-edit', name: 'edit' },
    { id: 'feather-external-link', name: 'external-link' },
    { id: 'feather-eye-off', name: 'eye-off' },
    { id: 'feather-eye', name: 'eye' },
    { id: 'feather-facebook', name: 'facebook' },
    { id: 'feather-fast-forward', name: 'fast-forward' },
    { id: 'feather-feather', name: 'feather' },
    { id: 'feather-figma', name: 'figma' },
    { id: 'feather-file-minus', name: 'file-minus' },
    { id: 'feather-file-plus', name: 'file-plus' },
    { id: 'feather-file-text', name: 'file-text' },
    { id: 'feather-file.csv', name: 'file.csv' },
    { id: 'feather-file', name: 'file' },
    { id: 'feather-film', name: 'film' },
    { id: 'feather-filter', name: 'filter' },
    { id: 'feather-flag', name: 'flag' },
    { id: 'feather-folder-minus', name: 'folder-minus' },
    { id: 'feather-folder-plus', name: 'folder-plus' },
    { id: 'feather-folder', name: 'folder' },
    { id: 'feather-framer', name: 'framer' },
    { id: 'feather-frown', name: 'frown' },
    { id: 'feather-gift', name: 'gift' },
    { id: 'feather-git-branch', name: 'git-branch' },
    { id: 'feather-git-commit', name: 'git-commit' },
    { id: 'feather-git-merge', name: 'git-merge' },
    { id: 'feather-git-pull-request', name: 'git-pull-request' },
    { id: 'feather-github', name: 'github' },
    { id: 'feather-gitlab', name: 'gitlab' },
    { id: 'feather-globe', name: 'globe' },
    { id: 'feather-grid', name: 'grid' },
    { id: 'feather-hard-drive', name: 'hard-drive' },
    { id: 'feather-hash', name: 'hash' },
    { id: 'feather-headphones', name: 'headphones' },
    { id: 'feather-heart', name: 'heart' },
    { id: 'feather-help-circle', name: 'help-circle' },
    { id: 'feather-hexagon', name: 'hexagon' },
    { id: 'feather-home', name: 'home' },
    { id: 'feather-image', name: 'image' },
    { id: 'feather-inbox', name: 'inbox' },
    { id: 'feather-info', name: 'info' },
    { id: 'feather-instagram', name: 'instagram' },
    { id: 'feather-italic', name: 'italic' },
    { id: 'feather-key', name: 'key' },
    { id: 'feather-layers', name: 'layers' },
    { id: 'feather-layout', name: 'layout' },
    { id: 'feather-life-buoy', name: 'life-buoy' },
    { id: 'feather-link-2', name: 'link-2' },
    { id: 'feather-link', name: 'link' },
    { id: 'feather-linkedin', name: 'linkedin' },
    { id: 'feather-list', name: 'list' },
    { id: 'feather-loader', name: 'loader' },
    { id: 'feather-lock', name: 'lock' },
    { id: 'feather-log-in', name: 'log-in' },
    { id: 'feather-log-out', name: 'log-out' },
    { id: 'feather-mail', name: 'mail' },
    { id: 'feather-map-pin', name: 'map-pin' },
    { id: 'feather-map', name: 'map' },
    { id: 'feather-maximize-2', name: 'maximize-2' },
    { id: 'feather-maximize', name: 'maximize' },
    { id: 'feather-meh', name: 'meh' },
    { id: 'feather-menu', name: 'menu' },
    { id: 'feather-message-circle', name: 'message-circle' },
    { id: 'feather-message-square', name: 'message-square' },
    { id: 'feather-mic-off', name: 'mic-off' },
    { id: 'feather-mic', name: 'mic' },
    { id: 'feather-minimize-2', name: 'minimize-2' },
    { id: 'feather-minimize', name: 'minimize' },
    { id: 'feather-minus-circle', name: 'minus-circle' },
    { id: 'feather-minus-square', name: 'minus-square' },
    { id: 'feather-minus', name: 'minus' },
    { id: 'feather-monitor', name: 'monitor' },
    { id: 'feather-moon', name: 'moon' },
    { id: 'feather-more-horizontal', name: 'more-horizontal' },
    { id: 'feather-more-vertical', name: 'more-vertical' },
    { id: 'feather-mouse-pointer', name: 'mouse-pointer' },
    { id: 'feather-move', name: 'move' },
    { id: 'feather-music', name: 'music' },
    { id: 'feather-navigation-2', name: 'navigation-2' },
    { id: 'feather-navigation', name: 'navigation' },
    { id: 'feather-octagon', name: 'octagon' },
    { id: 'feather-package', name: 'package' },
    { id: 'feather-paperclip', name: 'paperclip' },
    { id: 'feather-pause-circle', name: 'pause-circle' },
    { id: 'feather-pause', name: 'pause' },
    { id: 'feather-pen-tool', name: 'pen-tool' },
    { id: 'feather-percent', name: 'percent' },
    { id: 'feather-phone-call', name: 'phone-call' },
    { id: 'feather-phone-forwarded', name: 'phone-forwarded' },
    { id: 'feather-phone-incoming', name: 'phone-incoming' },
    { id: 'feather-phone-missed', name: 'phone-missed' },
    { id: 'feather-phone-off', name: 'phone-off' },
    { id: 'feather-phone-outgoing', name: 'phone-outgoing' },
    { id: 'feather-phone', name: 'phone' },
    { id: 'feather-pie-chart', name: 'pie-chart' },
    { id: 'feather-play-circle', name: 'play-circle' },
    { id: 'feather-play', name: 'play' },
    { id: 'feather-plus-circle', name: 'plus-circle' },
    { id: 'feather-plus-square', name: 'plus-square' },
    { id: 'feather-plus', name: 'plus' },
    { id: 'feather-pocket', name: 'pocket' },
    { id: 'feather-power', name: 'power' },
    { id: 'feather-printer', name: 'printer' },
    { id: 'feather-radio', name: 'radio' },
    { id: 'feather-refresh-ccw', name: 'refresh-ccw' },
    { id: 'feather-refresh-cw', name: 'refresh-cw' },
    { id: 'feather-repeat', name: 'repeat' },
    { id: 'feather-rewind', name: 'rewind' },
    { id: 'feather-rotate-ccw', name: 'rotate-ccw' },
    { id: 'feather-rotate-cw', name: 'rotate-cw' },
    { id: 'feather-rss', name: 'rss' },
    { id: 'feather-save', name: 'save' },
    { id: 'feather-scissors', name: 'scissors' },
    { id: 'feather-search', name: 'search' },
    { id: 'feather-send', name: 'send' },
    { id: 'feather-server', name: 'server' },
    { id: 'feather-settings', name: 'settings' },
    { id: 'feather-share-2', name: 'share-2' },
    { id: 'feather-share', name: 'share' },
    { id: 'feather-shield-off', name: 'shield-off' },
    { id: 'feather-shield', name: 'shield' },
    { id: 'feather-shopping-bag', name: 'shopping-bag' },
    { id: 'feather-shopping-cart', name: 'shopping-cart' },
    { id: 'feather-shuffle', name: 'shuffle' },
    { id: 'feather-sidebar', name: 'sidebar' },
    { id: 'feather-skip-back', name: 'skip-back' },
    { id: 'feather-skip-forward', name: 'skip-forward' },
    { id: 'feather-slack', name: 'slack' },
    { id: 'feather-slash', name: 'slash' },
    { id: 'feather-sliders', name: 'sliders' },
    { id: 'feather-smartphone', name: 'smartphone' },
    { id: 'feather-smile', name: 'smile' },
    { id: 'feather-speaker', name: 'speaker' },
    { id: 'feather-square', name: 'square' },
    { id: 'feather-star', name: 'star' },
    { id: 'feather-stop-circle', name: 'stop-circle' },
    { id: 'feather-sun', name: 'sun' },
    { id: 'feather-sunrise', name: 'sunrise' },
    { id: 'feather-sunset', name: 'sunset' },
    { id: 'feather-table', name: 'table' },
    { id: 'feather-tablet', name: 'tablet' },
    { id: 'feather-tag', name: 'tag' },
    { id: 'feather-target', name: 'target' },
    { id: 'feather-terminal', name: 'terminal' },
    { id: 'feather-thermometer', name: 'thermometer' },
    { id: 'feather-thumbs-down', name: 'thumbs-down' },
    { id: 'feather-thumbs-up', name: 'thumbs-up' },
    { id: 'feather-toggle-left', name: 'toggle-left' },
    { id: 'feather-toggle-right', name: 'toggle-right' },
    { id: 'feather-tool', name: 'tool' },
    { id: 'feather-trash-2', name: 'trash-2' },
    { id: 'feather-trash', name: 'trash' },
    { id: 'feather-trello', name: 'trello' },
    { id: 'feather-trending-down', name: 'trending-down' },
    { id: 'feather-trending-up', name: 'trending-up' },
    { id: 'feather-triangle', name: 'triangle' },
    { id: 'feather-truck', name: 'truck' },
    { id: 'feather-tv', name: 'tv' },
    { id: 'feather-twitch', name: 'twitch' },
    { id: 'feather-twitter', name: 'twitter' },
    { id: 'feather-type', name: 'type' },
    { id: 'feather-umbrella', name: 'umbrella' },
    { id: 'feather-underline', name: 'underline' },
    { id: 'feather-unlock', name: 'unlock' },
    { id: 'feather-upload-cloud', name: 'upload-cloud' },
    { id: 'feather-upload', name: 'upload' },
    { id: 'feather-user-check', name: 'user-check' },
    { id: 'feather-user-minus', name: 'user-minus' },
    { id: 'feather-user-plus', name: 'user-plus' },
    { id: 'feather-user-x', name: 'user-x' },
    { id: 'feather-user', name: 'user' },
    { id: 'feather-users', name: 'users' },
    { id: 'feather-video-off', name: 'video-off' },
    { id: 'feather-video', name: 'video' },
    { id: 'feather-voicemail', name: 'voicemail' },
    { id: 'feather-volume-1', name: 'volume-1' },
    { id: 'feather-volume-2', name: 'volume-2' },
    { id: 'feather-volume-x', name: 'volume-x' },
    { id: 'feather-volume', name: 'volume' },
    { id: 'feather-watch', name: 'watch' },
    { id: 'feather-wifi-off', name: 'wifi-off' },
    { id: 'feather-wifi', name: 'wifi' },
    { id: 'feather-wind', name: 'wind' },
    { id: 'feather-x-circle', name: 'x-circle' },
    { id: 'feather-x-octagon', name: 'x-octagon' },
    { id: 'feather-x-square', name: 'x-square' },
    { id: 'feather-x', name: 'x' },
    { id: 'feather-youtube', name: 'youtube' },
    { id: 'feather-zap-off', name: 'zap-off' },
    { id: 'feather-zap', name: 'zap' },
    { id: 'feather-zoom-in', name: 'zoom-in' },
    { id: 'feather-zoom-out', name: 'zoom-out' },

];