import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture } from "../../../../utils";
import { RECRUITMENT_JOB_APPLICATION_STATUS, RECRUITMENT_JOB_OPPORTUNITY_TYPES } from "../../../../config";

const GET_QUERY = gql`
    query Get($id: String!) {
        JobOpportunityById(id: $id) {
            id
            company {
                id
                name
            }
            department {
                id
                name
            }
            type
            title
            description
            referenceCode
            category {
                id
                name
            }
            location
            recruiter {
                id
                name
                picture
                employee {
                    id
                    picture
                }
            }
            isActive
            deadline
            replyMessage
            rejectMessage
            createdDate
            applications {
                id
                status
                createdDate
            }
        }
    }
`;

const GET_APPLICATIONS_QUERY = gql`
    query Get($id: UUID) {
        JobApplicationExternalList(filter: { jobOpportunity: { id: { _eq: $id } } }) {
            id
            jobApplicant {
                id
                name
            }
            status
            createdDate
        }
        JobApplicationInternalList(filter: { jobOpportunity: { id: { _eq: $id } } }) {
            id
            employee {
                id
                fullName
            }
            status
            createdDate
        }
    }
`;

function AdminJobOpportunity() {

    let { id } = useParams();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: applicationsData, loading: applicationsLoading, error: applicationsError, refetch: applicationsRefetch } = useQuery(GET_APPLICATIONS_QUERY, { variables: { id: id }, skip: !id});

    const applications = (applicationsData?.JobApplicationExternalList || []).concat(applicationsData?.JobApplicationInternalList || []).slice()
    .sort(function (a, b) {
        const d1 = new Date(a.createdDate).getTime()
        const d2 = new Date(b.createdDate).getTime()
        return d2 - d1;
    });

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Vaga</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.JobOpportunityById?.title}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Tipo de Vaga
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{RECRUITMENT_JOB_OPPORTUNITY_TYPES.filter(e => e.id === data?.JobOpportunityById?.type)[0]?.name }</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Referência
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.referenceCode}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Publicada? 
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><i className={`${data?.JobOpportunityById?.isActive ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.company?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Departamento
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.department?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Localização
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.location}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Título
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.title}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.description}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Responsável RH
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.recruiter?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Prazo de Candidaturas
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.deadline && <Moment format="DD/MM/YYYY">{data?.JobOpportunityById?.deadline}</Moment> || "-"}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Mensagem de Resposta
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.replyMessage || "-"}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Mensagem de Rejeição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.rejectMessage || "-"}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Criação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.JobOpportunityById?.createdDate && <Moment format="DD/MM/YYYY">{data?.JobOpportunityById?.createdDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <Link to={`/admin/recruitment/jobOpportunities/edit/${data?.JobOpportunityById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Candidaturas</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ applications.length } resultados</h4>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4 text-left">Nome</th>
                                                                <th className="border-0 p-2 px-4">Data</th>
                                                                <th className="border-0 p-2 px-4">Estado</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {applications.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/recruitment/jobApplications/${value.id}`}>{value.jobApplicant?.name || value.employee?.fullName}</Link></span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.createdDate && <Moment format="DD/MM/YYYY">{value.createdDate}</Moment>}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminJobOpportunity;