import LoadingOverlay from 'react-loading-overlay-ts'

export default function CustomLoadingOverlay({ active, children }) {
    return (
      <LoadingOverlay
        active={active}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(255, 255, 255, 0.5)'
          }),
          spinner: (base) => ({
            ...base,
            '& svg circle': {
              //stroke: '#1E74FD'
              stroke: '#10d876'
            }
          })
        }}
      >
        {active ? null : children}
      </LoadingOverlay>
    )
  }