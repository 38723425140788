import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import WYSIWYGEditor from "../../../components/WYSIWYGEditor";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { getImageUrl } from "../../../utils";
import moment from "moment";
import { resizeImage } from "../../../utils/resize-image";
import { UPLOAD_IMAGE_ACCEPT_FILETYPES } from "../../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            email
            employee {
                id
            }
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        EventCategoryList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $title: String!, $description: String, $startDate: OffsetDateTime, $endDate: OffsetDateTime, $location: String, $thumbnail: Upload, $userId: UUID!, $category: inp_EventCategory) {
        upsert_Event(event: { id: $id, title: $title, description: $description, startDate: $startDate, endDate: $endDate, location: $location, thumbnail: $thumbnail, user: { id: $userId }, category: $category }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        EventById(id: $id) {
            id
            title
            description
            startDate
            endDate
            location
            thumbnail
            category {
                id
                name
            }
        }
    }
`;

function AdminNewEvent() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null)

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);

    useEffect(() => {
        reset(editData?.EventById);
        setValue("startDate", editData?.EventById?.startDate && moment(editData?.EventById?.startDate).format("yyyy-MM-DDTHH:mm:ss.SSSZ"))
        setValue("endDate", editData?.EventById?.endDate && new moment(editData?.EventById?.endDate).format("yyyy-MM-DDTHH:mm:ss.SSSZ"))
    }, [editData, listsData]);

    const upsert = async (formData) => {
        console.log(formData)
        formData.thumbnail = selectedFile || editData?.EventById?.thumbnail;
        
        if(!formData?.category?.id || formData?.category?.id === '') {
            formData.category = null
        }
        
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
                userId: viewerData?.Viewer?.id
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/events`)
        }
    }

    const onFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFile(file)
    };

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Evento</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("title", { required: true })} />
                                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <Controller
                                                        render={({ field }) => <WYSIWYGEditor {...field} editorStyle={{maxHeight: '400px', border: '2px #eee solid'}} />}
                                                        name="description"
                                                        control={control}
                                                        //defaultValue=""
                                                        /*rules={{
                                                            validate: {
                                                            required: (v) =>
                                                                (v && stripHtml(v).result.length > 0) ||
                                                                "Description is required",
                                                            maxLength: (v) =>
                                                                (v && stripHtml(v).result.length <= 2000) ||
                                                                "Maximum character limit is 2000",
                                                            },
                                                        }}*/
                                                    />
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Início</label>
                                                    <Controller
                                                        control={control}
                                                        name="startDate"
                                                        rules={{ required: true, valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                onChange={(date) => setValue(field.name, date?.toDate())}
                                                                format={"DD/MM/YYYY HH:mm"}
                                                                plugins={[
                                                                    <TimePicker position="right"/>,
                                                                ]}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.startDate && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data de Fim</label>
                                                    <Controller
                                                        control={control}
                                                        name="endDate"
                                                        rules={{ required: true, valueAsDate: true }}
                                                        render={({
                                                            field, onChange
                                                        }) => (
                                                            <DatePicker
                                                                value={field.value ? moment(field.value).format('DD-MM-YYYY HH:mm:ss') : ""}
                                                                onChange={(date) => setValue(field.name, date?.toDate())}
                                                                format={"DD/MM/YYYY HH:mm"}
                                                                plugins={[
                                                                    <TimePicker position="right"/>,
                                                                ]}
                                                                className="green"
                                                                inputClass="form-control"
                                                            />
                                                        )}
                                                    />
                                                    {errors.endDate && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Localização</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("location")} />
                                                    {errors.location && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("category.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.EventCategoryList && listsData.EventCategoryList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.category?.id && <span className="text-red">Verifique este campo</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Imagem</label>
                                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!editData?.EventById?.thumbnail || selectedFile) && <>
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar uma imagem' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { editData?.EventById?.thumbnail && !selectedFile && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFile.current.click()}><img src={getImageUrl(editData?.EventById?.thumbnail)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewEvent;