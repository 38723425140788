import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { getImageUrl, getProfilePicture } from "../../utils";
import { DATE_FILTERS } from "../../config";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

const GET_BADGE_ASSIGNMENTS_QUERY = gql`
    query GetBadgeAssignments($dateFilter: [inp_DateTimeFilterCondition]) {
        BadgeAssignmentList(filter: { createdDate: $dateFilter }) {
            id
            createdDate
            badge {
                id
                name
                description
                thumbnail
                points
            }
            receiver {
                id
                name
                jobTitle
                picture
                isActive
                user {
                    id
                    picture
                }
            }
        }
    }
`;

function BadgeRanking() {

    const [selectedDateFilter, setSelectedDateFilter] = useState({})

    const { loading, error, data } = useQuery(GET_BADGE_ASSIGNMENTS_QUERY, { variables: { dateFilter: selectedDateFilter }});

    const setDateFilter = (id) => {
        setSelectedDateFilter(DATE_FILTERS.filter(e => e.id === id)[0].value)
    }

    const badgeAssignments = (data && data.BadgeAssignmentList) || [];

    let employees = []
    
    badgeAssignments.map(ba => {
        if(ba.receiver.isActive) {
            let employee = null;
            if(employees.filter(e => e.id == ba.receiver.id).length > 0) {
                employee = employees.filter(e => e.id == ba.receiver.id)[0];
                employee.badgeAssignments.push(ba)
            } else {
                employee = {
                    ...ba.receiver,
                    badgeAssignments: [ba]
                }
                employees.push(employee)
            };
        }
    })
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title="Mérito" customButton={{link: '/badges/interactions', icon: 'feather-share-2'}} color="success"/>

                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <select className="searchCat float-right sort ms-1 me-1" onChange={e => setDateFilter(e.target.value)}>
                                            <option value="">Data</option>
                                            { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                                
                                <CustomLoadingOverlay active={loading}/>
                                
                                <div className="row ps-2 pe-1">
                                    {employees.sort((a, b) => b.badgeAssignments.length - a.badgeAssignments.length).map((value , index) => (

                                    <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2">
                                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                            <div className="card-body d-block w-100 p-4 text-center">
                                                <Link to={`/employees/${value.id}`}><figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src={getProfilePicture(value)} alt={value.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure></Link>
                                                <div className="clearfix"></div>
                                                <h4 className="fw-700 font-xss mt-3 mb-0"><Link to={`/employees/${value.id}`}>{value.name}</Link> </h4>
                                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.jobTitle || '-'}</p>
                                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.connections} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block"></span></h4></li>
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.badgeAssignments.length} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Aplausos</span></h4></li>
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.following} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block"></span></h4></li>
                                                </ul>
                                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                                    {value.badgeAssignments.length > 0 ? <li className="m-1"><img className="w40 aspect-ratio-1" src={getImageUrl(value.badgeAssignments[0].badge.thumbnail)} alt={value.badgeAssignments[0].badge.name} /></li> : ''}
                                                    {value.badgeAssignments.length > 1 ? <li className="m-1"><img className="w40 aspect-ratio-1" src={getImageUrl(value.badgeAssignments[1].badge.thumbnail)} alt={value.badgeAssignments[1].badge.name} /></li> : ''}
                                                    {value.badgeAssignments.length > 2 ? <li className="m-1"><img className="w40 aspect-ratio-1" src={getImageUrl(value.badgeAssignments[2].badge.thumbnail)} alt={value.badgeAssignments[2].badge.name} /></li> : ''}
                                                    {value.badgeAssignments.length > 3 ? <li className="m-1"><img className="w40 aspect-ratio-1" src={getImageUrl(value.badgeAssignments[3].badge.thumbnail)} alt={value.badgeAssignments[3].badge.name} /></li> : ''}
                                                </ul>
                                                <Link to={`/badges/give/${value.id}`} className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">DAR UM APLAUSO</Link>
                                            </div>
                                        </div>
                                    </div>

                                    ))}
                                    
                                    {!loading && employees.length === 0 && <p className="text-grey-500 text-center">Nenhum aplauso atribuído</p>}
                                    
                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
    
}

export default BadgeRanking;