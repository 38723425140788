import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { EMPLOYEE_AVAILABILITY_TYPES, GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";

const GET_LISTS_QUERY = gql`
    query GetLists {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } } orderBy: { name: ASC }) {
            id
            fullName
            department {
                id
                name
            }
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $employee: inp_Employee!, $date: LocalDate!, $availabilityType: AvailabilityType!) {
        upsert_EmployeeAvailability(employeeAvailability: { id: $id, employee: $employee, date: $date, availabilityType: $availabilityType }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        EmployeeAvailabilityById(id: $id) {
            id
            employee {
                id
            }
            date
            availabilityType
        }
    }
`;

function AdminNewEmployeeAvailability() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);

    useEffect(() => {
        reset(editData?.EmployeeAvailabilityById);
    }, [editData, listsData]);

    const upsert = async (formData) => {
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/shifts/availability`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Disponibilidade/Indisponibilidade</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Colaborador</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("employee.id", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData?.EmployeeList?.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.fullName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.employee?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Tipo</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("availabilityType", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            EMPLOYEE_AVAILABILITY_TYPES.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.availabilityType?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Data</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("date", { required: true })} />
                                                    {errors.date && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewEmployeeAvailability;