import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";


const GET_QUERY = gql`
    query Get {
        QuickLinkList {
            id
            title
            icon
            url
            openInNewWindow
            isVisible
            displayOrder
        }
    }
`;

const UPSERT_QUICK_LINK_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $isVisible: Boolean!) {
        upsert_QuickLink(quickLink:{ id: $id, isVisible: $isVisible }) {
            id
        }
    }
`;

const UPSERT_QUICK_LINK_ORDER_MUTATION = gql`
    mutation Upsert($id: UUID, $displayOrder: Int) {
        upsert_QuickLink(quickLink:{ id: $id, displayOrder: $displayOrder }) {
            id
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_QuickLink(id: $id)
    }
`;

function AdminQuickLinkList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const [upsertQuickLinkStatusMutation, { upsertQuickLinkStatusMutationData }] = useMutation(UPSERT_QUICK_LINK_STATUS_MUTATION);
    const [upsertQuickLinkOrderMutation, { upsertQuickLinkOrderMutationData }] = useMutation(UPSERT_QUICK_LINK_ORDER_MUTATION);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const updateQuickLinkStatus = async (id, isVisible) => {
        const upsertResult = await upsertQuickLinkStatusMutation({ 
            variables: {
                id,
                isVisible
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Link ${isVisible ? 'ativado' : 'desativado'}`)
        }
    }

    const updateOrder = async (id, displayOrder) => {
        const upsertResult = await upsertQuickLinkOrderMutation({ 
            variables: {
                id,
                displayOrder
            },
        });

        if(upsertResult.data) {
            refetch()
        }
    }

    const deleteQuickLink = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o link?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Link eliminado com sucesso!')
            }
        }
    }

    const quickLinks = (data && data.QuickLinkList.slice().sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))) || [];

    const displayOrderArray = quickLinks.map(o => { return o.displayOrder ?? 0; });
    const maxDisplayOrder = Math.max(...displayOrderArray)

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Links Rápidos"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ quickLinks.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <Link to="/admin/quickLinks/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Link</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">Ordem</th>
                                                            <th className="border-0 p-4 text-left">Título</th>
                                                            <th className="border-0 p-4">Ícone</th>
                                                            <th className="border-0 p-4">URL</th>
                                                            <th className="border-0 p-4">Visível?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {quickLinks.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.displayOrder}</span>
                                                            </td>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/quickLinks/edit/${value.id}`}>{value.title}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><i className={`font-xs text-success ${value.icon || 'feather-globe'}`}></i></span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.url}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isVisible ? ' ti-eye text-success ' : ' ti-close text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                { (value?.displayOrder !== maxDisplayOrder) ? <a role="button" onClick={() => updateOrder(value.id, value.displayOrder + 1)}><i className="ti-arrow-circle-down font-xs text-grey-500 me-2"></i></a> : null }
                                                                { (value?.displayOrder > 0) ? <a role="button" onClick={() => updateOrder(value.id, value.displayOrder - 1)}><i className="ti-arrow-circle-up font-xs text-grey-500 me-2"></i></a> : null }
                                                                <a role="button" onClick={() => updateQuickLinkStatus(value.id, !value.isVisible)}><i className={`${!value.isVisible ? ' ti-eye text-success ' : ' ti-close text-danger '} font-xs me-2`}></i></a>
                                                                <a role="button" onClick={() => deleteQuickLink(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminQuickLinkList;