import React, { useState } from 'react';

function CreateComment({ id, handleCreate }) {

    const [inputValue, setInputValue] = useState("");

    // Input Field handler
    const handleUserInput = (e) => {
        setInputValue(e.target.value);
    };

    // Reset Input Field handler
    const resetInputField = () => {
        setInputValue("");
    };
    
    const handleKeyDown = (event) => {
        /*
        if (event.key === 'Enter') {
            event.preventDefault();
            submitComment()
        }
        */
    }

    const submitComment = () => {
        handleCreate(id, inputValue);
        resetInputField();
    }

    return (
        <div className="w-100 border-0 mt-0 mb-3">
            <div className="card-body p-0 mt-0 position-relative">
                <textarea name="message" className="w-100 rounded-xxl p-2 font-xssss fw-500 theme-dark-bg bg-lightgreen" style={{resize: "none", border: `3px solid #f2faf6`, overflow: 'hidden'}} rows="1" placeholder="Escreve o teu comentário..." onKeyDown={handleKeyDown} value={inputValue} onChange={handleUserInput}></textarea>
                <button className="bg-current position-absolute mt-1 top-5 right-15" style={{border: 0, width: 30, height: 30, borderRadius: 15}} onClick={submitComment}><i className="ti-arrow-right text-white"></i></button>
            </div>
        </div>
    );
}

export default CreateComment;