import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Moment from "react-moment";
import { calculateAge, getAttachmentUrl, getProfilePicture } from "../../../../utils";
import { RECRUITMENT_JOB_APPLICATION_STATUS } from "../../../../config";

const GET_QUERY = gql`
    query Get($id: String!) {
        JobApplicationExternalById(id: $id) {
            id
            jobOpportunity {
                id
                title
                referenceCode
            }
            motivation
            jobApplicant {
                id
                name
                email
                phone
                birthdate
                applications {
                    id
                }
            }
            status
            createdDate
            attachments {
                id
                file
                name
            }
            logs {
                id
                user {
                    id
                    name
                }
                status
                createdDate
            }
        }
        JobApplicationInternalById(id: $id) {
            id
            jobOpportunity {
                id
                title
                referenceCode
            }
            motivation
            employee {
                id
                name
                fullName
            }
            status
            createdDate
            attachments {
                id
                file
                name
            }
            logs {
                id
                user {
                    id
                    name
                }
                status
                createdDate
            }
        }
    }
`;

const UPSERT_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $status: JobApplicationStatus!) {
        upsert_JobApplication(jobApplication:{ id: $id, status: $status }) {
            id
        }
    }
`;

const JobApplicationExternalBlock = ({ jobApplication }) => {

    return (
        <>
            <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                <div className="card-body p-0">
                    <h6 className="fw-600 text-grey-500 font-xssss">Candidatura</h6>
                    <h2 className="font-xl text-grey-900 fw-600">{jobApplication?.jobOpportunity.title} - {jobApplication?.jobApplicant.name}</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Vaga
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/admin/recruitment/jobOpportunities/${jobApplication?.jobOpportunity.id}`}>{jobApplication?.jobOpportunity.title}</Link></span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Referência
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobOpportunity.referenceCode}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa 
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobOpportunity.company?.name}</span></h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Candidato
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/admin/recruitment/jobApplicants/${jobApplication?.jobApplicant.id}`}>{jobApplication?.jobApplicant.name}</Link> {jobApplication?.jobApplicant?.applications?.length > 1 && `(${jobApplication?.jobApplicant?.applications?.length - 1} outras candidaturas)`}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobApplicant.email}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telefone
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobApplicant.phone}</span></h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Nascimento
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobApplicant.birthdate && <><Moment format="DD/MM/YYYY">{jobApplication?.jobApplicant.birthdate}</Moment> ({calculateAge(new Date(jobApplication?.jobApplicant.birthdate))} anos)</>}</span></h4>
                    </div>
                </div>
            </div>
        </>
    )
}

const JobApplicationInternalBlock = ({ jobApplication }) => {

    return (
        <>
            <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                <div className="card-body p-0">
                    <h6 className="fw-600 text-grey-500 font-xssss">Candidatura</h6>
                    <h2 className="font-xl text-grey-900 fw-600">{jobApplication?.jobOpportunity?.title} - {jobApplication?.employee.name}</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Vaga
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobOpportunity.title}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Referência
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobOpportunity.referenceCode}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa 
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.jobOpportunity.company?.name}</span></h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Candidato/Colaborador
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Link to={`/admin/employees/${jobApplication?.employee.id}`}>{jobApplication?.employee.fullName}</Link></span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.employee.email}</span></h4>
                    </div>
                </div>
                
                <div className="col-lg-12 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Nascimento
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.employee.birthdate && <Moment format="DD/MM/YYYY">{jobApplication?.employee.birthdate}</Moment>}</span></h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.employee.company?.name}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Departamento
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.employee.department?.name}</span></h4>
                    </div>
                </div>
                <div className="col-lg-4 mb-3">
                    <div className="d-block pt-1">
                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Superior Hierárquico
                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.employee.manager?.fullName}</span></h4>
                    </div>
                </div>
            </div>
        </>
    )
}

function AdminJobApplication() {

    let { id } = useParams();

    const [isStatusFormOpen, setIsStatusFormOpen] = useState(false);
    const toggleStatusFormOpen = () => setIsStatusFormOpen(!isStatusFormOpen);

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();
    const [upsertStatusMutation, { upsertStatusMutationData }] = useMutation(UPSERT_STATUS_MUTATION);

    const updateJobApplicationStatus = async (status) => {
        const statusName = RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === status)[0]?.name

        if(statusName && window.confirm(`Tem a certeza que pretende alterar o estado da candidatura para ${statusName}`)) {
            await updateStatus({status});
        }
    }

    const updateStatus = async (formData) => {
        const upsertResult = await upsertStatusMutation({ 
            variables: {
                id,
                ...formData,
            },
        })

        if(upsertResult.data) {
            toast.success(`Candidatura alterada com sucesso!`)
            setIsStatusFormOpen(false);
            await refetch();
        }
    }

    const jobApplication = data?.JobApplicationExternalById || data?.JobApplicationInternalById;

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                { data?.JobApplicationExternalById && <JobApplicationExternalBlock jobApplication={jobApplication} /> }
                                                { data?.JobApplicationInternalById && <JobApplicationInternalBlock jobApplication={jobApplication} /> }

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Motivação
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.motivation || "-"}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data da Candidatura
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{jobApplication?.createdDate && <Moment format="DD/MM/YYYY">{jobApplication?.createdDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Estado
                                                                <span className="d-block font-xsss fw-500 mt-2 lh-3 text-grey-500">
                                                                    <span className={`p-1 ps-2 pe-2 ${RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === jobApplication?.status)[0]?.className} font-xssss rounded-3`}><strong>{RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === jobApplication?.status)[0]?.name}</strong></span>
                                                                    { !isStatusFormOpen && <a role="button" className="ms-2" onClick={toggleStatusFormOpen}><i className="ti-pencil font-xs text-grey-500"></i></a>}
                                                                </span>
                                                            </h4>
                                                            { isStatusFormOpen &&
                                                            <form onSubmit={handleSubmit(updateStatus)}>
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <div className="form-group">
                                                                            <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("status", { required: true })}>
                                                                                <option value=''>Selecionar...</option>
                                                                                {
                                                                                    RECRUITMENT_JOB_APPLICATION_STATUS.map((e) => {
                                                                                        return (
                                                                                            <option key={e.id} value={e.id}>{e.name}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                            {errors.status && <span className="text-red">Verifique este campo</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 w-100 rounded-3 d-inline-block" onClick={handleSubmit(updateStatus)}>Guardar</a>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        { jobApplication?.status !== 'REJECTED' && <a role="button" className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-danger me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => updateJobApplicationStatus('REJECTED')}>Rejeitar</a> }
                                                        { (jobApplication?.status === 'NEW' || jobApplication?.status === 'IN_PROGRESS') && <a role="button" className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => updateJobApplicationStatus('SCREENING')}>Pré-selecionar</a> }
                                                    </form>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Anexos</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <ul>
                                                        {jobApplication?.attachments.map(attachment => (
                                                            <li key={attachment.id}>
                                                                <a href={getAttachmentUrl(attachment.file)} className="d-inline font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                                                    <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                        </ul>
                                                        {jobApplication?.attachments?.length === 0 && <p><span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">Não existem anexos</span></p>}
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Histórico</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{jobApplication?.logs?.length} alterações</h4>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">Data</th>
                                                                <th className="border-0 p-2 px-4">Utilizador</th>
                                                                <th className="border-0 p-2 px-4">Estado</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {jobApplication?.logs?.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.createdDate && <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment>}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.user?.name}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{RECRUITMENT_JOB_APPLICATION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminJobApplication;