import React, { Fragment } from "react";
import { Link } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import CreditCard from "../components/CreditCard";
import Moment from "react-moment";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            name
            wallet {
                id
                balance
                transactions {
                    id
                    amount
                    finalBalance
                    completedDate
                    createdDate
                }
            }
        }
    }
`;


function Wallet() {
    
    const { loading, error, data, refetch } = useQuery(GET_VIEWER_QUERY);

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            { loading && <LoadingOverlayWrapper/>}
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Wallet</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-5 mb-5">
                                            <div className="col-lg-12 ps-0">
                                                <h4 className="mb-4 font-lg fw-700 mont-font mb-5">Saldo </h4>
                                            </div>
                                            <div className="cleafrfix"></div>
                                            { data?.Viewer?.wallet &&
                                                <CreditCard bank="empathize" holder={data.Viewer.name} balance={data.Viewer.wallet.balance} currencySymbol={<i className="icon-empathize text-success"></i>} />
                                            }
                                        </div>
                                        <div className="col-lg-6 offset-lg-1">
                                            <div className="rounded-xxl bg-greylight h-100 p-3">
                                                <div className="col-lg-12 ps-0">
                                                </div>
                                                
                                                <h4 className="mont-font fw-600 font-sm mb-2 p-2">Últimos movimentos</h4>
                                                <div className="table-content table-responsive border-0 p-2">
                                                    <table className="table order-table order-table-2 mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="border-0">Data</th>
                                                                <th className="text-right border-0">Valor</th>
                                                                <th className="text-right border-0">Saldo</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.Viewer?.wallet?.transactions?.slice()
                                                            .sort(function (a, b) {
                                                                const d1 = new Date(a.createdDate).getTime()
                                                                const d2 = new Date(b.createdDate).getTime()
                                                                return d2 - d1;
                                                            })
                                                            .slice(0, 10)
                                                            .map((transaction, index) => (
                                                            <tr key={index}>
                                                                <th className="text-grey-500 fw-500 font-xsss"><Moment format="DD/MM/YYYY">{transaction.createdDate}</Moment></th>
                                                                <td className={`text-right fw-500 font-xsss ${transaction.amount > 0 ? ' text-success ' : ' text-grey-500 '}`}>{transaction.amount > 0 ? '+' : '' }{transaction.amount} <i className="icon-empathize-square ms1" /></td>
                                                                <td className="text-grey-500 text-right fw-500 font-xsss">{transaction.finalBalance} <i className="icon-empathize-square ms1" /></td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            
                        </div>
                    </div>
                </div>              
            </div>          

            <Appfooter />

        </Fragment>
    );
}

export default Wallet;