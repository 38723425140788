import React, { createContext, useContext } from "react";
import AuthService from "../services/authService";

export const AuthContext = createContext({
    signinRedirectCallback: () => ({}),
    signinCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    signinSilent: () => ({}),
    getUser: () => ({})
});

export const AuthProvider = (props) => {
    const authService = new AuthService()
    return (
      <AuthContext.Provider value={authService}>
        {props.children}
      </AuthContext.Provider>
    )
}
  
export const AuthConsumer = AuthContext.Consumer;

export function useAuth() {
  return useContext(AuthContext);
}
