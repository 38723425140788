import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller } from "react-hook-form";

import WYSIWYGEditor from "../../../components/WYSIWYGEditor";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { getImageUrl } from "../../../utils";
import { APP_COMPONENT_TYPES } from "../../../config";

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $path: String!, $component: AppComponentType!, $title: String!, $headline: String, $content: String) {
        upsert_StaticPage(staticPage: { id: $id, path: $path, component: $component, title: $title, headline: $headline, content: $content, isDeletionProtected: false }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        StaticPageById(id: $id) {
            id
            path
            title
            headline
            content
            component
        }
    }
`;

function AdminNewStaticPage() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.StaticPageById);
    }, [editData]);

    const upsert = async (formData) => {
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/staticPages`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Página</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("title", { required: true })} />
                                                    {errors.title && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Componente</label>
                                                <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("component", { required: true })}>
                                                    <option value=''>Selecionar...</option>
                                                    {
                                                        APP_COMPONENT_TYPES.map((e) => {
                                                            return (
                                                                <option key={e.id} value={e.id}>{e.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {errors.component && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">URL</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("path", { required: true })} />
                                                    {errors.path && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Introdução</label>
                                                    <textarea className="form-control mb-0 p-3 h75 lh-16" rows="2" {...register("headline")} ></textarea>
                                                    {errors.headline && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Texto</label>
                                                    <Controller
                                                        render={({ field }) => <WYSIWYGEditor {...field} editorStyle={{maxHeight: '400px', border: '2px #eee solid'}} />}
                                                        name="content"
                                                        control={control}
                                                        //defaultValue=""
                                                        /*rules={{
                                                            validate: {
                                                            required: (v) =>
                                                                (v && stripHtml(v).result.length > 0) ||
                                                                "Description is required",
                                                            maxLength: (v) =>
                                                                (v && stripHtml(v).result.length <= 2000) ||
                                                                "Maximum character limit is 2000",
                                                            },
                                                        }}*/
                                                    />
                                                    {false && <textarea className="form-control mb-0 p-3 h200 lh-16" rows="10" {...register("content")} ></textarea>}
                                                    {errors.content && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewStaticPage;