import { useState } from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';
import { RBACWrapper } from "react-simple-rbac";
import { toast } from "react-toastify";

import CreateComment from "./CreateComment";

const DELETE_COMMENT_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Comment_(id: $id)
    }
`;

export default function CommentsChange({ type, id, currentUser, username, commentsEnabled, handleCreateReply, onCommentDeleted }) {

  const [isReplyOpen, setIsReplyOpen] = useState(false);

  const toggleReplyOpen = () => setIsReplyOpen(!isReplyOpen);

  const [deleteCommentMutation, { deleteCommentMutationData }] = useMutation(DELETE_COMMENT_MUTATION);

  const deleteComment = async () => {
      if (window.confirm('Tem a certeza que pretende eliminar o comentário?')) {
          const deleteResult = await deleteCommentMutation({ 
              variables: {
                  id
              },
          })

          if(deleteResult.data) {
              toast.success('Comentário eliminado com sucesso!')
              onCommentDeleted && onCommentDeleted()
          }
      }
  }

  return (
    <>
      {false && currentUser?.username === username ? (
        <div style={{ display: "flex", gap: "1rem" }}>
          <button
            className="card-buttons"
            //onClick={() => deleteComment(id, type)}
          >
            <img src="./images/icon-delete.svg" alt="reply" />
            <p style={{ color: "red", opacity: "0.6" }}>Delete</p>
          </button>
          <button className="card-buttons">
            <img src="./images/icon-edit.svg" alt="reply" />
            <p>Edit</p>
          </button>
        </div>
      ) : (
        <>
        <RBACWrapper requiredRoles={['admin']} oneOf>
          <a role="button" className="d-flex align-items-center fw-600 text-danger font-xssss" onClick={deleteComment}><i className="feather-trash text-danger btn-round-sm font-xsss ms-auto"></i><span>Apagar</span></a>
        </RBACWrapper>
        { commentsEnabled && type === 'comment' && <a role="button" className="d-flex align-items-center fw-600 text-grey-900 text-dark font-xssss" onClick={toggleReplyOpen}><i className="feather-corner-down-right text-dark text-grey-900 btn-round-sm font-xs ms-auto"></i><span className="d-none-xss">Responder</span></a> }
        { isReplyOpen && <div className="ms-3"><CreateComment id={id} handleCreate={handleCreateReply} /></div> }
        </>
      )}
    </>
  );
}
