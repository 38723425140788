import React, { Component , Fragment } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from "../components/Appfooter";


const UPDATE_PASSWORD_MUTATION = gql`
    mutation UpdatePassword($password: String!) {
        UpdatePassword(password: $password)
    }
`;

function Password() {

    const navigate = useNavigate();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();
    let newPassword = watch("newPassword");

    const [updatePasswordMutation, { updatePasswordMutationData }] = useMutation(UPDATE_PASSWORD_MUTATION);

    const updatePassword = async (formData) => {
        const upsertResult = await updatePasswordMutation({ 
            variables: {
                newPassword: formData.newPassword,
            },
        });

        if(upsertResult.data) {
            toast.success('Password alterada com sucesso!');
            navigate(`/settings`)
        } else {
            toast.error('Ocorreu um erro ao alterar a password.');
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Alterar Password</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(updatePassword)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600">Nova password</label>
                                                    <input type="password" className="form-control" {...register("newPassword", { required: 'Este campo é obrigatório' })} />
                                                    {errors.newPassword && <span className="text-red">{errors.newPassword.message}</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600">Confirme a nova password</label>
                                                    <input type="password" className="form-control" {
                                                        ...register("confirmNewPassword", {
                                                            required: 'Este campo é obrigatório',
                                                            validate: value => value === newPassword || "As passwords não correspondem"}
                                                        )}
                                                    />
                                                    {errors.confirmNewPassword && <span className="text-red">{errors.confirmNewPassword.message}</span>}
                                                </div>        
                                            </div>                                     
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(updatePassword)}>Alterar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
    
}

export default Password;