import React,{Fragment, useEffect, useState} from 'react';
import { Link , NavLink } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { config } from '../config';
import { Modal } from 'react-bootstrap';

const MARK_POPUP_NOTIFICATION_AS_READ_MUTATION = gql`
    mutation MarkPopuipNotificationAsRead($id: UUID) {
        MarkPopupNotificationAsRead(id: $id)
    }
`;


const DailyBonusContent = ({ notification, onHide }) => {
    return (
        <Fragment>
            <Modal.Body style={{padding: 0}}>
                <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 no-radius-top">
                    <div className="card-body position-relative h150 bg-image-cover bg-image-center"
                    style={{backgroundImage: `url("/assets/images/bg/bg-daily-bonus.jpg")`}}></div>
                    <div className="card-body d-block pt-4 text-center">
                        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img src="/assets/images/logo_green.png" alt={config.APP_NAME} className="p-1 bg-white rounded-xl w-100 aspect-ratio-1" style={{objectFit: 'contain'}} /></figure>
                        <h4 className="font-md ls-1 fw-700 text-grey-900">
                            <span className="fw-700 text-success">{notification?.title}</span><br />
                            <span className="fw-700 text-success mt-2">{notification?.body} <i className="icon-empathize-square ms1" /></span>
                            <span className="d-block fw-700 mt-2 lh-3 text-grey-500 font-xss">Volta a amanhã para não perderes o teu bónus diário!</span>
                        </h4>

                        <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                            <a role="button" className="p-2 lh-20 w100 bg-success mt-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-auto me-auto" onClick={onHide}>Fechar</a>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Fragment>
    )
}

const DefaultContent = (props) => {
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
                    {props.notification?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.notification?.body}
            </Modal.Body>
        </Fragment>
    )
}

const NotificationContentModal = (props) => {
    let modalContent = null;
    if(props.notification?.type?.toLowerCase() == 'daily_bonus') {
        modalContent = <DailyBonusContent notification={props.notification} {...props} />
    } else {
        modalContent = <DefaultContent {...props} />
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={false}
        contentClassName="shadow-xss rounded-xxl border-0"
      >
        {modalContent}
      </Modal>
    );
}

function NotificationModal({notification}) {

    const [modalShow, setModalShow] = useState(false);
    const [currentNotification, setCurrentNotification] = useState(null);

    const [markPopupNotificationAsReadMutation] = useMutation(MARK_POPUP_NOTIFICATION_AS_READ_MUTATION);

    const markPopupNotificationAsRead = async (id) => {
        const mutationResult = await markPopupNotificationAsReadMutation({ 
            variables: {
                id
            },
        });
    }

    useEffect(() => {
        if(!modalShow && notification?.id) {
            setCurrentNotification(notification)
            setModalShow(true)
            markPopupNotificationAsRead(notification.id)
        }
    }, [notification])

    return (
        <NotificationContentModal
            show={modalShow}
            notification={currentNotification}
            onHide={() => {
                setModalShow(false)
                setCurrentNotification(null)
            }}
        />
    );
}

export default NotificationModal;