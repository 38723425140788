import React from 'react';

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { getImageUrl } from '../utils';

const GET_QUERY = gql`
    query Get {
        AppSettingList(filter: { OR: [
            { key:{ _eq: "tenant.logo" }},
            { key:{ _eq: "tenant.coverImage" }},
            { key:{ _eq: "tenant.name" }}
        ]}) {
            id
            key
            value
            file
        }
    }
`;

function CompanyWidget() {

    const { loading, error, data } = useQuery(GET_QUERY);
    if (loading) return <LoadingOverlayWrapper/>;

    const imageCover = getImageUrl(data?.AppSettingList?.filter(e => e.key === 'tenant.coverImage')[0]?.file, "/assets/images/abstract-bg-green.png");
    const logo = getImageUrl(data?.AppSettingList?.filter(e => e.key === 'tenant.logo')[0]?.file, "/assets/images/unknown-company-icon.png");
    const tenantName = data?.AppSettingList?.filter(e => e.key === 'tenant.name')[0]?.value;

    if(!tenantName) return null;

    return (
        <div className="card w-100 border-0 mb-4 shadow-xss position-relative rounded-3 bg-white rounded-xxl overflow-hidden">
            <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("`+ imageCover +`")`}}></div>
            <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 z-index-1"><img src={logo} alt={tenantName} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                <div className="clearfix"></div>
                <h4 className="fw-700 font-xsss mt-2 mb-1">{tenantName} </h4>
                <div className="clearfix mb-2"></div>
            </div>
        </div>
    );
}

export default CompanyWidget;