import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from "../../../components/Appfooter";
import { getImageUrl } from "../../../utils";

const GET_LISTS_QUERY = gql`
    query GetLists {
        JobOpportunityList(filter: { isActive: { _eq: true } }, orderBy: { title: ASC }) {
            id
            title
        }
    }
`;

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID!, $value: String, $file: Upload) {
        upsert_AppSetting(appSetting: { id: $id, value: $value, file: $file }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get {
        AppSettingList {
            id
            key
            value
            file
        }
    }
`;

function AdminRecruitmentSettings() {

    const navigate = useNavigate();

    let { id } = useParams();

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY);

    useEffect(() => {
        const appSettings = {
            homepage: {
                banner: {
                    title: editData?.AppSettingList?.filter(s => s.key === "recruitment.homepage.banner.title")[0]?.value,
                    subtitle: editData?.AppSettingList?.filter(s => s.key === "recruitment.homepage.banner.subtitle")[0]?.value,
                },
                image: editData?.AppSettingList?.filter(s => s.key === "recruitment.homepage.image")[0]?.file,
                search: {
                    enabled: editData?.AppSettingList?.filter(s => s.key === "recruitment.homepage.search.enabled")[0]?.value === true,
                },
                content: editData?.AppSettingList?.filter(s => s.key === "recruitment.homepage.content")[0]?.value,
            },
            externalButton: {
                text: editData?.AppSettingList?.filter(s => s.key === "recruitment.externalButton.text")[0]?.value,
                link: editData?.AppSettingList?.filter(s => s.key === "recruitment.externalButton.link")[0]?.value,
            },
            customCss: editData?.AppSettingList?.filter(s => s.key === "recruitment.customCss")[0]?.value,
            customScript: editData?.AppSettingList?.filter(s => s.key === "recruitment.customScript")[0]?.value,
            faq: {
                enabled: editData?.AppSettingList?.filter(s => s.key === "recruitment.faq.enabled")[0]?.value === "true",
            },
            spontaneousApplication: {
                id: editData?.AppSettingList?.filter(s => s.key === "recruitment.spontaneousApplication.id")[0]?.value,
            },
            jobApplication: {
                defaultReplyMessage: editData?.AppSettingList?.filter(s => s.key === "recruitment.jobApplication.defaultReplyMessage")[0]?.value,
                defaultRejectMessage: editData?.AppSettingList?.filter(s => s.key === "recruitment.jobApplication.defaultRejectMessage")[0]?.value,
            },
        }
        reset(appSettings);
        console.log(appSettings)
    }, [editData]);

    const upsert = async (formData) => {
        const submitData = [
            {key: "recruitment.homepage.banner.title", value: formData?.homepage?.banner?.title },
            {key: "recruitment.homepage.banner.subtitle", value: formData?.homepage?.banner?.subtitle },
            {key: "recruitment.homepage.image", file: formData?.homepage?.image },
            {key: "recruitment.homepage.search.enabled", value: formData?.homepage?.search?.enabled },
            {key: "recruitment.homepage.content", value: formData?.homepage?.content },
            {key: "recruitment.externalButton.text", value: formData?.externalButton?.text },
            {key: "recruitment.externalButton.link", value: formData?.externalButton?.link },
            {key: "recruitment.customCss", value: formData?.customCss },
            {key: "recruitment.customScript", value: formData?.customScript },
            {key: "recruitment.faq.enabled", value: formData?.faq?.enabled },
            {key: "recruitment.spontaneousApplication.id", value: (!formData?.spontaneousApplication?.id || formData?.spontaneousApplication?.id === '') ? null : formData?.spontaneousApplication?.id },
            {key: "recruitment.jobApplication.defaultReplyMessage", value: formData?.jobApplication?.defaultReplyMessage },,
            {key: "recruitment.jobApplication.defaultRejectMessage", value: formData?.jobApplication?.defaultRejectMessage },
        ];

        let upsertError = false;
        submitData.forEach(async (d) => {
            const upsertResult = await upsertMutation({
                variables: {
                    id: editData?.AppSettingList?.filter(s => s.key === d.key)[0]?.id,
                    value: d.value,
                    file: d.file
                },
            });

            if (!upsertResult.data) {
                upsertError = true;
                toast.error('Ocorreu um erro ao guardar as alterações!');
            }
        })

        if(!upsertError) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/recruitment`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Recrutamento {">"} Configurações</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h5 className="font-xs fw-600 mb-0 mt-2">Candidaturas</h5>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Mensagem de Resposta</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("jobApplication.defaultReplyMessage")} ></textarea>
                                                    {errors.jobApplication?.defaultReplyMessage && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Mensagem de Rejeição</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("jobApplication.defaultRejectMessage")} ></textarea>
                                                    {errors.jobApplication?.defaultRejectMessage && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h5 className="font-xs fw-600 mb-0 mt-2">Portal de Recrutamento - Homepage</h5>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Título</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("homepage.banner.title", {required: true})} />
                                                    {errors.homepage?.banner?.title && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Subtítulo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("homepage.banner.subtitle")} />
                                                    {errors.homepage?.banner?.subtitle && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Imagem</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("homepage.banner.image")} />
                                                    {errors.homepage?.banner?.image && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="homepage.search.enabled" {...register("homepage.search.enabled")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="homepage.search.enabled">Mostrar Pesquisa?</label>
                                                    {errors.homepage?.search?.enabled && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Conteúdo</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("homepage.content")} ></textarea>
                                                    {errors.homepage?.content && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h5 className="font-xs fw-600 mb-0 mt-2">Portal de Recrutamento - Botão Superior</h5>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Texto</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("externalButton.text", {required: true})} />
                                                    {errors.externalButton?.title && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Link</label>
                                                    <input type="externalButton.text" className="form-control" autoComplete="off" {...register("externalButton.link")} />
                                                    {errors.externalButton?.link && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h5 className="font-xs fw-600 mb-0 mt-2">Portal de Recrutamento - Outras Configurações</h5>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">CSS Personalizado</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("customCss")} ></textarea>
                                                    {errors.customCss && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss">Script Personalizado</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("jobApplication.customScript")} ></textarea>
                                                    {errors.customScript && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <label className="mont-font fw-600 font-xsss">Vaga para Candidatura Espontânea</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("spontaneousApplication.id")}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            listsData && listsData.JobOpportunityList && listsData.JobOpportunityList.map((e) => {
                                                                return (
                                                                    <option key={e.id} value={e.id}>{e.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.spontaneousApplication?.id && <span className="text-red">Este campo é obrigatório</span>}
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <input type="checkbox" className="form-check-input" id="faq.enabled" {...register("faq.enabled")} />
                                                    <label className="mont-font fw-600 font-xsss ms-1" htmlFor="faq.enabled">Mostrar FAQ?</label>
                                                    {errors.faq?.enabled && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminRecruitmentSettings;