import React, { Fragment, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import moment from "moment";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import Pagetitle from "../../components/Pagetitle";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getProfilePicture } from "../../utils";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";
import Moment from "react-moment";

const GET_BIRTHDAYS_QUERY = gql`
    query GetBirthdays {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
            id
            name
            birthdate
            jobTitle
            picture
            user {
                id
                picture
            }
        }
    }
`;

function Birthdays() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()

    const { loading, error, data } = useQuery(GET_BIRTHDAYS_QUERY);

    useEffect(() => {
        const month = searchParams.get('month');
        if(!month) {
            return;
        }

        const element_to_scroll_to = document.getElementById('m' + month);
        if(element_to_scroll_to) {
            element_to_scroll_to.scrollIntoView();
        }
    }, [data])

    console.log(searchParams)

    const birthdays = (data && data.EmployeeList
                        .slice()
                        .filter(e => e.birthdate)
                        .sort(function (a, b) {
                            const d1 = new Date(a.birthdate)
                            const d2 = new Date(b.birthdate)
                            return d1.getMonth() !== d2.getMonth() ? d1.getMonth() - d2.getMonth() : d1.getDate() - d2.getDate();
                        })) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Aniversários" color="success"/>
                            </div>

                            <CustomLoadingOverlay active={loading}>
                                {[0,1,2,3,4,5,6,7,8,9,10,11].map((m) => (
                                <div key={m} className="row" id={`m${m+1}`}>
                                    <div className="col-lg-12">
                                        <h4 className="mt-4 font-md fw-700 mont-font mt-lg-5 font-md-xs"><Moment format="MMMM">{moment().month(m)}</Moment></h4>
                                    </div>
                                    {birthdays.filter(e => new Date(e.birthdate).getMonth() === m).length === 0 && <span className="p-3">Sem aniversários</span> }

                                    {birthdays.filter(e => new Date(e.birthdate).getMonth() === m).map((value , index) => (
                                
                                    <div key={index} className="col-lg-3 col-md-6 pe-2 ps-2">
                                        <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                            <div className="card-image w-100">
                                                <img src={getProfilePicture(value, '/assets/images/user.png')} alt={value.name} className="w-100 rounded-3 aspect-ratio-1" />
                                            </div>
                                            <div className="card-body d-flex ps-0 pe-0 pb-0">
                                                <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500 text-uppercase">{moment(value.birthdate).format('MMMM').substring(0, 3)}</span>{moment(value.birthdate).format('DD')}</h4></div>
                                                <h2 className="fw-700 lh-3 font-xss">{value.name}
                                                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-briefcase me-1"></i>{value.jobTitle || '-'} </span>
                                                </h2>
                                            </div>
                                            { false &&
                                            <div className="card-body p-0">
                                                <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">
                                                    <li><a href="/defaultevent"><img src="/assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                    <li><a href="/defaultevent"><img src="/assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                    <li><a href="/defaultevent"><img src="/assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                    <li><a href="/defaultevent"><img src="/assets/images/user.png" alt="user" className="w30 d-inline-block" /></a></li>
                                                    <li className="last-member"><a href="/defaultevent" className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+2</a></li>
                                                </ul>
                                                <a href="/defaultevent" className="font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white me-1">APPLY</a>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                ))}
                            </CustomLoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );
}

export default Birthdays;