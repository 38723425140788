import React,{ useState } from 'react';
import Slider from "react-slick";
import { config } from '../config';
import { getImageUrl } from '../utils';

function BadgeSlider(props) {
    
    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
    };

    const [selectedBadgeId, setSelectedBadgeId] = useState(null);

    const handleSelectedBadgeChange = (id) => {
        setSelectedBadgeId(id);
        props.onChange && props.onChange(id)
    }

    return (
        <Slider {...sliderSettings}>
            {props.badges.map((value , index) => (
            <div key={index} className="card w150 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3 me-3 pointer" onClick={() => handleSelectedBadgeChange(value.id)}>
                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                    <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={getImageUrl(value.thumbnail, '/assets/images/user.png')} alt={value.name} className="float-right p-0 bg-white rounded-circle w-100 shadow-xss aspect-ratio-1" /></figure>
                    <div className="clearfix"></div>
                    <h4 className="fw-700 font-xssss mt-3 mb-1 d-block w-100"> {value.name} </h4>
                    <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-2">{value.points ? `${value.points} ${value.points === 1 ? 'ponto' : 'pontos'}` : '-'}</p>
                    <a role="button" className={`text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl ${selectedBadgeId === value.id ? 'bg-success' : 'bg-grey'} font-xsssss fw-700 ls-lg text-white`}>ESCOLHER</a>
                </div>
            </div>
            ))}
        </Slider>
    );
}

export default BadgeSlider;