import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from "./providers/authProvider";

import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import 'moment/locale/pt'

import * as Sentry from "@sentry/react";

import { TrackedApp } from './App';
import * as serviceWorker from './serviceWorker';

import { config } from './config';
import { postMessageWebView } from './utils/postMessageWebView';

const postAuthSessionStorage = () => {
    const sessionStorageKey = `oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`;
    const authSessionStorage = window.sessionStorage.getItem(sessionStorageKey);
    postMessageWebView(sessionStorageKey, authSessionStorage);
    console.log(authSessionStorage)
}

/*
const oidcConfig = {
    userManager: null,
    authority: config.openid.AUTHORITY_URI,
    clientId: config.openid.CLIENT_ID,
    responseType: 'code',
    scope: "openid",
    redirectUri: config.openid.REDIRECT_URI,
    postLogoutRedirectUri: config.openid.POST_LOGOUT_REDIRECT_URI,
    automaticSilentRenew: true,
    onSignIn: user => {
      // the `user` prop is actually the data the app received from `/userinfo` endpoint.
      console.log(user);
      localStorage.setItem('token', user.access_token);
      postAuthSessionStorage();
      //window.history.go('/home', user);
      window.history.go('/home');
    },
    onSignOut: () => {
        localStorage.removeItem('token');
    }
    //  process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : 'https://cobraz.github.io/example-oidc-react',
};

const userManager = new UserManager({
    ...oidcConfig,
    client_id: oidcConfig.clientId,
    redirect_uri: oidcConfig.redirectUri,
    silent_redirect_uri: oidcConfig.silentRedirectUri || oidcConfig.redirectUri,
    post_logout_redirect_uri: oidcConfig.postLogoutRedirectUri || oidcConfig.redirectUri,
    response_type: oidcConfig.responseType,
    popup_redirect_uri: oidcConfig.popupRedirectUri,
    sessionStore: new WebStorageStateStore({ store: window.localStorage }),
    userStore: new WebStorageStateStore({ store: window.localStorage }),

});

userManager.events.addUserLoaded(function(cb) {
    console.log("user (re)loaded...");
    localStorage.setItem('token', cb.access_token);
    postAuthSessionStorage();
});

oidcConfig.userManager = userManager;
*/

// Moment
// Sets the moment instance to use.
Moment.globalMoment = moment;

// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'pt';

// Set the output timezone for local for every instance.
Moment.globalLocal = true;

moment.locale('pt')

/*
// User auth in URL
const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
if(urlParams.get('authUser')) {
    console.log('authUser present in queryString')
    const userData = urlParams.get('authUser');
    console.log(userData)
    const user = User.fromStorageString(userData);
    console.log(user);
    oidcConfig.userManager.storeUser(user)
}
*/

// Receive data from app
window.addEventListener("message", message => {
    //alert('Data from app: ' + JSON.stringify(message.data))

    /*
    if(message.data && message.data.appInstallation) {
        localStorage.setItem('appInstallation', JSON.stringify(message.data.appInstallation));
    }
    */

    if(message?.data?.requestAuthToken) {
        postAuthSessionStorage()
    }
});

Sentry.init({
    dsn: "https://5a57494fbaa0e6b1b016f7d7ec716e57@o4507284233453568.ingest.de.sentry.io/4507285125267536",
    environment: config.environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <TrackedApp/>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();