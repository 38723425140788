import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Moment from "react-moment";
import { getImageUrl } from "../../utils";
import Appfooter from "../../components/Appfooter";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($fetchEmployee: Boolean! $id: String!) {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: {isActive: {_eq: true} }, orderBy: { name: ASC }) {
            id
            name
            jobTitle
            user {
                id
                picture
            }
        }
        EmployeeById(id: $id) @include(if: $fetchEmployee)  {
            id
            name
            birthdate
            email
            jobTitle
            user {
                picture
            }
        }
    }
`;

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const GET_FEEDBACK_REQUEST_QUERY = gql`
    query GetFeedbackRequest($fetchRequest: Boolean! $id: String!) {
        FeedbackRequestById(id: $id) @include(if: $fetchRequest)  {
            id
            requester {
                id
                name
                user {
                    id
                    picture
                }
            }
            feedbackReply {
                id
            }
            message
            createdDate
        }
    }
`;

const UPSERT_EMPLOYEE_FEEDBACK_MUTATION = gql`
    mutation UpsertEmployeeFeedback($senderId: UUID!, $receiverId: UUID!, $message: String) {
        upsert_EmployeeFeedback(employeeFeedback: { sender: { id: $senderId }, receiver: { id: $receiverId }, message: $message }) {
            id
        }
    }
`;

const UPSERT_EMPLOYEE_FEEDBACK_WITH_REQUEST_MUTATION = gql`
    mutation UpsertEmployeeFeedback($senderId: UUID!, $receiverId: UUID!, $message: String, $requestId: UUID) {
        upsert_EmployeeFeedback(employeeFeedback: { sender: { id: $senderId }, receiver: { id: $receiverId }, message: $message, request: { id: $requestId } }) {
            id
        }
    }
`;

function GiveFeedback() {

    const navigate = useNavigate();

    const { employeeId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const feedbackRequestId = searchParams.get("requestId");

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertEmployeeFeedbackMutation, { upsertEmployeeFeedbackMutationData }] = useMutation(UPSERT_EMPLOYEE_FEEDBACK_MUTATION);
    const [upsertEmployeeFeedbackWithRequestMutation, { upsertEmployeeFeedbackWithRequestMutationData }] = useMutation(UPSERT_EMPLOYEE_FEEDBACK_WITH_REQUEST_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    
    const { loading, error, data } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: employeeId || "00000000-0000-0000-0000-000000000000", fetchEmployee: employeeId !== null }});
    const { data: feedbackRequestData, loading: feedbackRequestLoading, error: feedbackRequestError } = useQuery(GET_FEEDBACK_REQUEST_QUERY, { variables: { id: feedbackRequestId || "00000000-0000-0000-0000-000000000000", fetchRequest: feedbackRequestId !== null }});

    const giveFeedback = async (formData) => {
        if(feedbackRequestId) {
            const upsertResult = await upsertEmployeeFeedbackWithRequestMutation({ 
                variables: {
                    senderId: viewerData.Viewer.employee.id,
                    receiverId: feedbackRequestData.FeedbackRequestById.requester.id,
                    message: formData.message,
                    requestId: feedbackRequestId,
                },
            });

            if(upsertResult.data) {
                toast.success('Feedback enviado!');
                navigate(`/employees/${feedbackRequestData.FeedbackRequestById.requester.id}`)
            }
        } else {
            const upsertResult = await upsertEmployeeFeedbackMutation({ 
                variables: {
                    senderId: viewerData.Viewer.employee.id,
                    receiverId: employeeId || formData.receiverId,
                    message: formData.message,
                },
            });

            if(upsertResult.data) {
                toast.success('Feedback enviado!');
                navigate(`/employees/${employeeId || formData.receiverId}`)
            }
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">

                { (loading || viewerData) && <LoadingOverlayWrapper /> }

                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Dar Feedback</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(giveFeedback)}>
                                        { feedbackRequestData && feedbackRequestData.FeedbackRequestById &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <h4 className="font-xs fw-600">Pedido de Feedback</h4>
                                                <div className="chat-body pt-3">
                                                    <div className="messages-content" ref={el => {
                                                    if (el) {
                                                        el.style.setProperty('padding-bottom', '0px ', 'important');
                                                    }
                                                    }}>
                                                        <div className="message-item">
                                                            <div className="message-user">
                                                                <figure className="avatar">
                                                                    <img src={getImageUrl(feedbackRequestData.FeedbackRequestById.requester.user.picture, '/assets/images/user.png')} alt={feedbackRequestData.FeedbackRequestById.requester.name} />
                                                                </figure>
                                                                <div>
                                                                    <h5>{feedbackRequestData.FeedbackRequestById.requester.name}</h5>
                                                                    <div className="time"><Moment format="DD/MM/YYYY">{feedbackRequestData.FeedbackRequestById.createdDate}</Moment></div>
                                                                </div>
                                                            </div>
                                                            <div className="message-wrap">{feedbackRequestData.FeedbackRequestById.message}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        }
                                        {!feedbackRequestId || (feedbackRequestData && feedbackRequestData.FeedbackRequestById && !feedbackRequestData.FeedbackRequestById.feedbackReply) ? (
                                        <>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xssss">Para</label>
                                                    { employeeId && <input type="text" className="form-control" defaultValue={data?.EmployeeById?.name}  {...register("receiverId")} readOnly /> }
                                                    { feedbackRequestId && feedbackRequestData && feedbackRequestData.FeedbackRequestById && <input type="text" className="form-control" defaultValue={feedbackRequestData.FeedbackRequestById.requester.name}  {...register("receiverId")} readOnly /> }
                                                    { !employeeId && !feedbackRequestId && 
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("receiverId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            data && data.EmployeeList && data.EmployeeList
                                                            .filter(e => viewerData && e.id !== viewerData?.Viewer?.employee?.id)
                                                            .map((employee) => {
                                                                return (
                                                                    <option key={employee.id} value={employee.id}>{employee.name} {employee.jobTitle && `(${employee.jobTitle})`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    }
                                                    {errors.receiverId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mt-3 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xssss">Mensagem</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("message", { required: true })} />
                                                    {errors.message && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>                                     
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(giveFeedback)}>Enviar</a>
                                            </div>
                                        </div>
                                        </>) : (
                                            <div className="row">
                                                <div className="col-lg-12 mb-4">
                                                    <div className="card alert-warning p-3">
                                                        Este pedido de feedback já foi respondido.
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        }

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default GiveFeedback;