import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { MARKET_ORDER_STATUS_OPTIONS } from "../../../config";

const GET_QUERY = gql`
    query Get {
        Order_List(orderBy: {createdDate: DESC}) {
            id
            user {
                id
                name
            }
            total
            status
            createdDate
            items {
                id
            }
        }
    }
`;

function AdminOrderList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const orders = (data && data.Order_List) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Encomendas"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ orders.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/market/products" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Produtos</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Data</th>
                                                            <th className="border-0 p-4">Utilizador</th>
                                                            <th className="border-0 p-4">Produtos</th>
                                                            <th className="border-0 p-4">Total</th>
                                                            <th className="border-0 p-4">Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orders.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/market/orders/${value.id}`}><Moment format="DD/MM/YYYY HH:MM">{value.createdDate}</Moment></Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.user.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.items.length}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="money text-grey-500 fw-600 font-xsss">{value.total} <i className="icon-empathize-square ms-1"></i></span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === value.status)[0].className} font-xssss rounded-3`}><strong>{MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === value.status)[0].name}</strong></span>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminOrderList;