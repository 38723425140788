import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_QUERY = gql`
    query Get {
        DocumentCategoryList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { parentCategory: { _isNull: true } }) {
            id
            name
            description
            documents {
                id
            }
            categories {
                id
            }
        }
    }
`;

function DocumentCategoryList() {
    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data } = useQuery(GET_QUERY);

    const categories = (data && data.DocumentCategoryList.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title="Documentos" color="success"/>
                                
                                <div className="row">
                                    <div className="search-form-2 mb-3">
                                        <input type="text" className="form-control mb-0 pt-0 pv-0 theme-dark-bg border-0" placeholder="Pesquisar ficheiros..." onKeyDown={(e) => { if(e.key === 'Enter') { navigate(`/documents/search?q=${e.target.value}`); }}} />
                                    </div>
                                </div>
                                
                                <CustomLoadingOverlay active={loading}/>

                                <div className="row ps-2 pe-1">
                                    {categories.filter(e => !searchParams.get("q") || e.name.toLowerCase().includes(searchParams.get("q").toLowerCase())).map((value , index) => (

                                    <div key={index} className="col-md-6 col-sm-6 pe-2 ps-2 cursor-pointer" onClick={() => navigate(`/documents/categories/${value.id}`)}>
                                        <div className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4">
                                            <i className="feather-folder font-md text-grey-500 position-absolute right-0 me-3"></i>
                                            <h4 className="font-xss fw-700 text-grey-900 mb-2 pe-4">{value.name}</h4>
                                            <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className=" fw-500text-grey-500 font-xssss">{ value.description }</span></h5>
                                            <h5 className="font-xssss mb-2 text-grey-500 fw-500">{value.categories?.length} categorias | {value.documents?.length} documentos</h5>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default DocumentCategoryList;