import React, { Fragment, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import { getAttachmentUrl, getProfilePicture } from "../../utils";
import { COMPLAINT_STATUS_OPTIONS } from "../../config";
import Moment from "react-moment";
import Appfooter from "../../components/Appfooter";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            email
            employee {
                id
                phone
                email
            }
        }
    }
`;

const GET_COMPLAINT_SECURE_QUERY = gql`
    query GetComplaintSecure($password: String) {
        GetComplaintSecure(password: $password) {
            id
            user {
                id
                name
            }
            name
            phone
            email
            subject
            description
            status
            category {
                id
                name
            }
            createdDate
        }
        GetComplaintAttachmentsSecure(password: $password) {
            id
            name
            file
        }
        GetComplaintMessagesSecure(password: $password) {
            id
            sender {
                id
                name
            }
            isUserReply
            content
            createdDate
            attachments {
                id
                name
                file
            }
        }
    }
`;

const UPSERT_COMPLAINT_MESSAGE_MUTATION = gql`
    mutation UpsertComplaintMessage($complaintId: UUID!, $senderId: UUID!, $content: String!, $password: String) {
        upsert_ComplaintMessage(complaintMessage: { complaint: { id: $complaintId }, sender: { id: $senderId }, content: $content, password: $password }) {
            id
        }
    }
`;

const UPSERT_ANONYMOUS_COMPLAINT_MESSAGE_MUTATION = gql`
    mutation UpsertComplaintMessage($subject: String, $description: String, $categoryId: UUID, $password: String) {
        upsert_ComplaintMessage(complaintMessage: { subject: $subject, description: $description, category: { id: $categoryId }, password: $password, status: NEW }) {
            id
        }
    }
`;

const UPSERT_COMPLAINT_MESSAGE_ATTACHMENT_MUTATION = gql`
    mutation UpsertComplaintMessageAttachment($messageId: UUID, $file: Upload, $password: String) {
        upsert_ComplaintMessageAttachment(complaintMessageAttachment: { message: { id: $messageId }, file: $file, password: $password }) {
            id
            name
        }
    }
`;

function FollowupComplaint() {

    const navigate = useNavigate();

    const [complaintId, setComplaintId] = useState(null);
    const [complaintPassword, setComplaintPassword] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const [isConfidentialReadMoreOpen, setIsConfidentialReadMoreOpen] = useState(false);
    const [isAnonymousReadMoreOpen, setIsAnonymousReadMoreOpen] = useState(false);

    const toggleConfidentialReadMoreOpen = () => setIsConfidentialReadMoreOpen(!isConfidentialReadMoreOpen);
    const toggleAnonymousReadMoreOpen = () => setIsAnonymousReadMoreOpen(!isAnonymousReadMoreOpen);

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertComplaintMessageMutation, { upsertComplaintMessageMutationData }] = useMutation(UPSERT_COMPLAINT_MESSAGE_MUTATION);
    const [upsertComplaintMessageAttachmentMutation, { upsertComplaintMessageAttachmentMutationData }] = useMutation(UPSERT_COMPLAINT_MESSAGE_ATTACHMENT_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: complaintData, loading: complaintLoading, error: complaintError, refetch: complaintRefetch } = useQuery(GET_COMPLAINT_SECURE_QUERY, { variables: { password: '' } });

    const getComplaint = async (formData) => {
        setComplaintId(formData.password.substring(0, formData.password.indexOf(":")));
        setComplaintPassword(formData.password.substring(formData.password.indexOf(":")+1, formData.password.length));
        complaintRefetch({ password: formData.password });
    }

    const createComplaintMessage = async (formData) => {
        const upsertResult = await upsertComplaintMessageMutation({ 
            variables: {
                complaintId: complaintData.GetComplaintSecure.id,
                senderId: viewerData.Viewer.id,
                content: formData.message,
                password: complaintPassword,
            },
        });

        if(upsertResult.data) {
            attachments.map(async a => {
                uploadAttachment(upsertResult.data.upsert_ComplaintMessage.id, a)
            })
            reset({
                message: ""
            });
            setAttachments([])
            toast.success('Mensagem enviada com sucesso!');
            //navigate(`/whistleblowing/complaints`)
            complaintRefetch({ password: `${complaintId}:${complaintPassword}` });
        }
    }

    const uploadAttachment = async (messageId, file) => {
        const upsertResult = await upsertComplaintMessageAttachmentMutation({ 
            variables: {
                messageId: messageId,
                file: file,
                password: complaintPassword,
            },
        });
    }

    const onFileChange = event => { 
        //uploadAttachment(event.target.files[0])
        setSelectedFile(event.target.files[0])
        setAttachments(prevState => [...prevState, event.target.files[0]])
        console.log(attachments)
    };

    const removeAttachment = name => {
        setAttachments(prevState => prevState.filter(a => a.name !== name))
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Acompanhar Denúncia</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    { !complaintData?.GetComplaintSecure?.id &&
                                    <form onSubmit={handleSubmit(getComplaint)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <p>Quando criou a denúncia, foi-lhe atribuída uma palavra-passe única. Cole a palavra-passe abaixo.</p>
                                                    <label className="mont-font fw-600 font-xsss">Palavra-passe</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("password")} />
                                                    {errors.password && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(getComplaint)}>Ir para a denúncia</a>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                    
                                    { complaintData?.GetComplaintSecure &&
                                    <form onSubmit={handleSubmit(createComplaintMessage)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={complaintData.GetComplaintSecure.name || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={complaintData.GetComplaintSecure.email || ''} readOnly />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={complaintData.GetComplaintSecure.phone || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Categoria</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={complaintData.GetComplaintSecure.category.name || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Assunto</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={complaintData.GetComplaintSecure.subject || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} value={complaintData.GetComplaintSecure.description || ''} readOnly />
                                                </div>
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Estado</label>
                                                    <input type="text" className="form-control" autoComplete="off" value={COMPLAINT_STATUS_OPTIONS.filter(o => o.id == complaintData.GetComplaintSecure.status)[0].name} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <label className="mont-font fw-600 font-xsss">Anexos</label>
                                                    {complaintData.GetComplaintAttachmentsSecure?.length === 0 && "(sem anexos)" }

                                                    <ul className="mb-3">
                                                    {complaintData.GetComplaintAttachmentsSecure?.map(attachment => (
                                                        <li>
                                                            <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                                                <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <h5 className="mont-font fw-600 font-xs mt-3">Mensagens</h5>
                                            {complaintData.GetComplaintMessagesSecure?.length === 0 && <p className="text-center">Ainda não existem respostas a esta denúncia.</p>}
                                            <div className="col-lg-12 pe-0 ps-0">
                                                <div className="card w-100 d-block chat-body p-0 border-0 position-relative">
                                                    <div className="messages-content p-3">
                                                        {complaintData.GetComplaintMessagesSecure?.slice()
                                                        .sort(function (a, b) {
                                                            const d1 = new Date(a.createdDate).getTime()
                                                            const d2 = new Date(b.createdDate).getTime()
                                                            return d1 - d2;
                                                        })
                                                        .map(m => (
                                                        <div key={m.id} className={`message-item ${m.isUserReply ? ' outgoing-message ' : ''}`}>
                                                            <div className="message-user">
                                                                <figure className="avatar">
                                                                    <img src={getProfilePicture(m.sender) || "/assets/images/user.png"} alt={m.sender?.name || '(Utilizador)'} />
                                                                </figure>
                                                                <div>
                                                                    <h5 className="font-xssss mt-2">{m.sender?.name || '(Utilizador)'}</h5>
                                                                    <div className="time"><Moment format="DD/MM/YYYY HH:MM">{m.createdDate}</Moment></div>
                                                                </div>
                                                            </div>
                                                            <div className="message-wrap shadow-none">{m.content}</div>
                                                            <ul className="mt-1 mb-3">
                                                                {m.attachments?.map(attachment => (
                                                                    <li>
                                                                        <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark mb-1">
                                                                            <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <h5 className="mont-font fw-600 font-xs">Nova Mensagem</h5>
                                            <div className="col-lg-12 mt-2 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Mensagem</label>
                                                    <textarea className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("message", { required: true })} />
                                                    {errors.message && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card border-0">
                                                    <label className="mont-font fw-600 font-xsss">Anexos</label>
                                                    <ul className="mb-3">
                                                    {attachments.map((a, index) => (
                                                        <li key={index}><i className="font-xss text-grey-500 feather-paperclip me-2"></i> {a.name}<a role="button" onClick={() => removeAttachment(a.name)}><i className="font-xss text-grey-500 feather-x ms-2"></i></a></li>
                                                    ))}
                                                    </ul>
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createComplaintMessage)}>Enviar mensagem</a>
                                            </div>
                                        </div>
                                    </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default FollowupComplaint;