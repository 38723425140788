import React, { Component, Fragment, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';

import { useQuery, gql } from '@apollo/client';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getImageUrl } from "../../utils";

const GET_BENEFITS_QUERY = gql`
    query GetBenefitCategoriesAndBenefits {
        BenefitCategoryList {
            id
            name
        }
        BenefitList {
            id
            name
            isAvailable
            thumbnail
            partner {
                id
                name
                logo
            }
            category {
                id
                name
            }
        }
    }
`;


function BenefitList() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedCategoryId, setSelectedCategoryId] = useState('');

    const { loading, error, data } = useQuery(GET_BENEFITS_QUERY);
    
    const partnerId = searchParams.get('partner');
    
    const benefits = (data && data.BenefitList && data.BenefitList
        .filter(b => b.isAvailable)
        .filter(b => !partnerId || b.partner.id === partnerId)
        .filter(b => selectedCategoryId === '' || b.category.id === selectedCategoryId)
    ) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Benefícios e Parcerias" color="success" />
                                <div className="row">
                                    <div className="search-form-2 mb-3">
                                        <select type="text" className="form-control mb-0 pt-0 pv-0 theme-dark-bg border-0" defaultValue='' onChange={(e) => setSelectedCategoryId(e.target.value)}>
                                            <option value=''>Todas as Categorias</option>
                                            {
                                                data && data.BenefitCategoryList && data.BenefitCategoryList
                                                .map((c) => {
                                                    return (
                                                        <option key={c.id} value={c.id}>{c.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <CustomLoadingOverlay active={loading}>
                                    <div className="row ps-2 pe-2">
                                        {
                                            benefits
                                            .map((benefit, index) => {
                                                return (
                                                    <div key={index} className="col-lg-3 col-md-3 col-sm-6 mb-3 pe-2 ps-2">
                                                        <div className="card w-100 p-0 hover-card shadow-xss border-0 rounded-3 overflow-hidden me-1 h-100">
                                                            {benefit.feature ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''}
                                                            <div className="card-image w-100 mb-3">
                                                                <a href={"/benefits/" + benefit.id} className="position-relative d-block"><img src={getImageUrl(benefit.thumbnail, "/assets/images/product.png")} alt={benefit.name} className="w-100 aspect-ratio-385-300" /></a>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                {false && <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i>}
                                                                <h4 className="fw-700 font-xss mt-0 lh-20 mb-2"><a href={"/benefits/" + benefit.id} className="text-dark text-grey-900">{benefit.name}</a></h4>
                                                                <h6 className="font-xssss text-grey-500 fw-600 mt-0 mb-2"> {benefit.category.name}</h6>
                                                                { false && <>
                                                                <div className="star d-block w-100 text-left mt-0">
                                                                    <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                                    <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                                    <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                                    <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                                    <img src="assets/images/star-disable.png" alt="star" className="w15 me-1 float-left me-2" />
                                                                </div>
                                                                <div className="mt-4 w-100"></div>
                                                                <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-ruler-pencil text-grey-500 me-1"></i> 200 sq</h5>
                                                                <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-rss-alt text-grey-500 me-1"></i> WiFi</h5>
                                                                <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500"><i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i> Card</h5>
                                                                </>}
                                                                <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><Link to={`/benefits?partner=${benefit.partner.id}`}>{benefit.partner.name}</Link></h5>
                                                                <div className="clearfix"></div>
                                                                {false && <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-success float-left"><span className="font-xsssss">$</span> {benefit.price} <span className="font-xsssss text-grey-500">/ mo</span> </span>}
                                                                <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-success float-left"><br /><br /> <span className="font-xsssss text-grey-500"> </span> </span>
                                                                <a href={"/benefits/" + benefit.id} className="position-absolute bottom-15 mb-2 right-15"><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {!loading && benefits.length === 0 && <p className="text-center">Não existem benefícios ou parcerias.</p>}
                                    </div>
                                </CustomLoadingOverlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter />
        </Fragment>
    );
}

export default BenefitList;