import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import Moment from 'react-moment';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getAttachmentUrl } from "../../utils";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_EMPLOYEE_REFERRALS_QUERY = gql`
    query GetEmployeeReferrals($userId: UUID) {
        EmployeeReferralList(filter: { referrer: { id: { _eq: $userId } } }, orderBy: { createdDate: DESC }) {
            id
            name
            email
            phone
            linkedinUrl
            cv
            createdDate
        }
    }
`;

function EmployeeReferralsList() {

    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { data: referralsData, loading: referralsLoading, error: referralsError } = useQuery(GET_EMPLOYEE_REFERRALS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { userId: viewerData && viewerData.Viewer && viewerData.Viewer.id },
        skip: !(viewerData && viewerData.Viewer)
    });

    const referrals = referralsData && referralsData.EmployeeReferralList || [];

    const nodeRef = React.useRef(null);
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <img src="/assets/images/employee-refer.png" alt="Refer a Friend" className="w-100" />
                                        </div>
                                        <div className="col-lg-6 ps-lg-5">
                                            <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">Recruta um Amigo</h2>
                                            <p className="font-xssss fw-500 text-grey-500 lh-26">Acreditamos que os nossos colaboradores são os melhores embaixadores da nossa empresa e que podem transmitir a experiência positiva de trabalhar connosco.</p>
                                            <p className="font-xssss fw-500 text-grey-500 lh-26">Vamos recrutar juntos e encontrar o teu/a tua próximo(a) colega.</p>

                                            <Link to="/referrals/new" className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Recomendar</Link>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title="Amigos Referenciados" customButton={{link: `/referrals/new`, icon: 'feather-plus'}} color="success" />
                                
                                
                                <div className="row">
                                    <CustomLoadingOverlay active={viewerLoading || referralsLoading}>
                                    {referrals.map((value , index) => (
                                    <div key={index} className="col-md-6">
                                        <div className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4">
                                            <i className="feather-user font-md text-grey-500 position-absolute right-0 me-3"></i>
                                            <h4 className="font-xss fw-700 text-grey-900 mb-3 pe-4">{value.name} <span className="font-xssss fw-500 text-grey-500 ms-4">(<Moment format="DD/MM/YYYY">{value.createdDate}</Moment>)</span> </h4>
                                            <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Email:</span> {value.email}</h5>
                                            <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className="text-grey-900 font-xssss text-dark">Telefone:</span> {value.phone}</h5>
                                            <h5 className="font-xssss text-grey-500 fw-600 mb-3"><span className="text-grey-900 font-xssss text-dark">LinkedIn:</span> <a href={value.linkedinUrl} target="_blank" rel="noopener">{value.linkedinUrl}</a></h5>
                                            <h5 className="font-xssss text-grey-500 fw-600 mb-3"><span className="text-grey-900 font-xssss text-dark">CV:</span> { value.cv ? <a href={getAttachmentUrl(value.cv)}><i className="feather-download font-xss ms-2"></i></a> : '-' }</h5>
                                            { false && <h6 className="d-inline-block p-2 text-success alert-success fw-600 font-xssss rounded-3 me-2">UX Design</h6> }
                                            { false && <h6 className="d-inline-block p-2 text-warning alert-warning fw-600 font-xssss rounded-3 me-2">Andriod</h6> }
                                            { false && <h6 className="d-inline-block p-2 text-secondary alert-secondary fw-600 font-xssss rounded-3 me-2">Developer</h6> }
                                            { false && <a href="/defaultjob" className="position-absolute bottom-15 mb-3 right-15"><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></a> }
                                        </div>
                                    </div>
                                    ))}
                                    
                                    {referrals.length === 0 && <p className="text-grey-500 text-center">Ainda não existem amigos referenciados.</p>}

                                    </CustomLoadingOverlay>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeReferralsList;