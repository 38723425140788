import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import ShopContext from "../../context/shop-context";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import MarketHeader from "../../components/MarketHeader";
import CreditCard from "../../components/CreditCard";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            name
            wallet {
                id
                balance
            }
        }
    }
`;

const GET_GEOGRAPHIC_QUERY = gql`
    query GetGeographic($countryId: UUID, $fetchCities: Boolean!) {
        CountryList(orderBy: { name: ASC }) {
            id
            name
        }
        CityList(filter: {region: {country: { id: { _eq: $countryId } } } }, orderBy: { name: ASC }) @include(if: $fetchCities) {
            id
            name
        }
    }
`;

const UPSERT_CREATE_ORDER_MUTATION = gql`
    mutation CreateOrder($paymentMethod: inp_PaymentMethod, $shippingAddress: inp_Address, $items: [inp_OrderItem]) {
        CreateOrder(paymentMethod: $paymentMethod, shippingAddress: $shippingAddress, items: $items) {
            id
        }
    }
`;

function Checkout() {
    
    const context = useContext(ShopContext);
    const navigate = useNavigate();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();
    
    const [createOrderMutation, { createOrderMutationData, createOrderMutationLoading }] = useMutation(UPSERT_CREATE_ORDER_MUTATION);
    const { loading, error, data, refetch } = useQuery(GET_VIEWER_QUERY);
    const { loading: geoLoading, error: geoError, data: geoData, refetch: geoRefetch } = useQuery(GET_GEOGRAPHIC_QUERY, { variables: { fetchCities: false, countryId: "00000000-0000-0000-0000-000000000000" } });

    const watchCountryId = watch("countryId");

    useEffect(() => {
        reset(formValues => ({
            ...formValues,
            name: data?.Viewer.name,
        }))
    }, [data]);

    useEffect(() => {
        geoRefetch({
            fetchCities: watchCountryId !== null && watchCountryId?.length > 0,
            countryId: watchCountryId
        })
    }, [watchCountryId]);
    
    const placeOrder = async (formData) => {
        const amount = context.cart.reduce((total, curItem) => { return total + (curItem.price * curItem.quantity);}, 0);
        const userBalance = data?.Viewer?.wallet?.balance;

        if(amount <= 0) {
            toast.warning("Não existem produtos no carrinho!");
            return;
        }

        if(amount > userBalance) {
            toast.warning("Saldo insuficiente!");
            return;
        }

        var orderItems = []
        context.cart.map(cartItem => {
            orderItems.push({
                product: { id: cartItem.id },
                quantity: cartItem.quantity
            })
        });

        const upsertResult = await createOrderMutation({
            variables: {
                paymentMethod: {
                    id: data?.Viewer?.wallet?.id
                },
                shippingAddress: {
                    deliveryName: formData.name,
                    country: { id: formData.countryId },
                    city: { id: formData.cityId },
                    address: formData.address,
                    zipCode: formData.zipCode,
                    locality: formData.locality
                },
                items: orderItems,
            },
        });

        if(upsertResult.data) {
            toast.success('Encomenda realizada com sucesso!');
            context.clearCart();
            navigate(`/market/orders`)
            
        }
    }

    const cartTotal = context.cart.reduce((total, curItem) => { return total + (curItem.price * curItem.quantity);}, 0);
    const canBuy = cartTotal > 0 && data?.Viewer?.wallet?.balance >= cartTotal;

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <MarketHeader title="Resumo da Compra" />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    {false && <div className="col-sm-12">
                                        <div className="card bg-greyblue border-0 p-4 mb-5">
                                            <p className="mb-0 mont-font font-xssss text-uppercase fw-600 text-grey-500"><i className="fa fa-exclamation-circle"></i> Have A Coupon? <a className="expand-btn text-grey-500 fw-700" href="/checkoutcoupon_info">Click Here To Enter Your Code.</a></p>
                                        </div>
                                    </div>}

                                    <div className="col-xl-6 col-lg-6">
                                        <div className="page-title">
                                            <h4 className="mont-font fw-600 font-md mb-lg-5 mb-4">Morada de Entrega</h4>
                                            <form onSubmit={handleSubmit(placeOrder)}>
                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">Nome</label>
                                                            <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                            {errors.name && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">País</label>
                                                            <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("countryId", { required: true })}>
                                                                <option value=''>Selecionar...</option>
                                                                {
                                                                    geoData?.CountryList?.map((country) => {
                                                                        return (
                                                                            <option key={country.id} value={country.id}>{country.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.countryId && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">Cidade</label>
                                                            <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("cityId", { required: true })}>
                                                                <option value=''>Selecionar...</option>
                                                                {
                                                                    geoData?.CityList?.map((city) => {
                                                                        return (
                                                                            <option key={city.id} value={city.id}>{city.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.cityId && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">Morada</label>
                                                            <input type="text" className="form-control" autoComplete="off" {...register("address", { required: true })} />
                                                            {errors.address && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">Código Postal</label>
                                                            <input type="text" className="form-control" autoComplete="off" {...register("zipCode", { required: true })} />
                                                            {errors.zipCode && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-gorup">
                                                            <label className="mont-font fw-600 font-xssss">Localidade</label>
                                                            <input type="text" className="form-control" autoComplete="off" {...register("locality", { required: true })} />
                                                            {errors.locality && <span className="text-red">Por favor, verifique este campo</span>}
                                                        </div>        
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 offset-xl-1 col-lg-6">
                                        <CustomLoadingOverlay active={createOrderMutationLoading}>
                                        <div className="order-details">
                                            <h4 className="mont-font fw-600 font-md mb-5">Detalhes da Encomenda</h4>
                                            <div className="table-content table-responsive mb-5 card border-0 bg-greyblue p-lg-5 p-4">
                                                <table className="table order-table order-table-2 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Produto</th>
                                                            <th className="text-right border-0">Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {context.cart.map((cartItem, index) => (
                                                        <tr key={index}>
                                                            <th className="text-grey-500 fw-500 font-xsss">{cartItem.name}
                                                                <strong className="float-right"><span>✕</span> {cartItem.quantity}</strong>
                                                            </th>
                                                            <td className="text-right text-grey-500 fw-500 font-xsss">{cartItem.price * cartItem.quantity} <i className="icon-empathize-square ms1" /></td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        {false && <tr className="cart-subtotal">
                                                            <th>Subtotal</th>
                                                            <td className="text-right text-grey-700 font-xsss fw-700">$0</td>
                                                        </tr>}
                                                        {false && <tr className="shipping">
                                                            <th>Shipping</th>
                                                            <td className="text-right">
                                                                <span className="text-grey-700 font-xsss fw-700">Flat Rate; $20.00</span>
                                                            </td>
                                                        </tr>}
                                                        <tr className="order-total">
                                                            <th>Total</th>
                                                            <td className="text-right text-grey-700 font-xsss fw-700"><span className="order-total-ammount">{ cartTotal } <i className="icon-empathize-square ms-1"></i></span></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            
                                            <div className="checkout-payment card border-0 mb-3 bg-greyblue p-lg-5 p-4">
                                                <div className="payment-group mb-4">
                                                    <div className="payment-radio">
                                                        <input type="radio" value="bank" name="payment-method" id="bank" checked readOnly />
                                                        <label className="payment-label fw-600 font-xsss text-grey-900 ms-2" >Empathize Wallet</label>
                                                    </div>
                                                </div>
                                                { data?.Viewer?.wallet &&
                                                    <CreditCard bank="empathize" holder={data.Viewer.name} balance={data.Viewer.wallet.balance} currencySymbol={<i className="icon-empathize text-success"></i>} />
                                                }
                                            </div>
                                            <div className="clearfix"></div>

                                            <div className="card shadow-none border-0">
                                                <a role="button" className={`w-100 p-3 mt-3 font-xsss text-center text-white ${canBuy ? 'bg-current' : 'bg-grey'} rounded-3 text-uppercase fw-600 ls-3`} onClick={handleSubmit(placeOrder)}>Concluir</a>    
                                            </div>
                                        </div>
                                        </CustomLoadingOverlay>
                                    </div>

                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default Checkout;