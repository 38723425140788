import { Link } from "react-router-dom";
import { config } from "./config";

export const getInitials = (name) => {
    if(!name) return;
    
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials;
}

export const diffHours = (dt2, dt1) => {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
}

String.prototype.format = function () {
    // store arguments in an array
    var args = arguments;
    // use replace to iterate over the string
    // select the match and check if the related argument is present
    // if yes, replace the match with the argument
    return this.replace(/{([0-9]+)}/g, function (match, index) {
      // check if the argument is present
      return typeof args[index] == 'undefined' ? match : args[index];
    });
};

export const getImageUrl = (image, fallbackUrl) => {
    if(image?.startsWith("http://") || image?.startsWith("https://")) {
        return image;
    }
    
    return image ? config.IMAGE_URL_FORMAT.format(encodeURIComponent(image)) : fallbackUrl;
}

export const getAttachmentUrl = (file) => {
    return config.FILE_URL_FORMAT.format(encodeURIComponent(file));
}

export const getProfilePicture = (employeeOrUser) => {
    if(!employeeOrUser) return null;

    // if Employee type and has User property
    if(employeeOrUser.user) {
        return getImageUrl(employeeOrUser.user.picture || employeeOrUser.picture, '/assets/images/user.png')
    }

    if(employeeOrUser.employee) {
        return getImageUrl(employeeOrUser.picture || employeeOrUser.employee.picture, '/assets/images/user.png')
    }

    return getImageUrl(employeeOrUser.picture, '/assets/images/user.png');
}


export const HASHTAG_FORMATTER = string => {
    return string !== null && string.split(/(^|\s|\B)(#[a-z\d-]+)/gi).filter(Boolean).map((v,i)=>{
        if(v.startsWith('#')){
            return <Link key={i} className="text-success pointer" to={`/feed/hashtags/${v.substring(1)}`}>{v}</Link>
        } else {
            return <div key={i} dangerouslySetInnerHTML={{ __html: v }}></div>
        }
    })
};

function padToTwoDigits(num) {
    return num.toString().padStart(2, '0');
}

export const formatDuration = (totalMinutes) => {
    if(totalMinutes < 60) {
        return padToTwoDigits(totalMinutes) + "m";
    } else {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return `${padToTwoDigits(hours)}h${padToTwoDigits(minutes)}m`;
    }
}

export const generateRandonString = (length) => {
    //const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
       result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
 }

 export const getPastDaysDate = (days) => {
    var d = new Date();
    d.setDate(d.getDate() - days);
    d.setHours(0,0,0,0);
    return d;
 }

 export const calculateAge = (birthdate) => {
    let today = new Date();
    let year_difference = today.getFullYear() - birthdate.getFullYear();
    let one_or_zero = (today.getMonth() < birthdate.getMonth()) ||
                    (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()) ? 1 : 0;
    let age = year_difference - one_or_zero;
    return age;
 }