import React, { Component , Fragment, useContext, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';


import { useQuery, gql, useMutation } from '@apollo/client';

import Moment from 'react-moment';

import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";

import { getInitials } from '../utils';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import CustomLoadingOverlay from "../components/CustomLoadingOverlay";

import { getProfilePicture } from "../utils";

import { config, NOTIFICATIONS_META, NOTIFICATIONS_SEND_EMAIL_KEY, NOTIFICATIONS_SEND_REMINDERS_KEY } from "../config";
import { useAuth } from "../providers/authProvider";

import MobileAppCallToActionWidget from "../components/MobileAppCallToActionWidget";

const GET_NOTIFICATIONS_QUERY = gql`
    query GetNotifications($externalId: String!) {
        NotificationList(filter: { receiver: { externalId: { _eq: $externalId }} }, orderBy: { createdDate: DESC }) {
            id
            type {
                id
                code
                name
            }
            sender {
                id
                name
                picture
                employee {
                    id
                    picture
                }
            }
            title
            subtitle
            body
            customData
            readDate
            createdDate
        }
    }
`;

const MARK_NOTIFICATION_AS_READ_MUTATION = gql`
    mutation MarkNotificationAsRead($id: UUID) {
        MarkNotificationAsRead(id: $id)
    }
`;

const MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION = gql`
    mutation MarkNotificationAsRead {
        MarkNotificationAsRead
    }
`;

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_USER_SETTINGS_QUERY = gql`
    query Get($userId: UUID!) {
        UserSettingList(filter: [ 
            { user: { id: { _eq: $userId } } },
            { key: { _startsWith: "notifications." } }
        ]) {
            id
            key
            value
        }
    }
`;

const UPSERT_USER_SETTING_MUTATION = gql`
    mutation Upsert($id: UUID, $userId: UUID, $key: String!, $value: String) {
        upsert_UserSetting(userSetting: { id: $id, user: { id: $userId }, key: $key, value: $value }) {
            id
        }
    }
`;

const SettingsModal = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Definições de Notificações
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group p-1 ps-4 pe-4">
                <CustomLoadingOverlay active={props.loading}>
                    {false && <h4 className="font-xsss text-success fw-700 mb-3">Notificações por email</h4> }
                    <div className="row">
                        <div className="form-check text-left ms-4 mb-3">
                            <input type="checkbox" className="form-check-input mt-2" id="send-email-checkbox" defaultChecked={props.data?.UserSettingList?.filter(e => e.key === NOTIFICATIONS_SEND_EMAIL_KEY)[0]?.value == 'true'} onChange={(e) => { props.update(NOTIFICATIONS_SEND_EMAIL_KEY, e.target.checked.toString())}} />
                            <label className="form-check-label font-xsss text-grey-700" htmlFor="send-email-checkbox">Notificações por email</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-check text-left ms-4 mb-3">
                            <input type="checkbox" className="form-check-input mt-2" id="send-reminders-checkbox" defaultChecked={props.data?.UserSettingList?.filter(e => e.key === NOTIFICATIONS_SEND_REMINDERS_KEY)[0]?.value == 'true'} onChange={(e) => { props.update(NOTIFICATIONS_SEND_REMINDERS_KEY, e.target.checked.toString())}} />
                            <label className="form-check-label font-xsss text-grey-700" htmlFor="send-reminders-checkbox">Resumo de notificações não lidas (por email)</label>
                            <br />
                        </div>
                    </div>
                </CustomLoadingOverlay>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Fechar</a>
        </Modal.Footer>
      </Modal>
    );
}

function Notification() {

    const [currentUser, setCurrentUser] = useState(null);

    const auth = useAuth()
    
    useEffect(() => {
        auth.getUser().then(u => {
            if(auth.isAuthenticated()) {
                setCurrentUser(u?.profile)
            }
        })
    }, [auth])

    const navigate = useNavigate()

    const { loading, error, data, refetch } = useQuery(GET_NOTIFICATIONS_QUERY, { variables: { externalId: currentUser?.sub } });
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: userSettingsData, loading: userSettingsLoading, error: userSettingsError, refetch: userSettingsRefetch } = useQuery(GET_USER_SETTINGS_QUERY, {
        variables: { userId: viewerData?.Viewer?.id },
        skip: !viewerData?.Viewer?.id
    });

    const [markNotificationAsReadMutation, { markNotificationAsReadMutationData }] = useMutation(MARK_NOTIFICATION_AS_READ_MUTATION);
    const [markAllNotificationsAsReadMutation, { markAllNotificationsAsReadMutationData }] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION);
    const [upsertUserSetting, { upsertUserSettingData, upsertUserSettingLoading }] = useMutation(UPSERT_USER_SETTING_MUTATION);

    const [modalShow, setModalShow] = React.useState(false);

    const notifications = data && data.NotificationList || [];
    const unreadCount = notifications.filter(n => !n.readDate).length;

    const markNotificationAsRead = async (id) => {
        const mutationResult = await markNotificationAsReadMutation({ 
            variables: {
                id
            },
        });

        if(mutationResult.data) {
            refetch()
        }
    }

    const markAllNotificationsAsRead = async () => {
        const mutationResult = await markAllNotificationsAsReadMutation();

        if(mutationResult.data) {
            refetch()
        }
    }

    const handleClickNotification = async (id) => {
        markNotificationAsRead(id)
        // TODO redirect
    }

    const upsertNotificationSettings = async (settingKey, settingValue) => {
        const result = await upsertUserSetting({ 
            variables: {
                //...formData,
                id: userSettingsData?.UserSettingList?.filter(e => e.key === settingKey)[0]?.id,
                userId: viewerData?.Viewer?.id,
                key: settingKey,
                value: settingValue
            },
        }).catch(errors => {
            toast.error("Ocorreu um erro.")
        })

        if(result?.data?.upsert_UserSetting?.id) {
            toast.success("Definições atualizadas com sucesso.")
        }

        userSettingsRefetch()
    }

    return (

        <Fragment> 
            <Header />
            <Leftnav />
            <div className="main-content theme-dark-bg">
            
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">
                                        <a role="button" onClick={() => navigate(-1)} className="d-inline-block mt-1 ms-3 me-3"><i className="ti-arrow-left font-sm text-grey-900"></i></a>
                                        Notificações
                                        <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">{ unreadCount }</span> 
                                        <a role="button" className="ms-auto btn-round-sm bg-greylight rounded-3" onClick={() => setModalShow(true) }><i className="feather-settings font-xss text-grey-500"></i></a>
                                        <a role="button" className="ms-1 btn-round-sm bg-greylight rounded-3" onClick={() => markAllNotificationsAsRead() }><i className="feather-check font-xss text-grey-500"></i></a>
                                    </h2>

                                    <ul className="notification-box">
                                        <CustomLoadingOverlay active={loading}>
                                            <MobileAppCallToActionWidget user={viewerData?.Viewer} />
                                            { notifications.map((n) => {
                                                const notificationMeta = NOTIFICATIONS_META[n.type.code];
                                                return (
                                                    <li key={n.id}>
                                                        <Link to={notificationMeta && notificationMeta.link.format(n.customData) } className={`d-flex align-items-center p-3 rounded-3 ${!n.readDate && 'bg-lightblue theme-light-bg'}`} onClick={() => handleClickNotification(n.id)}>
                                                            <img src={getProfilePicture(n.sender) || config.APP_LOGO_URL} alt={n.sender?.name || config.APP_NAME} className="w45 me-3" />
                                                            <i className={`text-white me-2 font-xssss notification-react ${notificationMeta?.icon}`}></i>
                                                            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><strong>{n.sender?.name || config.APP_NAME }</strong> {n.title}<span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> <Moment fromNow>{ n.createdDate }</Moment></span> </h6>
                                                            { false && <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i> }
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                            }
                                        </CustomLoadingOverlay>
                                    </ul>
                                </div>
                                
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        
            <Appfooter />

            <SettingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={userSettingsData}
                loading={userSettingsLoading || upsertUserSettingLoading}
                update={upsertNotificationSettings}
            />
        </Fragment>
    );
}

export default Notification;