import React, { Fragment, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import { getProfilePicture } from "../utils";
import { resizeImage } from "../utils/resize-image";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                jobTitle
                phone
                bio
                birthdate
                picture
                company {
                    id
                    name
                }
            }
        }
    }
`;

const UPSERT_EMPLOYEE_PICTURE_MUTATION = gql`
    mutation UpsertEmployee($id: UUID!, $picture: Upload) {
        upsert_Employee(employee: { id: $id, picture: $picture }) {
            id
        }
    }
`;

const UPSERT_EMPLOYEE_BIO_MUTATION = gql`
    mutation UpsertEmployee($id: UUID!, $bio: String) {
        upsert_Employee(employee: { id: $id, bio: $bio }) {
            id
        }
    }
`;

const Account = () => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const inputFile = useRef(null) 

    const { loading, error, data, refetch } = useQuery(GET_VIEWER_QUERY);

    const [upsertEmployeePictureMutation, { upsertEmployeePictureMutationData }] = useMutation(UPSERT_EMPLOYEE_PICTURE_MUTATION);
    const [upsertEmployeeBioMutation, { upsertEmployeeBioMutationData }] = useMutation(UPSERT_EMPLOYEE_BIO_MUTATION);

    useEffect(() => {
        reset(formValues => ({
            ...formValues,
            bio: data && data.Viewer && data.Viewer.employee && data.Viewer.employee.bio,
        }))
        console.log(data)
    }, [data]);

    const updatePicture = async (file) => {
        const upsertResult = await upsertEmployeePictureMutation({ 
            variables: {
                id: data.Viewer.employee.id,
                picture: file,
            },
        });

        if(upsertResult.data) {
            toast.success('Fotografia atualizada enviado!');
            refetch();
        }
    }

    const onFileChange = async(event) => { 
        const file = event.target.files[0];
        console.log(file)

        try {
            const image = await resizeImage(file, 720);
            updatePicture(image);
        } catch (err) {
            console.log(err);
        }

        //updatePicture(image);
    };

    const updateProfile = async (formData) => {
        const upsertResult = await upsertEmployeeBioMutation({ 
            variables: {
                id: data.Viewer.employee.id,
                bio: formData.bio,
            },
        });

        if(upsertResult.data) {
            toast.success('Perfil atualizado!');
            refetch();
        }
    }

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
                { loading && <LoadingOverlayWrapper/>}
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Detalhes</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-4 text-center">
                                        <figure className="avatar ms-auto me-auto mb-0 mt-2 w100 aspect-ratio-1 pointer" onClick={() => inputFile.current.click()}><img src={data && getProfilePicture(data?.Viewer)} alt={data?.Viewer?.name} className="shadow-sm rounded-3 w-100 aspect-ratio-1" /></figure>
                                        { data && data.Viewer && (data.Viewer.picture || (data.Viewer.employee && data.Viewer.employee.picture)) && <a role="button" onClick={() => updatePicture(null)}><i className="feather-x text-dark rounded-xxl p-1 bg-grey font-xsssss"></i> Remover</a> }
                                        <h2 className="fw-700 font-sm text-grey-900 mt-3">{data?.Viewer?.name}</h2>
                                        <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">{data?.Viewer?.employee?.jobTitle}</h4>
                                        <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">{data?.Viewer?.employee?.company?.name}</h4>
                                        <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} style={{display: 'none'}} />
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit(updateProfile)}>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Nome Completo</label>
                                                <input type="text" className="form-control" readOnly value={data?.Viewer?.employee?.fullName || ''} />
                                            </div>        
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Utilizador</label>
                                                <input type="text" className="form-control" readOnly value={data?.Viewer?.username || ''} />
                                            </div>        
                                        </div>

                                    <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Email {data?.Viewer?.isEmailVerified && <i className="feather-check text-white rounded-xxl p-1 bg-success font-xsssss"></i>}</label>
                                                <input type="email" className="form-control" readOnly value={data?.Viewer?.email || ''} />
                                            </div>        
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Telefone</label>
                                                <input type="tel" className="form-control" readOnly value={data?.Viewer?.employee?.phone || ''} />
                                            </div>        
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Data de Nascimento</label>
                                                <input type="date" className="form-control" readOnly value={data?.Viewer?.employee?.birthdate || ''} />
                                            </div>        
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">Sobre mim</label>
                                            <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("bio")} ></textarea>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-0">
                                            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(updateProfile)}>Guardar</a>
                                        </div>
                                    </div>
                                    
                                    {false && <>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Country</label>
                                                <input type="text" className="form-control" />
                                            </div>        
                                        </div>

                                        <div className="col-lg-12 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Address</label>
                                                <input type="text" className="form-control" />
                                            </div>        
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Twon / City</label>
                                                <input type="text" className="form-control" />
                                            </div>        
                                        </div>

                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label className="mont-font fw-600 font-xsss mb-2">Postcode</label>
                                                <input type="text" className="form-control" />
                                            </div>        
                                        </div>

                                        <div className="col-lg-12 mb-3">
                                            <div className="card mt-3 border-0">
                                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                    <div className="form-group mb-0 w-100">
                                                        <input type="file" name="file" id="file" className="input-file" />
                                                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                        <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                        <span className="js-fileName">Drag and drop or click to replace</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mb-3">
                                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">Description</label>
                                            <textarea className="form-control mb-0 p-3 h100 bg-greylight lh-16" rows="5" placeholder="Write your message..." ></textarea>
                                        </div>

                                        <div className="col-lg-12">
                                            <a href="/account" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block">Save</a>
                                        </div>
                                    </div>
                                    </>}

                                </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>            
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default Account;