import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';

import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import { TRAINING_ACTION_STATUS } from "../../../../config";
import Moment from "react-moment";

const GET_QUERY = gql`
    query Get($id: String!) {
        TrainingEntityById(id: $id) {
            id
            name
            description
            type {
                id
                name
            }
            fiscalNumber
            phone
            email
            website
            address
            trainers {
                id
                name
                email
                phone
                certificateNumber
                isActive
            }
            trainingActions {
                id
                name
                externalId
                startDate
                endDate
                trainingHoursAmount
                cost
                status
                type {
                    id
                    name
                }
                area {
                    id
                    name
                }
                initiativeType {
                    id
                    name
                }
                scheduleType {
                    id
                    name
                }
                certificateType {
                    id
                    name
                }
                qualificationLevel {
                    id
                    name
                }
            }
        }
    }
`;

const UPSERT_TRAINER_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $isActive: Boolean!) {
        upsert_Trainer(trainer:{ id: $id, isActive: $isActive }) {
            id
        }
    }
`;

const DELETE_TRAINER_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Trainer(id: $id)
    }
`;

const DELETE_TRAINING_ACTION_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_TrainingAction(id: $id)
    }
`;

function AdminTrainingEntity() {

    let { id } = useParams();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [upsertTrainerStatusMutation, { upsertTrainerStatusMutationData }] = useMutation(UPSERT_TRAINER_STATUS_MUTATION);
    const [deleteTrainerMutation, { deleteTrainerMutationData }] = useMutation(DELETE_TRAINER_MUTATION);
    const [deleteTrainingActionMutation, { deleteTrainingActionMutationData }] = useMutation(DELETE_TRAINING_ACTION_MUTATION);

    const updateTrainerStatus = async (id, isActive) => {
        const upsertResult = await upsertTrainerStatusMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Formador ${isActive ? 'ativado' : 'desativado'}`)
        }
    }

    const deleteTrainer = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o formador?')) {
            const deleteResult = await deleteTrainerMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Formador eliminado com sucesso!')
            }
        }
    }

    const deleteTrainingAction = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a ação de formação?')) {
            const deleteResult = await deleteTrainingActionMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Formação eliminada com sucesso!')
            }
        }
    }

    const trainers = (data && data.TrainingEntityById.trainers?.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];
    const trainingActions = (data && data.TrainingEntityById.trainingActions?.slice()
    .sort(function (a, b) {
        const d1 = new Date(a.startDate).getTime()
        const d2 = new Date(b.startDate).getTime()
        return d2 - d1;
    })) || [];

    return (
        <Fragment> 
            <AdminHeader />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <CustomLoadingOverlay active={loading} />
                                <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                        <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                <div className="card-body p-0">
                                                    <h6 className="fw-600 text-grey-500 font-xssss">Entidade Formadora</h6>
                                                    <h2 className="font-xl text-grey-900 fw-600">{data?.TrainingEntityById?.name}</h2>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.name}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">NIF
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.fiscalNumber}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Tipo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.type.name}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telefone
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.phone}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.email}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Website
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.website}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Morada
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.address}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição/Notas
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.description}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <i className={`${data?.TrainingEntityById?.isActive ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i>
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Ativa? 
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.TrainingEntityById?.isActive ? 'Sim' :'Não'}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                <form className="d-block overflow-hidden">
                                                    { false && <button className="bg-dark border-0 btn-round-md float-left d-lg-block d-none"><i className="ti-microphone text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-clip text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-image text-white lh-4 font-xs"></i></button> }
                                                    <Link to={`/admin/training/trainingEntities/edit/${data?.TrainingEntityById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                </form>
                                            </div>

                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                <div className="card-body p-0">
                                                    <h3 className="font-md text-grey-900 fw-600">Formadores</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainers.length } resultados</h4>
                                                    { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                                    <Link to={`/admin/training/trainers/new?entityId=${data?.TrainingEntityById?.id}`} className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Formador</Link>
                                                </div>
                                            </div>

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-2 px-4">Nome</th>
                                                            <th className="border-0 p-2 px-4">Email</th>
                                                            <th className="border-0 p-2 px-4">Telefone</th>
                                                            <th className="border-0 p-2 px-4">N.º Certificado</th>
                                                            <th className="border-0 p-2 px-4">Ativo?</th>
                                                            <th className="border-0 p-2 px-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trainers.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column px-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainers/edit/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.phone}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.certificateNumber}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => updateTrainerStatus(value.id, !value.isActive)}><i className={`${!value.isActive ? ' ti-check text-success ' : ' ti-na text-danger '} font-xs me-2`}></i></a>
                                                                <Link to={`/admin/training/trainers/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteTrainer(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                <div className="card-body p-0">
                                                    <h3 className="font-md text-grey-900 fw-600">Ações de Formação</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainingActions.length } resultados</h4>
                                                    { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                                    <Link to={`/admin/training/trainingActions/new?entityId=${data?.TrainingEntityById?.id}`} className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova Ação de Formação</Link>
                                                </div>
                                            </div>

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-2 px-4">Ação</th>
                                                            <th className="border-0 p-2 px-4">ID Externo</th>
                                                            <th className="border-0 p-2 px-4">Data de Início</th>
                                                            <th className="border-0 p-2 px-4">Data de Fim</th>
                                                            <th className="border-0 p-2 px-4">Carga Horária</th>
                                                            <th className="border-0 p-2 px-4">Custo</th>
                                                            <th className="border-0 p-2 px-4">Estado</th>
                                                            <th className="border-0 p-2 px-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trainingActions.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column px-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainingActions/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.externalId}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.startDate ? <Moment format="DD/MM/YYYY HH:mm">{value.startDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.endDate ? <Moment format="DD/MM/YYYY HH:mm">{value.endDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.trainingHoursAmount} h</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.cost} €</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].className} font-xssss rounded-3`}><strong>{TRAINING_ACTION_STATUS.filter(s => s.id === value.status)[0].name}</strong></span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/training/trainingActions/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteTrainingAction(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );
}

export default AdminTrainingEntity;