import React, { Fragment } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getProfilePicture } from "../../utils";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                jobTitle
                phone
                birthdate
                picture
            }
        }
    }
`;

const GET_EMPLOYEE_FEEDBACK_QUERY = gql`
    query GetEmployeeFeedbacks($employeeId: UUID) {
        EmployeeFeedbackList(filter: { receiver: { id: { _eq: $employeeId } } }, orderBy: { createdDate: DESC }) {
            id
            sender {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
            message
            request {
                id
                message
                createdDate
            }
            createdDate
        }
    }
`;

function EmployeeFeedbackList() {
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { data: employeeFeedbacksData, loading: employeeFeedbacksLoading, error: employeeFeedbacksError } = useQuery(GET_EMPLOYEE_FEEDBACK_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { employeeId: viewerData && viewerData.Viewer && viewerData.Viewer.employee && viewerData.Viewer.employee.id },
        skip: !(viewerData && viewerData.Viewer && viewerData.Viewer.employee)
    });

    const feedbacks = employeeFeedbacksData && employeeFeedbacksData.EmployeeFeedbackList || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title="Feedback Contínuo" customButton={{link: `/feedback/requests/new`, icon: 'feather-plus'}} customButton2={{link: `/feedback/requests`, icon: 'feather-corner-down-right'}} color="success" />
                                
                                <CustomLoadingOverlay active={employeeFeedbacksLoading}>
                                {feedbacks.map((value , index) => (

                                <div key={index} className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 p-4 pe-4 pt-4 pb-4" id={`b-${value.id}`}>
                                    <div className="pt-0 w-100 position-relative bg-white theme-dark-bg">
                                        <div className="chat-body p-3 ">
                                            <div className="messages-content" style={{paddingBottom: '0 important!'}}>
                                                {value.request &&
                                                <div className="message-item outgoing-message">
                                                    <div className="message-user">
                                                        <figure className="avatar">
                                                            <img src={getProfilePicture(viewerData?.Viewer, 'assets/images/user.png')} alt={viewerData?.Viewer.name} />
                                                        </figure>
                                                        <div>
                                                            <h5>{viewerData?.Viewer.name}</h5>
                                                            <div className="time"><Moment format="DD/MM/YYYY HH:mm">{ value.request.createdDate }</Moment></div>
                                                        </div>
                                                    </div>
                                                    <div className="message-wrap">{value.request.message}</div>
                                                </div>}

                                                <div className="message-item">
                                                    <div className="message-user">
                                                        <figure className="avatar">
                                                            <Link to={`/employees/${value.sender.id}`}><img src={getProfilePicture(value.sender, 'assets/images/user.png')} alt={value.sender.name} /></Link>
                                                        </figure>
                                                        <div>
                                                            <h5><Link to={`/employees/${value.sender.id}`}>{value.sender.name}</Link></h5>
                                                            <div className="time"><Moment format="DD/MM/YYYY HH:mm">{ value.createdDate }</Moment></div>
                                                        </div>
                                                    </div>
                                                    <div className="message-wrap">{value.message}</div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                ))}

                                {!employeeFeedbacksLoading && feedbacks.length === 0 && <p className="text-grey-500 text-center">Ainda não existe feedback recebido</p>}
                                </CustomLoadingOverlay>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeFeedbackList;