import React from 'react';

function StatCard({loading, title, value, valueClass, bgColor, icon, iconBgClass, onClick }) {
    return (
        <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background: bgColor ||`#e2f6e9`}} onClick={onClick}>
            <div className="card-body d-flex p-0">
                <i className={`btn-round-lg d-inline-block me-3 ${iconBgClass || 'bg-success'} ${icon} font-md text-white`}></i>
                <h4 className={`${valueClass || 'text-success'} font-xl fw-700`}>{ value || '-' } <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">{title}</span></h4>
            </div>
        </div>
    );
}

export default StatCard;