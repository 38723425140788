import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";

const GET_QUERY = gql`
    query Get {
        FaqList(orderBy: { displayOrder: ASC }) {
            id
            question
            category {
                id
                name
            }
            displayOrder
            createdDate
        }
    }
`;

const UPSERT_FAQ_ORDER_MUTATION = gql`
    mutation Upsert($id: UUID, $displayOrder: Int!) {
        upsert_Faq(faq:{ id: $id, displayOrder: $displayOrder }) {
            id
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Faq(id: $id)
    }
`;

function AdminFaqList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [upsertFaqOrderMutation, { upsertFaqOrderMutationData }] = useMutation(UPSERT_FAQ_ORDER_MUTATION);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const updateOrder = async (id, displayOrder) => {
        const upsertResult = await upsertFaqOrderMutation({ 
            variables: {
                id,
                displayOrder
            },
        });

        if(upsertResult.data) {
            refetch()
        }
    }

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a FAQ?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('FAQ eliminada com sucesso!')
            }
        }
    }

    const results = (data && data.FaqList) || [];

    const displayOrderArray = results.map(o => { return o.displayOrder ?? 0; });
    const maxDisplayOrder = Math.max(...displayOrderArray)

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="FAQs"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/faqs/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                                <Link to="/admin/faqs/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova FAQ</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">Ordem</th>
                                                            <th className="border-0 p-4">Categoria</th>
                                                            <th className="border-0 p-4">Pergunta</th>
                                                            <th className="border-0 p-4">Data de Publicação</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.displayOrder}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.category?.name}</span>
                                                            </td>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/faqs/edit/${value.id}`}>{value.question}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                            <td className="text-right">
                                                                { (value?.displayOrder !== maxDisplayOrder) ? <a role="button" onClick={() => updateOrder(value.id, value.displayOrder + 1)}><i className="ti-arrow-circle-down font-xs text-grey-500 me-2"></i></a> : null }
                                                                { (value?.displayOrder > 0) ? <a role="button" onClick={() => updateOrder(value.id, value.displayOrder - 1)}><i className="ti-arrow-circle-up font-xs text-grey-500 me-2"></i></a> : null }
                                                                <a role="button" onClick={() => deleteResult(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminFaqList;