import React from 'react';

import { getAttachmentUrl } from '../utils';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';


function DocumentList({ documents, showCategory }) {

    if(!documents) return null;
    
    return (
        <div className="table-content table-responsive">
            <table className="table text-center">
                <thead className="bg-greyblue rounded-3">
                    <tr>
                        <th className="border-0 p-4">Nome</th>
                        { showCategory && <th className="border-0 p-4">Categoria</th> }
                        <th className="border-0 p-4">Data</th>
                        <th className="border-0 p-4">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {documents?.map((value, index) => (
                    <tr key={value.id}>
                        <td className="text-left" style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <h3>
                                <span className="text-grey-900 fw-600 font-xsss">
                                    { false && <Link to={`/documents/${value.id}`}>{value.name}{' '}</Link> }
                                    <a href={getAttachmentUrl(value.currentVersion?.file)}>{value.name}{' '}</a>
                                </span>
                            </h3>
                        </td>
                        { showCategory && <td className="text-center">
                            <span className="text-grey-900 font-xsss">{value.category?.name}</span>
                        </td>}
                        <td className="text-center">
                            <span className="text-grey-900 font-xsss">{value.currentVersion?.createdDate ? <Moment format="DD/MM/YYYY">{value.currentVersion?.createdDate}</Moment> : '-'}</span>
                        </td>
                        <td className="text-right">
                            <a href={getAttachmentUrl(value.currentVersion?.file)}><i className="ti-cloud-down font-xs text-grey-500"></i></a>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DocumentList;