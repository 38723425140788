import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { getImageUrl } from "../../../../utils";


const GET_QUERY = gql`
    query Get {
        JobOpportunityList(orderBy: { createdDate: DESC }) {
            id
            company {
                id
                name
            }
            department {
                id
                name
            }
            type
            title
            description
            referenceCode
            category {
                id
                name
            }
            location
            recruiter {
                id
                name
                picture
                employee {
                    id
                    picture
                }
            }
            isActive
            deadline
            createdDate
            applications {
                id
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_JobOpportunity(id: $id)
    }
`;

function AdminJobOpportunityList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a vaga?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Vaga eliminada com sucesso!')
            }
        }
    }

    const results = (data && data.JobOpportunityList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Vagas"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/recruitment/jobOpportunities/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                                <Link to="/admin/recruitment/jobOpportunities/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova Vaga</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Título</th>
                                                            <th className="border-0 p-4">Tipo</th>
                                                            <th className="border-0 p-4">Empresa</th>
                                                            <th className="border-0 p-4">Categoria</th>
                                                            <th className="border-0 p-4">Candidaturas</th>
                                                            <th className="border-0 p-4">Publicada?</th>
                                                            <th className="border-0 p-4">Prazo de Candidaturas</th>
                                                            <th className="border-0 p-4">Data de Criação</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/recruitment/jobOpportunities/${value.id}`}>{value.title}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center wide-column ps-4">
                                                                {value.type === 'EXTERNAL' ? (
                                                                    <h6 className="d-inline-block p-2 text-success alert-success fw-600 font-xssss rounded-3 me-2">Externa</h6>
                                                                ) : (
                                                                    <h6 className="d-inline-block p-2 text-warning alert-warning fw-600 font-xssss rounded-3 me-2">Interna</h6>
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.company?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.category?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.applications?.length}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.deadline && <Moment format="DD/MM/YYYY">{value.deadline}</Moment>}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/recruitment/jobOpportunities/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteResult(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminJobOpportunityList;