import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { getProfilePicture } from "../../../utils";
import { USER_MOOD_SCORE, DATE_FILTERS, GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";


const GET_QUERY = gql`
    query Get($dateFilter: [inp_DateTimeFilterCondition]) {
        UserMoodList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { createdDate: DESC }, filter: { createdDate: $dateFilter }) {
            id
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    picture
                }
            }
            score
            comments
            createdDate
        }
    }
`;

function AdminUserMoodList() {

    const [selectedDateFilter, setSelectedDateFilter] = useState({})
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { dateFilter: selectedDateFilter }});

    const setDateFilter = (id) => {
        setSelectedDateFilter(DATE_FILTERS.filter(e => e.id === id)[0].value)
    }

    const results = (data && data.UserMoodList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Bem-estar"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <div className="float-right">
                                                <Link to="/admin/moodtracker" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Voltar</Link>

                                                <select className="searchCat sort ms-1 me-1" onChange={e => setDateFilter(e.target.value)}>
                                                    <option value="">Data</option>
                                                    { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Utilizador</th>
                                                            <th className="border-0 p-4">Resposta</th>
                                                            <th className="border-0 p-4">Comentários</th>
                                                            <th className="border-0 p-4">Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getProfilePicture(value.user)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">
                                                                        { value.user.employee ? <Link to={`/admin/employees/${value.user.employee.id}`}>{value.user.employee.name}</Link> : value.user.name }
                                                                    </span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-md"><i className={`em ${USER_MOOD_SCORE.filter(e => e.score == value.score)[0]?.icon}`}></i></span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.comments}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminUserMoodList;