import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from 'react-router';
import { useAuth } from 'oidc-react';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql, useMutation } from '@apollo/client';

import { useForm } from "react-hook-form";
import Load from "../../components/Load";

import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            name
            employee {
                id
                jobTitle
                company {
                    id
                    name
                }
            }
        }
    }
`;

const GET_STATIC_PAGES_QUERY = gql`
    query GetStaticPages {
        StaticPageList(filter: {
            AND: [
              { component: { _eq: APP_FRONTEND } }
              { OR: [
                { path: { _eq: "privacy-policy" } }
                { path: { _eq: "terms-and-conditions" } }
              ]}
            ]
        }) {
            id
            component
            path
            title
            content
        }
    }
`;

const GET_USER_SETTINGS_QUERY = gql`
    query Get($userId: UUID!) {
        UserSettingList(filter: { user: { id: { _eq: $userId } } } ) {
            id
            key
            value
        }
    }
`;

const UPSERT_USER_SETTING_MUTATION = gql`
    mutation Upsert($id: UUID, $userId: UUID, $key: String!, $value: String) {
        upsert_UserSetting(userSetting: { id: $id, user: { id: $userId }, key: $key, value: $value }) {
            id
        }
    }
`;

const InfoModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.content}
        </Modal.Body>
        <Modal.Footer>
          <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Fechar</a>
        </Modal.Footer>
      </Modal>
    );
}

function Welcome() {
    
    const navigate = useNavigate();

    //const auth = useAuth();

    const [modalShow, setModalShow] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalContent, setModalContent] = React.useState("");

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { data: userSettingsData, loading: userSettingsLoading, error: userSettingsError } = useQuery(GET_USER_SETTINGS_QUERY, {
        variables: { userId: viewerData?.Viewer?.id },
        skip: !viewerData?.Viewer?.id
    });
    const { data: staticPagesData, loading: staticPagesLoading, error: staticPagesError } = useQuery(GET_STATIC_PAGES_QUERY);

    const [upsertUserSetting, { upsertUserSettingData }] = useMutation(UPSERT_USER_SETTING_MUTATION);

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    useEffect(() => {
        console.log(viewerData)

        if(viewerData?.Viewer?.id) {
            //navigate('/feed')
        }
    }, [viewerData]);

    if(viewerLoading || userSettingsLoading) {
        return <Load />
    }

    if(userSettingsData?.UserSettingList?.filter(e => e.key === 'accept-privacy-policy').length > 0
    && userSettingsData?.UserSettingList?.filter(e => e.key === 'accept-terms-and-conditions').length > 0) {
        navigate('/feed')
    }

    /*
    if(viewerError || (!viewerLoading && !viewerData?.Viewer)) {
        setTimeout(3000);
        auth.signIn();
    }
    */

    const showPrivacyPolicyModal = () => {
        const page = staticPagesData?.StaticPageList?.filter(e => e.path === 'privacy-policy')[0];
        setModalTitle(page?.title)
        setModalContent(page?.content)
        setModalShow(true)
    }

    const showTermsAndConditonsModal = () => {
        const page = staticPagesData?.StaticPageList?.filter(e => e.path === 'terms-and-conditions')[0];
        setModalTitle(page?.title)
        setModalContent(page?.content)
        setModalShow(true)
    }

    const upsert = async (formData) => {
        
        let hasErrors = false;
        await Promise.all(['accept-privacy-policy', 'accept-terms-and-conditions'].map(async k => {
            const result = await upsertUserSetting({ 
                variables: {
                    //...formData,
                    id: userSettingsData?.UserSettingList?.filter(e => e.key === k)[0]?.id,
                    userId: viewerData?.Viewer?.id,
                    key: k,
                    value: 'true'
                },
            }).catch(errors => {
                hasErrors = true
            })
    
            if(!result?.data?.upsert_UserSetting?.id) {
                hasErrors = true
            }
        }))
        
        if(hasErrors) {
            toast.error("Ocorreu um erro.")
        } else {
            navigate('/feed')
        }
    }

    return (
        <Fragment> 
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0">
                    <div className="nav-top w-100">
                        <a href="/"><i className="icon-empathize text-success display1-size me-2 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xxl logo-text mb-0">empathize </span> </a>
                        <button className="nav-menu me-0 ms-auto"></button>
                    </div>
                </div>

                <div className="main-content pt-0 bg-white ps-0 pe-0">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-contain bg-image-center bg-no-repeat" 
                            style={{backgroundImage: `url("/assets/images/coming-soon.png")`}}></div>
                            <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ps-lg--5 me-auto coming-soon-card">
                                    <div className="card-body rounded-0 text-left pt-md-5  ps-0 pe-0">
                                        { !viewerData?.Viewer?.id &&
                                        <h2 className="fw-700 text-grey-900 display3-size display4-md-size lh-2">
                                            <span className="text-success">Olá!</span><br/>
                                            Estamos a preparar tudo para ti.
                                        </h2>
                                        }
                                        { viewerData?.Viewer?.id &&
                                        <h2 className="fw-700 text-grey-900 display3-size display4-md-size lh-2">
                                            <span className="text-success">Olá!</span><br/>
                                            Temos tudo preparado para ti.
                                        </h2>
                                        }
                                        <CustomLoadingOverlay active={!viewerData?.Viewer?.id}>
                                            <form onSubmit={handleSubmit(upsert)}>
                                                <div className="form-group mt-4 p-1 p-2">
                                                    <div className="row">
                                                        <div className="form-check text-left mb-3">
                                                            <input type="checkbox" className="form-check-input mt-2" id="check-accept-privacy-policy" {...register("acceptPrivacyPolicy", { required: true })} />
                                                            <label className="form-check-label font-xsss text-grey-500">Li e aceito a <a role="button" className="text-success fw-700" onClick={showPrivacyPolicyModal}>Política de Privacidade</a>.</label>
                                                            <br />
                                                            {errors.acceptPrivacyPolicy && <span className="text-red">Este campo é obrigatório</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-check text-left mb-3">
                                                            <input type="checkbox" className="form-check-input mt-2" id="check-accept-terms-and-conditions" {...register("acceptTermsAndConditions", { required: true })} />
                                                            <label className="form-check-label font-xsss text-grey-500">Li e aceito os <a role="button" className="text-success fw-700" onClick={showTermsAndConditonsModal}>Termos e Condições</a>.</label>
                                                            <br />
                                                            {errors.acceptTermsAndConditions && <span className="text-red">Este campo é obrigatório</span>}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 p-0 text-left">
                                                            <div className="form-group mb-1"><a role="button" className="form-control text-center style2-input text-white fw-600 bg-success border-0 p-0 " onClick={handleSubmit(upsert)}>Começar</a></div>
                                                            {false && <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">Already have account <a href="/login" className="fw-700 ms-1">Login</a></h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </CustomLoadingOverlay>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>  
            </div>

            <InfoModal
                show={modalShow}
                title={modalTitle}
                content={modalContent}
                onHide={() => setModalShow(false)}
            />
        </Fragment>    
    );
}

export default Welcome;