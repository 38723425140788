import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT, RSVP_STATUS_OPTIONS, TRAINING_ACTION_STATUS } from "../../../config";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture, getImageUrl } from "../../../utils";
import AdminMediaGallery from "../../../components/AdminMediaGallery";

const GET_LISTS_QUERY = gql`
    query GetLists {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
            id
            name
            company {
                id
                name
            }
            jobTitle
            user {
                id
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        EventById(id: $id) {
            id
            title
            description
            startDate
            endDate
            location
            createdDate
            category {
                id
                name
            }
            invitations {
                id
                inviter {
                    id
                    name
                    picture
                }
                guest {
                    id
                    name
                    picture
                }
                status
                comments
            }
            mediaGallery {
                id
                name
                items {
                    id
                    name
                    file
                    displayOrder
                }
            }
        }
    }
`;

const UPSERT_INVITATION_MUTATION = gql`
    mutation Upsert($eventId: UUID, $guest: inp_User) {
        upsert_EventInvitation(eventInvitation: { event: { id: $eventId }, guest: $guest }) {
            id
        }
    }
`;

const DELETE_INVITATION_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_EventInvitation(id: $id)
    }
`;

const UPSERT_ASSOCIATE_MEDIA_GALLERY_MUTATION = gql`
    mutation Upsert($id: UUID!, $galleryId: UUID!) {
        upsert_Event(event: { id: $id, mediaGallery: { id: $galleryId } }) {
            id
        }
    }
`;

function AdminEvent() {

    let { id } = useParams();

    const [isInvitationFormOpen, setIsInvitationFormOpen] = useState(false);

    
    const { register: registerInvitation, handleSubmit: handleSubmitInvitation, formState: { errors: errorsInvitation }, reset: resetInvitation } = useForm();
    

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);

    const [upsertInvitationMutation] = useMutation(UPSERT_INVITATION_MUTATION);
    const [deleteInvitationMutation] = useMutation(DELETE_INVITATION_MUTATION);
    const [upsertAssociateMediaGalleryMutation] = useMutation(UPSERT_ASSOCIATE_MEDIA_GALLERY_MUTATION);

    const upsertInvitation = async (formData) => {
        const upsertResult = await upsertInvitationMutation({ 
            variables: {
                eventId: id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            toast.success('Inscrição adicionada!');
            setIsInvitationFormOpen(false)
            resetInvitation();
            await refetch();
        }
    }

    const deleteInvitation = async (id) => {
        if (window.confirm('Tem a certeza que pretende remover o convite/inscrição?')) {
            const deleteResult = await deleteInvitationMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Convite removido com sucesso!')
                await refetch();
            }
        }
    }

    const associateMediaGallery = async(galleryId) => {
        return upsertAssociateMediaGalleryMutation({ 
            variables: {
                id: data.EventById?.id,
                galleryId
            },
        });
    }

    const invitations = (data && data.EventById?.invitations?.slice().sort((a, b) => a.guest.name && b.guest.name && a.guest.name.localeCompare(b.guest.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Evento</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.EventById?.title}</h2>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Título
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EventById?.title}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EventById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500 post-preview-container" dangerouslySetInnerHTML={{ __html: data?.EventById?.description }}></span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Início
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EventById?.startDate && <Moment format="DD/MM/YYYY HH:mm">{data?.EventById?.startDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Fim
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EventById?.endDate && <Moment format="DD/MM/YYYY HH:mm">{data?.EventById?.endDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Localização
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EventById?.location}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <Link to={`/admin/events/edit/${data?.EventById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                    </form>
                                                </div>

                                                <AdminMediaGallery mediaGallery={data?.EventById?.mediaGallery} associateMediaGallery={associateMediaGallery} refetch={refetch} />
                                                
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h3 className="font-md text-grey-900 fw-600">Inscrições/Interessados</h3>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ invitations.length } resultados</h4>
                                                        <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setIsInvitationFormOpen(true)}>Adicionar</a>
                                                    </div>
                                                </div>

                                                <div className="table-content table-responsive">
                                                    <table className="table text-center">
                                                        <thead className="bg-greyblue rounded-3">
                                                            <tr>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                                <th className="border-0 p-2 px-4">Nome</th>
                                                                <th className="border-0 p-2 px-4">Estado</th>
                                                                <th className="border-0 p-2 px-4">Comentários</th>
                                                                <th className="border-0 p-2 px-4">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { isInvitationFormOpen &&
                                                            <tr className="bg-lightgreen">
                                                                <td className="text-center px-4"></td>
                                                                <td>
                                                                    <select type="text" className="form-control mb-0 pt-0 pv-0 lh-24" style={{height: '28px'}} defaultValue='' {...registerInvitation("guest.id", { required: true })}>
                                                                        <option value=''>Selecionar...</option>
                                                                        {
                                                                            listsData && listsData.EmployeeList && listsData.EmployeeList
                                                                            .slice()
                                                                            .filter(e => e.user)
                                                                            .sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                                                            .map((e) => {
                                                                                return (
                                                                                    <option key={e.id} value={e.user.id}>{e.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {errorsInvitation.guest?.id && <span className="text-red">Este campo é obrigatório</span>}
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="text-center">
                                                                    <a role="button" className="p-2 ps-3 pe-3 bg-success border-0 text-white text-center font-xssss fw-600 rounded-xl" onClick={handleSubmitInvitation(upsertInvitation)}>Adicionar</a>
                                                                    <a role="button" className="ms-2 p-2 ps-3 pe-3 bg-grey border-0 text-grey-700 text-center font-xssss fw-600 rounded-xl" onClick={() => setIsInvitationFormOpen(false)}>Cancelar</a>
                                                                </td>
                                                            </tr>
                                                            }

                                                            {invitations.map((value, index) => (
                                                            <tr key={value.id}>
                                                                <td className="text-center ps-0">
                                                                    <img src={getProfilePicture(value.guest)} alt={value.guest.name} className="w35 rounded-circle aspect-ratio-1" />
                                                                </td>
                                                                <td className="text-left wide-column px-4">
                                                                    <h3>
                                                                        <span className="text-grey-900 fw-600 font-xsss">{value.guest.name}</span>
                                                                    </h3>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.status ? RSVP_STATUS_OPTIONS.filter(e => e.id === value.status)[0].description : "Pendente"}</span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="text-grey-900 font-xsss">{value.comments}</span>
                                                                </td>
                                                                <td className="text-right">
                                                                    <a role="button" onClick={() => deleteInvitation(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminEvent;