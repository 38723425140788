import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Moment from "react-moment";
import moment from 'moment';
import { toast } from "react-toastify";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import Pagetitle from "../../components/Pagetitle";
import { getImageUrl, getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;

const GET_EVENTS_QUERY = gql`
    query GetEvents($currentDate: OffsetDateTime) {
        EventList(filter: { startDate: { _gte: $currentDate }}, orderBy: { startDate: ASC }) {
            id
            title
            description
            startDate
            endDate
            location
            thumbnail
            createdDate
            invitations {
                id
                guest {
                    id
                    name
                    picture
                    employee {
                        id
                        picture
                    }
                }
                status
            }
        }
    }
`;

const GET_PAST_EVENTS_QUERY = gql`
    query GetEvents($currentDate: OffsetDateTime) {
        EventList(filter: { startDate: { _lt: $currentDate }}, orderBy: { startDate: DESC }) {
            id
            title
            description
            startDate
            endDate
            location
            thumbnail
            createdDate
            invitations {
                id
                guest {
                    id
                    name
                    picture
                    employee {
                        id
                        picture
                    }
                }
                status
            }
        }
    }
`;

const UPSERT_EVENT_INVITATION_MUTATION = gql`
    mutation UpsertEventInvitation($eventId: UUID!, $userId: UUID!) {
        upsert_EventInvitation(eventInvitation: { event: { id: $eventId }, inviter : { id: $userId }, guest: { id: $userId }, status: YES }) {
            id
        }
    }
`;

const DELETE_EVENT_INVITATION_MUTATION = gql`
    mutation DeleteEventInvitation($id: String!) {
        delete_EventInvitation(id: $id)
    }
`;


function EventList() {
    
    const [upsertEventInvitationMutation, { upsertEventInvitationMutationData }] = useMutation(UPSERT_EVENT_INVITATION_MUTATION);
    const [deleteEventInvitationMutation, { deleteEventInvitationMutationData }] = useMutation(DELETE_EVENT_INVITATION_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data, refetch } = useQuery(GET_EVENTS_QUERY, { variables: { currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` }});
    const { loading: pastEventsLoading, error: pastEventsError, data: pastEventsData } = useQuery(GET_PAST_EVENTS_QUERY, { variables: { currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` }});
    
    const createEventInvitation = async (id) => {
        const upsertResult = await upsertEventInvitationMutation({ 
            variables: {
                userId: viewerData.Viewer.id,
                eventId: id,
            },
        });

        if(upsertResult.data) {
            refetch({ currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` })
        }
    }

    const deleteEventInvitation = async (id) => {
        const deleteResult = await deleteEventInvitationMutation({ 
            variables: {
                id: id,
            },
        });

        if(deleteResult.data) {
            refetch({ currentDate: `${moment().format('YYYY-MM-DD')}T00:00:00+00:00` })
        }
    }

    const events = (data && data.EventList) || [];
    const pastEvents = (pastEventsData && pastEventsData.EventList) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                <Pagetitle title="Próximos Eventos" customButton={{link: '/admin/events/new', icon: 'feather-plus', requiredRoles: ['admin', 'post-contributor']}} color="success"/>
                            </div>
                            
                            <CustomLoadingOverlay active={loading} />

                            { !loading && events.length === 0 && <p className="text-center fw-600">Não existem eventos agendados</p> }

                            {events.map((value , index) => (
                            <div key={index} className="col-lg-4 col-md-6 pe-2 ps-2">
                                <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                    <div className="card-image w-100">
                                        <img src={getImageUrl(value.thumbnail, '/assets/images/bg/event.jpg')} alt="event" className="w-100 rounded-3 aspect-ratio-385-300" style={{objectFit: 'cover'}} />
                                    </div>
                                    <div className="card-body d-flex ps-0 pe-0 pb-0">
                                        <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500"><Moment format="MMM">{value.startDate}</Moment></span><Moment format="DD">{value.startDate}</Moment></h4></div>
                                        <h2 className="fw-700 lh-3 font-xss"><Link to={`/events/${value.id}`} className="text-dark">{value.title}</Link>
                                            <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-time me-1"></i><Moment format="HH:mm">{value.startDate}</Moment> </span>
                                            <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-location-pin me-1"></i>{value.location || '-'} </span>
                                        </h2>
                                    </div>
                                    <div className="card-body p-0">
                                        <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">
                                            { value.invitations.slice(0, 4).map(invitation => (
                                                <li><img src={getProfilePicture(invitation.guest)} alt={invitation.guest.name} className="w30 d-inline-block" /></li>
                                            ))}
                                            { value.invitations.length > 4 && <li className="last-member"><a role="button" className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center">+{value.invitations.length - 4}</a></li>}
                                        </ul>
                                        { value.invitations.filter(i => i.guest.id === viewerData.Viewer.id).length == 0 && <a role="button" className='font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-greylight d-inline-block text-grey-500 me-1' onClick={() => createEventInvitation(value.id)}><i className="feather-star me-2"></i> TENHO INTERESSE</a> }
                                        { value.invitations.filter(i => i.guest.id === viewerData.Viewer.id).length > 0 && <a role="button" className='font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-success d-inline-block text-white me-1' onClick={() => deleteEventInvitation(value.invitations.filter(i => i.guest.id === viewerData.Viewer.id)[0].id)}><i className="feather-thumbs-up me-2"></i>ESTOU INTERESSADO/A</a> }
                                    </div>
                                </div>
                            </div>
                            ))}

                        </div>

                        <div className="row mt-4">
                            <div className="col-xl-12">
                                <h2 className="fw-700 font-sm text-grey-700 mt-3">Eventos Anteriores</h2>
                            </div>

                            <CustomLoadingOverlay active={pastEventsLoading} />
                            
                            { !pastEventsLoading && pastEvents.length === 0 && <p className="text-center fw-600">Sem eventos</p> }

                            {pastEvents.map((value , index) => (
                            <div key={index} className="col-lg-4 col-md-6 pe-2 ps-2">
                                <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                                    <div className="card-image w-100">
                                        <img src={getImageUrl(value.thumbnail, '/assets/images/bg/event.jpg')} alt="event" className="w-100 rounded-3 aspect-ratio-385-300" style={{objectFit: 'cover'}} />
                                    </div>
                                    <div className="card-body d-flex ps-0 pe-0 pb-0">
                                        <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500"><Moment format="MMM">{value.startDate}</Moment></span><Moment format="DD">{value.startDate}</Moment></h4></div>
                                        <h2 className="fw-700 lh-3 font-xss"><Link to={`/events/${value.id}`} className="text-dark">{value.title}</Link>
                                            <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500"> <i className="ti-time me-1"></i><Moment format="HH:mm">{value.startDate}</Moment> </span>
                                            <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500 text-max-1-line"> <i className="ti-location-pin me-1"></i>{value.location || '-'} </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );

}

export default EventList;