import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { getProfilePicture } from "../../../utils";
import { DATE_FILTERS } from "../../../config";

const GET_QUERY = gql`
    query Get($dateFilter: [inp_DateTimeFilterCondition]) {
        UserSuggestionList(orderBy: { createdDate: DESC }, filter: { createdDate: $dateFilter }) {
            id
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    picture
                }
            }
            subject
            description
            createdDate
        }
    }
`;

function AdminUserSuggestionList() {

    const [selectedDateFilter, setSelectedDateFilter] = useState({})
    
    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { dateFilter: selectedDateFilter }});

    const setDateFilter = (id) => {
        setSelectedDateFilter(DATE_FILTERS.filter(e => e.id === id)[0].value)
    }

    const results = (data && data.UserSuggestionList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Ideias e Sugestões"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <div className="float-right">
                                                <select className="searchCat sort ms-1 me-1" onChange={e => setDateFilter(e.target.value)}>
                                                    <option value="">Data</option>
                                                    { DATE_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Utilizador</th>
                                                            <th className="border-0 p-4">Assunto</th>
                                                            <th className="border-0 p-4">Ideia/Sugestão</th>
                                                            <th className="border-0 p-4">Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getProfilePicture(value.user)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">
                                                                        { value.user.employee ? <Link to={`/admin/employees/${value.user?.employee?.id}`}>{value.user?.employee?.name}</Link> : value.user.name }
                                                                    </span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.subject}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.description}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminUserSuggestionList;