import React from 'react';
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import Load from './Load';
import { getImageUrl } from '../utils';

const GET_LATEST_NEWS_QUERY = gql`
    query GetLatestNewsArticles {
        NewsArticleList(orderBy: { createdDate: DESC }, limit: 6) {
            id
            title
            headline
            content
            thumbnail
            createdDate
            category {
                id
                name
            }
        }
    }
`;

function NewsSlider() {
    
    const navigate = useNavigate();

    const sliderSettings = {
        arrows: false,
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: false,
        variableWidth: true,
    };

    const { loading, error, data } = useQuery(GET_LATEST_NEWS_QUERY);
    if (loading) return <Load/>;

    if(!data || !data.NewsArticleList || data.NewsArticleList.length === 0) return null;
    

    return (
        <div>
            <h4 className="fw-700 font-xsss text-grey-900 mt-4 mb-3">Últimas Notícias <i className="em em-newspaper ms-1"></i></h4>
            <Slider {...sliderSettings}>
                {data.NewsArticleList.map((value , index) => (
                <div key={index} className="card w200 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3 pointer" onClick={() => navigate(`/news/${value.id}`)}>
                    <div className="card-body position-relative h100 bg-image-cover bg-image-center w-100" style={{backgroundImage: `url("${getImageUrl(value.thumbnail, 'assets/images/story.png')}")`}}></div>
                    <div className="card-body d-block w-100 ps-3 pe-3 pb-4">
                        <div className="clearfix"></div>
                        <h4 className="fw-700 font-xsss mt-2 mb-2 text-max-3-lines">{value.title}</h4>
                        <p className="fw-500 font-xssss text-grey-500 lh-4 mt-0 mb-2"><Moment fromNow>{value.createdDate}</Moment></p>
                        <div className="clearfix mb-2"></div>
                    </div>
                </div>
                ))}
            </Slider>
        </div>
    );

}

export default NewsSlider;