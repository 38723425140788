import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { config } from '../config'

const IDENTITY_CONFIG = {
  authority: config.openid.AUTHORITY_URI, //(string): The URL of the OIDC provider.
  client_id: config.openid.CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: config.openid.REDIRECT_URI, //The URI of your client application to receive a response from the OIDC provider.
  //login: config.openid.AUTHORITY_URI + "/login",
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: config.openid.SILENT_REDIRECT_URI, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: config.openid.POST_LOGOUT_REDIRECT_URI, // (string): The OIDC post-logout redirect URI.
  //audience: "https://example.com", //is there a way to specific the audience when making the jwt
  //responseType: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_type: "code",
  response_mode: "query",
  //grantType: "password",
  scope: "openid email", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "id_token token",
};

/*
const METADATA_OIDC = {
  issuer: config.openid.AUTHORITY_URI,
  jwks_uri: config.openid.AUTHORITY_URI + "/protocol/openid-connect/certs",
  authorization_endpoint: config.openid.AUTHORITY_URI + "/protocol/openid-connect/auth",
  token_endpoint: config.openid.AUTHORITY_URI + "/protocol/openid-connect/token",
  userinfo_endpoint: config.openid.AUTHORITY_URI + "/protocol/openid-connect/userinfo",
  end_session_endpoint: config.openid.AUTHORITY_URI + "/protocol/openid-connect/logout",
  check_session_iframe: config.openid.AUTHORITY_URI + "/protocol/openid-connect/login-status-iframe.html",
  revocation_endpoint: config.openid.AUTHORITY_URI + "https://id.qa.empathize.pt/realms/dizme/protocol/openid-connect/revoke",
  introspection_endpoint: config.openid.AUTHORITY_URI + "/protocol/openid-connect/token/introspect"
};
*/

export default class AuthService {

    UserManager;

    //context;

    constructor(props, context) {

        //this.context = context;

        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            /*metadata: {
                ...METADATA_OIDC
            }*/
            metadataUrl: config.openid.AUTHORITY_URI + '/.well-known/openid-configuration'
        });
        // Logger
        Log.logger = console;
        Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1 || window.location.href.indexOf("signin-token") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }
    
    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then((user) => {
            // do nothing
        }).catch(function (e) {
            console.error(e);
        });
    };

    /*
    signinCallback = () => {
        this.UserManager.signinCallback()
        /*this.UserManager.signinRedirectCallback().then(() => {
            "";
        });
    };
*/


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/welcome");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`))

        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        console.log('signinSilent')
        this.UserManager.signinSilent()
            .then((user) => {
                console.log("signed in", user);

                /*
                this.context.dispatch({
                    type: 'update',
                    userData: {
                      id: user.profile.sub,
                      jti: user.profile.jti,
                      roles: user.profile.roles,
                      auth: true,
                      profile: user.profile
                    }
                })
                */
            })
            .catch((err) => {
                /*
                this.context.dispatch({
                    type: 'reset'
                })
                */

                console.log(err);
                window.location = '/logout'
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(IDENTITY_CONFIG.post_logout_redirect_uri);
        });
        this.UserManager.clearStaleState();
    };
}