import React, { Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthConsumer } from "../../providers/authProvider";
import CustomLoadingOverlay from "../CustomLoadingOverlay";
import { config } from "../../config";

export const SigninToken = () => (
    <AuthConsumer>
        {({ signinRedirectCallback, isAuthenticated, signinSilent, getUser }) => {
            
            //console.log(sessionStorage.getItem(`oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`))
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const token = params.get('token');
            //console.log(token)
            
            sessionStorage.setItem(`oidc.user:${config.openid.AUTHORITY_URI}:${config.openid.CLIENT_ID}`, token)

            signinSilent()
            
            //return <span>loading</span>;
            return (
                <Fragment>
                    <div className="main-content pt-0 bg-white ps-0 pe-0">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                                    <div className="card border-0 text-center d-block p-0" style={{width: "100%"}}>
                                        <CustomLoadingOverlay active={true} />
                                        <p className="text-grey-500 font-xsss mt-5">A carregar...</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>  
                </Fragment>
            );
        }}
    </AuthConsumer>
);