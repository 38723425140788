import React from 'react';

function FeedLinkShortcuts({ links }) {

    if(!links || links.length === 0) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4 pb-3">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Links Úteis</h4>
            </div>
                <div className="row">
                    {links.slice().sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((value , index) => (
                    <div className="col-md-3 col-xs-6" key={index}>
                        <div className={`card-body bg-transparent-card d-flex p-2 ms-3 rounded-3 mb-1`}>
                            <a href={value.url} rel="noopener" target={value.openInNewWindow ? '_blank' : '_self'}><i className={`btn-round-sm font-xs text-white ${value.icon || 'feather-globe'} me-3 bg-success`}></i></a>
                            <a href={value.url} rel="noopener" target={value.openInNewWindow ? '_blank' : '_self'}><h4 className="fw-700 text-grey-900 font-xssss mt-1">{value.title}</h4></a>
                        </div>
                    </div>
                    ))}
                </div>
        </div>
    );
}

export default FeedLinkShortcuts;