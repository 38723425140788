import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import { getProfilePicture } from "../../../utils";
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../../config";

const GET_USERS_QUERY = gql`
    query GetUsers {
        UserList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}) {
            id
            username
            name
            email
            isEmailVerified
            isActive
            picture
            employee {
                id
            }
            createdDate
        }
    }
`;

function AdminUserList() {

    const { loading, error, data, refetch } = useQuery(GET_USERS_QUERY);

    const users = (data?.UserList?.slice().sort((a, b) => a.name?.localeCompare(b.name))) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Utilizadores"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ users.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            { false && <Link to="/admin/employees/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Colaborador</Link> }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4">Nome de Utilizador</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Email Verificado</th>
                                                            <th className="border-0 p-4">Colaborador associado?</th>
                                                            <th className="border-0 p-4">Data de Criação</th>
                                                            <th className="border-0 p-4">Ativo?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getProfilePicture(value)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">
                                                                        <Link to={`/admin/users/${value.id}`}>{value.name}</Link>{' '}
                                                                    </span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.username}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isEmailVerified ? ' ti-check-box text-success ' : ' ti-close text-danger '} font-xs`}></i></td>
                                                            <td className="text-center"><i className={`${value.employee?.id ? ' feather-user-check text-success ' : ' feather-user-x  '} font-xs`}></i></td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.createdDate ? <Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment> : '-'}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminUserList;