import React, { Fragment, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from "../../components/Appfooter";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_EMPLOYEE_REFERRAL_MUTATION = gql`
    mutation UpsertEmployeeReferral($userId: UUID!, $name: String!, $email: String!, $phone: String!, $linkedinUrl: String, $cv: Upload) {
        upsert_EmployeeReferral(employeeReferral: { referrer: { id: $userId }, name: $name, email: $email, phone: $phone, linkedinUrl: $linkedinUrl, cv: $cv }) {
            id
        }
    }
`;

function ReferFriend() {

    const navigate = useNavigate();

    const [selectedFile, setSelectedFile] = useState(null);

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertEmployeeReferralMutation, { upsertEmployeeReferralMutationData }] = useMutation(UPSERT_EMPLOYEE_REFERRAL_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);

    const referFriend = async (formData) => {
        const upsertResult = await upsertEmployeeReferralMutation({ 
            variables: {
                userId: viewerData.Viewer.id,
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                linkedinUrl: formData.linkedinUrl,
                cv: selectedFile,
            },
        });

        if(upsertResult.data) {
            toast.success('Enviado com sucesso!');
            navigate(`/referrals`)
        }
    }

    const onFileChange = event => { 
        setSelectedFile(event.target.files[0])
    };

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Refer a Friend</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(referFriend)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("email", { required: true })} />
                                                    {errors.email && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone", { required: true })} />
                                                    {errors.phone && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">URL do Perfil LinkedIn</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("linkedinUrl")} />
                                                    {errors.linkedinUrl && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar um ficheiro' }</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                    Não nos responsabilizamos por contactar candidatos/as que não tenham dado a sua autorização para a partilha de dados.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(referFriend)}>Enviar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default ReferFriend;