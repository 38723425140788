import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';
import { getAttachmentUrl } from '../utils';

const GET_DOCUMENTS_QUERY = gql`
    query GetDocuments {
        DocumentList(orderBy: { createdDate: DESC }, limit: 4) {
            id
          name
          category {
              id
              name
          }
          currentVersion {
            id
            file
          }
          createdDate
        }
    }
`;

function RecentDocumentsWidget() {
    
    const { loading, error, data } = useQuery(GET_DOCUMENTS_QUERY);
    if (loading) return <LoadingOverlayWrapper/>;

    const documents = data?.DocumentList.slice().sort(function (a, b) {
        const d1 = new Date(a.createdDate).getTime()
        const d2 = new Date(b.createdDate).getTime()
        return d2 - d1;
    }) || [];

    if(documents?.length == 0) return null;

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Últimos Documentos <i className="em em-file_folder ms-1"></i></h4>
                <Link to="/documents" className="fw-600 ms-auto font-xssss text-success">Ver todos</Link>
            </div>
            {documents.map((value , index) => (

            <div key={index} className="card-body bg-transparent-card d-flex p-2 ps-3 pe-3 bg-greylight ms-3 me-3 rounded-3 mb-3">
                <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                    {value.name?.length > 20 ? value.name.substring(0, 20) + "..." : value.name} 
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.category?.name}</span>
                    {value.createdDate && <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"><Moment fromNow>{value.createdDate}</Moment></span> }
                </h4>
                <a href={getAttachmentUrl(value.currentVersion?.file)} className="btn-round-sm bg-white ms-auto mt-auto mb-auto"><span className="feather-file-text font-xss text-grey-900"></span></a>
                
            </div>

            ))}

            
        </div>
    );
    
}

export default RecentDocumentsWidget;