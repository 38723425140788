import React, { Fragment } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql, useMutation } from '@apollo/client';

import Pagetitle from "../../components/Pagetitle";
import DocumentList from "../../components/DocumentList";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_QUERY = gql`
    query Get($q: String!) {
        DocumentList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: {
            OR: [
                { reference: { _contains: $q }},
                { name: { _contains: $q }},
                { description: { _contains: $q }},
                { category: { name: { _contains: $q }}},
            ]
        }) {
            id
            name
            reference
            description
            category {
                id
                name
            }
            currentVersion {
                id
                file
                createdDate
            }
        }
    }
`;

function DocumentCategorySingle() {
    
    let { id } = useParams();

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_QUERY, { variables: { q: searchParams.get("q") }});
    
    const documents = (data && data.DocumentList) || [];
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <CustomLoadingOverlay active={loading} />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">    
                                <Pagetitle title={`Documentos > Pesquisa`} color="success"/>

                                <div className="row">
                                    <div className="search-form-2 mb-3">
                                        <input type="text" className="form-control mb-0 pt-0 pv-0 theme-dark-bg border-0" placeholder={"Pesquisar ficheiros..."} onKeyDown={(e) => { if(e.key === 'Enter') { navigate(`/documents/search?q=${e.target.value}`); }}} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <p>Resultados para <span className="fw-700 text-grey-900">{searchParams.get("q")}</span></p>
                            <div className="col-xl-12">
                                <DocumentList showCategory={true} documents={documents} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Appfooter /> 

        </Fragment>
    );
}

export default DocumentCategorySingle;