import React, { Component , Fragment, useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import { config } from "../config";
import { useAuth } from "../providers/authProvider";

const GET_QUERY = gql`
    query Get {
        ViewerFederatedIdentities
    }
`;
const Settings = () => {

    const auth = useAuth()

    const { data, loading, error } = useQuery(GET_QUERY);

    //const isSsoEnabled = tenantSettings?.filter(e => e.key === 'tenant.isSsoEnabled')[0]?.value === "true";
    const isSsoEnabled = !(data?.ViewerFederatedIdentities?.length == 0)

    const logout = async (e) => {
        e.preventDefault();
        //await auth.signOutRedirect({ id_token_hint: auth.userData?.id_token })
        auth.logout();
        console.log('logout')
        console.log(auth)
    };

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Definições</h4>
                                            <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">Geral</div>
                                            <ul className="list-inline mb-4">
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/account" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-primary-gradiant text-white feather-user font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Minha Conta</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                { config.FEATURES_PREVIEW_ENABLED && <li className="list-inline-item d-block border-bottom me-0"><Link to="/contactinformation" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-map-pin font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Saved Address</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> }
                                                { config.FEATURES_PREVIEW_ENABLED && <li className="list-inline-item d-block me-0"><Link to="/socialaccount" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-twitter font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Redes Sociais</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> }
                                            </ul>

                                            <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Conta</div>
                                            <ul className="list-inline mb-4">
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/wallet" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Wallet</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                { config.FEATURES_PREVIEW_ENABLED && <li className="list-inline-item d-block border-bottom me-0"><Link to="/payment" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">My Cards</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> }
                                                { !isSsoEnabled && <li className="list-inline-item d-block  me-0"><Link to="/password" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Password</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> }
                                                
                                            </ul>

                                            <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">Outros</div>
                                            <ul className="list-inline">
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/notifications" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Notificações</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/faqs" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Ajuda</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                <li className="list-inline-item d-block border-bottom me-0"><Link to="/pages/privacy-policy" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant  text-white feather-shield font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Política de Privacidade</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                <li className="list-inline-item d-block me-0"><a href="#" onClick={logout} className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Terminar Sessão</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
};

export default Settings;