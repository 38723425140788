import React, { Component , Fragment, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import OrgChart from '@unicef/react-org-chart'

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";

import { getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_VIEWER_QUERY = gql`
  query GetViewer {
    Viewer {
      id
      name
      employee {
        id
        manager {
          id
        }
      }
    }
  }
`;

const GET_EMPLOYEES_QUERY = gql`
  query GetEmployees {
    EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
      id
      name
      birthdate
      email
      jobTitle
      picture
      user {
        picture
      }
      manager {
        id
      }
    }
  }
`;

const downloadImageId = "download-image";
const downloadPdfId = "download-pdf";

function EmployeeOrgChart() {
    
    const navigate = useNavigate();

    const [tree, setTree] = useState(null);
    const [downloadingChart, setDownloadingChart] = useState(false);
    const [orgChartConfig, setOrgChartConfig] = useState({});
    const stateRef = useRef();
    
    stateRef.current = orgChartConfig;

    const getEmployeeNode = (id, includeChildren) => {
      const emp = data && data.EmployeeList.filter(e => e.id === id) && data.EmployeeList.filter(e => e.id === id)[0];
      if(!emp) {
        return null;
      }

      console.log(emp)

      let children = [];
      if(includeChildren) {
        data && data.EmployeeList.filter(e => e.manager && e.manager.id === id).map(e => {
          children.push(getEmployeeNode(e.id, false))
        })
      }

      const empNode = {
        id: emp.id,
        person: {
          id: emp.id,
          avatar: getProfilePicture(emp),
          department: "",
          name: emp.name,
          title: emp.jobTitle,
          //totalReports: data.EmployeeList.filter(e => e.manager && e.manager.id === emp.id).length
          totalReports: 0,
          link: `/employees/${emp.id}`
        },
        hasChild: data.EmployeeList.filter(e => e.manager && e.manager.id === emp.id).length > 0,
        hasParent: emp.manager && emp.manager.id !== null,
        children: children
      }

      console.log(empNode)

      return empNode;
    }

    const getChild = id => {
      let childTree = [];
      data.EmployeeList.filter(e => e.manager && e.manager.id === id).map(e => {
        const empNode = getEmployeeNode(e.id, false);
        console.log(empNode)
        if(empNode) {
          childTree.push(empNode)
        }
      })
      console.log(childTree)
      return childTree;
    };
  
    const getParent = d => {
      const currentEmp = data.EmployeeList.filter(e => e.id === d.id)[0]
      return getEmployeeNode(currentEmp.manager.id, true)
    };
  
    const handleDownload = () => {
      setDownloadingChart(false);
    };

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data } = useQuery(GET_EMPLOYEES_QUERY);
    //if (loading || viewerLoading) return <LoadingOverlayWrapper />;

    const employees = (data && data.EmployeeList) || [];
    
    
    const [searchParams, setSearchParams] = useSearchParams();
    const employeeId = searchParams.get('employeeId');
    const showDirectReports = searchParams.get('showDirectReports') === 'true';

    let initialEmployeeTree = null;

    if(employeeId) {
      initialEmployeeTree = getEmployeeNode(employeeId, showDirectReports);
    } else {
      const currentEmployee = employees.length > 0 && viewerData && employees.filter(e => e.id === viewerData?.Viewer?.employee?.id) && employees.filter(e => e.id === viewerData?.Viewer?.employee?.id)[0]
      initialEmployeeTree = currentEmployee && getEmployeeNode(currentEmployee.id, false);
    }

    if(!tree && initialEmployeeTree) {
      setTree(initialEmployeeTree)
    }

    //console.log("tree>>", tree);

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" style={{maxWidth: '100%'}}>
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title="Organograma" customButton={{link: '/employees', icon: 'feather-grid'}} color="success"/>
                                
                                <CustomLoadingOverlay active={loading}/>
                                
                                <div className="row ps-2 pe-1" style={{height: '800px'}}>
                                  {tree && 
                                  <OrgChart
                                    tree={tree}
                                    downloadImageId={downloadImageId}
                                    downloadPdfId={downloadPdfId}
                                    onConfigChange={config => {
                                      setOrgChartConfig(config);
                                    }}
                                    loadConfig={d => {
                                      console.log(stateRef.current)
                                      return stateRef.current;
                                    }}
                                    downlowdedOrgChart={d => {
                                      handleDownload();
                                    }}
                                    loadImage={d => {
                                      return Promise.resolve(() => d.person.avatar);
                                    }}
                                    loadParent={d => {
                                      console.log('loadParent')
                                      const parentData = getParent(d);
                                      return parentData;
                                    }}
                                    loadChildren={d => {
                                      console.log('loadChildren')
                                      const childrenData = getChild(d.id);
                                      return childrenData;
                                    }}
                                  />
                                  }

                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeOrgChart;