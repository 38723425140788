import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { getImageUrl } from "../../../utils";

const GET_QUERY = gql`
    query Get {
        BadgeList(orderBy: {name: ASC}) {
            id
            name
            thumbnail
            isActive
            points
            maxPerDay
            maxPerUser
            assignments {
                id
            }
        }
    }
`;

const UPSERT_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $isActive: Boolean!) {
        upsert_Badge(badge:{ id: $id, isActive: $isActive }) {
            id
        }
    }
`;


const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_Badge(id: $id)
    }
`;

function AdminBadgeList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [upsertStatusMutation, { upsertStatusMutationData }] = useMutation(UPSERT_STATUS_MUTATION);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const updateStatus = async (id, isActive) => {
        const upsertResult = await upsertStatusMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Aplauso ${isActive ? 'ativado' : 'desativado'}`)
        }
    }

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o aplauso?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Aplauso eliminado com sucesso!')
            }
        }
    }

    const results = (data && data.BadgeList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Aplausos"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <div className="float-right">
                                                <Link to="/admin/badges/assignments" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Ver Aplausos Atribuídos</Link>
                                                <Link to="/admin/badges/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Criar Aplauso</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 d-none d-lg-table-cell">&nbsp;</th>
                                                            <th className="border-0 p-4 text-left">Designação</th>
                                                            <th className="border-0 p-4">Pontos</th>
                                                            <th className="border-0 p-4">Limite Diário</th>
                                                            <th className="border-0 p-4">Limite por Utilizador</th>
                                                            <th className="border-0 p-4">Total Atribuídos</th>
                                                            <th className="border-0 p-4">Ativo?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="product-thumbnail text-center ps-0 d-none d-lg-table-cell">
                                                                <img src={getImageUrl(value.thumbnail)} className="w75 rounded-3" />
                                                            </td>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/badges/edit/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.points}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.maxPerDay}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.maxPerUser}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.assignments.length}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => updateStatus(value.id, !value.isActive)}><i className={`${!value.isActive ? ' ti-check text-success ' : ' ti-na text-danger '} font-xs me-2`}></i></a>
                                                                <Link to={`/admin/badges/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteResult(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminBadgeList;