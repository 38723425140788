import React, {Fragment, useState, useEffect} from 'react';
import moment from "moment";


import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { config, USER_MOOD_STEPS, USER_MOOD_SCORE } from '../config'
import { diffHours } from '../utils';

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
        LatestUserMood {
            id
            createdDate
        }
    }
`;

const UPSERT_USER_MOOD_MUTATION = gql`
    mutation UpsertUserMood($id: UUID, $userId: UUID, $score: Int, $comments: String) {
        upsert_UserMood(userMood: { id: $id, user: { id: $userId }, score: $score, comments: $comments }) {
            id
        }
    }
`;

function MoodTrackerInput(props) {

    const [inputValue, setInputValue] = useState("");

    // Input Field handler
    const handleUserInput = (e) => {
        setInputValue(e.target.value);
    };

    // Reset Input Field handler
    const resetInputField = () => {
        setInputValue("");
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submitComments()
        }
    }

    const submitComments = () => {
        props.handleUserMoodComments(inputValue);
        resetInputField();
    }
    
    const scoreInput = (
        <div className="form-group style1-input mb-0">
            <ul className="emojis list-inline mb-0 text-center">
                {USER_MOOD_SCORE.map((value , index) => (
                    <li key={index} className="emoji list-inline-item font-xxl pointer" onClick={ () => props.handleUserMoodScore(value.score) }><i className={`em ${value.icon}`}></i></li>
                ))}
            </ul>
        </div>
    );

    const commentsInput = (
        <div className="form-group icon-right-input style1-input mb-0"><input type="text" placeholder="" className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3" onKeyDown={handleKeyDown} value={inputValue} onChange={handleUserInput} /><i className="feather-send text-grey-500 font-md pointer" onClick={submitComments}></i></div>
    )

    if(props.step === 'score') {
        return scoreInput;
    } else if(props.step === 'comments') {
        return commentsInput;
    } else {
        return null;
    }

}

function MoodTracker() {

    const [isOpen, setIsOpen] = useState(false);
    const [firstRender, setIsFirstRender] = useState(true);
    const [width, setWidth] = useState(800);
    const [height, setHeight] = useState(182);

    const [userMoodId, setUserMoodId] = useState(null);
    const [step, setStep] = useState(USER_MOOD_STEPS[0].id);
    const [isTyping, setIsTyping] = useState(false);

    const [messages, setMessages] = useState([]);

    const toggleOpen = () => setIsOpen(!isOpen);
        
    const menuClass = `${isOpen ? " d-block" : ""}`;
    const floatingButtonClass = `${isOpen ? "" : " d-block"}`;

    const [upsertUserMoodMutation, { upsertUserMoodMutationData }] = useMutation(UPSERT_USER_MOOD_MUTATION);

    const { loading, error, data } = useQuery(GET_VIEWER_QUERY);

    useEffect(() => {
        const firstStep = USER_MOOD_STEPS[0];
        
        const newMessages = [{
            selfMessage: false,
            content: firstStep.message,
            timestamp: new Date()
        }]
        setMessages([...newMessages]);
        setStep(firstStep.id)
        setUserMoodId(null)
    }, []);
    
    const updateToNextStep = async (success) => {
        if(!success) {
            const newMessages = [{
                selfMessage: false,
                content: 'Ocorreu um erro. Por favor, tenta mais tarde.',
                timestamp: new Date()
            }]
    
            setTimeout(function () {
                setMessages(messages => [...messages, ...newMessages]);
            }, 500);
            
    
            // TODO hide and reset
            setTimeout(function () {
                toggleOpen()
            }, 3000);
            
            return;
        }
        
        const currentStep = USER_MOOD_STEPS.filter(s => s.id === step)[0];
        if(currentStep && currentStep.nextStep) {
            const nextStep = USER_MOOD_STEPS.filter(s => s.id === currentStep.nextStep)[0];

            const newMessages = [{
                selfMessage: false,
                content: nextStep.message,
                timestamp: new Date()
            }]
                        
            setStep(currentStep.nextStep)
            
            setIsTyping(true);
            await setTimeout(function () {
                setMessages(messages => [...messages, ...newMessages]);
                setIsTyping(false);
            }, 500);
            
        } else {
            setStep(null)

            const newMessages = [{
                selfMessage: false,
                content: config.moodTracker.THANKYOU_MESSAGE,
                timestamp: new Date()
            }]
    
            setIsTyping(true);
            await setTimeout(function () {
                setMessages(messages => [...messages, ...newMessages]);
                setIsTyping(false);
            }, 500);
            
    
            // TODO hide and reset
            setTimeout(function () {
                toggleOpen()
            }, 5000);
        }
    }

    const handleUserMoodScore = async (score) => {
        const userMoodIcon = USER_MOOD_SCORE.filter(m => m.score === score)[0].icon
        let newMessages = [{
            selfMessage: true,
            content: <i className={`em ${userMoodIcon}`}></i>,
            timestamp: new Date()
        }]

        setMessages(messages => [...messages, ...newMessages]);

        const upsertResult = await upsertUserMoodMutation({
            variables: {
                score: score,
                userId: data.Viewer.id
            },
        })

        if(upsertResult && upsertResult.data && upsertResult.data.upsert_UserMood && upsertResult.data.upsert_UserMood.id) {
            setUserMoodId(upsertResult.data.upsert_UserMood.id)
        }

        await updateToNextStep(!upsertResult.errors);
    }

    const handleUserMoodComments = async (comments) => {
        let newMessages = [{
            selfMessage: true,
            content: comments,
            timestamp: new Date()
        }]

        setMessages(messages => [...messages, ...newMessages]);

        const upsertResult = await upsertUserMoodMutation({
            variables: {
                id: userMoodId,
                comments: comments,
                userId: data.Viewer.id
            },
        })

        await updateToNextStep(!upsertResult.errors);
    }

    if (loading) return <LoadingOverlayWrapper/>;

    if(data && data.LatestUserMood && data.LatestUserMood.createdDate && diffHours(new Date(), new Date(data.LatestUserMood.createdDate)) >= config.moodTracker.REMINDER_INTERVAL_HOURS && !isOpen && firstRender) {
        setIsFirstRender(false)
        setIsOpen(true)
    }

    return (
        
        
        <div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${width > 1500 ? "active-sidebar" : " "}`}>
            <div className={`me-2 mb-2 p-3 pointer font-xxl ${floatingButtonClass}`} style={{position: "fixed", bottom: 0, right: 0, display: 'none'}} onClick={toggleOpen}>
                <i className={`em em-full_moon_with_face`}></i>  
            </div>
            <div className={`modal-popup-chat ${menuClass}`}>
                <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                    <div className="modal-popup-header w-100 border-bottom">
                        <div className="card p-3 d-block border-0 d-block">
                            <figure className="avatar mb-0 float-left me-2">
                                <img src={ config.APP_LOGO_URL } alt={ config.APP_NAME } className="w35 me-1" />
                            </figure>
                            <h5 className="fw-700 text-success font-xssss mt-1 mb-1">{ config.APP_NAME }</h5>
                            <h4 className="text-grey-500 font-xsssss mt-0 mb-0"><span className="d-inline-block bg-success btn-round-xss m-0"></span> Disponível</h4>
                            <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer" onClick={toggleOpen}><i className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                        </div>
                    </div>
                    <div className="modal-popup-body w-100 p-3 h-auto">
                        {messages.map((value , index) => (
                            <Fragment key={index}>
                                <div className={`message ${value.selfMessage ? 'self text-right mt-2' : ''}`}>
                                    <div className="message-content font-xssss lh-24 fw-500">{value.content}</div>
                                </div>
                                <div className={`date-break font-xsssss lh-24 fw-500 text-grey-500 mt-2 mb-2 ${value.selfMessage ? 'text-right mt-2' : ''}`}>{moment(value.timestamp).format('HH:mm')}</div>
                            </Fragment>
                        ))}
                        { isTyping && <div className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 bg-grey rounded-xl float-left" data-title=".dot-typing"><div className="stage"><div className="dot-typing"></div></div></div> }
                        <div className="clearfix"></div>
                    </div>
                    <div className="modal-popup-footer w-100 border-top">
                        <div className="card p-3 d-block border-0 d-block">
                            <MoodTrackerInput step={step} handleUserMoodScore={handleUserMoodScore} handleUserMoodComments={handleUserMoodComments} />
                        </div>
                    </div>
                </div> 
            </div>   
        </div>

        


        

    );

}

export default MoodTracker;