import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import { KEY_RESULT_METRIC_OPTIONS, KEY_RESULT_PRIORITY_OPTIONS } from "../../config";
import Appfooter from "../../components/Appfooter";

const GET_OBJECTIVE_QUERY = gql`
    query GetObjective($fetchObjective: Boolean! $id: String!) {
        ObjectiveById(id: $id) @include(if: $fetchObjective)  {
            id
            name
        }
    }
`;


const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_KEY_RESULT_MUTATION = gql`
    mutation UpsertKeyResult($objectiveId: UUID!, $name: String!, $description: String, $dueDate: LocalDate, $metric: KeyResultMetricType, $initialValue: Int, $targetValue: Int, $currentValue: Int, $priority: KeyResultPriority) {
        upsert_KeyResult(keyResult: { objective: { id: $objectiveId }, name: $name, description: $description, dueDate: $dueDate, metric: $metric, initialValue: $initialValue, targetValue: $targetValue, currentValue: $currentValue priority: $priority, progress: 0, status: NOT_STARTED }) {
            id
        }
    }
`;


function CreateKeyResult() {

    const navigate = useNavigate();

    const { objectiveId } = useParams();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertKeyResultMutation, { upsertKeyResultMutationData }] = useMutation(UPSERT_KEY_RESULT_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    
    const { loading: objectiveLoading, error: objectiveError, data: objectiveData } = useQuery(GET_OBJECTIVE_QUERY, { variables: { id: objectiveId || "00000000-0000-0000-0000-000000000000", fetchObjective: objectiveId !== null }});
    
    if (objectiveLoading || viewerLoading) return <LoadingOverlayWrapper/>;

    const createKeyResult = async (formData) => {
        const upsertResult = await upsertKeyResultMutation({ 
            variables: {
                objectiveId: objectiveId,
                name: formData.name,
                description: formData.description,
                dueDate: formData.dueDate,
                metric: formData.metric,
                initialValue: formData.initialValue,
                targetValue: formData.targetValue,
                currentValue: formData.initialValue,
                priority: formData.priority,
            },
        });

        if(upsertResult.data) {
            toast.success('Resultado chave criado com sucesso!');
            navigate(-1)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Criar Resultado Chave</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(createKeyResult)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                    Texto texto
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xsss">Objetivo</label>
                                                    { objectiveId && <input type="text" className="form-control" defaultValue={objectiveData && objectiveData.ObjectiveById && objectiveData.ObjectiveById.name}  {...register("objectiveId")} readOnly /> }
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("description")} />
                                                    {errors.description && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Prazo</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("dueDate")} />
                                                    {errors.dueDate && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>        
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Prioridade</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("priority", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            KEY_RESULT_PRIORITY_OPTIONS
                                                            .map((o) => {
                                                                return (
                                                                    <option key={o.id} value={o.id}>{o.name}</option> 
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.priority && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Métrica</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("metric", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            KEY_RESULT_METRIC_OPTIONS
                                                            .map((o) => {
                                                                return (
                                                                    <option key={o.id} value={o.id}>{o.name}</option> 
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.metric && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Valor Inicial</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("initialValue", { required: true })} />
                                                    {errors.initialValue && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Alvo</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("targetValue")} />
                                                    {errors.targetValue && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>        
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createKeyResult)}>Guardar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default CreateKeyResult;