import CreateComment from "./CreateComment";
import Comment from "./Comment";

export default function Comments({ postId, comments, currentUser, type, commentsEnabled, handleCreateComment, handleCreateReply, onCommentDeleted }) {

    return (
        <section className={`${type === 'comment' ? 'mt-3' : 'mt-0'}`}>
            {commentsEnabled && type === 'comment' && <CreateComment id={postId} handleCreate={handleCreateComment} />}
            {comments.map((comment) => {
                return (
                <Comment
                    key={comment.id}
                    comment={comment}
                    currentUser={currentUser}
                    type={type}
                    commentsEnabled={commentsEnabled}
                    handleCreateReply={handleCreateReply}
                    onCommentDeleted={onCommentDeleted}
                />
                );
            })}
        </section>
    );
}
