import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import Moment from "react-moment";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";

import { getImageUrl } from "../../../utils";
import { MARKET_ORDER_STATUS_OPTIONS } from "../../../config";

const GET_QUERY = gql`
    query Get($id: String!) {
        Order_ById(id: $id) {
            id
            user {
                id
                name
                email
            }
            total
            status
            shippingAddress {
                id
                country {
                    id
                    name
                }
                city {
                    id
                    name
                }
                address
                zipCode
                locality
            }
            paymentMethod {
                id
                __typename
            }
            createdDate
            items {
                id
                name
                price
                quantity
                product {
                    id
                    thumbnail
                }
            }
            logs {
                id
                status
                createdDate
            }
        }
    }
`;

const UPSERT_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $status: OrderStatus!) {
        upsert_Order_(order:{ id: $id, status: $status }) {
            id
        }
    }
`;

function AdminOrder() {

    let { id } = useParams();

    const [isStatusFormOpen, setIsStatusFormOpen] = useState(false);
    const toggleStatusFormOpen = () => setIsStatusFormOpen(!isStatusFormOpen);

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    const [upsertStatusMutation, { upsertStatusMutationData }] = useMutation(UPSERT_STATUS_MUTATION);

    useEffect(() => {
        reset(data?.Order_ById);
    }, [data]);

    const updateStatus = async (formData) => {
        const upsertResult = await upsertStatusMutation({ 
            variables: {
                id,
                ...formData,
            },
        })

        if(upsertResult.data) {
            toast.success(`Encomenda alterada com sucesso!`)
            toggleStatusFormOpen();
            await refetch();
        }
    }

    return (
        <Fragment> 
            <AdminHeader />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <CustomLoadingOverlay active={loading} />
                                <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                        <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                <div className="card-body p-0">
                                                    <h6 className="fw-600 text-grey-500 font-xssss">Encomenda</h6>
                                                    <h2 className="font-xl text-grey-900 fw-600">{data?.Order_ById?.createdDate}</h2>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-8 mb-3">
                                                    <div className="table-content table-responsive">
                                                        <table className="table text-center">
                                                            <thead className="bg-greyblue rounded-3">
                                                                <tr>
                                                                    <th className="border-0 p-4 d-none d-lg-table-cell">&nbsp;</th>
                                                                    <th className="border-0 p-4 text-left">Produto</th>
                                                                    <th className="border-0 p-4">Preço</th>
                                                                    <th className="border-0 p-4">Quantidade</th>
                                                                    <th className="border-0 p-4 d-none d-lg-table-cell">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.Order_ById?.items.map(item => (
                                                                <tr key={item.id}>
                                                                    <td className="product-thumbnail text-center ps-0 d-none d-lg-table-cell">
                                                                        <img src={getImageUrl(item.product?.thumbnail, '/assets/images/product.png')} alt={item.name} className="w75 rounded-3" />
                                                                    </td>
                                                                    <td className="product-headline text-left wide-column ps-4">
                                                                        <h3>
                                                                            <Link to={`/market/products/${item.product.id}`} className="text-grey-900 fw-600 font-xsss">{item.name}</Link>
                                                                        </h3>
                                                                    </td>
                                                                    <td className="product-p">
                                                                        <span className="product-price-wrapper">
                                                                            <span className="money text-grey-500 fw-600 font-xsss">{item.price} <i className="icon-empathize-square ms-1"></i></span>
                                                                        </span>
                                                                    </td>
                                                                    <td className="product-total-price d-none d-lg-table-cell">
                                                                        <span className="product-price-wrapper">
                                                                            <span className="money fmont"><strong>{item.quantity}</strong></span>
                                                                        </span>
                                                                    </td>
                                                                    <td className="product-total-price d-none d-lg-table-cell">
                                                                        <span className="product-price-wrapper">
                                                                            <span className="money fmont"><strong>{item.price * item.quantity} <i className="icon-empathize-square ms-1"></i></strong></span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="table-content table-responsive">
                                                        <h4 className="mont-font fw-600 font-md mt-5">Histórico</h4>
                                                        <table className="table text-center">
                                                            <thead className="bg-greyblue rounded-3">
                                                                <tr>
                                                                    <th className="border-0 p-4">Data/Hora</th>
                                                                    <th className="border-0 p-4">Estado</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.Order_ById?.logs.map(value => (
                                                                <tr key={value.id}>
                                                                    <td className="product-headline text-center">
                                                                        <Moment format="DD/MM/YYYY HH:mm:ss">{value.createdDate}</Moment>
                                                                    </td>
                                                                    <td className="product-p">
                                                                        <span className={`p-1 ps-2 pe-2 ${MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.className} font-xssss rounded-3`}><strong>{MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.name}</strong></span>
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    {false &&
                                                    <a href="/cart" className="update-cart bg-dark float-right text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Update Cart</a>
                                                    }
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="checkout-payment card border-0 mb-3 bg-greyblue p-4">
                                                        <div className="cart-totals">
                                                            <h4 className="mont-font fw-600 font-md mb-5">Total</h4>
                                                            <div className="table-content table-responsive">
                                                                <table className="table order-table">
                                                                    <tbody>
                                                                        {false && <tr>
                                                                            <td className="font-xsss pt-2 fw-600">Subtotal</td>
                                                                            <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">{ data?.Order_ById?.total }<i className="icon-empathize-square ms-1"></i></td>  
                                                                        </tr>}
                                                                        {false && <tr>
                                                                            <td className="font-xsss pt-2 fw-600">Envio</td>
                                                                            <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">
                                                                                <span>Flat rate: $20.00</span>
                                                                            </td>  
                                                                        </tr>}
                                                                        <tr className="order-total">
                                                                            <td className="font-xsss pt-2 fw-600">Total</td>
                                                                            <td className="font-xssss fw-700 text-grey-600 pt-2 text-right">
                                                                                <span className="product-price-wrapper">
                                                                                    <span className="money fmont">{ data?.Order_ById?.total }<i className="icon-empathize-square ms-1"></i></span>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="order-details">
                                                        <div className="card border-0 bg-greyblue p-4">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="d-block pt-1">
                                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Cliente
                                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.user.name}</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="d-block pt-1">
                                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.user.email}</span></h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="d-block pt-1">
                                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Estado
                                                                            <span className="d-block font-xsss fw-500 mt-2 lh-3 text-grey-500">
                                                                                <span className={`p-1 ps-2 pe-2 ${MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === data?.Order_ById?.status)[0]?.className} font-xssss rounded-3`}><strong>{MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === data?.Order_ById?.status)[0]?.name}</strong></span>
                                                                                { !isStatusFormOpen && <a role="button" className="ms-2" onClick={toggleStatusFormOpen}><i className="ti-pencil font-xs text-grey-500"></i></a>}
                                                                            </span>
                                                                        </h4>
                                                                        { isStatusFormOpen &&
                                                                        <form onSubmit={handleSubmit(updateStatus)}>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <div className="form-group">
                                                                                        <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("status", { required: true })}>
                                                                                            <option value=''>Selecionar...</option>
                                                                                            {
                                                                                                MARKET_ORDER_STATUS_OPTIONS.map((e) => {
                                                                                                    return (
                                                                                                        <option key={e.id} value={e.id}>{e.name}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        {errors.status && <span className="text-red">Verifique este campo</span>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 w-100 rounded-3 d-inline-block" onClick={handleSubmit(updateStatus)}>Guardar</a>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="clearfix"></div>
                                                    </div>
                                                    
                                                    <div className="order-details">
                                                        <h4 className="mont-font fw-600 font-md mt-3">Detalhes da Entrega</h4>
                                                        <div className="mb-5 card border-0 bg-greyblue p-4">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="d-block pt-1">
                                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Destinatário
                                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.shippingAddress?.deliveryName || data?.Order_ById?.user.name}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="d-block pt-1">
                                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Morada
                                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.shippingAddress?.address}</span>
                                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.shippingAddress?.zipCode} {data?.Order_ById?.shippingAddress?.locality}</span>
                                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.Order_ById?.shippingAddress?.city?.name}, {data?.Order_ById?.shippingAddress?.country?.name}</span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>
    );
}

export default AdminOrder;