import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { getImageUrl } from "../../../../utils";


const GET_QUERY = gql`
    query Get {
        LearningAuthorList(orderBy: { name: ASC }) {
            id
            name
            items {
                id
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_LearningAuthor(id: $id)
    }
`;

function AdminLearningAuthorList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar o autor?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Autor eliminada com sucesso!')
            }
        }
    }

    const results = (data && data.LearningAuthorList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Learning > Autores"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <Link to="/admin/learning/authors/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Autor</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">Nome</th>
                                                            <th className="border-0 p-4">Cursos</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/learning/authors/edit/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.items.length}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => deleteResult(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminLearningAuthorList;