import React,{Component, Fragment} from 'react';
import { Link } from 'react-router-dom';

import { RBACWrapper } from "react-simple-rbac";

class Pagetitle extends Component {
    render() {
        const {title, showSearch, customButton, customButton2, color} = this.props;
        return (
            <div className={`card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ${color == 'success' ? 'bg-success': ''}`}>
                <h2 className={`fw-700 mb-0 mt-0 font-md ${color == 'success' ? 'text-white': 'text-grey-900'} d-flex align-items-center`}>{title}
                { showSearch &&
                <Fragment>
                    <form action="#" className="pt-0 pb-0 ms-auto">
                        <div className="search-form-2 ms-2">
                            <i className="ti-search font-xss"></i>
                            <input type="text" className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                        </div>
                    </form>
                    <a href="/" className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"><i className="feather-filter font-xss text-grey-500"></i></a>
                </Fragment>
                }
                { customButton &&
                    <RBACWrapper requiredRoles={customButton?.requiredRoles} oneOf>
                        <Link to={customButton.link} className={`btn-round-md ${showSearch ? 'ms-2' : 'ms-auto'} bg-greylight theme-dark-bg rounded-3 text-grey-500`}>
                            { customButton.icon && <i className={`${customButton.icon} font-xss text-grey-500`}></i> }
                        </Link>
                    </RBACWrapper>
                }
                { customButton2 &&
                    <RBACWrapper requiredRoles={customButton2?.requiredRoles} oneOf>
                        <Link to={customButton2.link} className={`btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 text-grey-500`}>
                            { customButton2.icon && <i className={`${customButton2.icon} font-xss text-grey-500`}></i> }
                        </Link>
                    </RBACWrapper>
                }
                </h2>
            </div>            
        );
    }
}

export default Pagetitle;


