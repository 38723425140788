import React, { Fragment } from "react";

import { useQuery, gql } from '@apollo/client';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';

import Load from '../components/Load';

import { useParams } from "react-router";
import Pagetitle from "../components/Pagetitle";
import FeedSearchResults from "../components/FeedSearchResults";
import track from "react-tracking";
import FeedSearchHeader from "../components/FeedSearchHeader";

const GET_SEARCH_QUERY = gql`
    query GetPostSearch($q: String!) {
        PostList(filter: { OR: [
            {title: { _contains: $q } },
            {content: { _contains: $q } },
            {user: { name: { _contains: $q } } },
            {user: { employee: { name: { _contains: $q } } } },
            {user: { employee: { fullName: { _contains: $q } } } }
        ]}) {            
            id
            title
            content
            type
            isPinned
            allowComments
            user {
                id
                name
                picture
                employee {
                    id
                    name
                    jobTitle
                    birthdate
                    picture
                    user {
                        id
                        picture
                    }
                }
            }
            thumbnail
            employee {
                id
                name
                birthdate
                jobTitle
            }
            event {
                id
                title
                startDate
                endDate
                location
                thumbnail
                createdDate
            }
            benefit {
                id
                name
                thumbnail
                category {
                    id
                    name
                }
            }
            mediaGallery {
                id
            }
            survey {
                id
            }
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        birthdate
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            birthdate
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
            attachments {
                id
                name
                file
            }
        }
    }
`;

function FeedSearch() {

    const { searchQuery } = useParams();

    const { data: postsData, loading: postsLoading, fetchMore: postsFetchMore, error: postsError, refetch: postsRefetch } = useQuery(GET_SEARCH_QUERY, {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: { q: searchQuery }
    });

    const posts = postsData?.PostList || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <Pagetitle title={`Resultados da Pesquisa - ${searchQuery}`} />
                                <FeedSearchHeader currentPage="posts" searchQuery={searchQuery} /> 

                                <FeedSearchResults posts={posts} postsLoading={postsLoading} postsRefetch={postsRefetch} />
                                { postsLoading && <Load /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default track(
    { page: "FeedSearch" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(FeedSearch);