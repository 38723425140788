import React, { Fragment } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from "../../components/Appfooter";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_USER_SUGGESTION_MUTATION = gql`
    mutation UpsertUserSuggestion($userId: UUID!, $subject: String!, $description: String) {
        upsert_UserSuggestion(userSuggestion: { user: { id: $userId }, subject: $subject, description: $description }) {
            id
        }
    }
`;

function CreateSuggestion() {

    const navigate = useNavigate();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertUserSuggestionMutation, { upsertUserSuggestionMutationData }] = useMutation(UPSERT_USER_SUGGESTION_MUTATION);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);

    const createSuggestion = async (formData) => {
        const upsertResult = await upsertUserSuggestionMutation({ 
            variables: {
                userId: viewerData.Viewer.id,
                subject: formData.subject,
                description: formData.description,
            },
        });

        if(upsertResult.data) {
            toast.success('Sugestão enviada com sucesso!');
            navigate(`/feed`)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Nova Sugestão</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(createSuggestion)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                Envia-nos pedidos de informação, elogios, sugestões ou reclamações.
                                                <br />
                                                O teu feedback é importante para nós! Envia-nos a tua mensagem.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xssss">Assunto</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("subject", { required: true })} />
                                                    {errors.subject && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xssss">Ideia/Sugestão</label>
                                                    <textarea className="form-control mb-0 p-3 h200 lh-16" rows="10" {...register("description")} ></textarea>
                                                    {errors.description && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createSuggestion)}>Enviar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default CreateSuggestion;