import React, { useEffect, useRef, useState } from "react";
import { Timeline } from "vis-timeline";

import 'vis-timeline/styles/vis-timeline-graph2d.min.css'


const VisTimeline = ({ items, groups, options, onClick}) => {
	// Create a ref to provide DOM access
	const visJsRef = useRef(null);
  const [timeline, setTimeline] = useState(null);

	useEffect(() => {
		!timeline && setTimeline(visJsRef.current && new Timeline(visJsRef.current));
		
    options && timeline?.setOptions(options);
    groups && timeline?.setGroups(groups);
    items && timeline?.setItems(items);

		//onClick && !timeline?.onClick && timeline?.on('click', onClick);

	}, [visJsRef, items, groups, options]);

	return <div ref={visJsRef} />;
};

export default VisTimeline;