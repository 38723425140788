import React from 'react';

import { getImageUrl } from '../utils';

function BenefitPartner({ partner }) {

    if(!partner) return null;

    const imageCover = getImageUrl(partner.coverPicture, "/assets/images/abstract-bg-green.png");
    const logo = getImageUrl(partner.logo, "/assets/images/user.png");
    
    return (
        <div className="card w-100 border-0 mt-3 mb-4 shadow-xss position-relative rounded-3 bg-white rounded-xxl overflow-hidden">
            <div className="card-body position-relative h100 bg-image-cover bg-image-center" style={{backgroundImage: `url("`+ imageCover +`")`}}></div>
            <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 z-index-1"><img src={logo} alt={partner.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                <div className="clearfix"></div>
                <h4 className="fw-700 font-xsss mt-2 mb-1">{partner.name} </h4>
                {partner.websiteUrl && <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2"><a href={partner.websiteUrl} target="_blank" rel="noopener">{partner.websiteUrl}</a></p>}
                {false && <span className="live-tag mt-2 mb-0 bg-danger p-2 z-index-1 rounded-3 text-white font-xsssss text-uppersace fw-700 ls-3">LIVE</span>}
                {partner.phone && <h5 className="mt-3 mb-3 font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-headphone-alt text-grey-500 me-1"></i> <a href={`tel:${partner.phone}`}>{partner.phone}</a></h5>}
                {partner.mobile && <h5 className="mt-3 mb-3 font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-mobile text-grey-500 me-1"></i> <a href={`tel:${partner.mobile}`}>{partner.mobile}</a></h5>}
                {partner.email && <h5 className="mt-3 mb-0 font-xssss fw-600 text-grey-500"><i className="btn-round-sm bg-greylight ti-email text-grey-500 me-1"></i> <a href={`mailto:${partner.email}`}>{partner.email}</a></h5>}
                <div className="clearfix mb-2"></div>
            </div>
        </div>
    );
}

export default BenefitPartner;