import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import { getProfilePicture } from "../../../../utils";
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { GRAPHQL_MAX_RESULTS_LIMIT, USER_SIGNUP_REQUEST_STATUS_OPTIONS } from "../../../../config";

import { Modal } from "react-bootstrap";
import { useEffect } from "react";

const STATUS_FILTERS = [
    { id: 'all', name: 'Todos', value: { } },
    { id: 'pending', name: 'Pendentes', value: { _eq: `PENDING` } },
    { id: 'accepted', name: 'Aceites', value: { _eq: `ACCEPTED` } },
    { id: 'rejected', name: 'Rejeitados', value: { _eq: `REJECTED` } },
];

const GET_USER_SIGNUP_REQUESTS_QUERY = gql`
    query GetUserSignupRequests($statusFilter: [inp_UserSignupRequestStatusFilterCondition]) {
        UserSignupRequestList(orderBy: { createdDate: DESC }, limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { status: $statusFilter }) {
            id
            email
            name
            companyName
            jobTitle
            fiscalNumber
            isEmailVerified
            status
            createdDate
        }

        Total: UserSignupRequestCount
        Pending: UserSignupRequestCount(filter: { status: { _eq: PENDING } })
        Accepted: UserSignupRequestCount(filter: { status: { _eq: ACCEPTED } })
        Rejected: UserSignupRequestCount(filter: { status: { _eq: REJECTED } })

        EmployeeList(orderBy: { fullName: ASC }, limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: [{ user: { _isNull: true }}, { isActive: { _eq: true } }] ) {
            id
            name
            fullName
            company {
                id
                name
            }
            department {
                id
                name
            }
            jobTitle
        }
    }
`;

const ACCEPT_USER_SIGNUP_REQUEST_MUTATION = gql`
    mutation AcceptUserSignupRequest($id: UUID!, $employeeId: UUID!) {
        AcceptUserSignupRequest(id: $id, employeeId: $employeeId) {
            id
        }
    }
`;

const REJECT_USER_SIGNUP_REQUEST_MUTATION = gql`
    mutation RejectUserSignupRequest($id: UUID!) {
        RejectUserSignupRequest(id: $id) {
            id
        }
    }
`;

const AcceptUserSignupRequestModal = (props) => {

    const { register, handleSubmit, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [acceptUserSignupRequestMutation, { loading: acceptUserSignupRequestMutationLoading }] = useMutation(ACCEPT_USER_SIGNUP_REQUEST_MUTATION);

    const acceptUserSignupRequest = async (formData) => {
        if (props.request?.isEmailVerified || window.confirm('O email não foi verificado pelo utilizador.\nTem a certeza que pretende aceitar o pedido?')) {
            const upsertResult = await acceptUserSignupRequestMutation({ 
                variables: {
                    id: props.request?.id,
                    ...formData
                },
            });

            if(upsertResult.data) {
                reset();
                toast.success('Pedido aceite!');
                props.onHide();
            }
        }
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Pedido de Registo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CustomLoadingOverlay active={acceptUserSignupRequestMutationLoading}>
            <form onSubmit={handleSubmit(acceptUserSignupRequest)}>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{props.request?.name}</span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{props.request?.email} <i className={`${props.request?.isEmailVerified ? "feather-check bg-success" : "feather-x bg-danger"} text-white rounded-xxl p-1 font-xsssss`}></i></span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{props.request?.companyName}</span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Função
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{props.request?.jobTitle}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">NIF
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{props.request?.fiscalNumber}</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data do Pedido
                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500"><Moment format="DD/MM/YYYY HH:mm">{props.request?.createdDate}</Moment></span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Colaborador</label>
                            <select type="text" className="form-control mb-0 pt-0 pv-0 lh-24" defaultValue='' {...register("employeeId", { required: true })}>
                                <option value=''>Selecionar...</option>
                                {
                                    props.employees?.slice().sort((a, b) => a.name && b.name && a.name.localeCompare(b.name))
                                    .map((e) => {
                                        return (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors?.employeeId && <span className="text-red">Este campo é obrigatório</span>}
                        </div>
                    </div>
                </div>
            </form>
            </CustomLoadingOverlay>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(acceptUserSignupRequest)}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}

function AdminUserSignupRequestList() {

    const [selectedStatusFilter, setSelectedStatusFilter] = useState({})

    const [acceptUserSignupRequestModalShow, setAcceptUserSignupRequestModalShow] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const { loading, error, data, refetch } = useQuery(GET_USER_SIGNUP_REQUESTS_QUERY, { variables: { statusFilter: selectedStatusFilter }});

    const [rejectUserSignupRequestMutation] = useMutation(REJECT_USER_SIGNUP_REQUEST_MUTATION);

    useEffect(() => {
        setStatusFilter("pending")
    }, [])

    const rejectUserSignupRequest = async (id) => {
        if (window.confirm('Tem a certeza que pretende rejeitar o pedido?')) {
            const deleteResult = await rejectUserSignupRequestMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Pedido rejeitado com sucesso!')
            }
        }
    }

    const setStatusFilter = (id) => {
        setSelectedStatusFilter(STATUS_FILTERS.filter(e => e.id === id)[0].value)
    }

    const requests = data?.UserSignupRequestList || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 cart-wrapper mb-4">
                                    <Pagetitle title="Utilizadores > Pedidos de Registo"/>
                                    
                                    <div className="row">
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e5f6ff`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-primary feather-users font-md text-white"></i>
                                                    <h4 className="text-primary font-xl fw-700">{data?.Total} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">pedidos de registo</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#fff0e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-warning feather-loader font-md text-white"></i>
                                                    <h4 className="text-warning font-xl fw-700">{data?.Pending}<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">pendentes</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2 ps-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#e2f6e9`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-success feather-user-check font-md text-white"></i>
                                                    <h4 className="text-success font-xl fw-700">{data?.Accepted} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">aceites</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 pe-2">
                                            <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{background:`#facccc`}}>
                                                <div className="card-body d-flex p-0">
                                                    <i className="btn-round-lg d-inline-block me-3 bg-danger feather-user-x font-md text-white"></i>
                                                    <h4 className="text-danger font-xl fw-700">{data?.Rejected} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">rejeitados</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ requests.length } resultados</h4>
                                            <select className="searchCat float-right sort" onChange={e => setStatusFilter(e.target.value)} defaultValue={""}>
                                                <option value="" disabled>Estado</option>
                                                { STATUS_FILTERS.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Email Verificado?</th>
                                                            <th className="border-0 p-4">Empresa</th>
                                                            <th className="border-0 p-4">Função</th>
                                                            <th className="border-0 p-4">NIF</th>
                                                            <th className="border-0 p-4">Data do Pedido</th>
                                                            <th className="border-0 p-4">Estado</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {requests.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss">{value.name}</span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-500 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isEmailVerified ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.companyName}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.jobTitle}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.fiscalNumber}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${USER_SIGNUP_REQUEST_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.className} font-xssss rounded-3`}><strong>{USER_SIGNUP_REQUEST_STATUS_OPTIONS.filter(o => o.id === value.status)[0]?.name}</strong></span>
                                                            </td>
                                                            <td className="text-center">
                                                                {value.status === 'PENDING' && <a role="button" className="p-2 lh-20 w100 bg-primary-gradiant me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => { setSelectedRequest(value); setAcceptUserSignupRequestModalShow(true) }}>Aceitar</a>}
                                                                {value.status === 'PENDING' && <a role="button" className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => rejectUserSignupRequest(value.id)}>Rejeitar</a>}
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter />

                <AcceptUserSignupRequestModal show={acceptUserSignupRequestModalShow} onHide={() => { setAcceptUserSignupRequestModalShow(false); refetch() }} request={selectedRequest} employees={data?.EmployeeList} />
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminUserSignupRequestList;