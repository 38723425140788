import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { getImageUrl } from "../../../../utils";
import { resizeImage } from "../../../../utils/resize-image";
import { UPLOAD_IMAGE_ACCEPT_FILETYPES } from "../../../../config";

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $name: String!, $description: String, $logo: Upload) {
        upsert_BenefitCategory(benefitCategory: { id: $id, name: $name, description: $description, logo: $logo }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        BenefitCategoryById(id: $id) {
            id
            name
            description
            logo
        }
    }
`;

function AdminNewBenefitCategory() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [selectedFile, setSelectedFile] = useState(null);

    const inputFile = useRef(null)

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.BenefitCategoryById);
    }, [editData]);

    const upsert = async (formData) => {
        formData.logo = selectedFile || editData?.logo;
        
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/benefits/categories`)
        }
    }

    const onFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFile(file)
    };

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Categoria</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("description")} ></textarea>
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Imagem</label>
                                                            <input type="file" name="file" id="file" ref={inputFile} className="input-file" onChange={onFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!editData?.BenefitCategoryById?.logo || selectedFile) && <>
                                                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFile ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFile ? selectedFile.name : 'Clique para selecionar uma imagem' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { editData?.BenefitCategoryById?.logo && !selectedFile && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFile.current.click()}><img src={getImageUrl(editData?.BenefitCategoryById?.logo)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewBenefitCategory;