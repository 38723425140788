import React from 'react';
import { getAttachmentUrl } from '../utils';

function BenefitAttachments({ attachments }) {

    if(!attachments) return;
    
    return (
        <div className="card w-100 border-0 mt-3 mb-4 p-lg-4 p-3 shadow-xss position-relative rounded-3 bg-white">
            <h4 className="fw-700 font-sm mt-1 ps-1 mb-3">Anexos</h4>

            <div className="row">
                <div className="col-12 ps-3">
                    <div className="content">
                        { attachments.map((attachment) => (
                            <a key={attachment.id} href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BenefitAttachments;