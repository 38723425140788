import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import MarketHeader from "../../components/MarketHeader";
import { getImageUrl } from "../../utils";
import CreditCard from "../../components/CreditCard";
import Moment from "react-moment";
import { MARKET_ORDER_STATUS_OPTIONS } from "../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            name
            wallet {
                id
                balance
            }
            orders {
                id
                total
                status
                createdDate
            }
        }
    }
`;

function OrdersList() {

    const { loading, error, data } = useQuery(GET_VIEWER_QUERY);

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-12 mb-3">
                                        <MarketHeader title="Encomendas" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-8 mb-3">
                                        <div className="table-content table-responsive">
                                            {data?.Viewer?.orders?.length <= 0 && <p className="text-center fw-600 mt-2 mb-2">Ainda não existem encomendas!</p>}
                                            {data?.Viewer?.orders?.length > 0 &&
                                            <table className="table text-center">
                                                <thead className="bg-greyblue rounded-3">
                                                    <tr>
                                                        <th className="border-0 p-4 text-left">Data</th>
                                                        <th className="border-0 p-4">Total</th>
                                                        <th className="border-0 p-4">Estado</th>
                                                        {false && <th className="border-0 p-4">&nbsp;</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.Viewer?.orders?.slice()
                                                    .sort(function (a, b) {
                                                        const d1 = new Date(a.createdDate).getTime()
                                                        const d2 = new Date(b.createdDate).getTime()
                                                        return d2 - d1;
                                                    }).map(order => (
                                                    <tr key={order.id}>
                                                        <td className="product-headline text-left wide-column ps-4">
                                                            <h3 className="text-grey-900 fw-600 font-xsss mb-0">
                                                                <Moment format="DD/MM/YYYY HH:MM">{order.createdDate}</Moment>
                                                            </h3>
                                                        </td>
                                                        <td className="product-p">
                                                            <span className="product-price-wrapper">
                                                                <span className="money text-grey-500 fw-600 font-xsss">{order.total} <i className="icon-empathize-square ms-1"></i></span>
                                                            </span>
                                                        </td>
                                                        <td className="product-total-price">
                                                            <span className="product-price-wrapper">
                                                                <span className={`d-inline-block p-1 ps-2 pe-2 ${MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === order.status)[0].className} font-xssss rounded-3`}><strong>{MARKET_ORDER_STATUS_OPTIONS.filter(o => o.id === order.status)[0].name}</strong></span>
                                                            </span>
                                                        </td>
                                                        {false && <td className="product-remove text-right"><a role="button"><i className="ti-trash font-xs text-grey-500"></i></a></td>}
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            }
                                        </div>
                                        {false &&
                                        <div className="coupon float-left mb-2">
                                            <input type="text" className="input-code form-control h60 p-3" placeholder="Coupon Code.." />
                                            <a href="/cart" className="bg-dark text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Apply Coupon</a>
                                        </div>
                                        }
                                        {false &&
                                        <a href="/cart" className="update-cart bg-dark float-right text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Update Cart</a>
                                        }
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="checkout-payment card border-0 mb-3 bg-greyblue p-4">
                                            <div className="cart-totals">
                                                <h4 className="mont-font fw-600 font-md mb-5">Wallet</h4>
                                                { data?.Viewer?.wallet &&
                                                    <CreditCard bank="empathize" holder={data.Viewer.name} balance={data.Viewer.wallet.balance} currencySymbol={<i className="icon-empathize text-success"></i>} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter /> 
        </Fragment>
    );
}

export default OrdersList;