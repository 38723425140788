import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';

import ShopContext from "../context/shop-context";

function MarketHeader(props) {
    
    return (
        <ShopContext.Consumer>
            {context => (
            <div className="card p-md-5 p-4 bg-primary-gradiant rounded-3 shadow-xss border-0 overflow-hidden">
                <div className="bg-pattern-div"></div>
                <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0">{ props.title }
                <Link to={'/market/cart'}><span className="fw-700 ls-3 text-grey-200 font-sm mt-2 d-block"><i className="feather-shopping-cart" /> { context.cart.reduce((count, curItem) => { return count + curItem.quantity;}, 0) } </span></Link></h2>
            </div>
            )}
        </ShopContext.Consumer>
    );
}

export default MarketHeader;


