import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";
import { getImageUrl } from "../../../../utils";
import Moment from "react-moment";
import VisTimeline from "../../../../components/VisTimeline";
import moment from "moment";
import { EMPLOYEE_AVAILABILITY_TYPES, GRAPHQL_MAX_RESULTS_LIMIT } from "../../../../config";


const GET_QUERY = gql`
    query Get {
        EmployeeAvailabilityList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { date: ASC }) {
            id
            employee {
                id
                name
            }
            date
            availabilityType
        }
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, orderBy: { name: ASC }) {
            id
            name
            department {
                id
                name
            }
            jobTitle
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_EmployeeAvailability(id: $id)
    }
`;

function AdminEmployeeAvailabilityHome() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);
    const [deleteMutation, { deleteMutationData }] = useMutation(DELETE_MUTATION);

    const deleteResult = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a disponibilidade?')) {
            const deleteResult = await deleteMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Disponibilidade eliminada com sucesso!')
            }
        }
    }

    const results = (data && data.EmployeeAvailabilityList) || [];

    const options = {
        locale: 'pt_PT',
        orientation: 'both',
        //start: new Date(),
        //end: new Date() + 7,
    };

    const groups = data?.EmployeeList?.map(e => ({ id: e.id, content: e.name }));
    const items = data?.EmployeeAvailabilityList?.map(e => ({
        id: e.id,
        content: EMPLOYEE_AVAILABILITY_TYPES.filter(a => a.id?.toLowerCase() === e.availabilityType?.toLowerCase())[0]?.name,
        start: moment(e.date).format('YYYY-MM-DD 00:00:00'),
        end: moment(e.date).format('YYYY-MM-DD 23:59:59'),
        group: e.employee.id,
        className: `${EMPLOYEE_AVAILABILITY_TYPES.filter(a => a.id?.toLowerCase() === e.availabilityType?.toLowerCase())[0]?.className } border-0`
    }));

    console.log(items)

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Turnos > Disponibilidade"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            <div className="float-right">
                                                { false && <Link to="/admin/events/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link> }
                                                <Link to="/admin/shifts/availability/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Novo Registo</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <VisTimeline items={items} groups={groups} options={options} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminEmployeeAvailabilityHome;