import React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { getProfilePicture } from '../utils';

const GET_BIRTHDAYS_QUERY = gql`
    query GetBirthdays {
        Birthdays {
            id
            name
            birthdate
            jobTitle
            picture
            user {
                id
                picture
            }
        }
    }
`;

function Birthdays() {

    const navigate = useNavigate();

    const { loading, error, data } = useQuery(GET_BIRTHDAYS_QUERY);
    if (loading) return <LoadingOverlayWrapper/>;

    const birthdays = (data && data.Birthdays
                        .slice()
                        .sort(function (a, b) {
                            const d1 = new Date(a.birthdate)
                            const d2 = new Date(b.birthdate)
                            return d1.getMonth() !== d2.getMonth() ? d1.getMonth() - d2.getMonth() : d1.getDate() - d2.getDate();
                        })) || [];

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Aniversários <i className="em em-birthday ms-1"></i></h4>
                <Link to={`/birthdays?month=${new Date().getMonth()+1}`} className="fw-600 ms-auto font-xssss text-success">Ver todos</Link>
            </div>
            {birthdays.slice(0, birthdays.length > 4 ? 4 : birthdays.length).map((value , index) => (
            <div className="wrap cursor-pointer" key={value.id} onClick={() => navigate(`/employees/${value.id}`)}>
                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                    <figure className="avatar me-3"><img src={getProfilePicture(value)} alt={value.name} className="shadow-sm rounded-circle w45 aspect-ratio-1" /></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">{value.name} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"><Moment format='DD/MM'>{value.birthdate}</Moment> { (new Date(value.birthdate).getMonth() === new Date().getMonth() && new Date(value.birthdate).getDate() === new Date().getDate()) && <i className="feather-gift fw-600 text-success"></i> }</span></h4>
                </div>
            </div>

            ))}

            {!loading && birthdays.length === 0 && <span className="d-block font-xssss fw-500 p-2 mb-3 text-grey-500 text-center">Já não existem aniversários este mês</span>}

            {birthdays.length > 4 && <span className="d-block font-xssss fw-500 p-2 mb-3 text-grey-500 text-center"><Link to={`/birthdays?month=${new Date().getMonth()+1}`}>Ver todos ({birthdays.length})</Link></span>}

            
        </div>
    );
}

export default Birthdays;