import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";
import Moment from "react-moment";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';
import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../components/Pagetitle";
import { getAttachmentUrl } from "../../../utils";


const GET_QUERY = gql`
    query Get {
        EmployeeReferralList(orderBy: { createdDate: DESC }) {
            id
            referrer {
                id
                name
                employee {
                    id
                    name
                }
            }
            name
            email
            phone
            linkedinUrl
            cv
            createdDate
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_EmployeeReferral(id: $id)
    }
`;

function AdminReferralList() {

    const { loading, error, data, refetch } = useQuery(GET_QUERY);

    const results = (data && data.EmployeeReferralList) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Refer a Friend"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ results.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            { false && 
                                            <div className="float-right">
                                                <Link to="/admin/news/categories" className="p-2 ps-3 pe-3 lh-20 bg-greylight me-2 text-grey-700 text-center font-xssss fw-600 ls-1 rounded-xl">Gerir Categorias</Link>
                                                <Link to="/admin/news/new" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova Notícia</Link>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4">Nome</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Telefone</th>
                                                            <th className="border-0 p-4">LinkedIn</th>
                                                            <th className="border-0 p-4">CV</th>
                                                            <th className="border-0 p-4">Enviado por</th>
                                                            <th className="border-0 p-4">Data de Envio</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {results.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center wide-column">
                                                                <span className="text-grey-900 font-xsss">{value.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.phone}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="font-xsss">{ value.linkedinUrl ? <a href={value.linkedinUrl} target="_blank" rel="noopener">{value.linkedinUrl}</a> : value.linkedinUrl }</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <a href={getAttachmentUrl(value.cv)}><i className="ti-clip font-xs"></i></a>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.referrer?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss"><Moment format="DD/MM/YYYY HH:mm">{value.createdDate}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminReferralList;