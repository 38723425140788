import React, { Component , Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from "../../components/Appfooter";

const GET_EMPLOYEE_QUERY = gql`
    query GetEmployee($fetchEmployee: Boolean! $id: String!) {
        EmployeeById(id: $id) @include(if: $fetchEmployee)  {
            id
            name
            birthdate
            email
            jobTitle
            user {
                picture
            }
        }
    }
`;

const GET_TIME_PERIODS_QUERY = gql`
    query GetTimePeriods {
        TimePeriodList(orderBy: { startDate: ASC }) {
            id
            name
            startDate
            endDate
        }
    }
`;

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            employee {
                id
            }
        }
    }
`;

const UPSERT_OBJECTIVE_MUTATION = gql`
    mutation UpsertObjective($timePeriodId: UUID!, $employeeId: UUID!, $name: String!, $description: String, $dueDate: LocalDate) {
        upsert_Objective(objective: { timePeriod: { id: $timePeriodId }, employee: { id: $employeeId }, name: $name, description: $description, dueDate: $dueDate }) {
            id
        }
    }
`;

function CreateObjective() {

    const navigate = useNavigate();

    const { employeeId } = useParams();

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm();

    const [upsertObjectiveMutation, { upsertObjectiveMutationData }] = useMutation(UPSERT_OBJECTIVE_MUTATION);

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    
    const { loading: timePeriodsLoading, error: timePeriodsError, data: timePeriodsData } = useQuery(GET_TIME_PERIODS_QUERY);
    const { loading: employeeLoading, error: employeeError, data: employeeData } = useQuery(GET_EMPLOYEE_QUERY, { variables: { id: employeeId || "00000000-0000-0000-0000-000000000000", fetchEmployee: employeeId !== null }});
    
    if (employeeLoading || timePeriodsLoading || viewerLoading) return <LoadingOverlayWrapper/>;

    const createObjective = async (formData) => {
        const upsertResult = await upsertObjectiveMutation({ 
            variables: {
                timePeriodId: formData.timePeriodId,
                employeeId: viewerData.Viewer.employee.id,
                name: formData.name,
                description: formData.description,
                dueDate: formData.dueDate,
            },
        });

        if(upsertResult.data) {
            toast.success('Objetivo criado com sucesso!');
            navigate(-1)
        }
    }

    return (
        <Fragment> 

        <div className="main-wrapper">

            <Header />
            <Leftnav />

            <div className="main-content bg-lightblue theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Criar Objetivo</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(createObjective)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-4">
                                                <div className="card alert-success p-3">
                                                    Texto texto
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-gorup">
                                                    <label className="mont-font fw-600 font-xssss">Período</label>
                                                    <select type="text" className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("timePeriodId", { required: true })}>
                                                        <option value=''>Selecionar...</option>
                                                        {
                                                            timePeriodsData && timePeriodsData.TimePeriodList && timePeriodsData.TimePeriodList
                                                            .map((p) => {
                                                                return (
                                                                    <option key={p.id} value={p.id}>{p.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors.timePeriodId && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Nome</label>
                                                    <input type="text" className="form-control" autocomplete="off" {...register("name", { required: true })} />
                                                </div>        
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea name="comment-name" className="form-control h100 theme-dark-bg lh-24" rows={5} {...register("description")} />
                                                    {errors.description && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>
                                            </div>                                     
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-4 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss mb-2">Prazo</label>
                                                    <input type="date" className="form-control" autoComplete="off" {...register("dueDate")} />
                                                    {errors.dueDate && <span className="text-red">Por favor, verifique este campo</span>}
                                                </div>        
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(createObjective)}>Guardar</a>
                                            </div>
                                        </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default CreateObjective;