import React, { Component , Fragment, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import OrgChart from '@unicef/react-org-chart'

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";

const GET_VIEWER_QUERY = gql`
  query GetViewer {
    Viewer {
      id
      name
      employee {
        id
        manager {
          id
        }
      }
    }
  }
`;

const GET_DEPARTMENTS_QUERY = gql`
  query Get {
    DepartmentList {
      id
      name
      parentDepartment {
        id
      }
      employees {
        id
      }
    }
  }
`;

const downloadImageId = "download-image";
const downloadPdfId = "download-pdf";

function DepartmentOrgChart() {
    
    const navigate = useNavigate();

    const [tree, setTree] = useState(null);
    const [downloadingChart, setDownloadingChart] = useState(false);
    const [orgChartConfig, setOrgChartConfig] = useState({});
    const stateRef = useRef();
    
    stateRef.current = orgChartConfig;

    const getDepartmentNode = (id, includeChildren) => {
      const dep = data && data.DepartmentList.filter(e => e.id === id) && data.DepartmentList.filter(e => e.id === id)[0];
      if(!dep) {
        return null;
      }

      console.log(dep)

      let children = [];
      if(includeChildren) {
        data && data.DepartmentList.filter(e => e.parentDepartment?.id === id).map(e => {
          children.push(getDepartmentNode(e.id, false))
        })
      }

      const depNode = {
        id: dep.id,
        person: {
          id: dep.id,
          avatar: "/assets/images/unknown-company-icon.png",
          department: "",
          name: dep.name,
          title: `${dep.employees?.length} colaboradores`,
          //totalReports: data.EmployeeList.filter(e => e.manager && e.manager.id === emp.id).length
          totalReports: 0,
          //link: `/employees/${emp.id}`
        },
        hasChild: data.DepartmentList.filter(e => e.parentDepartment?.id === dep.id).length > 0,
        hasParent: dep.parentDepartment && dep.parentDepartment?.id !== null,
        children: children
      }

      console.log(depNode)

      return depNode;
    }

    const getChild = id => {
      let childTree = [];
      data.DepartmentList.filter(e => e.parentDepartment?.id === id).map(e => {
        const depNode = getDepartmentNode(e.id, false);
        console.log(depNode)
        if(depNode) {
          childTree.push(depNode)
        }
      })
      console.log(childTree)
      return childTree;
    };
  
    const getParent = d => {
      const currentDep = data.DepartmentList.filter(e => e.id === d.id)[0]
      return getDepartmentNode(currentDep.parentDepartment?.id, true)
    };
  
    const handleDownload = () => {
      setDownloadingChart(false);
    };

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading, error, data } = useQuery(GET_DEPARTMENTS_QUERY);
    //if (loading || viewerLoading) return <LoadingOverlayWrapper />;

    const departments = (data && data.DepartmentList) || [];
    
    
    const [searchParams, setSearchParams] = useSearchParams();
    const departmentId = searchParams.get('departmentId');

    let initialDepartmentTree = null;

    if(departmentId) {
      initialDepartmentTree = getDepartmentNode(departmentId, false);
    } else {
      //const currentDepartment = departments.length > 0 && viewerData && departments.filter(e => e.id === viewerData?.Viewer?.employee?.department?.id) && departments.filter(e => e.id === viewerData?.Viewer?.employee?.department?.id)[0]
      const currentDepartment = departments.length > 0 && departments.filter(d => d.parentDepartment == null)[0]
      initialDepartmentTree = currentDepartment && getDepartmentNode(currentDepartment.id, false);
    }

    if(!tree && initialDepartmentTree) {
      setTree(initialDepartmentTree)
    }

    //console.log("tree>>", tree);

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title="Organograma - Departamentos" color="success"/>
                                
                                { loading && <LoadingOverlayWrapper /> }
                                
                                <div className="row ps-2 pe-1" style={{height: '1000px'}}>
                                  {tree && 
                                  <OrgChart
                                    tree={tree}
                                    downloadImageId={downloadImageId}
                                    downloadPdfId={downloadPdfId}
                                    onConfigChange={config => {
                                      setOrgChartConfig(config);
                                    }}
                                    loadConfig={d => {
                                      console.log(stateRef.current)
                                      return stateRef.current;
                                    }}
                                    downlowdedOrgChart={d => {
                                      handleDownload();
                                    }}
                                    loadImage={d => {
                                      return Promise.resolve(() => d.person.avatar);
                                    }}
                                    loadParent={d => {
                                      console.log('loadParent')
                                      const parentData = getParent(d);
                                      return parentData;
                                    }}
                                    loadChildren={d => {
                                      console.log('loadChildren')
                                      const childrenData = getChild(d.id);
                                      return childrenData;
                                    }}
                                  />
                                  }

                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default DepartmentOrgChart;