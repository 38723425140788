import React, { Fragment, useState } from 'react';

import { useQuery, gql, useMutation } from '@apollo/client';

import { getImageUrl } from '../utils';

import { toast } from "react-toastify";
import { resizeImage } from '../utils/resize-image';
import { UPLOAD_IMAGE_ACCEPT_FILETYPES, UPLOAD_MAX_FILE_SIZE } from '../config';
import CustomLoadingOverlay from './CustomLoadingOverlay';

const GET_QUERY = gql`
    query Get($id: String!) {
        MediaGalleryById(id: $id) {
            id
            items {
                id
                name
                file
                displayOrder
            }
        }
    }
`;

const UPSERT_MEDIA_GALLERY_MUTATION = gql`
    mutation Upsert($name: String!) {
        upsert_MediaGallery(mediaGallery: { name: $name }) {
            id
        }
    }
`;

const DELETE_MEDIA_ITEM_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_MediaItem(id: $id)
    }
`;

const UPSERT_MEDIA_ITEM_MUTATION = gql`
    mutation Upsert($galleryId: UUID, $file: Upload) {
        upsert_MediaItem(mediaItem: { gallery: { id: $galleryId }, file: $file }) {
            id
        }
    }
`;

function AdminMediaGallery({ mediaGallery, associateMediaGallery, refetch }) {

    const [isUploadInProgress, setIsUploadInProgress] = useState(false)
    const [upsertMediaGalleryMutation, { upsertMediaGalleryMutationData }] = useMutation(UPSERT_MEDIA_GALLERY_MUTATION);
    const [upsertMediaItemMutation, { upsertMediaItemMutationData }] = useMutation(UPSERT_MEDIA_ITEM_MUTATION);
    const [deleteMediaItemMutation, { deleteMediaItemMutationData }] = useMutation(DELETE_MEDIA_ITEM_MUTATION);
    
    const deleteMediaItem = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a imagem?')) {
            const deleteResult = await deleteMediaItemMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Imagem eliminada com sucesso!')
                refetch()
            }
        }
    }

    const onMediaGalleryFileChange = async (event) => { 
        setIsUploadInProgress(true);

        let galleryId = mediaGallery?.id;

        if(!galleryId) {
            const upsertGalleryResult = await upsertMediaGalleryMutation({ 
                variables: {
                    name: 'new gallery',
                },
            });
    
            if(upsertGalleryResult.data) {
                galleryId = upsertGalleryResult.data.upsert_MediaGallery.id

                await associateMediaGallery(galleryId);
    
            } else {
                toast.error('Ocorreu um erro ao adicionar a imagem!')
            }
        }

        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 1280);
        } catch (err) {
            console.log(err);
        }

        if(file.size > UPLOAD_MAX_FILE_SIZE) {
            toast.error('A imagem excede o tamanho permitido!')
            setIsUploadInProgress(false);
            return;
        }

        const upsertResult = await upsertMediaItemMutation({ 
            variables: {
                galleryId,
                file: file
            },
        });

        if(upsertResult.data) {
            toast.success('Imagem adicionada com sucesso!')
            await refetch();
        } else {
            toast.error('Ocorreu um erro ao adicionar a imagem!')
        }
        setIsUploadInProgress(false)
    };

    return (
        <Fragment>
            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                <div className="card-body p-0">
                    <h3 className="font-md text-grey-900 fw-600">Galeria</h3>
                </div>
            </div>
            
            <div className="row ps-2 pe-2">
                
                { isUploadInProgress && <div className="col-lg-2 col-md-6 pe-2 ps-2">
                    <CustomLoadingOverlay active={true} />
                </div>}

                {mediaGallery?.items?.slice().sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((value , index) => (
                    <div key={index} className="col-lg-2 col-md-6 pe-2 ps-2">
                        <div className="card p-3 bg-white w-100 hover-card border-0 shadow-xss rounded-xxl border-0 mb-3 overflow-hidden ">
                            <div className="card-image w-100">
                                <img src={getImageUrl(value.file)} className="w-100 rounded-3 aspect-ratio-385-300" style={{objectFit: 'cover'}} />
                            </div>
                            <div className="card-body p-0">
                                <a role="button" className='font-xsssss fw-700 ps-3 pe-3 lh-32 float-right mt-4 text-uppercase rounded-3 ls-2 bg-danger d-inline-block text-white me-1' onClick={() => deleteMediaItem(value.id)}><i className="feather-trash me-2"></i> Eliminar</a>
                            </div>
                        </div>
                    </div>
                ))}    
            </div>

            <div className="row">
                <div className="col-lg-12 mb-3">
                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                        <div className="form-group mb-0 w-100">
                            <input type="file" name="file" id="file" className="input-file" onChange={onMediaGalleryFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                            <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                            <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                            <span className="js-fileName">Clique para selecionar uma imagem</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AdminMediaGallery;