import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from "../../../../components/Appfooter";
import { getImageUrl } from "../../../../utils";
import { resizeImage } from "../../../../utils/resize-image";
import { UPLOAD_IMAGE_ACCEPT_FILETYPES } from "../../../../config";

const UPSERT_MUTATION = gql`
    mutation Upsert($id: UUID, $name: String!, $description: String, $email: String, $phone: String, $mobile: String, $websiteUrl: String, $logo: Upload, $coverPicture: Upload) {
        upsert_BenefitPartner(benefitPartner: { id: $id, name: $name, description: $description, email: $email, phone: $phone, mobile: $mobile, websiteUrl: $websiteUrl, logo: $logo, coverPicture: $coverPicture }) {
            id
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        BenefitPartnerById(id: $id) {
            id
            name
            description
            email
            phone
            mobile
            websiteUrl
            logo
            coverPicture
        }
    }
`;

function AdminNewBenefitPartner() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [selectedFileLogo, setSelectedFileLogo] = useState(null);
    const [selectedFilecoverPicture, setSelectedFilecoverPicture] = useState(null);

    const inputFileLogo = useRef(null);
    const inputFilecoverPicture = useRef(null);

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [upsertMutation, { upsertMutationData }] = useMutation(UPSERT_MUTATION);
    const { data: editData, loading: editLoading, error: editError } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});

    useEffect(() => {
        reset(editData?.BenefitPartnerById);
    }, [editData]);

    const upsert = async (formData) => {
        formData.logo = selectedFileLogo || editData?.logo;
        formData.coverPicture = selectedFilecoverPicture || editData?.coverPicture;
        
        const upsertResult = await upsertMutation({ 
            variables: {
                id: id,
                ...formData
            },
        });

        if(upsertResult.data) {
            toast.success('Alterações guardadas!');
            navigate(`/admin/benefits/partners`)
        }
    }

    const onLogoFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 480);
        } catch (err) {
            console.log(err);
        }
        setSelectedFileLogo(file)
    };

    const oncoverPictureFileChange = async(event) => { 
        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 720);
        } catch (err) {
            console.log(err);
        }
        setSelectedFilecoverPicture(file)
    };

    return (
        <Fragment> 

        <div className="main-wrapper">

            <AdminHeader />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
        
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link onClick={() => navigate(-1)} className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Parceiro</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit(upsert)}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Nome</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("name", { required: true })} />
                                                    {errors.name && <span className="text-red">Este campo é obrigatório</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Descrição</label>
                                                    <textarea className="form-control mb-0 p-3 h100 lh-16" rows="5" {...register("description")} ></textarea>
                                                    {errors.description && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Email</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("email")} />
                                                    {errors.email && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telefone</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("phone")} />
                                                    {errors.phone && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Telemóvel</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("mobile")} />
                                                    {errors.mobile && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <label className="mont-font fw-600 font-xsss">Website</label>
                                                    <input type="text" className="form-control" autoComplete="off" {...register("websiteUrl")} />
                                                    {errors.websiteUrl && <span className="text-red">Verifique este campo</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Imagem</label>
                                                            <input type="file" name="fileLogo" id="fileLogo" ref={inputFileLogo} className="input-file" onChange={onLogoFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!editData?.BenefitPartnerById?.logo || selectedFileLogo) && <>
                                                            <label htmlFor="fileLogo" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFileLogo ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFileLogo ? selectedFileLogo.name : 'Clique para selecionar uma imagem' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { editData?.BenefitPartnerById?.logo && !selectedFileLogo && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFileLogo.current.click()}><img src={getImageUrl(editData?.BenefitPartnerById?.logo)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="card mt-3 border-0">
                                                    <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                        <div className="form-group mb-0 w-100">
                                                            <label className="mont-font fw-600 font-xsss">Imagem de Capa</label>
                                                            <input type="file" name="fileCoverImage" id="fileCoverImage" ref={inputFilecoverPicture} className="input-file" onChange={oncoverPictureFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                                                            { (!editData?.BenefitPartnerById?.coverPicture || selectedFilecoverPicture) && <>
                                                            <label htmlFor="fileCoverImage" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                            <i className={`${selectedFilecoverPicture ? 'ti-file' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                                                            <span className="js-fileName">{ selectedFilecoverPicture ? selectedFilecoverPicture.name : 'Clique para selecionar uma imagem' }</span>
                                                            </label>
                                                            </> }
                                                            <br />
                                                            { editData?.BenefitPartnerById?.coverPicture && !selectedFilecoverPicture && <figure className="mb-0 mt-2 w100 pointer" onClick={() => inputFilecoverPicture.current.click()}><img src={getImageUrl(editData?.BenefitPartnerById?.coverPicture)} className="rounded-3 w-100" /></figure> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mb-0">
                                                <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" onClick={handleSubmit(upsert)}>Guardar</a>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>            
            </div>

            <Appfooter />
        </div>

        </Fragment>
    );
}

export default AdminNewBenefitPartner;