import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import Moment from 'react-moment';

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getProfilePicture } from '../../utils';
import { config, GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                jobTitle
                phone
                birthdate
                picture
            }
        }
    }
`;

const GET_QUERY = gql`
    query Get {
        DirectMessages {
            id
            sender {
                id
                name
                picture
            }
            receiver {
                id
                name
                picture
            }
            content
            createdDate
            readDate
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query Get {
        EmployeeList(
            limit: ${GRAPHQL_MAX_RESULTS_LIMIT},
            filter: { AND: [
                { user: { id: { _isNull: false }}}
                { isActive: { _eq: true }}
            ] },
            orderBy: { name: ASC }
        ) {
            id
            name
            user {
              id
            }
        }
    }
`;

function MessageList() {
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { loading: listsLoading, error: listsError, data: listsData } = useQuery(GET_LISTS_QUERY);
    const { data, loading, error } = useQuery(GET_QUERY, {
        pollInterval: config.messages.POLLING_INTERVAL_MILLIS,
    });

    const navigate = useNavigate();
    const [showEmployees, setShowEmployees] = useState(false)

    const viewer = viewerData?.Viewer;
    const conversations = data?.DirectMessages || [];

    (viewerLoading || loading) && <Load />

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <CustomLoadingOverlay active={loading}>
                                    <Pagetitle title="Mensagens" />
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <div className="float-right">
                                                <a role="button" className="p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setShowEmployees(true)}>Nova Mensagem</a>
                                            </div>
                                        </div>
                                    </div>

                                    { showEmployees && 
                                    <div className="row">
                                        <div className="search-form-2 mb-3" style={{zIndex: 999}}>
                                            <ReactSearchAutocomplete
                                                items={listsData?.EmployeeList}
                                                //onSearch={handleOnSearch}
                                                //onHover={handleOnHover}
                                                onSelect={(item) => navigate(`/messages/${item.user?.id}`)}
                                                //onFocus={handleOnFocus}
                                                //autoFocus
                                                placeholder="Pesquisa..."
                                                formatResult={(item) => {
                                                    return (
                                                        <>
                                                            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">{item.name}</h6>
                                                        </>
                                                    )
                                                }}
                                                showNoResults={true}
                                                showNoResultsText="Sem resultados"
                                            />
                                        </div>
                                    </div> }

                                    <ul className="email-message">
                                        {conversations.slice()
                                        .sort(function (a, b) {
                                            const d1 = new Date(a.createdDate).getTime()
                                            const d2 = new Date(b.createdDate).getTime()
                                            return d2 - d1;
                                        }).map((value , index) => (        
                                        <li key={index} >
                                            <Link to={`/messages/${value.sender.id === viewer?.id ? value.receiver.id : value.sender.id}`} className={`rounded-3 ${value.sender.id !== viewer?.id  && !value.readDate ? 'bg-lightblue theme-light-bg' : ''}`} >
                                                {false && <div className="form-check mt-1">
                                                    <input className="form-check-input" type="checkbox" id="blankCheckbox1" value="option1" />
                                                    <label className="text-grey-500 font-xssss" htmlFor="blankCheckbox1"></label>
                                                </div>}
                                                <div className="email-user">
                                                    {false && <span className="btn-round-xss ms-0 bg-success me-2"></span>}
                                                    {true && <img src={getProfilePicture(value.sender.id === viewer?.id ? value.receiver : value.sender)} alt="user" className="w35 me-2" />  }
                                                    <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">{value.sender.id === viewer?.id ? value.receiver.name : value.sender.name}</h6>
                                                </div>
                                                {false && <div className="email-subject text-grey-900 text-dark fw-600 font-xssss">
                                                    <i className="feather-star font-xss text-warning me-2"></i>
                                                    {value.content}
                                                </div>}
                                                <div className="email-text text-grey-500 fw-600 font-xssss">{value.sender.id === viewer?.id ? 'Eu: ': ''}{value.content.length > 100 ? value.content.substring(0, 100) + "..." : value.content}</div>
                                                {false && value.attach ?  <span className="email-file"><i className="feather-paperclip font-xss btn-round-sm text-grey-500 me-2 p-0"></i></span> : ''}
                                                <div className="email-time text-grey-500 fw-600"><Moment fromNow>{value.createdDate}</Moment></div>
                                            </Link>
                                        </li>

                                        ))}
                                    </ul>
                                    
                                    {!loading && conversations.length === 0 && <p className="text-grey-500 text-center">Não existem mensagens.</p>}
                                    </CustomLoadingOverlay>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default MessageList;