import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import Moment from 'react-moment';

import { toast } from "react-toastify";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import Load from '../../components/Load';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import { getProfilePicture } from "../../utils";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                jobTitle
                phone
                birthdate
            }
        }
    }
`;

const GET_FEEDBACK_REQUEST_QUERY = gql`
    query GetFeedbackRequests($employeeId: UUID) {
        FeedbackRequestList(filter: { 
            AND: [
                { recipient: { id: { _eq: $employeeId } } },
                { isDeclined: { _eq: false } },
                { feedbackReply: { _isNull: true } }
            ] }, orderBy: { createdDate: DESC }) {
            id
            requester {
                id
                name
                picture
                user {
                    id
                    picture
                }
            }
            message
            createdDate
        }
    }
`;

const UPSERT_DECLINE_FEEDBACK_REQUEST_MUTATION = gql`
    mutation UpsertFeedbackRequest($id: UUID!) {
        upsert_FeedbackRequest(feedbackRequest: { id: $id, isDeclined: true }) {
            id
        }
    }
`;

function FeedbackRequestList() {
    
    const { loading: viewerLoading, error: viewerError, data: viewerData } = useQuery(GET_VIEWER_QUERY);
    const { data: feedbackRequestsData, loading: feedbackRequestsLoading, error: feedbackRequestsError, refetch: feedbackRequestsRefetch } = useQuery(GET_FEEDBACK_REQUEST_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "cache-first",
        variables: { employeeId: viewerData && viewerData.Viewer && viewerData.Viewer.employee && viewerData.Viewer.employee.id },
        skip: !(viewerData && viewerData.Viewer && viewerData.Viewer.employee)
    });

    const [upsertDeclineFeedbackRequestMutation, { upsertDeclineFeedbackRequestMutationData }] = useMutation(UPSERT_DECLINE_FEEDBACK_REQUEST_MUTATION);
    
    const requests = feedbackRequestsData && feedbackRequestsData.FeedbackRequestList || [];

    const declineRequest = async (id) => {
        const upsertResult = await upsertDeclineFeedbackRequestMutation({ 
            variables: {
                id
            },
        })

        if(upsertResult.data) {
            toast.success('Pedido recusado!');
        }
        
        await feedbackRequestsRefetch();
    }

    console.log(feedbackRequestsData)
      
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <Pagetitle title="Pedidos de Feedback" color="success" />

                                <CustomLoadingOverlay active={feedbackRequestsLoading}>
                                {requests.map((value , index) => (

                                <div key={index} className="card d-block w-100 border-0 mb-3 shadow-xss bg-white rounded-3 pe-4 pt-4 pb-4" style={{paddingLeft: "120px"}} id={`b-${value.id}`}>
                                    <Link to={`/employees/${value.requester.id}`}><img src={getProfilePicture(value.requester)} alt={value.requester.name} className="position-absolute p-2 rounded-circle w75 ms-4 left-0" /></Link>
                                    <i className="feather-message-square font-md text-grey-500 position-absolute right-0 me-3"></i>
                                    <h4 className="font-xss fw-700 text-grey-900 mb-2 pe-4"><Link to={`/employees/${value.requester.id}`}>{value.requester.name}</Link></h4>
                                    <h5 className="font-xssss mb-2 text-grey-500 fw-600"><span className=" fw-500text-grey-500 font-xssss"><Moment format="DD \d\e MMMM \d\e YYYY">{ value.createdDate }</Moment></span></h5>
                                    <p className="text-grey-900">{value.message}</p>
                                    <div className="card-body d-flex align-items-center p-0">
                                        <Link to={`/feedback/give?requestId=${value.id}`} className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Responder</Link>
                                        <a role="button" className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => declineRequest(value.id)}>Recusar</a>
                                    </div>
                                </div>

                                ))}

                                {!feedbackRequestsLoading && requests.length === 0 && <p className="text-grey-500 text-center">Não existem pedidos pendentes.</p>}
                                </CustomLoadingOverlay>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 
        </Fragment>
    );
}

export default FeedbackRequestList;