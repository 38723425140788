import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import ShopContext from "../../context/shop-context";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';

import { getImageUrl } from "../../utils";
import MarketHeader from "../../components/MarketHeader";

const GET_PRODUCT_QUERY = gql`
    query GetProduct($id: String!) {
        ProductById(id: $id) {
            id
            name
            description
            sku
            thumbnail
            price
            stock
            category {
                id
                name
            }
            createdDate
        }
    }
`;

function ProductSingle() {
    
    let { id } = useParams();

    const { loading, error, data } = useQuery(GET_PRODUCT_QUERY, { variables: { id: id }});

    const product = data && data.ProductById;

    const hasStock = !(product?.stock == 0);

    return (
        <ShopContext.Consumer>
            {context => (
            <Fragment> 
                <Header />
                <Leftnav />

                { loading && <LoadingOverlayWrapper/> }

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row">
                                <div className="col-lg-12">
                                    <MarketHeader title="Market" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-5 mb-4 shop-slider">
                                    <div className="pt-lg--10 pb-lg--10 bg-white rounded-3">
                                        <img src={getImageUrl(product?.thumbnail, '/assets/images/product.png')} alt={product?.name} className="rounded-3 img-fluid w-100" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-12 pad-top-lg-200 pad-bottom-lg-100 pad-top-100 pad-bottom-75 ps-md--5">
                                    <h4 className="text-danger font-xssss fw-700 ls-2">{ product?.category?.name }</h4>
                                    <h2 className="fw-700 text-grey-900 display1-size lh-3 porduct-title display2-md-size"> { product?.name } </h2>

                                    <div className="clearfix"></div>
                                    <p className="font-xsss fw-400 text-grey-500 lh-30 pe-5 mt-3 me-5">{ product?.description }</p>

                                    <h6 className="display2-size fw-700 text-current ls-2 mb-2">{ product?.price } <i className="icon-empathize-square ms-1"></i></h6>
                                    
                                    <div className="clearfix"></div>
                                    <form action="#" className="form--action mt-4 mb-3">
                                        <div className="product-action flex-row align-items-center">
                                            {false && <div className="quantity me-3">
                                                <input type="number" className="quantity-input" name="qty" id="qty"  min="1" />
                                                <div className="dec qtybutton">-</div>
                                                <div className="inc qtybutton">+</div>
                                            </div>}
                                            
                                            { hasStock && <a role="button" className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-left border-dark border rounded-3 border-size-md d-inline-block mt-0 p-3 text-center ls-3" onClick={context.addProductToCart.bind(this, product)}>Adicionar ao Carrinho</a> }
                                            { !hasStock && <a role="button" className="add-to-cart bg-grey text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-left rounded-3 d-inline-block mt-0 p-3 text-center ls-3">Indisponível</a> }
                                        </div>  
                                    </form>
                                    <div className="clearfix"></div>
                                    <ul className="product-feature-list mt-5">
                                        { product?.category && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left"><b className="text-grey-900"> Categoria: </b>{ product?.category?.name }</li> }
                                        { false && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left">Straight fit</li> }
                                        { product?.sku && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left"><b className="text-grey-900">SKU: </b> { product?.sku }</li> }
                                        { false && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left">Dry clean</li> }
                                        { false && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left"><b className="text-grey-900">Tags : </b>Design, Toys</li> }
                                        { false && <li className="w-50 lh-32 font-xsss text-grey-500 fw-500 float-left">Cutton shirt</li> }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Appfooter />
            </Fragment>
            )}
        </ShopContext.Consumer>
    );

}

export default ProductSingle;