import { useNavigate } from 'react-router';

import Moment from 'react-moment';

import { getProfilePicture } from '../../utils';
import Comments from "./Comments";
import CommentsChange from "./CommentsChange";

export default function Comment({ comment, currentUser, type, commentsEnabled, handleCreateReply, onCommentDeleted }) {
    const {
        id,
        content,
        createdAt,
        score,
        voted,
        user,
        avatar,
        replies
    } = comment;

    const navigate = useNavigate();

    const goToProfile = () => {
        if(user && user.employee) {
            navigate(`/employees/${user.employee.id}`)
        }
    }

    return (
        <article className={`card-body bg-transparent-card p-0 rounded-xxl ps-0 pe-0 ${type === 'comment' ? 'mb-0 pb-0' : 'ms-4 mb-5'}`}>
            <div className="card-body p-0 d-flex">
                <figure className="avatar me-3 mb-2 pointer" onClick={goToProfile}><img src={getProfilePicture(user)} alt={user.name} className="shadow-sm rounded-circle w45 aspect-ratio-1" /></figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer" onClick={goToProfile}> {user.name} {user.id === currentUser && <span className="circle-count bg-success mt-0 p-1 text-white rounded-3 font-xsssss">Autor</span>} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> <Moment fromNow>{ comment.createdDate }</Moment></span></h4>
            </div>
            <div className={`fw-500 font-xssss w-100 mb-0 bg-grey ${type === 'comment' ? "p-2" : "p-1"} ps-3 pe-3 rounded-xxl`}>{content}</div>
            <div className="">
                <CommentsChange
                type={type}
                id={id}
                user={user}
                currentUser={currentUser}
                commentsEnabled={commentsEnabled}
                handleCreateReply={handleCreateReply}
                onCommentDeleted={onCommentDeleted}
                />
            </div>
            {replies?.length > 0 && (
                <Comments comments={replies} currentUser={currentUser} commentsEnabled={commentsEnabled} onCommentDeleted={onCommentDeleted} type="replies" />
            )}
        </article>
    );
}
