import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import { Modal } from "react-bootstrap";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import Moment from "react-moment";
import { getAttachmentUrl, getProfilePicture } from "../../../utils";
import { COMPLAINT_STATUS_OPTIONS } from "../../../config";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            email
            employee {
                id
                phone
                email
            }
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        ComplaintCategoryList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const GET_QUERY = gql`
    query Get($id: String!) {
        ComplaintById(id: $id) {
            id
            user {
                id
                name
                employee {
                    id
                    picture
                }
            }
            name
            phone
            email
            subject
            description
            category {
                id
                name
            }
            severity
            status
            createdDate
            attachments {
                id
                file
                name
            }
            messages {
                id
                sender {
                    id
                    name
                    employee {
                        id
                        picture
                    }
                }
                isUserReply
                content
                createdDate
                attachments {
                    id
                    file
                    name
                }
            }
            notes {
                id
                user {
                    id
                    name
                    employee {
                        id
                        picture
                    }
                }
                content
                createdDate
                attachments {
                    id
                    file
                    name
                }
            }
        }
    }
`;

const UPDATE_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID!, $status: ComplaintStatus!) {
        upsert_Complaint(complaint: { id: $id, status: $status }) {
            id
        }
    }
`;

const UPSERT_COMPLAINT_MESSAGE_MUTATION = gql`
    mutation UpsertComplaintMessage($complaintId: UUID!, $senderId: UUID!, $content: String!) {
        upsert_ComplaintMessage(complaintMessage: { complaint: { id: $complaintId }, sender: { id: $senderId }, content: $content }) {
            id
        }
    }
`;

const UPSERT_COMPLAINT_MESSAGE_ATTACHMENT_MUTATION = gql`
    mutation UpsertComplaintMessageAttachment($messageId: UUID, $file: Upload) {
        upsert_ComplaintMessageAttachment(complaintMessageAttachment: { message: { id: $messageId }, file: $file }) {
            id
            name
        }
    }
`;

const UPSERT_COMPLAINT_NOTE_MUTATION = gql`
    mutation UpsertComplaintNote($complaintId: UUID!, $userId: UUID!, $content: String!) {
        upsert_ComplaintNote(complaintNote: { complaint: { id: $complaintId }, user: { id: $userId }, content: $content }) {
            id
        }
    }
`;

const UPSERT_COMPLAINT_NOTE_ATTACHMENT_MUTATION = gql`
    mutation UpsertComplaintNoteAttachment($noteId: UUID, $file: Upload) {
        upsert_ComplaintNoteAttachment(complaintNoteAttachment: { note: { id: $noteId }, file: $file }) {
            id
            name
        }
    }
`;


const UpdateStatusModal = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [updateStatusMutation, { updateStatusMutationData }] = useMutation(UPDATE_STATUS_MUTATION);

    const updateStatus = async (formData) => {
        const upsertResult = await updateStatusMutation({ 
            variables: {
                id: props.id,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success('Alterações guardadas!');
            props.onHide();
        }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Alterar Estado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(updateStatus)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Perfil</label>
                            <select className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("status", { required: true })}>
                                <option value=''>Selecionar...</option>
                                {
                                    COMPLAINT_STATUS_OPTIONS.map((e) => {
                                        return (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.status && <span className="text-red">Este campo é obrigatório</span>}
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(updateStatus)}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}


function AdminComplaint() {

    const navigate = useNavigate();

    let { id } = useParams();

    const [statusModalShow, setStatusModalShow] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const [noteAttachments, setNoteAttachments] = useState([]);

    const { register: messageRegister, handleSubmit: messageHandleSubmit, formState: { errors: messageErrors }, reset: messageReset } = useForm();
    const { register: noteRegister, handleSubmit: noteHandleSubmit, formState: { errors: noteErrors }, clearErrors: noteClearErrors, reset: noteReset } = useForm();

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);

    const [upsertComplaintMessageMutation, { upsertComplaintMessageMutationData }] = useMutation(UPSERT_COMPLAINT_MESSAGE_MUTATION);
    const [upsertComplaintMessageAttachmentMutation, { upsertComplaintMessageAttachmentMutationData }] = useMutation(UPSERT_COMPLAINT_MESSAGE_ATTACHMENT_MUTATION);

    const [upsertComplaintNoteMutation, { upsertComplaintNoteMutationData }] = useMutation(UPSERT_COMPLAINT_NOTE_MUTATION);
    const [upsertComplaintNoteAttachmentMutation, { upsertComplaintNoteAttachmentMutationData }] = useMutation(UPSERT_COMPLAINT_NOTE_ATTACHMENT_MUTATION);

    const createComplaintMessage = async (formData) => {
        const upsertResult = await upsertComplaintMessageMutation({ 
            variables: {
                complaintId: id,
                senderId: viewerData.Viewer.id,
                content: formData.message,
            },
        });

        if(upsertResult.data) {
            attachments.map(async a => {
                await uploadAttachment(upsertResult.data.upsert_ComplaintMessage.id, a)
            })
            messageReset({
                message: ""
            });
            setAttachments([])
            toast.success('Mensagem enviada com sucesso!');
            //navigate(`/whistleblowing/complaints`)
            refetch();
        }
    }

    const uploadAttachment = async (messageId, file) => {
        const upsertResult = await upsertComplaintMessageAttachmentMutation({ 
            variables: {
                messageId: messageId,
                file: file,
            },
        });
    }

    const onFileChange = event => { 
        setAttachments(prevState => [...prevState, event.target.files[0]])
    };

    const removeAttachment = name => {
        setAttachments(prevState => prevState.filter(a => a.name !== name))
    }

    const createComplaintNote = async (formData) => {
        const upsertResult = await upsertComplaintNoteMutation({ 
            variables: {
                complaintId: id,
                userId: viewerData.Viewer.id,
                ...formData,
            },
        });

        if(upsertResult.data) {
            noteAttachments.map(async a => {
                await uploadNoteAttachment(upsertResult.data.upsert_ComplaintNote.id, a)
            })
            noteReset();
            setNoteAttachments([])
            toast.success('Nota adicionada com sucesso!');
            refetch();
        }
    }

    const uploadNoteAttachment = async (noteId, file) => {
        const upsertResult = await upsertComplaintNoteAttachmentMutation({ 
            variables: {
                noteId,
                file,
            },
        });
    }

    const onNoteFileChange = event => { 
        setNoteAttachments(prevState => [...prevState, event.target.files[0]])
    };

    const removeNoteAttachment = name => {
        setNoteAttachments(prevState => prevState.filter(a => a.name !== name))
    }

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <CustomLoadingOverlay active={loading} />
                                    <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                            <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xssss">Denúncia</h6>
                                                        <h2 className="font-xl text-grey-900 fw-600">{data?.ComplaintById?.subject}</h2>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Denunciante</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Utilizador
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.user?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.name || '(anónimo)'}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.email}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telefone
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.phone}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-4 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Detalhes</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-8 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Categoria
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.category?.name}</span></h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Registo
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.createdDate && <Moment format="DD/MM/YYYY HH:mm">{data?.ComplaintById?.createdDate}</Moment>}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Assunto
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.subject}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Descrição
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.description}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                            <h4 className="fw-700 text-grey-900 font-xsss mt-0">Severidade
                                                            <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.ComplaintById?.severity}</span></h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col-lg-4 mb-3">
                                                        <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Estado
                                                                <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">
                                                                    <span className={`d-inline-block p-1 ps-2 pe-2 ${COMPLAINT_STATUS_OPTIONS.filter(s => s.id === data?.ComplaintById?.status)[0]?.className} font-xssss rounded-3`}><strong>{COMPLAINT_STATUS_OPTIONS.filter(s => s.id === data?.ComplaintById?.status)[0]?.name}</strong></span>
                                                                </span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                    <form className="d-block overflow-hidden">
                                                        <a role="button" className={`p-2 ps-3 pe-3 lh-20 bg-dark border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => setStatusModalShow(true)}> Alterar Estado</a>
                                                    </form>
                                                </div>

                                                <div className="card dark-bg-transparent border-0 w-100 p-0 mt-3 mb-2 shadow-none">
                                                    <div className="card-body p-0">
                                                        <h6 className="fw-600 text-grey-500 font-xs">Anexos</h6>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <ul>
                                                        {data?.ComplaintById?.attachments.map(attachment => (
                                                            <li key={attachment.id}>
                                                                <a href={getAttachmentUrl(attachment.file)} className="d-inline font-xssss fw-600 ls-1 text-grey-700 text-dark mb-2">
                                                                    <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                        </ul>
                                                        {data?.ComplaintById?.attachments?.length === 0 && <p><span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">Não existem anexos</span></p>}
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                            <div className="card-body p-0">
                                                                <h3 className="font-md text-grey-900 fw-600">Mensagens</h3>
                                                                <p className="mont-font fw-500 text-grey-500 font-xsss">As mensagens são visíveis para o denunciante</p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                                                        <div className="chat-body p-lg-3 p-3 mt-lg-3 mt-0 messages-content">
                                                                            {data?.ComplaintById?.messages?.length === 0 && <p className="text-center">Ainda não existem mensagens nesta denúncia.</p>}
                                                                
                                                                            {data?.ComplaintById?.messages?.slice()
                                                                            .sort(function (a, b) {
                                                                                const d1 = new Date(a.createdDate).getTime()
                                                                                const d2 = new Date(b.createdDate).getTime()
                                                                                return d1 - d2;
                                                                            })
                                                                            .map(m => (
                                                                            <div key={m.id} className={`message-item mb-0 ${m.isUserReply ? '' : ' outgoing-message '}`}>
                                                                                <div className="message-user">
                                                                                    <figure className="avatar">
                                                                                        <img src={getProfilePicture(m.sender) || '/assets/images/user.png'} alt={m.sender?.name || '(Utilizador)'} />
                                                                                    </figure>
                                                                                    <div>
                                                                                        <h5 className="font-xssss mt-2">{m.sender?.name || '(Utilizador)'}</h5>
                                                                                        <div className="time">{m.createdDate && <Moment format="DD/MM/YYYY HH:MM">{m.createdDate}</Moment>}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="message-wrap shadow-none">{m.content}</div>
                                                                                <ul className="mt-1 mb-3">
                                                                                    {m.attachments?.map(attachment => (
                                                                                        <li key={attachment.id}>
                                                                                            <a href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark mb-1">
                                                                                                <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <form onSubmit={messageHandleSubmit(createComplaintMessage)}>
                                                            <div className="row px-3">
                                                                <h5 className="mont-font fw-600 font-xs">Nova Mensagem</h5>
                                                                <div className="col-lg-12 mt-2">
                                                                    <div className="form-group">
                                                                        <label className="mont-font fw-600 font-xsss">Mensagem</label>
                                                                        <textarea className="form-control h100 theme-dark-bg lh-24" rows={5} {...messageRegister("message", { required: true })} />
                                                                        {noteErrors.message && <span className="text-red">Este campo é obrigatório</span>}
                                                                    </div>
                                                                </div>                                     
                                                            </div>

                                                            <div className="row px-3">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="card border-0">
                                                                        <label className="mont-font fw-600 font-xsss">Anexos</label>
                                                                        <ul className="mb-0">
                                                                        {attachments.map((a, index) => (
                                                                            <li key={index}><i className="font-xss text-grey-500 feather-paperclip me-2"></i> {a.name}<a role="button" onClick={() => removeAttachment(a.name)}><i className="font-xss text-grey-500 feather-x ms-2"></i></a></li>
                                                                        ))}
                                                                        </ul>
                                                                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                                            <div className="form-group mb-0 w-100">
                                                                                <input type="file" name="file" id="file" className="input-file" onChange={onFileChange} />
                                                                                <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                                                <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                                                <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row px-3">
                                                                <div className="col-lg-12 mb-0">
                                                                    <a role="button" className=" float-right bg-current text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block" onClick={messageHandleSubmit(createComplaintMessage)}>Enviar mensagem</a>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div className="col-lg-6 mb-3">
                                                        <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                            <div className="card-body p-0">
                                                                <h3 className="font-md text-grey-900 fw-600">Notas</h3>
                                                                <p className="mont-font fw-500 text-grey-500 font-xsss">As notas não são visíveis para o denunciante</p>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                                                        <div className="chat-body p-lg-3 p-3 mt-lg-3 mt-0">
                                                                            <Accordion className="accodion-style--1 accordion" preExpanded={'0'} allowMultipleExpanded={true}>
                                                                                {data?.ComplaintById?.notes?.length === 0 && <p className="text-center">Ainda não existem notas nesta denúncia.</p>}
                                                            
                                                                                {data?.ComplaintById?.notes?.slice()
                                                                                .sort(function (a, b) {
                                                                                    const d1 = new Date(a.createdDate).getTime()
                                                                                    const d2 = new Date(b.createdDate).getTime()
                                                                                    return d1 - d2;
                                                                                })
                                                                                .map(note => (
                                                                                <AccordionItem className="shadow-none mb-0 p-0 bg-transparent">
                                                                                    <AccordionItemHeading className="card-header ps-0 pt-0 pb-2 pe-0 bg-transparent">
                                                                                        <AccordionItemButton className="d-flex">
                                                                                            <figure className="avatar me-2 mb-0 w35 mt-1 pt-1"><img src={getProfilePicture(note.user)} alt={note.user.name} className="float-right shadow-sm rounded-circle w-100" /></figure>
                                                                                            <span className="font-xssss text-grey-700 pt-2 mt-1 ps-2 fw-700 mb-0 me-auto text-dark">{note.user.name}</span>
                                                                                            <span className="font-xssss text-grey-500 pt-2 mt-1 ps-2 fw-600 mb-0 ms-auto">{note.createdDate && <Moment format="DD/MM/YYYY HH:mm">{note.createdDate}</Moment>}</span>
                                                                                        </AccordionItemButton>
                                                                                    </AccordionItemHeading>
                                                                                    <AccordionItemPanel className="card-body p-0">
                                                                                        <p className="text-grey-600 mb-4 font-xsss lh-28 fw-500 mt-3 text-dark">{ note.content }</p>
                                                                                        <ul className="mt-1 mb-3">
                                                                                            {note.attachments?.map(attachment => (
                                                                                                <li key={attachment.id}>
                                                                                                    <a href={getAttachmentUrl(attachment.file)} className="d-block font-xssss fw-600 ls-1 text-grey-700 text-dark mb-1">
                                                                                                        <i className="font-xsss text-grey-500 feather-paperclip me-2"></i><span>{ attachment.name || attachment.file }</span>
                                                                                                    </a>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </AccordionItemPanel>
                                                                                </AccordionItem>
                                                                                ))}
                                                                            </Accordion>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <form onSubmit={noteHandleSubmit(createComplaintNote)}>
                                                            <div className="row px-3">
                                                                <h5 className="mont-font fw-600 font-xs">Nova Nota</h5>
                                                                <div className="col-lg-12 mt-2">
                                                                    <div className="form-group">
                                                                        <label className="mont-font fw-600 font-xsss">Nota</label>
                                                                        <textarea className="form-control h100 theme-dark-bg lh-24" rows={5} {...noteRegister("content", { required: true })} />
                                                                        {noteErrors.content && <span className="text-red">Este campo é obrigatório</span>}
                                                                    </div>
                                                                </div>                                     
                                                            </div>

                                                            <div className="row px-3">
                                                                <div className="col-lg-12 mb-3">
                                                                    <div className="card border-0">
                                                                        <label className="mont-font fw-600 font-xsss">Anexos</label>
                                                                        <ul className="mb-0">
                                                                        {noteAttachments.map((a, index) => (
                                                                            <li key={index}><i className="font-xss text-grey-500 feather-paperclip me-2"></i> {a.name}<a role="button" onClick={() => removeNoteAttachment(a.name)}><i className="font-xss text-grey-500 feather-x ms-2"></i></a></li>
                                                                        ))}
                                                                        </ul>
                                                                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                                            <div className="form-group mb-0 w-100">
                                                                                <input type="file" name="noteFile" id="noteFile" className="input-file" onChange={onNoteFileChange} />
                                                                                <label htmlFor="noteFile" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                                                                <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                                                                                <span className="js-fileName">Clique para selecionar um ficheiro</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row px-3">
                                                                <div className="col-lg-12 mb-0">
                                                                    <a role="button" className=" float-right bg-current text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block" onClick={noteHandleSubmit(createComplaintNote)}>Adicionar</a>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                
                                                
                                                

                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <UpdateStatusModal
                    show={statusModalShow}
                    onHide={() => { 
                        setStatusModalShow(false);
                        refetch();
                    }}
                    id={data?.ComplaintById?.id}
                />

                <Appfooter /> 

            </Fragment>
        </RBACWrapper>
    );
}

export default AdminComplaint;