import React, { Fragment } from "react";

import { useQuery, gql } from '@apollo/client';

import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

const GET_FAQS_QUERY = gql`
query GetFaqs {
    FaqList(orderBy: { displayOrder: ASC }) {
        id
        question
        answer
        displayOrder
        category {
            id
            name
        }
    }
  }
`;

function FAQs() {

    const { data, loading, error } = useQuery(GET_FAQS_QUERY);
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />
            
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center" 
                                style={{backgroundImage: `url("/assets/images/help-bg.png")`}}>
                                    <div className="card-body p-md-5 p-4 text-center bg-primary-gradiant">
                                        <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Ajuda</h2>
                                        <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">Nesta página, pode encontrar as respostas para as perguntas mais frequentes. </p>
                                        { false && <div className="form-group w-lg-75 mt-4 border-light border p-1 bg-white rounded-3 ms-auto me-auto">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div className="form-group icon-input mb-0">
                                                        <i className="ti-search font-xs text-grey-400"></i>
                                                        <input type="text" className="style1-input border-0 ps-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent" placeholder="Search anythings.." />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <a href="helpbox" className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase ">Search</a>
                                                </div>
                                            </div>
                                        </div> }
                                    </div>
                                </div>
                                <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                                    <CustomLoadingOverlay active={loading}>
                                        <Accordion className="accodion-style--1 accordion" preExpanded={'0'}>
                                            { data?.FaqList?.map(faq => (
                                                <AccordionItem key={faq.id} className="card shadow-xss">
                                                    <AccordionItemHeading className="card-header">
                                                        <AccordionItemButton>
                                                            <h5 className="fw-600 pt-2 pb-2 mb-0">{faq.question}</h5>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel className="card-body">
                                                        <p>{faq.answer}</p>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </CustomLoadingOverlay>
                                    {!loading && data?.FaqList?.length == 0 && <p className="text-center">Não existem perguntas frequentes.</p> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter /> 

        </Fragment>            
    );
}

export default FAQs;