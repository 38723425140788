import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Appfooter from '../../components/Appfooter';
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";

import { useQuery, gql, useMutation } from '@apollo/client';

import track, { useTracking } from "react-tracking";

import 'react-image-lightbox/style.css';
import Pagetitle from "../../components/Pagetitle";
import LearningItemView from "../../components/LearningItemView";
import LearningItemsWidget from "../../components/LearningItemsWidget";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            name
            employee {
                id
                jobTitle
                company {
                    id
                    name
                }
            }
        }
    }
`;


const GET_LEARNING_VIDEO_QUERY = gql`
    query GetLearningVideo($id: String!) {
        LearningVideoById(id: $id) {
            id
            title
            description
            thumbnail
            thumbnailExternalUrl
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            url
            language {
                id
                name
            }
            duration
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        picture
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            picture
                            user {
                                id
                                picture
                            }
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
        }
    }
`;

const GET_LEARNING_COURSE_QUERY = gql`
    query GetLearningCourse($id: String!) {
        LearningCourseById(id: $id) {
            id
            title
            description
            thumbnail
            thumbnailExternalUrl
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            url
            language {
                id
                name
            }
            duration
            isPaid
            hasCertificate
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        picture
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            picture
                            user {
                                id
                                picture
                            }
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
        }
    }
`;

const GET_LEARNING_PODCAST_QUERY = gql`
    query GetLearningPodcast($id: String!) {
        LearningPodcastById(id: $id) {
            id
            title
            description
            thumbnail
            thumbnailExternalUrl
            category {
                id
                name
            }
            source {
                id
                name
            }
            author {
                id
                name
            }
            url
            language {
                id
                name
            }
            duration
            createdDate
            comments {
                id
                content
                user {
                    id
                    name
                    picture
                    employee {
                        id
                        name
                        jobTitle
                        picture
                        user {
                            id
                            picture
                        }
                    }
                }
                createdDate
                replies {
                    id
                    content
                    user {
                        id
                        name
                        picture
                        employee {
                            id
                            name
                            jobTitle
                            picture
                            user {
                                id
                                picture
                            }
                        }
                    }
                    createdDate
                }
            }
            reactions {
                id
                type
                user {
                    id
                }
            }
        }
    }
`;

const GET_LATEST_LEARNING_ITEMS_QUERY = gql`
    query GetLatestLearningItems($id: UUID, $categoryId: UUID) {
        LearningItemList(filter: [{ id: { _neq: $id } }, { category: { id: { _eq: $categoryId } } }, { isVisible: { _eq: true } }], orderBy: { createdDate: DESC }, limit: 12) {
            id
            title
            thumbnail
            thumbnailExternalUrl
            duration
            author {
                id
                name
            }
        }
    }
`;

const UPSERT_LEARNING_ITEM_REACTION_MUTATION = gql`
    mutation UpsertLearningItemReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_LearningItemReaction(learningItemReaction:{ learningItem: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_LEARNING_ITEM_REACTION_MUTATION = gql`
    mutation DeleteLearningItemReaction($id: String!) {
        delete_LearningItemReaction(id: $id)
    }
`;

const UPSERT_LEARNING_ITEM_COMMENT_MUTATION = gql`
    mutation UpsertLearningItemComment($id: UUID, $content: String, $userId: UUID) {
        upsert_LearningItemComment(learningItemComment: {
            learningItem: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

function LearningItemSingle() {

    let { id } = useParams();

    const { trackEvent } = useTracking();
    
    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);
    const { loading: loadingLearningVideo, error: errorLearningVideo, data: dataLearningVideo, refetch: refetchLearningVideo } = useQuery(GET_LEARNING_VIDEO_QUERY, { variables: { id: id }});
    const { loading: loadingLearningCourse, error: errorLearningCourse, data: dataLearningCourse, refetch: refetchLearningCourse } = useQuery(GET_LEARNING_COURSE_QUERY, { variables: { id: id }});
    const { loading: loadingLearningPodcast, error: errorLearningPodcast, data: dataLearningPodcast, refetch: refetchLearningPodcast } = useQuery(GET_LEARNING_PODCAST_QUERY, { variables: { id: id }});

    const categoryId = (dataLearningVideo && dataLearningVideo.LearningVideoById && dataLearningVideo.LearningVideoById.category.id) || (dataLearningCourse && dataLearningCourse.LearningCourseoById && dataLearningCourse.LearningCourseById.category.id) || dataLearningPodcast?.LearningPodcastById?.category.id
    const { data: latestLearningItemsData, loading: latestLearningItemsLoading, error: latestLearningItemsError } = useQuery(GET_LATEST_LEARNING_ITEMS_QUERY, {
        variables: { id: id, categoryId: categoryId  },
        skip: !categoryId
    });

    const [upsertLearningItemReactionMutation, { upsertLearningItemReactionMutationData }] = useMutation(UPSERT_LEARNING_ITEM_REACTION_MUTATION);
    const [deleteLearningItemReactionMutation, { deleteLearningItemReactionMutationData }] = useMutation(DELETE_LEARNING_ITEM_REACTION_MUTATION);

    const [upsertLearningItemCommentMutation, { upsertLearningItemCommentMutationData }] = useMutation(UPSERT_LEARNING_ITEM_COMMENT_MUTATION);
    const [upsertCommentReplyMutation, { upsertCommentReplyMutationData }] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    useEffect(() => {
        trackEvent({ event: "view", customData: id });
    }, [])

    //if (postsLoading && !postsData) return <LoadingOverlayWrapper/>;
    //if(viewerLoading) return <LoadingOverlayWrapper />;

    const video = dataLearningVideo && dataLearningVideo.LearningVideoById;
    const course = dataLearningCourse && dataLearningCourse.LearningCourseById;
    const podcast = dataLearningPodcast?.LearningPodcastById;
    
    const handleLike = async (id) => {
        await upsertLearningItemReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }

    const handleLove = async (id) => {
        await upsertLearningItemReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }

    const handleDeleteReaction = async (reactionId) => {
        await deleteLearningItemReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }

    const handleCreateComment = async (id, content) => {
        await upsertLearningItemCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }

    const onItemUpdated = async (id, content) => {
        video && await refetchLearningVideo();
        course && await refetchLearningCourse();
    }
    
    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <CustomLoadingOverlay active={loadingLearningVideo || loadingLearningCourse} />
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" style={{maxWidth: '1200px'}}>
                        <div className="row">
                            <div className="col-xs-12">
                                <Pagetitle title="Learning" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">

                                { video && (
                                    <LearningItemView video={video} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply}  handleLike={() => handleLike(video.id)} handleLove={() => handleLove(video.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} onCommentDeleted={onItemUpdated} />
                                )}
                                { course && (
                                    <LearningItemView course={course} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply}  handleLike={() => handleLike(course.id)} handleLove={() => handleLove(course.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} onCommentDeleted={onItemUpdated} />
                                )}
                                { podcast && (
                                    <LearningItemView podcast={podcast} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply}  handleLike={() => handleLike(course.id)} handleLove={() => handleLove(course.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} onCommentDeleted={onItemUpdated} />
                                )}

                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <LearningItemsWidget title="Também pode gostar" items={latestLearningItemsData && latestLearningItemsData.LearningItemList}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter /> 

        </Fragment>
    );
}

//export default LearningItemSingle;

export default track(
    { page: "LearningItem" },
    //{ dispatchOnMount: contextData => ({ event: "pageLoaded" }) }
)(LearningItemSingle);