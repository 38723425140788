import React, { Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql, useMutation } from '@apollo/client';

import { RBACWrapper } from "react-simple-rbac";

import Moment from "react-moment";
import { toast } from "react-toastify";

import AdminHeader from '../../../../components/AdminHeader';
import Leftnav from '../../../../components/Leftnav';
import Appfooter from '../../../../components/Appfooter';
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay";
import Pagetitle from "../../../../components/Pagetitle";


const GET_TRAINING_ENTITIES_QUERY = gql`
    query GetTrainingEntities {
        TrainingEntityList {
            id
            name
            description
            type {
                id
                name
            }
            fiscalNumber
            address
            phone
            email
            website
            isActive
        }
    }
`;

const UPSERT_TRAINING_ENTITY_MUTATION = gql`
    mutation UpsertTrainingEntity($id: UUID, $isActive: Boolean!) {
        upsert_TrainingEntity(trainingEntity:{ id: $id, isActive: $isActive }) {
            id
        }
    }
`;

const DELETE_TRAINING_ENTITY_MUTATION = gql`
    mutation DeleteTrainingEntity($id: String!) {
        delete_TrainingEntity(id: $id)
    }
`;

function AdminTrainingEntityList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data, refetch } = useQuery(GET_TRAINING_ENTITIES_QUERY);

    const [upsertTrainingEntityMutation, { upsertTrainingEntityMutationData }] = useMutation(UPSERT_TRAINING_ENTITY_MUTATION);
    const [deleteTrainingEntityMutation, { deleteTrainingEntityMutationData }] = useMutation(DELETE_TRAINING_ENTITY_MUTATION);

    const updateTrainingEntityStatus = async (id, isActive) => {
        const upsertResult = await upsertTrainingEntityMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Entitidade ${isActive ? 'ativada' : 'desativada'}`)
        }
    }

    const deleteTrainingEntity = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a entidade?')) {
            const deleteResult = await deleteTrainingEntityMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Entidade eliminada com sucesso!')
            }
        }
    }

    const trainingEntities = (data && data.TrainingEntityList.slice().sort(function (a, b) {
        const d1 = new Date(a.createdDate).getTime()
        const d2 = new Date(b.createdDate).getTime()
        return d1 - d2;
    })) || [];

    return (
        <RBACWrapper requiredRoles={['admin']} oneOf>
            <Fragment> 
                <AdminHeader />
                <Leftnav />

                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 ps-lg-3 pe-lg-3" style={{maxWidth: '100%'}}>
                            <div className="row">
                                <div className="col-xl-12 mb-4">
                                    <Pagetitle title="Entidades Formadoras"/>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ trainingEntities.length } resultados</h4>
                                            { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                            <Link to="/admin/training/trainingEntities/new" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Nova Entidade</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <CustomLoadingOverlay active={loading} />

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-4 text-left">Nome</th>
                                                            <th className="border-0 p-4">NIF</th>
                                                            <th className="border-0 p-4">Telefone</th>
                                                            <th className="border-0 p-4">Email</th>
                                                            <th className="border-0 p-4">Ativa?</th>
                                                            <th className="border-0 p-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trainingEntities.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column ps-4">
                                                                <h3>
                                                                    <span className="text-grey-900 fw-600 font-xsss"><Link to={`/admin/training/trainingEntities/${value.id}`}>{value.name}</Link></span>
                                                                </h3>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.fiscalNumber}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.phone}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.email}</span>
                                                            </td>
                                                            <td className="text-center"><i className={`${value.isActive ? ' ti-check-box text-success ' : ' ti-na text-danger '} font-xs`}></i></td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => updateTrainingEntityStatus(value.id, !value.isActive)}><i className={`${!value.isActive ? ' ti-check text-success ' : ' ti-na text-danger '} font-xs me-2`}></i></a>
                                                                <Link to={`/admin/training/trainingEntities/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                                <a role="button" onClick={() => deleteTrainingEntity(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Appfooter /> 
            </Fragment>
        </RBACWrapper>
    );
}

export default AdminTrainingEntityList;